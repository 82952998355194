<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Approve Time, Deliveries, &amp; Expenses</p>
      </header>
      <section class="modal-card-body">
        <!-- Select Period -->
        <div class="mb-lg">
          <b-field
            label="Billing Period"
            :type="('period' in errors) ? 'is-danger' : ''"
            :message="('period' in errors) ? errors['period'][0] : ''">
            <b-select
              v-model="showPeriod"
              placeholder="Select Funding"
              size="is-medium"
              disabled
              expanded>
              <option value="1">{{ $dates(period.start_date).format('MMMM YYYY') }}</option>
            </b-select>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button type="button" @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Approve Time &amp; Expenses</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api']),
    json () {
      const json = {
        billing_period_id: this.period.id,
        project_id: this.project.id,
        billables: this.billables
      }
      return json
    }
  },
  data () {
    return {
      showPeriod: 1,
      billables: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.errors = {}
      const endpoint = this.api.route + '/admin/projects/' + this.project.id + '/actions/approve'
      this.$http.put(endpoint, this.json).then(response => {
        this.$emit('project:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Pending items approved!' })
        this.$parent.close()
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {

  },
  props: {
    period: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
