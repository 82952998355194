<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-half">
        <!-- Multiple Contexts -->
        <div v-if="auth.user.roles.length > 0">
          <div v-for="role in auth.user.roles" :key="role.id">
            <div class="has-background-white pa-md mb-xs rounded-lg">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p class="is-size-5 has-text-weight-bold">{{ role.resource.name }}</p>
                  <p class="has-text-grey-light">
                    {{ $utils.upperFirst(role.resource_type) }}
                    {{ $utils.upperFirst(role.type.name) }}
                  </p>
                </div>
                <div class="column is-narrow">
                  <b-button type="is-primary" outlined @click="setContext(role)">Select</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Missing Context -->
        <div v-else>
          <div class="has-background-white py-xl mb-xs rounded-lg has-text-centered">
            <div class="mb-lg">
              <p>You don't have access to any accounts.</p>
              <p>Please contact support@gather.co for further assistance.</p>
            </div>
            <p class="has-text-primary is-size-2 is-marginless">401</p>
            <p class="has-text-grey-light">Error Code</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {

    }
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    setContext (role) {
      this.$store.dispatch('setRole', role).then(() => {
        const route = {
          name: (role.resource_type === 'network') ? 'network.projects' : 'partner.projects'
        }
        this.$router.push({ name: route.name, params: { code: role.resource.code } })
      })
    }
  },
  mounted: function () {

  }
}
</script>
