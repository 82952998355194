<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <div class="pa-lg">
        <div class="has-text-centered mb-lg">
          <p class="mb-sm">
            <Logo :width="130" height="46" />
          </p>
          <p class="has-text-grey">Application Error</p>
        </div>
        <div v-if="statusCode === 404" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">404 Not Found</p>
          <p class="has-text-grey">The requested data could not be found. If you
            need immediate assistance, please contact {{ app.supportEmail }}.
          </p>
        </div>
        <div v-else-if="statusCode === 403" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">403 Unauthorized</p>
          <p class="has-text-grey">You don't have permission to perform
            the requested action. If you think you're receiving this message in
            error, please contact {{ app.supportEmail }}.
          </p>
        </div>
        <div v-else-if="statusCode === 504" class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">504 Timeout</p>
          <p class="has-text-grey">Your request took longer than 30 seconds, so our servers have timed out. If
            possible, try to limit your data requests using filters. If you
            need a large data set, or think you're receiving this message in
            error, please contact {{ app.supportEmail }}.
          </p>
        </div>
        <div v-else class="has-text-centered">
          <p class="is-size-4 mb-lg has-text-danger">500 Application Error</p>
          <p class="has-text-grey">
            Our system was unabled to process your request. This is likely
            not your fault. Our team has been notified. If you
            need immediate assistance, please contact {{ app.supportEmail }}.
          </p>
        </div>
        <!-- Debug -->
        <div v-if="app.serverError !==  null && app.serverError.data && app.debug" class="is-size-8 has-text-grey-light has-text-centered pt-lg">
          {{ app.serverError.data.message }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/components/Logos/GatherWorks'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['app']),
    statusCode () {
      return (this.app.serverError !== null && this.app.serverError.status)
        ? this.app.serverError.status
        : 500
    }
  }
}
</script>
