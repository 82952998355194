<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Submit Timesheet?</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-md">
          <div class="has-text-centered py-xl">
            <p>Are you sure you are ready to submit your final hours?</p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Submit Completed Hours</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  methods: {
    save () {
      this.$emit('submit:hours')
      this.$parent.close()
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    timesheet: {
      type: Object,
      required: true
    }
  }
}
</script>
