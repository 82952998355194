<template>
  <div class="has-background-white rounded-lg pa-md has-text-centered">
    <p class="has-text-weight-bold mb-md">Authentication Error</p>
    <p class="has-text-grey is-size-7 mb-lg">
      We're sorry, but we don't recognize the email address you are using to try and sign in. At this time, GatherWorks is invite-only and
      you must use an email address from a connected partner.
    </p>
    <div class="has-text-centered">
      <p class="mb-sm"><router-link :to="{ name: 'auth.login' }">Return to Sign In</router-link></p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
