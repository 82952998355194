<template>
  <div>
    <div class="mb-md">
      <div class="has-background-white pa-md has-shadow">
        <b-table
          :data="contributors"
          :mobile-cards="false"
          class="is-size-7"
          hoverable>
          <b-table-column
            label="Contributor"
            v-slot="props">
            <p>
              <router-link
                class="has-text-weight-bold"
                :to="{
                  name: 'network.partner.contributor',
                  params: {
                    code: context.code,
                    connection: connection.id,
                    contributor: props.row.id
                  }
                }"
              >{{ props.row.role.user.full_name }}</router-link>
            </p>
          </b-table-column>
          <b-table-column
            cell-class="nowrap"
            label="Approved On"
            centered
            width="140"
            v-slot="props">
            <div v-if="props.row.approved_at">
              <p class="has-text-grey-light">{{ $dates.utc(props.row.approved_at).local().format('MMM D, YYYY') }}</p>
            </div>
            <div v-else>
              <p class="has-text-grey-light">--</p>
            </div>
          </b-table-column>
          <b-table-column
            cell-class="nowrap"
            label="Status"
            centered
            width="140"
            v-slot="props">
              <span v-if="props.row.approved_at" class="has-text-weight-bold has-text-success">Approved</span>
              <span v-else class="has-text-weight-bold has-text-danger">Pending</span>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/connections/' + this.connection.id + '/contributors'
    }
  },
  data () {
    return {
      loading: true,
      contributors: [],
      selected: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.contributors = response.data.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
