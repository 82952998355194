<template>
  <span>{{ output }}</span>
</template>

<script>
export default {
  computed: {
    output () {
      return (this.spend) ? this.$numbers(this.spend).formatCurrency({ mantissa: 0 }) : '--'
    }
  },
  props: ['spend']
}
</script>
