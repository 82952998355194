<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <p class="is-size-5 has-text-weight-bold mb-md">
            Change Project Dates
          </p>
          <div class="is-size-7">
            <p v-if="project.started_at">
              This project started on {{ $dates(project.start_date).format('MMM D, YYYY') }} and is scheduled
              to end on {{ $dates(project.end_date).format('MMM D, YYYY') }}. You may extend (or shorten) the
              project by entering a new end date below.
            </p>
            <p v-else>
              You can change the duration of this project by updating the dates below.
              Please note that any allocations or  billing events may be removed
              if they are not scheduled within the new date range.
            </p>
          </div>
        </b-message>
        <div class="columns">
          <div class="column">
            <b-field
              label="Start Date"
              :type="('start_date' in errors) ? 'is-danger' : ''"
              :message="('start_date' in errors) ? errors['start_date'][0] : ''">
              <b-input
                size="is-medium"
                type="date"
                v-model="startDate"
                :min="minStartDate"
                :max="maxStartDate"
                :readonly="project.started_at"
                :disabled="project.started_at"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="End Date"
              :type="('end_date' in errors) ? 'is-danger' : ''"
              :message="('end_date' in errors) ? errors['end_date'][0] : ''">
              <b-input
                size="is-medium"
                type="date"
                v-model="endDate"
                :min="minEndDate"
                :max="maxEndDate"
              ></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between has-background-white">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Update Project</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.startDate && this.endDate)
    },
    json () {
      return {
        start_date: this.startDate,
        end_date: this.endDate
      }
    },
    minStartDate () {
      return this.$dates().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
    },
    maxStartDate () {
      return (this.endDate) ? this.endDate : null
    },
    minEndDate () {
      return (this.startDate) ? this.startDate : null
    },
    maxEndDate () {
      return this.$dates().add(13, 'month').startOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      startDate: null,
      endDate: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/actions/reschedule'
      this.$http.put(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.startDate = this.project.start_date
    this.endDate = this.project.end_date
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
