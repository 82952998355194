<template>
  <div class="section">
    <div class="container">
      <!-- Client Loaded -->
      <div v-if="!loading && client !== null">
        <Breadcrumbs :history="breadcrumbs" current="Client Detail" />
        <!-- Header Loaded -->
        <div class="has-background-white rounded-lg px-lg py-md mb-lg">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <p class="is-size-5 has-text-weight-bold">{{ client.name }}</p>
              <p class="has-text-grey is-size-7 has-text-grey-light">Client ID: {{ client.id }}</p>
            </div>
            <!-- <div class="column is-narrow has-text-right">
              <p class="is-size-5 has-text-weight-bold">
                <StatusPresenter :connection="connection" />
              </p>
            </div> -->
          </div>
        </div>
        <!-- Actions -->
        <!-- <div class="has-background-white rounded-lg px-lg py-md mb-lg">
          Actions
        </div> -->
        <!-- Layout -->
        <div class="columns">
          <!-- Partner Menu -->
          <div class="column is-narrow">
            <b-menu class="pr-lg">
              <b-menu-list label="Client Menu">
                <b-menu-item
                  icon="clipboard-outline"
                  label="Summary"
                  tag="router-link"
                  :to="{ name: 'network.client', params: { code: context.code, id: client.id } }"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="contacts-outline"
                  label="Contacts"
                  tag="router-link"
                  :to="{ name: 'network.client.contacts', params: { code: context.code, id: client.id } }"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="card-account-details-outline"
                  label="Rate Card"
                  tag="router-link"
                  :to="{ name: 'network.client.rates', params: { code: context.code, id: client.id } }"
                  exact
                ></b-menu-item>
              </b-menu-list>
            </b-menu>
          </div>
          <!-- Partner Content -->
          <div class="column">
            <div>
              <router-view v-if="client !== null" :client="client" />
            </div>
          </div>
        </div>
      </div>
      <!-- Loading Skeleton -->
      <div v-else>
        <Loading message="Loading client..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Clients', name: 'network.clients', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/clients/' + this.$route.params.id
    }
  },
  data: function () {
    return {
      loading: true,
      client: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.client = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
