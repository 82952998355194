<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Announcements</p>
      </header>
      <section class="modal-card-body">
        <!-- Focus -->
        <div v-if="focusAnnouncement">
          <!-- Title -->
          <div class="border-b pb-md mb-md">
            <p class="has-text-weight-bold is-size-5">{{ focusAnnouncement.title }}</p>
            <p>
              {{ focusAnnouncement.author.full_name }}
              &bull;
              {{ $dates.utc(focusAnnouncement.created_at).local().format('MMM D, YYYY') }}
            </p>
          </div>
          <!-- Content -->
          <div class="content">
            <div v-html="compiledMarkdown"></div>
          </div>
        </div>
        <!-- No Announcements -->
        <div v-else class="py-md has-text-centered">
          <p><b-icon icon="check" custom-class="has-text-primary" size="is-large" /></p>
          <p>You're all caught up on announcements!</p>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving">
          {{ (announcements.length) ? 'Read Next' : 'Acknowledge'}}
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { marked } from 'marked'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    compiledMarkdown () {
      if (this.focusAnnouncement) {
        return marked(this.focusAnnouncement.markdown)
      }
      return ''
    }
  },
  data () {
    return {
      loadingAnnouncements: false,
      announcements: [],
      focusAnnouncement: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadAnnouncements () {
      this.loadingAnnouncements = true
      const endpoint = this.api.route + '/announcements'
      const query = {
        params: {
          unread: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.announcements = response.data.data
        if (this.announcements.length) {
          this.focusAnnouncement = this.announcements.shift()
        }
        this.loadingAnnouncements = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/announcements/' + this.focusAnnouncement.id + '/actions/acknowledge'
      this.$http.put(endpoint).then(response => {
        this.$store.dispatch('unreadAnnouncements', this.announcements.length)
        if (this.announcements.length) {
          this.focusAnnouncement = this.announcements.shift()
        } else {
          this.$parent.close()
        }
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadAnnouncements()
  }
}
</script>
