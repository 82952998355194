<template>
  <div>
    <!-- Header -->
    <div class="has-border-b pb-lg">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-5">Funding Distributions</p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="createDistribution">Add Funding Distribution</b-button>
        </div>
      </div>
    </div>
    <!-- Datatable -->
    <div>
      <b-table
        :data="funding.distributions"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="ID"
          header-class="is-size-8"
          width="48"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          label="Funding Line Item"
          header-class="is-size-8"
          v-slot="props">
          <span>{{ props.row.funding_line.description }}</span>
        </b-table-column>
        <b-table-column
          label="Budget"
          header-class="is-size-8"
          v-slot="props">
          <span>{{ props.row.budget.role }}</span>
        </b-table-column>
        <b-table-column
          label="Amount"
          header-class="is-size-8"
          centered
          width="128"
          v-slot="props">
          <Currency :value="props.row.amount" />
        </b-table-column>
        <b-table-column
          label="Status"
          header-class="is-size-8"
          centered
          width="128">
          <b-tag type="is-success is-light">Distributed</b-tag>
        </b-table-column>
        <b-table-column
          header-class="is-size-8"
          centered
          width="64"
          v-slot="props">
          <a href="#" @click.prevent="editDistribution(props.row)" class="is-size-8">Edit</a>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">No funding distributions added yet.</div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Update Schedule -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditDistributionModal"
      :can-cancel="['x', 'esc']"
      @close="resetModal"
      ><EditFundingDistributionModal :funding="funding" :distribution="focusDistribution" @funding:updated="$emit('funding:updated')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditFundingDistributionModal from '@/components/Models/Funding/Modals/EditDistribution'

export default {
  components: {
    EditFundingDistributionModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      focusDistribution: null,
      showEditDistributionModal: false
    }
  },
  methods: {
    createDistribution () {
      this.showEditDistributionModal = true
    },
    editDistribution (distribution) {
      this.focusDistribution = distribution
      this.showEditDistributionModal = true
    },
    resetModal () {
      this.focusDistribution = null
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
