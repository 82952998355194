<template>
  <div>
    <b-field
      v-if="file === null"
      label="Select File"
      :type="('file' in errors) ? 'is-danger' : ''"
      :message="('file' in errors) ? errors['file'][0] : ''"
      expanded>
      <b-upload
        v-model="file"
        drag-drop
        expanded
        :disabled="file !== null"
        @input="upload">
        <div class="has-text-centered py-xxl">
          <p><b-icon icon="upload" size="is-large"></b-icon></p>
          <p>Drop your files here or click to upload</p>
        </div>
      </b-upload>
    </b-field>
    <div v-if="file" class="has-background-light rounded-lg pa-md">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold is-size-7">File</p>
          <p class="is-size-7">{{ $utils.truncate(file.name, { length: 32 }) }}</p>
        </div>
        <div class="column">
          <div class="has-text-right is-size-7">
            <p :class="statusClass" class="has-text-weight-bold">{{ status }}</p>
            <p><a href="#" @click.prevent="reset" class="is-size-7">Clear File</a></p>
          </div>
        </div>
      </div>
      <!-- Error Message -->
      <div v-if="'file' in errors" class="is-size-7 has-text-danger">
        {{ errors['file'][0] }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.host + '/v1/tempfiles'
    }
  },
  data () {
    return {
      saving: true,
      file: null,
      errors: {},
      tempfile: null,
      status: 'Uploading...',
      statusClass: null
    }
  },
  methods: {
    upload () {
      this.saving = true
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.tag !== null) {
        formData.append('tag', this.tag)
      }
      this.$http.post(this.api.host + '/v1/tempfiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.status = 'Upload complete!'
        this.statusClass = 'has-text-success'
        this.tempfile = response.data
        this.$emit('input', this.tempfile)
      }).catch(error => {
        this.status = 'Upload Error'
        this.statusClass = 'has-text-danger'
        this.errors = error.response.data.errors
      }).finally(() => {
        this.saving = false
      })
    },
    reset () {
      this.errors = {}
      this.status = 'Uploading...'
      this.statusClass = null
      this.file = null
      this.tempfile = null
      this.$emit('input', null)
    }
  },
  props: {
    tag: {
      type: String
    }
  }
}
</script>
