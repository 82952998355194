<template>
  <b-dropdown
    ref="dropdown"
    class="has-text-left"
    position="is-bottom-left"
    :close-on-click="false"
    @active-change="reset">
    <template #trigger="{ active }">
      <b-button
        label="Options"
        type="is-primary"
        size="is-small"
        outlined
        :icon-right="active ? 'menu-up' : 'menu-down'" />
    </template>
    <b-dropdown-item @click="download">Download</b-dropdown-item>
    <b-dropdown-item v-if="!confirmRemoval" @click="confirm">Remove</b-dropdown-item>
    <b-dropdown-item v-else @click="remove" class="has-text-danger">Confirm Delete?</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.route + '/connections/' + this.attachment.connection_id + '/attachments/' + this.attachment.id
    }
  },
  data () {
    return {
      loading: true,
      confirmRemoval: false
    }
  },
  methods: {
    download () {
      this.loading = true
      this.$http.get(this.endpoint + '/download', { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: this.attachment.mime })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.attachment.filename
        link.click()
      }).finally(() => {
        this.$refs.dropdown.toggle()
        this.loading = false
      })
    },
    confirm () {
      this.confirmRemoval = true
    },
    remove () {
      this.loading = true
      this.$http.delete(this.endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Attachment Removed!' })
        this.$emit('attachment:removed')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Attachment could not be removed!' })
      }).finally(() => {
        this.$refs.dropdown.toggle()
        this.loading = false
      })
    },
    reset (status) {
      if (status) this.confirmRemoval = false
    }
  },
  props: ['attachment']
}
</script>
