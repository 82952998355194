<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <p class="is-size-5 has-text-weight-bold mb-md">Update Project Leader</p>
          <div class="is-size-7">
            <p class="mb-sm"><b>{{ project.lead.full_name }}</b> is the current project lead.
              Selecting a new project leader will automatically share this
              project with the new leader (if not already shared). You may
              also choose to remove access from the current leader.
            </p>
          </div>
        </b-message>
        <div class="mb-lg">
          <b-field
            class="mb-lg"
            label="Select New Project Lead"
            :type="('start_date' in errors) ? 'is-danger' : ''"
            :message="('start_date' in errors) ? errors['start_date'][0] : ''">
            <NetworkLeadSelect v-model="networkLead" />
          </b-field>
          <b-field>
            <b-checkbox v-model="removeAccess">Remove {{ project.lead.full_name }}'s access to this project?</b-checkbox>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between has-background-white">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Update Project Lead</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import NetworkLeadSelect from '@/components/Models/Role/NetworkLeaders'

export default {
  components: {
    NetworkLeadSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.networkLead && this.networkLead.user_id !== this.project.lead.id)
    },
    json () {
      return {
        network_lead_user_id: this.networkLead.id,
        remove_access: (this.removeAccess) ? '1' : '0'
      }
    }
  },
  data () {
    return {
      networkLead: null,
      removeAccess: 0,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/actions/reassign'
      this.$http.put(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.networkLead = this.project.lead
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
