<template>
  <div>
    <!-- Health Stats -->
    <div class="columns">
      <div class="column">
        <div class="box has-text-centered has-text-weight-bold">
          <p class="has-text-grey-light is-uppercase is-size-8">Starting Balance</p>
          <p class="has-text-weight-bold is-size-4">
            <Currency :value="funding.total_funding" />
          </p>
        </div>
      </div>
      <div class="column">
        <div class="box has-text-centered has-text-weight-bold">
          <p class="has-text-grey-light is-uppercase is-size-8">Funding Invoiced</p>
          <p class="has-text-weight-bold is-size-4">
            <Currency :value="funding.transactions_billed" />
          </p>
        </div>
      </div>
      <div class="column">
        <div class="box has-text-centered has-text-weight-bold">
          <p class="has-text-grey-light is-uppercase is-size-8">Funding Balance</p>
          <p class="has-text-weight-bold is-size-4">
            <Currency :value="funding.transactions_balance" />
          </p>
        </div>
      </div>
      <div class="column">
        <div class="box has-text-centered has-text-weight-bold">
          <p class="has-text-grey-light is-uppercase is-size-8">Actual Deliveries</p>
          <p class="has-text-weight-bold is-size-4">
            <Currency :value="funding.transactions_burn" />
          </p>
        </div>
      </div>
      <div class="column">
        <div class="box has-text-centered has-text-weight-bold">
          <p class="has-text-grey-light is-uppercase is-size-8">Prebill Balance</p>
          <p class=" is-size-4">
            <Currency :value="funding.prebill_balance" />
          </p>
        </div>
      </div>
    </div>
    <div class="mb-lg">
      <ClientInvoices :funding="funding" />
    </div>
  </div>
</template>

<script>
import ClientInvoices from '../components/Health/ClientInvoices'

export default {
  components: {
    ClientInvoices
  },
  computed: {

  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
