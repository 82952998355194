<template>
  <div>
    <!-- Table -->
    <div>
      <div class="has-background-white rounded-lg mb-xxs pa-md">
        <b-dropdown disabled class="mr-md">
          <template #trigger>
            <b-button label="Actions" icon-right="menu-down" />
          </template>
        </b-dropdown>
        <b-button type="is-primary" outlined @click="showEditTitleModal = true">Add Title</b-button>
      </div>
      <div style="overflow-x:auto" class="mb-md">
        <b-table
          :data="titles"
          :mobile-cards="false"
          :checked-rows.sync="selected"
          hoverable
          checkable
          class="is-size-7">
          <!-- <b-table-column
            label="Category"
            v-slot="props"
            width="164">
            <p>{{ props.row.category }}</p>
          </b-table-column> -->
          <b-table-column
            label="Title"
            v-slot="props">
            <p>{{ props.row.title }}</p>
          </b-table-column>
          <b-table-column
            cell-class="nowrap"
            label="Rate Card"
            centered
            width="140"
            v-slot="props">
            <p>{{ $numbers(props.row.rate_card).formatCurrency({ mantissa: 2 }) }}</p>
          </b-table-column>
          <b-table-column
            label=""
            centered
            width="140"
            v-slot="props">
            <b-button type="is-primary" outlined size="is-small" @click="focusTitle(props.row)">Edit</b-button>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered pa-lg">No titles or rate cards have been added yet.</div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Modal: Add Title -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditTitleModal"
      :can-cancel="['x', 'esc']"
      @close="title = null"
      ><EditTitleModal :client="client" :seed="title" @title:saved="load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTitleModal from '@/components/Models/ClientTitle/ClientTitleModal'
export default {
  components: {
    EditTitleModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/clients/' + this.client.id + '/titles'
    }
  },
  data () {
    return {
      loading: true,
      titles: [],
      title: null,
      selected: [],
      showEditTitleModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.titles = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    focusTitle (title) {
      this.title = title
      this.showEditTitleModal = true
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: ['client']
}
</script>
