<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Connection Loaded -->
        <div v-if="!loading && connection !== null">
          <Breadcrumbs :history="breadcrumbs" :current="connection.partner.name" />
          <!-- Layout -->
          <div class="columns">
            <!-- Partner Menu -->
            <div class="column is-narrow">
              <b-menu class="pr-lg">
                <b-menu-list label="Menu">
                  <b-menu-item
                    icon="clipboard-outline"
                    label="Summary"
                    tag="router-link"
                    :to="{ name: 'network.partner', params: { code: context.code, connection: connection.id } }"
                  ></b-menu-item>
                  <b-menu-item
                    icon="account-multiple-outline"
                    label="Contributors"
                    tag="router-link"
                    :to="{ name: 'network.partner.contributors', params: { code: context.code, connection: connection.id } }"
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
            <!-- Partner Content -->
            <div class="column">
              <div class="mb-lg">
                <div class="has-background-white has-shadow px-lg py-md mb-xxs">
                  <div class="columns is-vcentered is-mobile">
                    <div class="column">
                      <p class="is-size-5 has-text-weight-bold">{{ connection.partner.name }}</p>
                      <p class="has-text-grey is-size-7 has-text-grey-light">Connection ID: {{ connection.id }}</p>
                    </div>
                    <div class="column is-narrow has-text-right">
                      <p class="is-size-5 has-text-weight-bold">
                        <StatusPresenter :connection="connection" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <router-view
                  v-if="connection !== null"
                  :connection="connection"
                  @connection:updated="load(true)"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Loading Skeleton -->
        <div v-else>
          <Loading message="Loading connection" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Connection/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Partners', name: 'network.partners', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/connections/' + this.$route.params.connection
    }
  },
  data: function () {
    return {
      loading: true,
      connection: null
    }
  },
  methods: {
    load (refresh) {
      if (!refresh) {
        this.loading = true
      }
      this.$http.get(this.endpoint).then(response => {
        this.connection = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
