<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ this.seed !== null ? 'Update' : 'Add' }} Organization</p>
      </header>
      <section class="modal-card-body">
        <!-- Name -->
        <div class="mb-lg">
          <b-field
            label="Name"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''"
            expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="text"
              v-model="name"
            ></b-input>
          </b-field>
        </div>
        <!-- Owner -->
        <div class="mb-lg">
          <label class="label">Organization Owner</label>
          <RoleSelect v-model="owner" />
        </div>
        <!-- Fiscal Quarter -->
        <div class="mb-lg">
          <b-field
            label="Fiscal Year Start"
            :type="('fiscal_start_month' in errors) ? 'is-danger' : ''"
            :message="('fiscal_start_month' in errors) ? errors['name'][0] : ''"
            expanded>
            <b-select
              size="is-medium"
              type="text"
              v-model="fiscalStartMonth"
              placeholder="Select Month"
              expanded>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </b-select>
          </b-field>
        </div>
        <!-- Expense Type -->
        <div class="mb-lg">
          <p class="mb-md"><label class="label">Expense Approval</label></p>
          <b-field>
            <b-radio v-model="preapprovesExpenses" native-value="1">All expenses must be pre-approved by the client.</b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="preapprovesExpenses" native-value="0">Expenses do not require pre-approval.</b-radio>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import RoleSelect from '@/components/Models/Role/SelectMenu'

export default {
  components: {
    RoleSelect
  },
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (this.name)
    },
    endpoint () {
      const route = this.api.route + '/organizations'
      return (this.seed) ? route + '/' + this.seed.id : route
    },
    method () {
      return (this.seed !== null) ? 'patch' : 'post'
    },
    json () {
      const json = {
        name: this.name,
        fiscal_start_month: this.fiscalStartMonth,
        preapproves_expenses: this.preapprovesExpenses
      }
      if (this.owner) {
        json.owner_user_id = this.owner.user_id
      }
      return json
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Organization saved!' })
        this.$parent.close()
        this.$emit('organization:saved', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      name: null,
      fiscalStartMonth: null,
      preapprovesExpenses: 0,
      owner: null,
      saving: false,
      errors: []
    }
  },
  mounted () {
    if (this.seed) {
      this.name = this.seed.name
      if (this.seed.owner) {
        this.owner = {
          user_id: this.seed.owner.id,
          label: this.seed.owner.full_name
        }
      }
      this.fiscalStartMonth = this.seed.fiscal_start_month
      this.preapproves_expenses = (this.seed.preapproves_expenses) ? 1 : 0
    }
  },
  props: {
    seed: {
      type: Object,
      default: null
    }
  }
}
</script>
