export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter connections by status',
  options: [{
    value: 'active',
    label: 'Active'
  }, {
    value: 'draft',
    label: 'Draft'
  }, {
    value: 'paused',
    label: 'Paused'
  }, {
    value: 'completed',
    label: 'Completed'
  }]
}
