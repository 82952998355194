<template>
  <div>
    <div>
      <form v-if="!loading && delivery" @submit.prevent="save">
        <!-- Header & Plans -->
        <div class="has-background-white has-shadow pa-lg mb-xxs">
          <div class="border-b pb-md mb-lg">
            <div class="columns is-vcentered">
              <div class="column">
                <div class="is-size-5">
                  <p class="has-text-weight-bold">{{ delivery.title }}</p>
                  <p class="has-text-grey-light is-size-7">
                    <StatusPresenter :delivery="delivery" />
                  </p>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="has-text-right">
                  <router-link :to="{ name: 'network.project.lines', params: { code: context.code }}">&lt; Back to Project</router-link>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="field is-grouped">
                  <p class="control">
                    <b-button
                      v-if="delivery.submitted_at === null"
                      type="is-primary"
                      :disabled="!delivery.id"
                      @click="showFulfillDeliveryModal = true"
                    >Mark as Delivered</b-button>
                    <b-button
                      v-else-if="delivery.approved_at === null"
                      type="is-primary"
                      :disabled="!delivery.id"
                      @click="showApproveDeliveryModal = true"
                    >Approve</b-button>
                    <b-button
                      v-else
                      type="is-primary"
                      :disabled="!delivery.id"
                      @click="showUnapproveDeliveryModal = true"
                    >Remove Approval</b-button>
                  </p>
                  <p class="control">
                    <b-dropdown position="is-bottom-left">
                      <template #trigger="{ active }">
                        <b-button
                          type="is-primary"
                          :icon-right="active ? 'menu-up' : 'menu-down'"
                          outlined
                        >More</b-button>
                      </template>
                      <b-dropdown-item :disabled="isApproved" @click="showWithdrawDeliveryModal = true">Mark as Incomplete</b-dropdown-item>
                      <b-dropdown-item :disabled="!delivery.id || readonly" @click="showArchiveDeliveryModal = true">Archive Deliverable</b-dropdown-item>
                    </b-dropdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-sm mb-lg">
            <!-- Messaging -->
            <template>
              <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
                {{ errors.general[0] }}
              </b-message>
              <b-message v-else-if="isApproved" type="is-info" class="is-size-7">
                <b>Note:</b> This delivery was approved on {{ $dates(delivery.approved_at).format('MMM D, YYYY') }} and can no longer be updated unless approval is removed.
              </b-message>
              <b-message v-else-if="isSubmitted" type="is-info" class="is-size-7">
                <b>Note:</b> This delivery has been submitted for review and billing approval.
              </b-message>
            </template>
            <!-- Form -->
            <div class="columns is-multiline">
              <!-- Title -->
              <div class="column is-half">
                <b-field
                  label="Title"
                  :type="('title' in errors) ? 'is-danger' : ''"
                  :message="('title' in errors) ? errors['title'][0] : ''">
                  <b-input
                    v-model="title"
                    size="is-medium"
                    :readonly="readonly"
                    :disabled="readonly"
                  />
                </b-field>
              </div>
              <!-- Partner -->
              <div class="column is-half">
                <b-field
                  label="Partner"
                  :type="('partner_id' in errors) ? 'is-danger' : ''"
                  :message="('partner_id' in errors) ? errors['partner_id'][0] : ''">
                  <ConnectionSelect v-model="connection" :readonly="readonly" />
                </b-field>
              </div>
              <!-- Planned Delivery Date -->
              <div class="column is-half">
                <b-field
                  label="Planned Delivery Date"
                  :type="('planned_delivery_date' in errors) ? 'is-danger' : ''"
                  :message="('planned_delivery_date' in errors) ? errors['planned_delivery_date'][0] : ''">
                  <b-input
                    v-model="plannedDeliveryDate"
                    size="is-medium"
                    type="date"
                    :readonly="readonly"
                    :disabled="readonly"
                  />
                </b-field>
              </div>
              <!-- Reporter -->
              <div class="column is-half">
                <b-field
                  label="Partner Reporter"
                  :type="('reporting_partner_role_id' in errors) ? 'is-danger' : ''"
                  :message="('reporting_partner_role_id' in errors) ? errors['reporting_partner_role_id'][0] : ''">
                  <ConnectionContributorSelect
                    v-model="reporterId"
                    :connectionId="(connection) ? connection.id : null"
                    :readonly="readonly || connection === null"
                    />
                </b-field>
              </div>
              <!-- Estimated Hours-->
              <div class="column is-half">
                <b-field
                  label="Estimated Hours (optional)"
                  :type="('estimated_hours' in errors) ? 'is-danger' : ''"
                  :message="('estimated_hours' in errors) ? errors['estimated_hours'][0] : ''">
                  <b-input
                    v-model="hoursEstimate"
                    size="is-medium"
                    type="number"
                    :readonly="readonly"
                    :disabled="readonly" />
                </b-field>
              </div>
              <!-- Vendor (Legacy) -->
              <div class="column is-half">
                <b-field label="Vendor (Legacy)">
                  <b-input
                    v-model="vendor"
                    size="is-medium"
                    readonly
                    disabled />
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <!-- Rates -->
        <div class="has-background-white has-shadow pa-lg mb-xxs">
          <div class="columns is-mobile">
            <!-- Billable Fee -->
            <div class="column is-one-third">
              <div class="columns is-mobile">
                <div class="column">
                  <label class="label">Billable Fee</label>
                </div>
                <div v-if="(!readonly && calculate !== 'billRate')"  class="column is-narrow">
                  <a href="#" @click.prevent="calculate = 'billRate'"><b-icon icon="calculator" size="is-small" /></a>
                </div>
              </div>
              <b-field
                :type="('plan_bill_rate' in errors) ? 'is-danger' : ''"
                :message="('plan_bill_rate' in errors) ? errors['plan_bill_rate'][0] : ''">
                <p class="control"><span class="button is-medium border-none is-static" :class="(isSubmitted || calculate === 'billRate') ? 'border-none' : ''">$</span></p>
                <!-- Calculated -->
                <b-input
                  v-if="(readonly || calculate === 'billRate')"
                  size="is-medium"
                  type="text"
                  :value="calculatedBillRate"
                  expanded
                  readonly
                  disabled
                ></b-input>
                <!-- Input -->
                <b-input
                  v-else
                  size="is-medium"
                  type="text"
                  v-model="billRate"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <!-- Cost  -->
            <div class="column is-one-third">
              <div class="columns is-mobile">
                <div class="column">
                  <label class="label">Vendor Fee</label>
                </div>
                <div v-if="(!readonly && calculate !== 'costRate')" class="column is-narrow">
                  <a href="#" @click.prevent="calculate = 'costRate'"><b-icon icon="calculator" size="is-small" /></a>
                </div>
              </div>
              <b-field
                :type="('plan_cost_rate' in errors) ? 'is-danger' : ''"
                :message="('plan_cost_rate' in errors) ? errors['plan_cost_rate'][0] : ''">
                <p class="control"><span class="button border-none is-medium is-static" :class="(isSubmitted || calculate === 'costRate') ? 'border-none' : ''">$</span></p>
                <!-- Calculated -->
                <b-input
                  v-if="(readonly || calculate === 'costRate')"
                  size="is-medium"
                  type="text"
                  :value="calculatedCostRate"
                  expanded
                  readonly
                  disabled
                ></b-input>
                <!-- Input -->
                <b-input
                  v-else
                  size="is-medium"
                  type="text"
                  v-model="costRate"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <!-- Margin -->
            <div class="column is-one-third">
              <div class="columns is-mobile">
                <div class="column">
                  <label class="label">Margin <span class="is-size-8">(0-100%)</span></label>
                </div>
                <div v-if="(!readonly && calculate !== 'margin')" class="column is-narrow">
                  <a href="#" @click.prevent="calculate = 'margin'"><b-icon icon="calculator" size="is-small" /></a>
                </div>
              </div>
              <b-field
                :type="('margin' in errors) ? 'is-danger' : ''"
                :message="('margin' in errors) ? errors['margin'][0] : ''">
                <!-- Calculated -->
                <b-input
                  v-if="(readonly || calculate === 'margin')"
                  size="is-medium"
                  type="text"
                  :value="calculatedMargin"
                  expanded
                  readonly
                  disabled
                ></b-input>
                <!-- Input -->
                <b-input
                  v-else
                  size="is-medium"
                  type="number"
                  v-model="margin"
                  expanded
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <div class="has-background-white has-shadow px-lg py-md mb-xxs">
          <div class="columns">
            <div class="column is-one-quarter">
              <b-button
                native-type="submit"
                type="is-primary"
                size="is-medium"
                expanded
                :loading="saving"
                :disabled="readonly || saving || !ready"
              >Save Fee</b-button>
            </div>
          </div>
        </div>
      </form>
      <div v-else>
        Loading...
      </div>
    </div>
    <!-- Modal: Mark as Complete -->
    <b-modal
      v-if="delivery"
      has-modal-card
      scroll="keep"
      :active.sync="showFulfillDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><FulfillDeliveryModal :project="project" :delivery="delivery" @delivery:saved="loadDelivery(true)" />
    </b-modal>
    <!-- Modal: Withdraw -->
    <b-modal
      v-if="delivery"
      has-modal-card
      scroll="keep"
      :active.sync="showWithdrawDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><WithdrawDeliveryModal :project="project" :delivery="delivery" @delivery:saved="loadDelivery(true)" />
    </b-modal>
    <!-- Modal: Approve -->
    <b-modal
      v-if="delivery"
      has-modal-card
      scroll="keep"
      :active.sync="showApproveDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><ApproveDeliveryModal :project="project" :delivery="delivery" @delivery:saved="loadDelivery(true)" />
    </b-modal>
    <!-- Modal: Unapprove -->
    <b-modal
      v-if="delivery"
      has-modal-card
      scroll="keep"
      :active.sync="showUnapproveDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><UnapproveDeliveryModal :project="project" :delivery="delivery" @delivery:saved="loadDelivery(true)" />
    </b-modal>
    <!-- Modal: Archive -->
    <b-modal
      v-if="delivery"
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveDeliveryModal :project="project" :delivery="delivery" @delivery:saved="returnToProject" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Delivery/Presenters/Status'
import ConnectionSelect from '@/components/Models/Connection/SelectMenu'
import ConnectionContributorSelect from '@/components/Models/ConnectionContributor/SelectMenu'
import FulfillDeliveryModal from '@/components/Models/Delivery/Modals/Fulfill'
import WithdrawDeliveryModal from '@/components/Models/Delivery/Modals/Withdraw'
import ApproveDeliveryModal from '@/components/Models/Delivery/Modals/Approve'
import UnapproveDeliveryModal from '@/components/Models/Delivery/Modals/Unapprove'
import ArchiveDeliveryModal from '@/components/Models/Delivery/Modals/Archive'

export default {
  components: {
    StatusPresenter,
    ConnectionSelect,
    ConnectionContributorSelect,
    FulfillDeliveryModal,
    ApproveDeliveryModal,
    UnapproveDeliveryModal,
    ArchiveDeliveryModal,
    WithdrawDeliveryModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return Boolean(this.loadingDelivery)
    },
    method () {
      return (this.delivery) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.delivery)
        ? this.api.route + '/projects/' + this.project.id + '/deliveries/' + this.delivery.id
        : this.api.route + '/projects/' + this.project.id + '/deliveries'
    },
    isSubmitted () {
      return Boolean(this.delivery && this.delivery.submitted_at && this.delivery.approved_at === null)
    },
    isApproved () {
      return Boolean(this.delivery && this.delivery.approved_at)
    },
    readonly () {
      return Boolean(this.delivery && (this.delivery.approved_at || this.delivery.submitted_at))
    },
    ready () {
      return true
    },
    json () {
      const json = {
        title: this.title,
        connection_id: (this.connection) ? this.connection.id : null,
        reporting_partner_role_id: (this.reporterId) ? this.reporterId : null,
        planned_delivery_date: this.plannedDeliveryDate,
        hours_estimate: this.hoursEstimate,
        billable_subtotal: this.calculatedBillRate,
        cost: this.calculatedCostRate
      }
      return json
    },
    calculatedBillRate () {
      if (this.calculate !== 'billRate') return this.billRate
      // handle situations where bill rate is zero
      if (
        Number(this.costRate) === 0 ||
        Number(this.margin) === 0 ||
        (1 - Number(this.margin / 100) === 0)
      ) {
        return Number(0).toFixed(2)
      }
      // return a bill rate
      return Number(Number(this.costRate) / (1 - Number(this.margin / 100))).toFixed(2)
    },
    calculatedCostRate () {
      if (this.calculate !== 'costRate') return this.costRate
      // handle situations where cost rate is zero
      if (Number(this.costRate) === 0) return Number(0).toFixed(2)
      if (Number(this.billRate) === 0) return Number(0).toFixed(2)
      // return a cost rate
      return Number(-1 * (Number(this.billRate) * (Number(this.margin / 100) - 1))).toFixed(2)
    },
    calculatedMargin () {
      if (Number(this.costRate) === 0) return 0
      if (Number(this.billRate) === 0) return 0
      return Number((Number(this.billRate) - Number(this.costRate)) / Number(this.billRate) * 100).toFixed(2) + '%'
    }
  },
  data () {
    return {
      loadingDelivery: true,
      delivery: null,
      saving: false,
      errors: {},
      title: null,
      vendor: null,
      connection: null,
      reporterId: null,
      plannedDeliveryDate: null,
      hoursEstimate: null,
      calculate: 'margin',
      billRate: null,
      costRate: null,
      margin: null,
      showFulfillDeliveryModal: false,
      showWithdrawDeliveryModal: false,
      showApproveDeliveryModal: false,
      showUnapproveDeliveryModal: false,
      showArchiveDeliveryModal: false
    }
  },
  methods: {
    loadDelivery (silent = false) {
      if (!silent) this.loadingDelivery = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/deliveries/' + this.$route.params.delivery
      this.$http.get(endpoint).then(response => {
        this.delivery = response.data
        this.title = this.delivery.title
        this.vendor = this.delivery.vendor
        this.connection = this.delivery.connection
        this.reporterId = this.delivery.reporting_partner_role_id
        this.plannedDeliveryDate = this.delivery.planned_delivery_date
        this.hoursEstimate = Number(this.delivery.hours_estimate).toFixed(2)
        this.billRate = Number(this.delivery.billable_total).toFixed(2)
        this.costRate = Number(this.delivery.cost).toFixed(2)
        if (Number(this.billRate) && Number(this.costRate) && Number(this.billRate !== 0)) {
          const billRate = Number(this.delivery.billable_total)
          const costRate = Number(this.delivery.cost)
          this.margin = Number(((billRate - costRate) / billRate) * 100).toFixed(2)
        }

        this.loadingDelivery = false
      })
    },
    refresh () {
      this.$emit('project:saved')
      this.loadDelivery(true)
    },
    save () {
      this.saving = false
      const endpoint = this.api.route + '/projects/' + this.project.id + '/deliveries/' + this.delivery.id
      this.$http.patch(endpoint, this.json).then(response => {
        this.$emit('delivery:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
      }).catch(error => {
        this.errors = ('response' in error) ? error.response.data.errors : {}
      }).finally(() => {
        this.saving = false
      })
    },
    returnToProject () {
      this.$router.push({ name: 'network.project.lines', params: { code: this.context.code } })
    }
  },
  mounted () {
    this.loadDelivery()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
