export default {
  data () {
    return {
      /**
       * Signal that a loading action is taking place
       * @type {Boolean}
       */
      loading: true,
      /**
       * String that defines the local/session storage key
       * @type {String}
       */
      key: null,
      /**
       * Container for all query results
       * @type {Array}
       */
      results: [],
      /**
       * Container for all selected/checked results
       * @type {Array}
       */
      selected: [],
      /**
       * Container for all allowed presets
       * @type {Object}
       */
      presets: {},
      /**
       * Signal that all filters are loaded (in case some must fetch from
       * a remote source)
       * @type {Boolean}
       */
      filtersLoaded: false,
      /**
       * Container for all filters that must include 'preset', 'search',
       * 'facets', and 'timestamps' properties.
       * @type {Object}
       */
      filters: {
        preset: 'all',
        search: {},
        facets: {},
        timestamps: {}
      },
      /**
       * String to track how api/server should sort results
       * @type {String}
       */
      orderBy: 'id_desc',
      /**
       * The current page of the results
       * @type {Number}
       */
      currentPage: 1,
      /**
       * The total number of results returned by the api query
       * @type {Number}
       */
      total: 0,
      /**
       * The number of results to display on each page
       * @type {Number}
       */
      perPage: 25,
      /**
       * The view preferred by the user (usually either 'list' or 'grid')
       * @type {String}
       */
      view: 'list'
    }
  },
  computed: {
    /**
     * Given the filter set and pagination configs, return a query string
     * object that can be passed as a query string (e.g. to an axios GET)
     * @returns {Object}
     */
    query () {
      const query = {
        params: {}
      }
      // search params first
      if (
        'search' in this.filters &&
        'parameter' in this.filters.search &&
        'query' in this.filters.search &&
        this.filters.search.query !== null &&
        this.filters.search.query !== ''
      ) {
        query.params[this.filters.search.parameter] = this.filters.search.query
      }
      // facets next...
      if ('facets' in this.filters) {
        for (const facetKey in this.filters.facets) {
          const facet = this.filters.facets[facetKey]
          if (facet.length > 0) {
            const values = []
            facet.forEach(option => {
              values.push(option.value)
            })
            query.params[facetKey] = values.join(',')
          }
        }
      }
      // timestamps...
      if ('timestamps' in this.filters) {
        for (const tsKey in this.filters.timestamps) {
          const timestamp = this.filters.timestamps[tsKey]
          if (timestamp) {
            if (typeof timestamp[0] !== 'undefined' && timestamp[0] !== null) {
              query.params[tsKey + '_gte'] = timestamp[0]
            }
            if (typeof timestamp[1] !== 'undefined' && timestamp[1] !== null) {
              query.params[tsKey + '_lte'] = timestamp[1]
            }
          }
        }
      }
      // ...then order by...
      const sort = this.orderBy.split('_')
      if (sort.length === 2) {
        query.params.sortBy = sort[0]
        query.params.direction = sort[1]
      }
      // ...then pagination...
      query.params.page = this.currentPage
      query.params.limit = this.perPage
      // ...then the 'always on' params...
      if (typeof this.alwaysAttach === 'object') {
        Object.assign(query.params, this.alwaysAttach)
      }
      // ...finally return query string object
      return query
    },
    /**
     * Check if the query string has a 'preset' paramter and, if so, make sure
     * that preset object matching the code is defined.
     * {returns Object || null}
     */
    preset () {
      if (this.$route.query.preset && this.presets[this.$route.query.preset]) {
        return this.presets[this.$route.query.preset]
      }
      return null
    }
  },
  methods: {
    filter (payload) {
      this.currentPage = 1
      this.filters = payload
      this.load()
    },
    updatePreset (preset) {
      this.filterPreset = preset
    },
    paginate (page) {
      this.currentPage = page
      this.load()
    },
    sort () {
      this.load()
    },
    load (refreshOnly) {
      this.cacheSessionFilters()
      if (this.preset !== null) {
        this.$router.replace(this.$route.path)
      }
      if (!refreshOnly) this.loading = true
      this.selected = []
      this.$http.get(this.endpoint, this.query).then(response => {
        this.results = response.data.data
        this.total = response.data.meta.total
        this.perPage = response.data.meta.per_page
      }).finally(() => {
        this.loading = false
      })
    },
    cacheSessionFilters () {
      const querySet = {
        view: this.view,
        filters: this.filters,
        orderBy: this.orderBy,
        currentPage: this.currentPage,
        perPage: this.perPage
      }
      sessionStorage.setItem(this.key, JSON.stringify(querySet))
    },
    prepareFilters () {
      // prefer a matching preset first
      if (this.preset !== null) {
        this.filters = this.preset.filters()
      // then prefer any cached filters
      } else if (sessionStorage.getItem(this.key)) {
        const querySet = JSON.parse(sessionStorage.getItem(this.key))
        if (querySet !== null) {
          this.view = querySet.view
          this.filters = querySet.filters
          this.orderBy = querySet.orderBy
          this.currentPage = querySet.currentPage
        }
      // then prefer a locally defined default filters
      } else if (typeof this.defaultFilters === 'function') {
        this.filters = this.defaultFlters()
      // otherwise, just use an empty filter set
      } else {
        this.filters = {
          preset: 'all',
          search: {},
          facets: {},
          timestamps: {}
        }
      }
      this.filtersLoaded = true
    },
    toggleView (value) {
      this.view = value
      this.cacheSessionFilters()
    }
  },
  created () {
    this.prepareFilters()
  },
  watch: {
    preset (preset) {
      if (preset) {
        this.prepareFilters()
        this.load()
      }
    }
  }
}
