<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-size-7 has-text-grey-light">{{ transfers.length }} {{ (transfers.length === 1) ? 'Transfer' : 'Transfers' }}</p>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div class="has-background-white rounded-lg pa-md">
      <b-table
        :data="transfers"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          width="64"
          centered
          cell-class="has-text-grey-light"
          v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          label="Name"
          v-slot="props">
          <router-link :to="{ name: 'network.funding.transfers', params: { code: context.code, id: funding.id, transfer: props.row.id } }" class="has-text-weight-bold">{{ props.row.name }}</router-link>
        </b-table-column>
        <b-table-column
          label="From"
          v-slot="props">
          {{ props.row.from.name }}
        </b-table-column>
        <b-table-column
          label="To"
          v-slot="props">
          {{ props.row.to.name }}
        </b-table-column>
        <b-table-column
          label="Transfer Amount"
          numeric
          v-slot="props">
          <Currency :value="props.row.total_amount" />
        </b-table-column>
        <b-table-column
          label="Status"
          numeric
          v-slot="props">
          <StatusPresenter :transfer="props.row" />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered pt-md">No transfers have been created yet.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/FundingTransfer/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      loadingTransfers: true,
      transfers: []
    }
  },
  methods: {
    loadTransfers (refresh) {
      if (!refresh) this.loadingTransfers = true
      const endpoint = this.api.route + '/funding/' + this.funding.id + '/transfers'
      this.$http.get(endpoint).then(response => {
        this.transfers = response.data.data
        this.loadingTransfers = false
      })
    }
  },
  mounted () {
    this.loadTransfers()
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
