<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    availableBalance () {
      if (this.sourceFundingLineType) {
        const line = this.source.line_summary.find(type => {
          return type.code === this.sourceFundingLineType.code
        })
        return parseFloat(line.amount)
      }
      return null
    }
  },
  data () {
    return {
      loadingFundingLineTypes: true,
      fundingLineTypes: [],
      sourceFundingLineType: null,
      destinationFundingLineType: null,
      amount: null
    }
  },
  methods: {
    loadFundingLineTypes () {
      this.loadingFundingLineTypes = true
      const endpoint = this.api.route + '/funding-line-types'
      this.$http.get(endpoint).then(response => {
        this.fundingLineTypes = response.data.data
        this.loadingFundingLineTypes = false
      })
    },
    addLine () {
      const line = {
        source_line_type: this.sourceFundingLineType,
        destination_line_type: this.destinationFundingLineType,
        amount: this.amount
      }
      this.$emit('line:added', line)
      this.sourceFundingLineType = null
      this.destinationFundingLineType = null
      this.amount = null
    }
  },
  mounted () {
    this.loadFundingLineTypes()
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    }
  }
}
</script>
