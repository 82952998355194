<template>
  <form @submit.prevent="saveNotes">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Notes for {{ notesDate }}</p>
      </header>
      <section class="modal-card-body">
        <div>
          <b-input type="textarea" placeholder="Enter notes" v-model="notes" />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button @click="saveNotes" type="is-primary">Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    selectedDay: Object
  },
  data () {
    return {
      notes: this.selectedDay.notes
    }
  },
  computed: {
    notesDate () {
      return this.$dates(this.selectedDay.date).format('MMMM Do')
    }
  },
  methods: {
    saveNotes () {
      this.$emit('notes:updated', this.notes)
      this.$parent.close()
    }
  }
}
</script>
