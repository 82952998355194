<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Partner</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Business Name"
          :type="('company' in errors) ? 'is-danger' : ''"
          :message="('company' in errors) ? errors['company'][0] : ''">
          <b-input
            autocomplete="off"
            size="is-medium"
            type="text"
            v-model="company"
          ></b-input>
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field
              label="First"
              :type="('first_name' in errors) ? 'is-danger' : ''"
              :message="('first_name' in errors) ? errors['first_name'][0] : ''">
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                v-model="firstName"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Last Name"
              :type="('last_name' in errors) ? 'is-danger' : ''"
              :message="('last_name' in errors) ? errors['last_name'][0] : ''">
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                v-model="lastName"
              ></b-input>
            </b-field>
          </div>
        </div>
        <b-field
          label="Email"
          :type="('email' in errors) ? 'is-danger' : ''"
          :message="('email' in errors) ? errors['email'][0] : ''">
          <b-input
            autocomplete="off"
            size="is-medium"
            type="email"
            v-model="email"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()" >Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (this.company && this.firstName && this.lastName && this.email)
    }
  },
  data () {
    return {
      company: null,
      firstName: null,
      lastName: null,
      email: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/admin/partners'
      const body = {
        company: this.company,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email
      }
      this.$http.post(endpoint, body).then(response => {
        this.$emit('partner:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Partner added.' })
        this.$parent.close()
      }).catch(error => {
        if ('response' in error && error.response.status === 422) {
          this.errors = error.response.data.errors
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
