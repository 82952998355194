export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter funding by status.',
  options: [{
    value: 'approved',
    label: 'Approved'
  }, {
    value: 'draft',
    label: 'Draft'
  }, {
    value: 'paused',
    label: 'Paused'
  }, {
    value: 'completed',
    label: 'Completed'
  }]
}
