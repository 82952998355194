<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-four-fifths-tablet is-two-thirds-desktop is-half-widescreen">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-4">Create Funding</p>
              </div>
              <div class="column">
                <div class="has-text-right">
                  <p class="is-size-4 has-text-primary"><Currency :value="fundingSummary.budgetTotal" /></p>
                  <p>Total Budget</p>
                </div>
              </div>
            </div>
            <div class="has-background-white has-shadow pa-lg">
              <!-- Errors -->
              <div v-if="errorMessage" class="mb-lg">
                <b-message type="is-warning">
                  {{ errorMessage }}
                </b-message>
              </div>
              <b-steps
                v-if="!loading && fundingTypes.length"
                v-model="step"
                :animated="true"
                :has-navigation="false"
                label-position="bottom">
                <!-- Step 1: General -->
                <b-step-item step="1" label="Client" :clickable="true">
                  <div class="pt-md">
                    <!-- Form Fields -->
                    <div>
                      <div class="mb-md">
                        <b-field
                          label="Client"
                          :type="('client' in errors) ? 'is-danger' : ''"
                          :message="('client' in errors) ? errors['client'][0] : ''">
                          <ClientSelect v-model="client" size="is-medium" />
                        </b-field>
                        <b-field
                          label="Name"
                          :type="('name' in errors) ? 'is-danger' : ''"
                          :message="('name' in errors) ? errors['name'][0] : ''">
                          <b-input
                            size="is-medium"
                            type="text"
                            v-model="name"
                          ></b-input>
                        </b-field>
                      </div>
                      <div class="mb-md">
                        <div class="columns">
                          <div class="column">
                            <b-field
                              label="Funding Type"
                              :type="('funding_type' in errors) ? 'is-danger' : ''"
                              :message="('funding_type' in errors) ? errors['funding_type'][0] : ''" >
                              <b-select
                                size="is-medium"
                                type="text"
                                v-model="fundingType"
                                expanded>
                                <option v-for="type in fundingTypes" :key="type.id" :value="type">{{ type.name }}</option>
                              </b-select>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field
                              label="Client Identifier"
                              :type="('client_identifier' in errors) ? 'is-danger' : ''"
                              :message="('client_identifier' in errors) ? errors['client_identifier'][0] : ''">
                              <b-input
                                size="is-medium"
                                type="text"
                                v-model="clientIdentifier"
                                placeholder="Optional"
                              ></b-input>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Navigate -->
                    <div class="pt-lg">
                      <div class="columns">
                        <div class="column">
                          <b-button type="is-light rounded-lg" size="is-medium" expanded disabled><b-icon icon="arrow-left"></b-icon></b-button>
                        </div>
                        <div class="column">
                          <b-button @click="goToLines" type="is-primary rounded-lg" size="is-medium" :disabled="!readyForLines" :loading="loading" expanded>Next Step: Line Items</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-step-item>
                <!-- Step 2: Line Items -->
                <b-step-item step="2" label="Lines" :clickable="readyForLines">
                  <div>
                    <table class="table is-fullwidth is-vcentered is-paddingless mb-md">
                      <tr>
                        <th style="width:70%">Line Items</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                      <template v-if="lines.length">
                        <tr v-for="(line, index) in lines" :key="index">
                          <td><b-input v-model="line.description" size="is-medium" type="text" placeholder="Line item description" /></td>
                          <td><b-input v-model="line.amount" size="is-medium" type="number" :min="0" /></td>
                          <td>
                            <p class="px-sm"><a v-if="lines.length > 1" href="#" class="is-size-7" @click.prevent="removeLine(index)">X</a></p>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="3"><p class="has-text-centered has-text-grey-light is-size-7 py-lg">No Lines!</p></td>
                        </tr>
                      </template>
                    </table>
                    <p class="pl-md"><a href="#" @click.prevent="addLine">+ Add Additional Line Item</a></p>
                  </div>
                  <!-- Navigate -->
                  <div class="pt-lg">
                    <div class="columns">
                      <div class="column">
                        <b-button @click="goToGeneral" type="is-light rounded-lg" size="is-medium" expanded><b-icon icon="arrow-left"></b-icon></b-button>
                      </div>
                      <div class="column">
                        <b-button @click="goToAssociation" type="is-primary rounded-lg" size="is-medium" :disabled="!readyForAssociation"  expanded>Next Step</b-button>
                      </div>
                    </div>
                  </div>
                </b-step-item>
                <!-- Step 3: Projects -->
                <b-step-item step="3" label="Projects" :clickable="readyForAssociation">
                  <div>
                    <table class="table is-fullwidth is-vcentered is-borderless is-narrow is-size-7 mb-md">
                      <tr>
                        <th>Projects</th>
                        <th style="width:32px"></th>
                      </tr>
                      <tr v-for="(project, index) in projects" :key="index">
                        <td>
                          <b-select v-model="projects[index]" size="is-medium" expanded>
                            <option v-for="availableProject in availableProjects" :key="availableProject.id" :value="availableProject.id">
                              {{ availableProject.name }} (ID: {{ availableProject.id }})
                            </option>
                          </b-select>
                        </td>
                        <td>
                          <p v-if="projects.length > 1"><a href="#" class="is-size-7" @click.prevent="removeAssociation(index)">X</a></p>
                        </td>
                      </tr>
                      <tr>
                        <td><a href="#" @click.prevent="addAssociation">Link funding to another project...</a></td>
                      </tr>
                    </table>
                  </div>
                  <!-- Navigate -->
                  <div class="pt-lg">
                    <div class="columns">
                      <div class="column">
                        <b-button @click="goToLines" type="is-light rounded-lg" size="is-medium" :disabled="loading" :loading="loading" expanded><b-icon icon="arrow-left"></b-icon></b-button>
                      </div>
                      <div class="column">
                        <b-button @click="save" type="is-primary rounded-lg" size="is-medium" :disabled="!readyForSave" :loading="saving" expanded>Save Funding</b-button>
                      </div>
                    </div>
                  </div>
                </b-step-item>
              </b-steps>
              <div v-else>
                <Loading message="Loading..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientSelect from '@/components/Models/Client/SelectMenu'
import { mapGetters } from 'vuex'

export default {
  components: {
    ClientSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingFundingTypes)
    },
    readyForLines () {
      return Boolean(this.client && this.name && this.fundingType)
    },
    readyForAssociation () {
      let ready = Boolean(this.lines.length)
      this.lines.forEach(line => {
        if (!line.description || Number(line.amount) <= 0) ready = false
      })
      return ready
    },
    readyForSave () {
      return Boolean(this.projects.length)
    },
    hasValidDates () {
      if (!this.startDate || !this.endDate) return false
      return Boolean(
        this.startDate &&
        this.endDate &&
        this.$dates(this.endDate).isSameOrAfter(this.$dates(this.startDate), 'day')
      )
    },
    validationTip () {
      if (!this.readyForLines) {
        return {
          level: 'has-text-grey-light',
          message: 'Please fill out every field.'
        }
      }
      if (!this.readyForBilling) {
        return {
          level: 'has-text-warning',
          message: 'Please verify your funding line items are accurate.'
        }
      }
      if (!this.hasValidDates) return 'Please check your start and end dates.'
      if (!this.readyForSave) return 'Please verify that your billing totals match your budget totals.'
      return null
    },
    json () {
      const json = {
        client_id: this.client.id,
        name: this.name,
        funding_type_id: this.fundingType.id,
        client_identifier: this.clientIdentifier,
        funding_lines: [],
        projects: []
      }
      this.lines.forEach(line => {
        if (line.description) json.funding_lines.push(line)
      })
      this.projects.forEach(projectId => {
        json.projects.push({
          id: projectId
        })
      })
      return json
    },
    fundingSummary () {
      const summary = {
        lines: 0,
        events: 0,
        budgetTotal: 0,
        billingTotal: 0
      }
      this.lines.forEach(line => {
        summary.lines++
        summary.budgetTotal += Number(line.amount)
      })
      return summary
    },
    months () {
      return this.$dates(this.endDate).diff(this.$dates(this.startDate), 'month')
    }
  },
  data () {
    return {
      step: 0,
      loadingFundingTypes: true,
      loadingProjects: true,
      fundingTypes: [],
      availableProjects: [],
      // form data
      client: null,
      startDate: null,
      endDate: null,
      fundingType: null,
      name: null,
      clientIdentifier: null,
      lines: [{
        description: null,
        amount: null,
        funding_line_type_code: 'project_fees'
      }],
      projects: [{
        id: null
      }],
      saving: false,
      errors: {},
      errorMessage: null
    }
  },
  methods: {
    loadFundingTypes () {
      this.loadingFundingTypes = true
      const endpoint = this.api.route + '/funding-types'
      this.$http.get(endpoint).then(response => {
        this.fundingTypes = response.data.data
        this.fundingType = response.data.data[0]
        this.loadingFundingTypes = false
      })
    },
    loadAvailableProjects () {
      this.loadingProjects = true
      const endpoint = this.api.route + '/projects'
      const query = {
        params: {
          client: this.client.id,
          status: 'pending,active'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.availableProjects = response.data.data
        this.loadAvailableProjects = false
      })
    },
    goToGeneral () {
      this.step = 0
    },
    goToLines () {
      this.step = 1
    },
    goToAssociation () {
      this.step = 2
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/funding'
      this.$http.post(endpoint, this.json).then(response => {
        this.errors = {}
        this.errorMessage = null
        this.$buefy.toast.open({ type: 'is-success', message: 'New funding added!' })
        this.$router.push({ name: 'network.funding', params: { code: this.context.code, id: response.data.id } })
        this.$emit('funding:created', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.errorMessage = error.response.data.message
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    addLine () {
      this.lines.push({
        description: null,
        amount: null,
        funding_line_type_code: 'project_fees'
      })
    },
    addAssociation () {
      this.projects.push({
        id: null
      })
    },
    removeLine (index) {
      if (this.lines.length > 1) {
        this.lines.splice(index, 1)
      }
    },
    removeAssociation (index) {
      if (this.projects.length > 1) {
        this.projects.splice(index, 1)
      }
    },
    resetEvents () {
      this.projects = []
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadFundingTypes()
    }, 500)
  },
  watch: {
    client (client) {
      if (client) {
        this.loadAvailableProjects()
      }
    }
  }
}
</script>
