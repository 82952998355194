<template>
  <b-select
    v-model="contributor"
    placeholder="Select Reporter"
    expanded
    :size="size"
    :loading="loadingContributors"
    :disabled="readonly"
    @input="announce">
    <option v-for="(contributor, index) in contributors" :key="index" :value="contributor">{{ contributor.label }}</option>
  </b-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingContributors: false,
      contributor: null,
      contributors: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.contributor.id)
    },
    loadContributors () {
      this.loadingContributors = true
      const endpoint = this.api.route + '/connections/' + this.connectionId + '/contributors/list'
      const query = {
        params: {
          connection_id: this.connectionId
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.contributors = response.data
        if (this.value) {
          this.contributor = this.contributors.find((contributor) => { return contributor.id === this.value })
          this.announce()
        } else if (this.contributors.length === 1) {
          this.contributor = response.data[0]
          this.announce()
        }
        this.loadingContributors = false
      })
    }
  },
  mounted () {
    if (this.connectionId) this.loadContributors()
  },
  props: {
    value: {
      type: [Number, null]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    connectionId: {
      type: Number
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  },
  watch: {
    connectionId (id) {
      if (id) {
        this.loadContributors()
      } else {
        this.contributor = null
      }
    }
  }
}
</script>
