<template>
  <div>
    <p class="has-text-weight-bold border-b pb-sm is-size-7">
      <span class="has-text-grey-light">Budget: </span>
      <span class="has-text-primary ">Expenses</span>
    </p>
    <div class="pt-sm buttons">
      <b-button
        size="is-small"
        type="is-primary"
        outlined
        :disabled="!selected.length"
        @click="approveExpenses"
      >Approve</b-button>
      <b-button
        size="is-small"
        type="is-light"
        :disabled="!selected.length"
        @click="unapproveExpenses"
      >Remove Approvals</b-button>
    </div>
    <b-table
      :data="line.expenses"
      :mobile-cards="false"
      :checked-rows.sync="selected"
      hoverable
      narrowed
      checkable
      class="is-size-7">
      <b-table-column
        label="Type"
        v-slot="props"
        header-class="is-size-8"
        cell-class="nowrap"
        width="96">
        <p :title="props.row.id">Expense</p>
      </b-table-column>
      <b-table-column
        label="Vendor"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <p><a href="#" @click.prevent="focus(props.row)" class="has-text-weight-bold">{{ props.row.vendor }}</a></p>
      </b-table-column>
      <b-table-column
        label="Billable ($)"
        centered
        width="128"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <Currency :value="props.row.billable_total" />
      </b-table-column>
      <b-table-column
        label="Non Billable ($)"
        centered
        width="96"
        header-class="is-size-8"
        v-slot="props">
        <Currency :value="props.row.nonbillable_total" />
      </b-table-column>
      <b-table-column
        label="Status"
        v-slot="props"
        centered
        header-class="is-size-8"
        width="96">
        <ExpenseStatus :expense="props.row" />
      </b-table-column>
      <template #footer>
        <td></td>
        <td></td>
        <td></td>
        <td class="has-text-centered has-text-weight-bold">
          <Currency :value="summary.billableTotal" />
        </td>
        <td class="has-text-centered has-text-weight-bold">
          <Currency :value="summary.nonBillableTotal" />
        </td>
        <td class="has-text-centered has-text-weight-bold">
          <Currency :value="Number(summary.nonBillableTotal + summary.billableTotal)" />
        </td>
      </template>
      <template #empty>
        <div class="has-text-centered pa-lg">No deliveries were submitted for billing during this period.</div>
      </template>
    </b-table>
    <!-- Modal: Focus -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showDetailModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><ExpenseDetailsModal
        :project="project"
        :expense="focusExpense"
        @project:saved="resetAfterUpdate" />
    </b-modal>
    <!-- Modal: Approve -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showApprovalModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><ApproveExpensesModal
        :project="project"
        :expenses="selected"
        :period="period"
        @project:saved="resetAfterUpdate" />
    </b-modal>
    <!-- Modal: Remove Approve -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showRemoveApprovalModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UnapproveExpensesModal
        :project="project"
        :expenses="selected"
        @project:saved="resetAfterUpdate" />
    </b-modal>
  </div>
</template>

<script>
import ExpenseStatus from '@/components/Models/Expense/Presenters/Status'
import ApproveExpensesModal from '@/components/Models/Expense/Modals/Approve'
import UnapproveExpensesModal from '@/components/Models/Expense/Modals/Unapprove'
import ExpenseDetailsModal from '@/components/Models/Expense/Modals/Details'

export default {
  components: {
    ExpenseStatus,
    ExpenseDetailsModal,
    ApproveExpensesModal,
    UnapproveExpensesModal
  },
  computed: {
    summary () {
      const summary = {
        nonBillableTotal: 0,
        billableTotal: 0
      }
      this.line.expenses.forEach(expense => {
        summary.nonBillableTotal += Number(expense.nonbillable_total)
        summary.billableTotal += Number(expense.billable_total)
      })
      return summary
    }
  },
  data () {
    return {
      selected: [],
      focusExpense: null,
      showDetailModal: false,
      showApprovalModal: false,
      showRemoveApprovalModal: false
    }
  },
  methods: {
    approveExpenses () {
      this.showApprovalModal = true
    },
    unapproveExpenses () {
      this.showRemoveApprovalModal = true
    },
    focus (expense) {
      this.focusExpense = expense
      this.showDetailModal = true
    },
    reset () {
      this.focusExpense = null
      this.showDetailModal = false
      this.showApprovalModal = false
      this.showRemoveApprovalModal = false
    },
    resetAfterUpdate () {
      this.selected = []
      this.$emit('project:saved')
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
