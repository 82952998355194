<template>
  <div>
    <b-select
      v-model="platformAccountId"
      :size="size"
      placeholder="Select Platform Acccount"
      :loading="loadingPlatformAccounts"
      @input="announce"
      :disabled="disabled"
      expanded>
      <option v-for="platformAccount in platformAccounts" :key="platformAccount.id" :value="platformAccount.id">
        {{ platformAccount.platform.name }} (ID: {{ platformAccount.id }})
      </option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingPlatformAccounts: false,
      platformAccounts: [],
      platformAccountId: null
    }
  },
  methods: {
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.api.route + '/admin/platform-accounts?paginate=0'
      this.$http.get(endpoint).then(response => {
        this.platformAccounts = response.data
        // if the value is set, attempt to locate it in the list
        if (this.value) {
          this.platformAccountId = this.value.id
        // otherwise, if there is only 1 value, use that value to save user a click
        } else if (this.platformAccounts.length === 1) {
          this.platformAccountId = response.data[0].id
          this.announce()
        }
        this.loadingPlatformAccounts = false
      })
    },
    announce () {
      const first = this.$utils.find(this.platformAccounts, o => { return o.id === this.platformAccountId })
      this.$emit('input', first)
    }
  },
  mounted () {
    this.loadPlatformAccounts()
  },
  props: {
    value: {
      type: [Object, null]
    },
    category: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>
