<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Invite Partner</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Business Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="name"
              v-model="name"
            ></b-input>
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field
            label="First"
            :type="('first_name' in errors) ? 'is-danger' : ''"
            :message="('first_name' in errors) ? errors['first_name'][0] : ''"
            expanded>
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                v-model="firstName"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Last Name"
              :type="('last_name' in errors) ? 'is-danger' : ''"
              :message="('last_name' in errors) ? errors['last_name'][0] : ''"
              expanded>
                <b-input
                  autocomplete="off"
                  size="is-medium"
                  type="text"
                  v-model="lastName"
                ></b-input>
            </b-field>
          </div>
        </div>
        <b-field
          label="Email"
          :type="('email' in errors) ? 'is-danger' : ''"
          :message="('email' in errors) ? errors['email'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="email"
              v-model="email"
            ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Send Invite</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return true
    },
    endpoint () {
      return this.api.route + '/invites'
    },
    json () {
      return {
        name: this.name,
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Partner invited!' })
        this.$parent.close()
        this.$emit('partner:invited', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again!' })
        this.saving = false
      })
    }
  },
  data () {
    return {
      name: null,
      email: null,
      firstName: null,
      lastName: null,
      saving: false,
      errors: []
    }
  }
}
</script>
