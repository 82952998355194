import Index from './views/Index'
import Billing from './views/Billing'
import BillingPeriods from './views/BillingPeriods'
import Finance from './views/Finance'
import ClientInvoices from './views/ClientInvoices'
import ClientInvoiceCreate from './views/ClientInvoiceCreate'
import Activity from './views/ProjectTimesheets'
import ProfitByOrganization from './views/ProfitByOrganization'
import ProfitByProject from './views/ProfitByProject'
import ProfitByContributor from './views/ProfitByContributor'
import Reports from './views/Reports'
import Revisions from './views/Revisions'
import Revision from './views/Revision'
import Timesheets from './views/Timesheets'
import Transfers from './views/Transfers'
import TransferCreate from './views/TransferCreate'

export default [{
  path: '/network/:code/finance',
  component: Index,
  children: [{
    path: 'billing',
    name: 'network.finance.billing',
    component: Billing
  }, {
    path: 'billing-periods',
    name: 'network.finance.periods',
    component: BillingPeriods
  }, {
    path: 'activity',
    name: 'network.finance.activity',
    component: Activity
  }, {
    path: 'profit/organization',
    name: 'network.finance.profit.organization',
    component: ProfitByOrganization
  }, {
    path: 'profit/project',
    name: 'network.finance.profit.project',
    component: ProfitByProject
  }, {
    path: 'profit/contributor',
    name: 'network.finance.profit.contributor',
    component: ProfitByContributor
  }, {
    path: 'client-invoices',
    name: 'network.finance.client.invoices',
    component: ClientInvoices
  }, {
    path: 'create-client-invoice',
    name: 'network.finance.client.invoices.create',
    component: ClientInvoiceCreate
  }, {
    path: 'reports',
    name: 'network.finance.reports',
    component: Reports
  }, {
    path: 'revisions',
    name: 'network.finance.revisions',
    component: Revisions
  }, {
    path: 'revisions/:revision',
    name: 'network.finance.revision',
    component: Revision
  }, {
    path: 'timesheets',
    name: 'network.finance.timesheets',
    component: Timesheets
  }, {
    path: 'transfers',
    name: 'network.finance.transfers',
    component: Transfers
  }, {
    path: 'transfers/create',
    name: 'network.finance.transfers.create',
    component: TransferCreate
  }, {
    path: 'transfers/:transfer',
    name: 'network.finance.transfer',
    component: TransferCreate
  }, {
    path: '',
    name: 'network.finance',
    component: Finance
  }]
}]
