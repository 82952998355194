<template>
  <div>
    <!-- Transfer -->
    <div class="has-background-white rounded-lg pa-lg mb-xxs">
      <!-- Back Link -->
      <div class="border-b pb-md mb-lg">
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-bold has-text-primary is-uppercase">{{ (announcement) ? 'Edit' : 'Create' }} Announcement</p>
          </div>
          <div class="column is-narrow">
            <div class="has-text-right">
              <router-link :to="{ name: 'network.settings.announcements', params: { code: context.code }}">&lt; Back to Announcements</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Form -->
      <div class="columns">
        <div class="column is-half">
          <!-- Title & Purpose -->
          <div class="mb-lg">
            <b-field label="Target"
              :type="('visibility' in errors) ? 'is-danger' : ''"
              :message="('visibility' in errors) ? errors['visibility'][0] : ''">
              <b-select v-model="visibility" size="is-medium" placeholder="Select Target Audience" expanded>
                <option value="network">{{ context.name }} Internal Leadership</option>
                <option value="connections">{{ context.name }} Partner Network</option>
              </b-select>
            </b-field>
            <b-field label="Status"
              :type="('status' in errors) ? 'is-danger' : ''"
              :message="('status' in errors) ? errors['status'][0] : ''">
              <b-select v-model="status" size="is-medium" placeholder="Select Status" expanded>
                <option value="published">Published</option>
                <option value="draft">Draft</option>
              </b-select>
            </b-field>
            <b-field label="Title"
              :type="('title' in errors) ? 'is-danger' : ''"
              :message="('title' in errors) ? errors['title'][0] : ''">
              <b-input type="text" size="is-medium" v-model="title"></b-input>
            </b-field>
            <div class="mb-lg">
              <div class="mb-sm">
                <p><label class="label">Body Copy</label></p>
                <p class="has-text-grey is-size-7">
                  Use <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">Markdown</a>
                  for formatting your message.
                </p>
              </div>
              <b-input type="textarea" v-model="markdown" rows="10"></b-input>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="pl-md">
            <p class="mb-md"><label class="label">Preview</label></p>
            <div class="border pa-md">
              <!-- Title -->
              <div class="has-text-weight-bold mb-md">
                <p v-if="title">{{ title }}</p>
                <p v-else class="has-text-grey-light">Enter Title...</p>
              </div>
              <!-- Content -->
              <div>
                <div v-if="compiledMarkdown !== null" class="content">
                  <div v-html="compiledMarkdown"></div>
                </div>
                <div v-else>
                  <p class="has-text-grey-light">Enter body copy...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Transfer Save -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns">
        <div class="column is-one-quarter">
          <b-button
            type="is-primary"
            native-type="submit"
            size="is-medium"
            :loading="saving"
            :disabled="!ready || saving"
            @click="save"
          >Save Announcement</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { marked } from 'marked'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (
        true
      )
    },
    method () {
      return (this.announcement) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.announcement)
        ? this.api.route + '/admin/announcements/' + this.announcement.id
        : this.api.route + '/admin/announcements/'
    },
    ready () {
      return Boolean(
        this.title && this.markdown && this.status
      )
    },
    json () {
      const json = {
        visibility: this.visibility,
        title: this.title,
        subtitle: null,
        markdown: this.markdown,
        status: this.status
      }
      return json
    },
    compiledMarkdown () {
      if (this.markdown !== null && this.markdown !== '') {
        return marked(this.markdown)
      }
      return null
    }
  },
  data () {
    return {
      loadingAnnouncement: false,
      announcement: null,
      visibility: null,
      title: null,
      markdown: null,
      status: 'draft',
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadAnnouncement () {
      this.loadingAnnouncement = true
      const endpoint = this.api.route + '/admin/announcements/' + this.$route.params.announcement
      this.$http.get(endpoint).then(response => {
        this.announcement = response.data
        this.visibility = this.announcement.visibility
        this.title = this.announcement.title
        this.markdown = this.announcement.markdown
        this.status = this.announcement.status
        this.loadingAnnouncement = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Announcement published!' })
        this.$router.push({ name: 'network.settings.announcements', params: this.context.code })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (typeof this.$route.params.announcement !== 'undefined') {
      this.loadAnnouncement()
    }
  }
}
</script>
