<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-narrow">
            <div class="">
              <b-menu>
                <b-menu-list label="Partners Menu">
                  <b-menu-item
                    icon="lan"
                    label="Partners"
                    tag="router-link"
                    :to="{ name: 'network.partners', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <!-- <b-menu-item
                    icon="email-outline"
                    label="Invitations"
                    tag="router-link"
                    :to="{ name: 'network.partner.invites', params: { code: context.code } }"
                  ></b-menu-item> -->
                </b-menu-list>
              </b-menu>
            </div>
          </div>
          <!-- Child Router View -->
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/connections'
    }
  },
  data: function () {
    return {
      key: 'clients',
      showCreateClientModal: false
    }
  },
  methods: {

  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
