<template>
<div>
  <div class="section">
    <div class="container">
      <!-- Header -->
      <div v-if="!loading && timesheet">
        <div class="has-background-white pa-md has-shadow mb-md">
          <div class="columns space-between is-vcentered">
            <!-- Project Info -->
            <div class="column">
              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <div class="border px-md py-xl">
                    <cld-image :publicId="timesheet.seat.position.project.client.organization.logo_path" width="96" dpr="2.0" crop="scale" style="display:block;" />
                  </div>
                </div>
                <div class="column">
                  <p class="has-text-weight-bold is-size-5 has-text-primary">
                    {{ timesheet.seat.position.project.name }}
                    <span v-if="timesheet.seat.reporting_partner_role_id !== timesheet.seat.partner_role_id" class="is-size-7 has-text-grey-light">{{ timesheet.seat.contributor.user.full_name }}</span>
                  </p>
                  <div class="is-size-7">
                    <p><span class="has-text-grey-light">Project #</span> {{ timesheet.seat.position.project_id }}</p>
                    <p>
                      <span class="has-text-grey-light">Role</span>
                      {{ timesheet.seat.position.role }}
                      <span v-if="timesheet.seat.role" class="has-text-grey-light">({{ timesheet.seat.role }})</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Dates -->
            <div class="column is-one-third">
              <p class="is-size-7 mb-sm has-text-right">
                <span class="has-text-grey-light">Project Run Dates</span>
                {{ $dates(timesheet.seat.position.project.start_date).format('MMM D, YYYY') }} -
                {{ $dates(timesheet.seat.position.project.end_date).format('MMM D, YYYY') }}
              </p>
              <div>
                <b-select
                  v-model="selectedTimesheet"
                  size="is-medium"
                  placeholder="Select a timesheet..."
                  :loading="loadingTimesheets"
                  expanded>
                  <option v-for="timesheet in timesheets" :key="timesheet.id" :value="timesheet">
                    {{ $dates(timesheet.billing_period.start_date).format('MMMM YYYY') }}
                    {{ (timesheet.billing_period.closed_at) ? '(Closed)' : '(Open)' }}
                  </option>
                </b-select>
              </div>
            </div>
          </div>
        </div>
        <div >
            <router-view
              :timesheet="timesheet"
              @refresh="loadTimesheet(true)"
              @hours:saved="loadTimesheet(true)"
            />
        </div>
      </div>`
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingSeat || this.loadingBillingPeriods || this.loadingTimesheets)
    },
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      loadingTimesheet: true,
      loadingTimesheets: true,
      selectedTimesheet: null,
      timesheet: null,
      timesheets: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadTimesheets () {
      this.loadingTimesheets = true
      const endpoint = this.api.route + '/timesheets'
      const query = {
        params: {
          seat_id: this.seat.id,
          paginate: 0
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.timesheets = response.data
        this.timesheets.forEach(timesheet => {
          if (timesheet.id === parseInt(this.$route.params.timesheet)) {
            this.selectedTimesheet = timesheet
          }
        })
        this.loadingTimesheets = false
      })
    },
    loadTimesheet (silent = false) {
      if (!silent) this.loadingTimesheet = true
      const endpoint = this.api.route + '/timesheets/' + this.selectedTimesheet.id
      this.$http.get(endpoint).then(response => {
        this.timesheet = response.data
        this.loadingTimesheets = false
      })
    }
  },
  mounted () {
    this.seat = {
      id: this.$route.params.seat
    }
    this.selectedTimesheet = {
      id: this.$route.params.timesheet
    }
    this.loadTimesheet()
    this.loadTimesheets()
  },
  watch: {
    selectedTimesheet (timesheet) {
      if (timesheet) {
        this.$router.replace({ params: { timesheet: timesheet.id } })
        this.loadTimesheet()
      }
    }
  }
}
</script>
