export default {
  active: {
    label: 'Active',
    tooltip: 'Projects that are active',
    filters () {
      return {
        preset: 'active',
        search: {},
        facets: {
          status: [{
            label: 'Active',
            value: 'active'
          }]
        },
        timestamps: {}
      }
    }
  },
  draft: {
    label: 'Draft',
    tooltip: 'Projects that are draf',
    filters () {
      return {
        preset: 'draft',
        search: {},
        facets: {
          status: [{
            label: 'Draft',
            value: 'draft'
          }]
        },
        timestamps: {}
      }
    }
  }
}
