<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ actionLabel }}</p>
      </header>
      <section class="modal-card-body">
        <div>
          <div v-if="action === 'hide'">
            <b-message type="is-warning">
              Are you sure you want to hide this role from your dashboard?
            </b-message>
          </div>
          <div v-if="action === 'unhide'">
            <b-message type="is-warning">
              Are you sure you want to show this role on your dashboard?
            </b-message>
          </div>
          <div v-else-if="action === 'hold'">
            <b-message type="is-warning">
              Are you sure your want to place this role on hold?
            </b-message>
          </div>
          <div v-else-if="action === 'resume'">
            <b-message type="is-warning">
              Are you sure you want to resume this role?
            </b-message>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >{{ actionLabel }}</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    method () {
      switch (this.action) {
        default:
          return 'put'
      }
    },
    endpoint () {
      const base = this.api.route + '/seats/' + this.seat.id
      switch (this.action) {
        case 'hide':
          return base + '/actions/hide'
        case 'unhide':
          return base + '/actions/unhide'
        case 'hold':
          return base + '/actions/hold'
        case 'resume':
          return base + '/actions/resume'
        default:
          return base
      }
    },
    actionLabel () {
      switch (this.action) {
        case 'hide':
          return 'Hide Contributor Role'
        case 'unhide':
          return 'Unhide Role'
        case 'hold':
          return 'Place Role on Hold'
        case 'resume':
          return 'Resume Role'
        default:
          return 'Edit Role'
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$emit('seat:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  }
}
</script>
