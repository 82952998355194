<template>
  <div class="section">
    <div class="container">
      <div class="has-background-white has-shadow pa-xl">
        <!-- Header -->
        <div>
          <p class="is-size-5 mb-sm">Add Team Member</p>
          <p class="is-size-7 has-text-grey">
            Team members can contribute to projects on behalf of {{ context.name }} once they are approved by connected networks.
          </p>
        </div>
        <!-- From -->
        <form @submit.prevent="save" class="mt-lg">
          <div class="mb-lg">
            <div class="columns">
              <div class="column is-half">
                <div v-if="!loading && roleTypes.length > 0">
                  <!-- Role Configuration -->
                  <div>
                    <b-field
                      custom-class="has-text-weight-normal is-size-7 has-text-grey"
                      :type="('first_name' in errors) ? 'is-danger' : ''"
                      :message="('first_name' in errors) ? errors['first_name'][0] : ''">
                      <template #label>
                        First Name <span class="has-text-danger is-size-8">*</span>
                      </template>
                      <b-input v-model="firstName" type="text"/>
                    </b-field>
                    <b-field
                      custom-class="has-text-weight-normal is-size-7 has-text-grey"
                      :type="('last_name' in errors) ? 'is-danger' : ''"
                      :message="('last_name' in errors) ? errors['last_name'][0] : ''">
                      <template #label>
                        Last Name <span class="has-text-danger is-size-8">*</span>
                      </template>
                      <b-input v-model="lastName" type="text"/>
                    </b-field>
                  </div>
                  <!-- User Configuration -->
                  <div>
                    <div class="mt-md">
                      <p class="has-text-weight-normal is-size-7 has-text-grey mb-md">Does this team member require sign in access for time and deliverable reporting?</p>
                      <div class="mb-sm"><b-radio v-model="accessRequired" native-value="yes">Yes</b-radio></div>
                      <div class="mb-sm"><b-radio v-model="accessRequired" native-value="no">No</b-radio></div>
                    </div>
                    <b-field
                      v-if="accessRequired === 'yes'"
                      custom-class="has-text-weight-normal is-size-7 has-text-grey"
                      :type="('email' in errors) ? 'is-danger' : ''"
                      :message="('email' in errors) ? errors['email'][0] : ''">
                      <template #label>
                        Email Address <span class="has-text-danger is-size-8">*</span>
                      </template>
                      <b-input v-model="email" type="email"/>
                    </b-field>
                    <b-field
                      v-if="accessRequired === 'yes'"
                      custom-class="has-text-weight-normal is-size-7 has-text-grey"
                      :type="('email' in errors) ? 'is-danger' : ''"
                      :message="('email' in errors) ? errors['email'][0] : ''">
                      <template #label>
                        Role <span class="has-text-danger is-size-8">*</span>
                      </template>
                      <b-select
                        placeholder="Select a role"
                        expanded
                        v-model="roleType">
                        <option v-for="role in roleTypes" :key="role.id" :value="role" >{{ role.name }}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <!-- Permissions -->
                  <div v-if="roleType && accessRequired === 'yes'" class="mt-lg">
                    <p class="has-text-weight-normal is-size-7 has-text-grey mb-sm">Permissions</p>
                    <div v-if="roleType.permissions.length > 0">
                      <div v-for="permission in roleType.permissions" :key="permission">
                        <div class="has-background-light py-sm px-md mb-xxs rounded-lg is-size-7">
                          {{ $utils.startCase(permission) }}
                        </div>
                      </div>
                      <div class="has-background-light py-sm px-md mb-xxs rounded-lg is-size-7">
                        All Standard View Permissions
                      </div>
                    </div>
                    <div v-else>
                      <div class="has-background-light py-sm px-md mb-xxs rounded-lg is-size-7">
                        All Standard View Permissions
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Loading -->
                <div v-else>
                  <Loading message="Loading roles..." />
                </div>
              </div>
            </div>
          </div>
          <div>
            <b-button
              type="is-primary"
              native-type="submit"
              :loading="saving"
              :disabled="!ready || saving"
            >Save</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      if (!this.firstName || !this.lastName || !this.accessRequired) return false
      if (this.accessRequired === 'yes') {
        if (!this.email || !this.roleType) return false
      }
      return true
    },
    endpoint () {
      return this.api.route + '/admin/roles'
    },
    json () {
      const json = {
        first_name: this.firstName,
        last_name: this.lastName,
        role_type_id: this.roleType.id,
        access_required: false
      }
      if (this.accessRequired === 'yes') {
        json.access_required = true
        json.email = this.email
      }
      return json
    }
  },
  data () {
    return {
      loading: true,
      roleTypes: [],
      firstName: null,
      lastName: null,
      accessRequired: null,
      email: null,
      roleType: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.api.route + '/role-types'
      this.$http.get(endpoint).then(response => {
        this.roleTypes = response.data
        this.roleTypes.forEach(roleType => {
          if (roleType.code === 'member') {
            this.roleType = roleType
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        console.log(response.data);
        this.$buefy.toast.open({ type: 'is-success', message: 'Team member added!' })
        this.$router.push({ name: 'partner.people.general', params: { code: this.context.code, id: response.data.id } })
      }).catch(error => {
        console.log(error)
        this.errors = error.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
