<template>
  <b-tag :type="presenter.class" :size="size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.position.paused_at) {
        presenter.class = 'is-danger is-light'
        presenter.label = 'Paused'
      } else if (this.position.started_at) {
        presenter.class = 'is-success is-light'
        presenter.label = 'Active'
      } else if (this.position.approved_at) {
        presenter.class = 'is-info is-light'
        presenter.label = 'Approved'
      } else if (this.position.completed_at) {
        presenter.class = 'is-info is-light'
        presenter.label = 'Completed'
      } else {
        presenter.class = 'is-grey-light'
        presenter.label = 'Pending'
      }
      return presenter
    }
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: null
    }
  }
}
</script>
