<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Revise Funding</p>
      </header>
      <section class="modal-card-body">
        <!-- Messaging -->
        <b-message v-if="'message' in errors" type="is-danger" class="is-size-7">
          {{ errors.message }}
        </b-message>
        <!-- Revision name & purpose -->
        <div class="mb-lg">
          <b-field label="Revision Description">
            <b-input
              type="text"
              size="is-medium"
              v-model="name"
            ></b-input>
          </b-field>
          <div class="mb-lg">
            <div class="mb-sm">
              <p><label class="label">Description</label></p>
              <p class="has-text-grey is-size-7">Please describe the reason & purpose for this revision.</p>
            </div>
            <b-input
              type="textarea"
              v-model="description"
            ></b-input>
          </div>
        </div>
        <!-- Summary -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <div class="has-background-light pa-md has-text-centered">
                <p class="is-size-5 has-text-weight-bold"><Currency :value="summary.original" /></p>
                <p class="has-text-grey is-size-7">Original Funding Value</p>
              </div>
            </div>
            <div class="column">
              <div class="has-background-light pa-md has-text-centered">
                <p class="is-size-5 has-text-weight-bold"><Currency :value="summary.revised" /></p>
                <p class="has-text-grey is-size-7">Revised Funding Value</p>
              </div>
            </div>
            <div class="column">
              <div class="has-background-light pa-md has-text-centered">
                <p class="is-size-5 has-text-weight-bold" :class="summary.differenceClass"><Currency :value="summary.difference" /></p>
                <p class="has-text-grey is-size-7">Revision Value</p>
              </div>
            </div>
          </div>
        </div>
        <!-- FundingLines -->
        <div v-if="funding.lines.length > 0">
          <p class="has-text-weight-bold border-b pb-sm">Funding Line Items</p>
          <b-table
            :data="lines"
            :mobile-cards="false"
            hoverable
            class="is-size-7">
            <b-table-column
              label="Type"
              v-slot="props">
              <p>{{ props.row.lineName }}</p>
            </b-table-column>
            <b-table-column
              label="Original"
              v-slot="props"
              centered>
              <Currency :value="props.row.originalValue" />
            </b-table-column>
            <b-table-column
              label="Revised"
              v-slot="props"
              width="200"
              centered>
              <b-input v-model="props.row.revisedValue" type="numeric" />
            </b-table-column>
          </b-table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
          @click="save"
        >Add Revision</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(
        this.name &&
        this.description &&
        this.lines.length > 0
      )
    },
    json () {
      const json = {
        name: this.name,
        description: this.description,
        funding_revision_lines: []
      }
      this.lines.forEach(line => {
        json.funding_revision_lines.push({
          resource_type: 'funding_line',
          resource_id: line.lineId,
          revised_amount: line.revisedValue
        })
      })
      return json
    },
    summary () {
      const summary = {
        original: 0,
        revised: 0,
        difference: 0,
        differenceClass: null
      }
      this.lines.forEach(line => {
        summary.original += Number(line.originalValue)
        summary.revised += Number(line.revisedValue)
      })
      summary.difference = Number(summary.revised - summary.original).toFixed(2)
      summary.differenceClass = (summary.difference >= 0) ? 'has-text-success' : 'has-text-info'
      return summary
    }
  },
  data () {
    return {
      name: null,
      description: null,
      lines: [],
      saving: false,
      errors: []
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/funding/' + this.funding.id + '/revisions'
      this.$http.post(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Revision added!' })
        this.$emit('revision:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.funding.lines.forEach(line => {
      this.lines.push({
        lineId: line.id,
        lineName: (line.description) ? line.description : line.funding_line_type.name,
        originalValue: Number(line.amount).toFixed(2),
        revisedValue: Number(line.amount).toFixed(2)
      })
    })
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
