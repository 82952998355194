import Detail from './views/Detail'
import Summary from './views/Summary'
import Activities from './views/Activities'
import Statements from './views/Statements'
import Statement from './views/Statement'

export default [{
  path: '/network/:code/work/purchase-orders/:id',
  component: Detail,
  children: [{
    path: 'activities',
    name: 'network.order.activities',
    component: Activities
  }, {
    path: 'statements',
    name: 'network.order.statements',
    component: Statements
  }, {
    path: 'statements/:statement',
    name: 'network.order.statement',
    component: Statement
  }, {
    path: '',
    name: 'network.order',
    component: Summary
  }]
}]
