<template>
  <b-dropdown
    ref="dropdown"
    class="has-text-left"
    position="is-bottom-left"
    :close-on-click="false"
    @active-change="reset">
    <template #trigger="{ active }">
      <b-button
        label="Options"
        type="is-ghost is-size-7"
        outlined
        :icon-right="active ? 'menu-up' : 'menu-down'" />
    </template>
    <b-dropdown-item v-if="!confirmRemoval" @click="confirm">Unlink Funding</b-dropdown-item>
    <b-dropdown-item v-else @click="unlink" class="has-text-danger">Confirm?</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.route + '/funding/' + this.funding.id
    }
  },
  data () {
    return {
      saving: true,
      confirmRemoval: false
    }
  },
  methods: {
    confirm () {
      this.confirmRemoval = true
    },
    unlink () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/unlink'
      const body = {
        funding_id: this.funding.id
      }
      this.$http.post(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Funding removed!' })
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.$refs.dropdown.toggle()
        this.saving = false
      })
    },
    reset (status) {
      if (status) this.confirmRemoval = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
