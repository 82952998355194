<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Publish Client Invoice</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Platform">
          <PlatformAccountSelect v-model="account" />
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Export Invoice</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/Models/PlatformAccount/SelectMenu'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      if (!this.account) return null
      switch (this.account.platform.code) {
        case 'quickbooks':
          return this.api.route + '/admin/quickbooks/publish-invoice'
        default:
          return null
      }
    },
    json () {
      return {
        client_invoice_id: this.invoice.id
      }
    },
    ready () {
      return (this.account)
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Invoice published!' })
        this.$emit('invoice:published', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      account: null,
      saving: false,
      errors: []
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
