<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Upload Logo</p>
      </header>
      <section class="modal-card-body">
        <!-- Image File Preview -->
        <div class="mb-lg">
          <div v-if="file">
            <Cropper
              ref="cropper"
              :autoZoom="true"
              class="upload-example-cropper"
              :src="image.src"
              :default-size="{
                width: 300,
                height: 100
              }"
              :stencil-size="{ width: 300, height: 100 }"
              :stencil-props="{ movable: false, resizable: false, aspectRatio: 3/1 }"
            />
          </div>
          <div v-else class="pa-xl has-background-light has-text-centered">
            <p>Upload an image file to add a logo</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-button
              type="is-primary"
              @click="reset"
              :disabled="!file"
              expanded
              outlined
            >Reset</b-button>
          </div>
          <div class="column">
            <b-field class="file is-primary s">
              <b-upload ref="file" v-model="file" @input="loadImage" accept="image/*" expanded>
                <a class="button is-primary is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span>Select File</span>
                </a>
              </b-upload>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="close">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Upload</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// func
function getMimeType (file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

export default {
  beforeUnmount () {
    alert('allo')
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
  components: {
    Cropper
  },
  data () {
    return {
      saving: false,
      ready: false,
      file: null,
      image: {
        src: null,
        type: null
      }
    }
  },
  methods: {
    crop () {
      const { canvas } = this.$refs.cropper.getResult()
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type)
    },
    close () {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src)
      }
      this.$parent.close()
    },
    reset () {
      this.file = null
      this.image = {
        src: null,
        type: null
      }
    },
    loadImage () {
      // ensure we have a fule
      if (this.file) {
        // revoke the object URL to allow the garbage collector to
        // destroy the uploaded before another file is uploaded
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(this.file)
        // create a new FileReader to read this image binary data
        const reader = new FileReader()
        reader.onloadend = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, this.file.type)
          }
        }
        // start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(this.file)
      }
    }
  }
}
</script>
