<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Contributor Rate</p>
      </header>
      <section class="modal-card-body">
        <!-- Change Rate -->
        <div class="mb-lg">
          <b-message type="is-info is-size-7">
            The current rate for <b>{{ seat.contributor.user.full_name }}</b> is
            <b>
              <Currency :value="seat.rate.rate" />
              {{ (seat.rate.model === 'hourly') ? '/ hr' : ''}}
            </b>
            and was made effective <b>{{ $dates(seat.rate.start_date).format("MMMM D, YYYY") }}</b>.
            Please enter a new rate and effective date below. All <u>open</u> timesheets
            will be automatically updated, but project leaders may need to adjust their project plans.
          </b-message>
          <b-field
            label="Effective Date"
            :type="('effective_date' in errors) ? 'is-danger' : ''"
            :message="('effective_date' in errors) ? errors['effective_date'][0] : ''">
            <b-input
              size="is-medium"
              type="date"
              :min="minimumDate"
              v-model="effectiveDate"
            ></b-input>
          </b-field>
          <!-- New Rates -->
          <div class="columns">
            <div class="column">
              <b-field
                label="Cost Rate"
                :type="('rate' in errors) ? 'is-danger' : ''"
                :message="('rate' in errors) ? errors['rate'][0] : ''">
                <b-input
                  size="is-medium"
                  type="number"
                  :step="0.01"
                  v-model="newCostRate"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Bill Rate"
                :type="('bill_rate' in errors) ? 'is-danger' : ''"
                :message="('bill_rate' in errors) ? errors['bill_rate'][0] : ''">
                <b-input
                  size="is-medium"
                  type="number"
                  :step="0.01"
                  v-model="newBillRate"
                ></b-input>
              </b-field>
            </div>
          </div>
          <!-- New Rates -->
          <!-- <div class="pt-sm">
            <b-field>
              <b-checkbox v-model="updatePlanRates">Update Plan Rates</b-checkbox>
            </b-field>
          </div> -->
        </div>
        <!-- Rate History -->
        <div>
          <!-- View Rates -->
          <div v-if="!loadingRateHistory && seatWithRates">
            <p class="is-uppercase has-text-weight-bold is-size-7 has-text-centered has-text-primary">Rate History</p>
            <table class="table is-fullwidth is-size-7 is-narrow">
              <thead>
                <tr>
                  <th>Effective Date</th>
                  <th class="has-text-centered">Cost Rate</th>
                  <th class="has-text-centered">Bill Rate</th>
                  <th class="has-text-centered">Margin</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rate, index) in seatWithRates.rates" :key="index">
                  <td>{{ $dates(rate.start_date).format('MMM D, YYYY') }}</td>
                  <td class="has-text-centered">
                    <Currency :value="rate.rate" />
                  </td>
                  <td class="has-text-centered">
                    <Currency :value="rate.bill_rate" />
                  </td>
                  <td class="has-text-centered">
                    <Margin :revenue="rate.bill_rate" :cogs="rate.rate" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Loading -->
          <div v-else><Loading /></div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save Rate</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    minimumDate () {
      return this.$dates(this.project.start_date).format('YYYY-MM-DD')
    },
    ready () {
      return (this.effectiveDate && this.newCostRate && this.newBillRate)
    },
    json () {
      return {
        effective_date: this.effectiveDate,
        rate: this.newCostRate,
        bill_rate: this.newBillRate
      }
    }
  },
  data () {
    return {
      loadingRateHistory: false,
      seatWithRates: null,
      effectiveDate: null,
      newCostRate: null,
      newBillRate: null,
      updatePlanRates: true,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadRateHistory () {
      this.loadingRateHistory = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/rates'
      this.$http.get(endpoint).then(response => {
        this.seatWithRates = response.data
        this.loadingRateHistory = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/rates'
      this.$http.post(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Rate changed!' })
        this.$emit('seat:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadRateHistory()
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
