<template>
  <div>
    <!-- Filter & Search Input Controls -->
    <form @submit.prevent="search">
      <b-field>
        <template v-if="allowSearch">
          <p v-if="searchParams.length" class="control">
            <b-select v-model="searchParam" size="is-medium">
              <option v-for="param in searchParams" :key="param.code" :value="param.code">{{ param.label }}</option>
            </b-select>
          </p>
          <b-input icon="magnify" type="search" size="is-medium" placeholder="Search within results..." expanded v-model="searchQuery"></b-input>
          <p class="control">
            <button type="submit" class="button is-primary is-medium">
              <b-icon icon="keyboard-return" custom-class="mdi-flip-h"></b-icon>
            </button>
          </p>
        </template>
        <template v-if="allowFilters">
          <p class="control" :class="(allowSearch) ? 'ml-md' : ''">
            <button type="button" class="button is-medium" slot="trigger" size="is-medium" @click="showFiltersModal = true">
              <b-icon icon="filter-variant"></b-icon>
            </button>
          </p>
        </template>
      </b-field>
    </form>
    <!-- Filter Modal -->
    <b-modal v-if="allowFilters" :active.sync="showFiltersModal" has-modal-card animation="none" :can-cancel="['escape', 'x']" :on-cancel="close">
      <div class="modal-card">
        <section class="modal-card-body is-paddingless">
          <!-- Instructions -->
          <p class="has-background-white-ter has-text-centered pa-sm border-b"
            :class="(hasChanges) ? 'has-text-danger' : 'has-text-grey-light'">
            {{ instructions }}
          </p>
          <!-- Filters Slot -->
          <slot></slot>
        </section>
        <footer class="modal-card-foot" style="justify-content:space-between">
          <div class="buttons is-marginless">
            <button class="button" type="button" @click="close">Close</button>
            <button class="button" type="button" @click="clear">Clear All</button>
          </div>
          <button class="button is-primary" @click="update" :disabled="!hasChanges">Update</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showFiltersModal: false,
      searchParam: this.defaultSearchParam,
      searchQuery: null
    }
  },
  computed: {
    instructions () {
      return (this.hasChanges)
        ? 'Click \'Update\' to apply new filters.'
        : 'Explore the filters below to target specific results.'
    }
  },
  methods: {
    search () {
      this.$emit('search', { param: this.searchParam, query: this.searchQuery })
    },
    update () {
      this.$emit('update')
      this.showFiltersModal = false
      this.$buefy.toast.open({ type: 'is-success', message: 'Filters updated!' })
    },
    close () {
      if (this.hasChanges) {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Filters not applied.' })
      }
      this.showFiltersModal = false
    },
    clear () {
      this.$emit('clear')
      this.searchQuery = null
      this.showFiltersModal = false
      this.$buefy.toast.open({ type: 'is-success', message: 'Filters cleared.' })
    }
  },
  mounted () {
    if ('query' in this.appliedFilters.search && this.appliedFilters.search.query !== '') {
      this.searchParam = this.appliedFilters.search.parameter
      this.searchQuery = this.appliedFilters.search.query
    } else {
      this.searchParam = this.defaultSearchParam
    }
  },
  props: {
    hasChanges: {
      type: Boolean
    },
    appliedFilters: {
      type: Object
    },
    allowFilters: {
      type: Boolean,
      default: true
    },
    allowSearch: {
      type: Boolean,
      default: true
    },
    searchParams: {
      type: Array,
      default () {
        return []
      }
    },
    defaultSearchParam: {
      type: String,
      default () {
        return 'search'
      }
    }
  }
}
</script>
