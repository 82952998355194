<template>
  <div>
    <div>
      <!-- Error Handling -->
      <div v-if="typeof this.errors === 'object' && Object.keys(this.errors).length !== 0">
        <RequestErrors :errors="errors" />
      </div>
      <div class="mb-lg">
        <div class="columns space-between is-hidden-print">
          <!-- Summary -->
          <div class="column">
            <div class="has-background-white has-shadow py-md px-xl is-fullheight">
              <div class="columns is-vcentered">
                <div class="column">
                  <p class="is-size-4">{{ $dates(timesheet.billing_period.start_date).format('MMMM YYYY') }}</p>
                </div>
                <div class="column">
                  <p class="has-text-right"><a href="#" @click.prevent="showInstructionsModal = true">Timesheet Instructions</a></p>
                </div>
              </div>
              <div class="columns">
                <!-- <div v-if="'allocations' in timesheet.seat && timesheet.seat.allocations.length" class="column">
                  <div class="has-background-light has-shadow pa-sm  has-text-weight-bold">
                    <p class="is-size-5 has-text-weight-bold"><Hours :hours="timesheet.seat.allocations[0].units" /></p>
                    <p class="is-size-8 is-uppercase has-text-grey">Allocated</p>
                  </div>
                </div> -->
                <div class="column">
                  <div class="has-text-centered py-lg has-border-r">
                    <p class="is-size-3" :class="summary.hoursClass"><Hours :hours="summary.hours" /></p>
                    <p class="is-size-8 has-text-grey">Actual Hours</p>
                  </div>
                </div>
                <div class="column">
                  <div class="has-text-centered py-lg has-border-r">
                    <p class="is-size-3">
                      <Currency v-if="summary.rate" :value="summary.rate" />
                      <Currency v-else :value="timesheet.seat.plan_cost_rate" />
                    </p>
                    <p class="is-size-8 has-text-grey">Rate</p>
                  </div>
                </div>
                <div class="column">
                  <div class="has-text-centered py-lg">
                    <p class="is-size-3"><Currency :value="summary.earnings" /></p>
                    <p class="is-size-8 has-text-grey">Expected Earnings</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Actions -->
          <div class="column is-one-third">
            <div class="has-background-white has-shadow px-xl is-fullheight">
              <div>
                <div class="has-text-centered pa-sm mb-sm">
                  <span class="has-text-grey has-text-weight-bold">Timesheet Status &nbsp;</span>
                  <span class="is-size-5"><StatusPresenter :timesheet="timesheet" /></span>
                </div>
                <!-- Save -->
                <div class="mb-sm">
                  <b-button
                    type="is-primary"
                    expanded
                    outlined
                    @click="saveHours"
                    :disabled="!canSave || saving"
                    :loading="saving"
                  >Save Hours</b-button>
                </div>
                <!-- Submit -->
                <div v-if="timesheet.submitted_at === null" class="mb-sm">
                  <b-button
                    type="is-primary"
                    outlined
                    expanded
                    @click="showSubmitTimesheetModal = true"
                    :disabled="!canSubmit || saving"
                    :loading="saving"
                  >Submit Final Timesheet</b-button>
                </div>
                <!-- Withdraw -->
                <div v-else-if="timesheet.submitted_at !== null" class="mb-sm">
                  <b-button
                    type="is-primary"
                    outlined
                    expanded
                    @click="showWithdrawTimesheetModal = true"
                    :disabled="!canWithdraw || saving"
                    :loading="saving"
                  >Withdraw Timesheet</b-button>
                </div>
                <!-- Export -->
                <div class="mb-sm py-sm has-text-centered">
                  <a href="#"
                    @click.prevent="exportHours"
                  >Export Hours to XLS</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Calendar -->
    <div class="has-background-white has-shadow mb-md">
      <div class="pa-md py-lg">
        <div class="columns is-vcentered">
          <div class="column"></div>
          <div class="column"></div>
          <div class="column">
            <div class="has-text-right pr-lg">
              <p class="is-pulled-right ml-lg"><b-icon icon="close-circle-outline" type="is-primary" size="is-small" class="mr-sm"/>Did not work</p>
              <p class="is-pulled-right"><b-icon icon="note-outline" type="is-success" size="is-small" class="mr-sm"/>Notes</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Toolbar -->
      <div class="has-background-white pa-md rounded-lg">
        <!-- Headers -->
        <div class="border-b pb-md mb-lg is-hidden-mobile">
          <div class="columns has-text-weight-bold is-gapless">
            <div class="column">
              <p class="has-text-centered">Sun</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Mon</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Tue</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Wed</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Thu</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Fri</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Sat</p>
            </div>
            <div class="column">
              <p class="has-text-centered">Week Total</p>
            </div>
          </div>
        </div>
        <!-- Weekly Inputs -->
        <div v-for="(week, index) in timesheet.billing_period.weeks" :key="week.id" :class="{ 'mb-lg': index !== timesheet.billing_period.weeks.length - 1 }">
          <WeekInput :period="timesheet.billing_period" :week="week" :timesheet="timesheet" :forecasts="forecasts" @set:activity="setActivities" @edit:activity="editActivities" />
        </div>
      </div>
    </div>
    <!-- Monthly Notes -->
    <div class="mb-md">
      <div class="has-background-white pa-md rounded-lg">
        <div class="has-text-centered mb-md is-size-7">
          <p class="has-text-weight-bold is-uppercase">Monthly Notes</p>
          <p class=" has-text-grey-light">If you have any notes for your project lead, please include them in the space below</p>
        </div>
        <b-field>
          <b-input
            type="textarea"
            v-model="notes"
            :disabled="!canSave"
          ></b-input>
        </b-field>
      </div>
    </div>
    <!-- Daily Notes -->
    <div v-if="hoursWithNotes.length" class="mb-md">
      <div class="has-background-white pa-md rounded-lg">
        <div class="is-size-7 has-text-centered mb-md">
          <p class="has-text-weight-bold is-uppercase">Daily Notes</p>
        </div>
        <div v-for="(currentHours, index) in hoursWithNotes" :key="currentHours.id" class="is-size-7 pb-md" :class="{ 'mb-md border-b': index !== hoursWithNotes.length - 1 }">
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <span currentHours class="has-text-weight-bold">{{ formatNotesDate(currentHours) }}</span>
            </div>
            <div class="column">
              <p class="has-text-grey">{{ currentHours.notes }}</p>
            </div>
            <div class="column is-narrow">
              <b-tag v-if="currentHours.is_unavailable" type="is-primary" size="is-small">Unavailable</b-tag>
              <b-tag v-else type="is-primary" size="is-small">{{ (currentHours.hours) ? currentHours.hours : 0 }} hours</b-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Submit Modal -->
    <b-modal :active.sync="showSubmitTimesheetModal"
      has-modal-card
      @close="contact = null">
      <SubmitTimesheetModal :timesheet="timesheet" @submit:hours="submitHours" />
    </b-modal>
    <!-- Withdraw Modal -->
    <b-modal :active.sync="showWithdrawTimesheetModal"
      has-modal-card
      @close="contact = null">
      <WithdrawTimesheetModal :timesheet="timesheet" @timesheet:saved="$emit('hours:saved')" />
    </b-modal>
    <!-- Instructions Modal -->
    <b-modal :active.sync="showInstructionsModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Timesheet Instructions</p>
        </header>
        <section class="modal-card-body">
          <div class="pa-xl">
            <p class="mb-md">
              Enter your time by adding the total number of hours you
              worked each day. Providing hours on a regular basis allows your project
              lead to accurately forecast your overall progress
              <span class="has-text-weight-bold">so please update your hours weekly.</span>
            </p>
            <p>
              <span class="has-text-weight-bold has-text-danger">Please Note: </span>
              your final timesheet needs to be submitted to your project leader
              before <span class="has-text-weight-bold"><Date :value="timesheet.billing_period.hours_due_on" /></span>
              for approval. If you need to make changes after a period has
              closed, please contact your project leader.
            </p>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <button class="button" type="button" @click="showInstructionsModal = false">Close</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import WeekInput from '../components/Timesheets/WeekInput'
import StatusPresenter from '@/components/Models/Timesheet/Presenters/Status'
import SubmitTimesheetModal from '@/components/Models/Timesheet/Modals/Submit'
import WithdrawTimesheetModal from '@/components/Models/Timesheet/Modals/Withdraw'

export default {
  components: {
    StatusPresenter,
    SubmitTimesheetModal,
    WithdrawTimesheetModal,
    WeekInput
  },
  computed: {
    ...mapGetters(['api']),
    canSave () {
      // rule out periods & timesheets that are closed
      if (this.timesheet.billing_period.closed_at || this.timesheet.closed_at) return false
      // rule out timesheets that have already been submitted or approved...
      if (this.timesheet.submitted_at || this.timesheet.approved_at) return false
      // make sure we are in the billing period
      if (this.$dates().isBefore(this.timesheet.billing_period.start_date, 'day')) return false
      // otherwise, we're good...
      return true
    },
    canSubmit () {
      // rule out periods & timesheets that are closed
      if (this.timesheet.billing_period.closed_at || this.timesheet.closed_at) return false
      // rule out timesheets that have already been submitted or approved...
      if (this.timesheet.submitted_at || this.timesheet.approved_at) return false
      // next, ensure that the current date is equal to or after the due date
      if (this.$dates().isBefore(this.timesheet.billing_period.hours_due_on)) return false
      return true
    },
    canWithdraw () {
      // rule out periods & timesheets that are closed
      if (this.timesheet.billing_period.closed_at || this.timesheet.closed_at) return false
      // rule out timesheets that have not been submitted
      if (!this.timesheet.submitted_at || this.timesheet.approved_at) return false
      return true
    },
    fullMonthName () {
      return this.$dates(this.timesheet.billing_period.start_date).format('MMMM')
    },
    summary () {
      const summary = {
        allocated: 0,
        hours: 0,
        earnings: 0,
        hoursClass: 'has-text-success'
      }
      if (this.timesheet) {
        this.timesheet.activities.forEach(activity => {
          summary.hours += Number(activity.hours_reported)
          summary.earnings += Number(activity.cost)
        })
        summary.rate = (summary.hours > 0) ? summary.earnings / summary.hours : 0
      }
      if (this.forecasts.length) {
        this.forecasts.forEach((forecast) => {
          summary.allocated += forecast.hours
        })
      }
      if (summary.hours && summary.allocated) {
        if (summary.hours > summary.allocated) {
          summary.hoursClass = 'has-text-warning'
        } else if (summary.hours < summary.allocated) {
          summary.hoursClass = 'has-text-info'
        }
      }
      return summary
    },
    hoursWithNotes () {
      return Object.values(this.hours).filter(data => data.notes)
    }
  },
  data () {
    return {
      loadingForecasts: true,
      saving: false,
      errors: {},
      forecasts: [],
      notes: null,
      hours: {},
      showSaveReminder: false,
      showSubmitTimesheetModal: false,
      showWithdrawTimesheetModal: false,
      showInstructionsModal: false
    }
  },
  methods: {
    loadForecasts () {
      this.loadingForecasts = true
      const endpoint = this.api.route + '/timesheets/' + this.timesheet.id + '/forecasts'
      this.$http.get(endpoint).then(response => {
        this.forecasts = response.data
        this.loadingForecasts = false
      })
    },
    setActivities (days) {
      days.forEach(day => {
        if (day.date && !day.disabled) {
          this.$set(this.hours, day.date, day)
        }
      })
    },
    editActivities (days) {
      days.forEach(day => {
        if (day.date && !day.disabled) {
          this.$set(this.hours, day.date, day)
        }
      })
      this.showSnackbarReminder()
    },
    showSnackbarReminder () {
      if (!this.showSaveReminder) {
        this.showSaveReminder = true
        this.$buefy.snackbar.open({
          indefinite: true,
          message: 'You have unsaved changes to your hours.',
          actionText: 'Save Changes',
          onAction: () => {
            this.save(false)
          }
        })
      }
    },
    submitHours () {
      this.save(true)
    },
    saveHours () {
      this.save(false)
    },
    save (submit = false) {
      this.saving = true
      const endpoint = this.api.route + '/timesheets/' + this.timesheet.id + '/hours'
      const body = {
        submit: (submit) ? 1 : 0,
        activities: [],
        notes: this.notes
      }
      for (const date in this.hours) {
        const activity = {
          date: date,
          hours: null,
          notes: null,
          is_unavailable: 0
        }
        // only set hours if the key is set and the value can evaluate as a number gte 0
        if ('hours' in this.hours[date]) {
          const hours = Number(this.hours[date].hours)
          if (!Number.isNaN(hours) && hours >= 0) {
            activity.hours = this.hours[date].hours
          }
        }
        if ('notes' in this.hours[date]) {
          activity.notes = (this.hours[date].notes) ? this.hours[date].notes : null
        }
        if ('is_unavailable' in this.hours[date]) {
          activity.is_unavailable = (this.hours[date].is_unavailable) ? 1 : 0
        }
        body.activities.push(activity)
      }
      this.$http.put(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Hours saved!' })
        this.$emit('hours:saved')
        this.showSaveReminder = false
        this.errors = {}
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Your hours could not be saved!' })
        this.errors = error.response.data
      }).finally(() => {
        this.saving = false
      })
    },
    exportHours () {
      const endpoint = this.api.route + '/timesheets/' + this.timesheet.id + '/export'
      const filename = this.timesheet.seat.position.project.client.organization.filesystem_name +
        '_' + this.timesheet.seat.position.project.filesystem_name +
        '_' + this.timesheet.billing_period.label_filesystem +
        '.xlsx'
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
    },
    formatNotesDate (date) {
      return this.$dates(date.date).format('MMM D')
    }
  },
  mounted () {
    // this.loadForecasts()
    if (this.timesheet) {
      this.notes = this.timesheet.notes
    }
  },
  props: {
    timesheet: {
      type: Object,
      required: true
    }
  },
  watch: {
    timesheet (timesheet) {
      if (timesheet) {
        this.hours = {}
        // this.loadForecasts()
      } else {
        this.forecasts = []
      }
    }
  }
}
</script>
