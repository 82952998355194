import Index from './views/Index'
import Timesheet from './views/Timesheet'
import Hours from './views/Hours'
import Expenses from './views/Expenses'
import Expense from './views/Expense'
import Attachments from './views/Attachments'
import Invoices from './views/Invoices'

export default [{
  path: '/partner/:code/projects',
  name: 'partner.projects',
  component: Index
}, {
  path: '/partner/:code/seats/:seat/timesheets/:timesheet',
  component: Timesheet,
  children: [{
    path: 'expenses',
    name: 'partner.timesheet.expenses',
    component: Expenses
  }, {
    path: 'expenses/create',
    name: 'partner.timesheet.expense.create',
    component: Expense
  }, {
    path: 'expenses/:expense/attachments',
    name: 'partner.timesheet.expense.attachments',
    component: Attachments
  }, {
    path: 'expenses/:expense',
    name: 'partner.timesheet.expense',
    component: Expense
  }, {
    path: 'invoices',
    name: 'partner.timesheet.invoices',
    component: Invoices
  }, {
    path: 'hours',
    name: 'partner.timesheet.hours',
    component: Hours
  }]
}]
