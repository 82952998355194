<template>
  <div>
    <div>
      <div>
        <!-- User Loaded Loaded -->
        <div v-if="!loading && role !== null">
          <!-- Header Loaded -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-5 has-text-weight-bold">{{ role.user.full_name }}</p>
                <p class="has-text-grey is-size-7 has-text-grey-light">ID: {{ role.id }}</p>
              </div>
            </div>
          </div>
          <!-- Actions -->
          <div class="has-background-white rounded-lg px-lg py-md mb-lg">
            <Workflow :role="role"
              @change:role="showEditModal = true"
              @activate:role="showActivationModal = true"
              @suspend:role="showSuspensionModal = true"
            />
          </div>
          <!-- Profile -->
          <div class="has-background-white rounded-lg px-lg py-md mb-lg">
            <div class="columns">
              <div class="column">
                <h1 class="has-text-weight-bold has-border-b pb-sm">General Information</h1>
                <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                  <tbody>
                    <tr>
                      <td>ID</td>
                      <td class="has-text-right">{{ role.id }}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td class="has-text-right">{{ role.user.full_name }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td class="has-text-right">{{ role.user.email }}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td class="has-text-right">{{ $utils.upperFirst(role.type.name) }}</td>
                    </tr>
                    <tr>
                      <td>Created</td>
                      <td class="has-text-right">
                        <span v-if="role !== null">{{ $dates.utc(role.created_at).format('MMM D, YYYY') }}</span>
                        <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading Skeleton -->
        <div v-else>
          <Loading message="Loading details" />
        </div>
      </div>
    </div>
    <!-- Modal: Change Role -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditModal"
      :can-cancel="['x', 'esc']"
      ><EditRoleModal :current="role" @role:saved="load(true)" />
    </b-modal>
    <!-- Modal: Suspend Role -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showSuspensionModal"
      :can-cancel="['x', 'esc']"
      ><SuspendRoleModal :role="role" @role:suspended="load(true)" />
    </b-modal>
    <!-- Modal: Activate Role -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showActivationModal"
      :can-cancel="['x', 'esc']"
      ><ActivateRoleModal :role="role" @role:activated="load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Workflow from '../components/Role/Workflow'
import ActivateRoleModal from '@/components/Models/Role/ActivateRoleModal'
import EditRoleModal from '@/components/Models/Role/EditRoleModal'
import SuspendRoleModal from '@/components/Models/Role/SuspendRoleModal'

export default {
  components: {
    ActivateRoleModal,
    EditRoleModal,
    SuspendRoleModal,
    Workflow
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/admin/roles/' + this.$route.params.id
    }
  },
  data: function () {
    return {
      loading: true,
      role: null,
      showEditModal: false,
      showSuspensionModal: false,
      showActivationModal: false
    }
  },
  methods: {
    load (refresh) {
      if (!refresh) {
        this.loading = true
      }
      this.$http.get(this.endpoint).then(response => {
        this.role = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
