<template>
  <div>
    <p class="has-text-weight-bold border-b pb-sm is-size-7">
      <span class="has-text-grey-light">Budget: </span>
      <span class="has-text-primary">{{ line.role }}</span>
    </p>
    <b-table
      :data="line.deliveries"
      :mobile-cards="false"
      hoverable
      narrowed
      class="is-size-7">
      <b-table-column
        label="ID"
        v-slot="props"
        cell-class="nowrap has-text-grey-light"
        width="64">
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        label="Delivery"
        cell-class="nowrap"
        v-slot="props">
        <p>{{ props.row.title }}</p>
      </b-table-column>
      <b-table-column
        label="Est. Hours"
        centered
        width="128"
        cell-class="nowrap"
        v-slot="props">
        <Hours :hours="props.row.hours_actual" />
      </b-table-column>
      <b-table-column
        label="Billable ($)"
        centered
        width="128"
        cell-class="nowrap"
        v-slot="props">
        <Currency :value="props.row.billable_total" />
      </b-table-column>
      <b-table-column
        label="Cost"
        centered
        width="96"
        v-slot="props">
        <Currency :value="props.row.cost" />
      </b-table-column>
      <b-table-column
        label="Margin"
        centered
        width="96"
        v-slot="props">
        <Margin :revenue="props.row.billable_total" :cogs="props.row.cost"  />
      </b-table-column>
      <b-table-column
        label="Status"
        v-slot="props"
        centered
        width="96">
        <DeliveryStatus :delivery="props.row" />
      </b-table-column>
      <template #empty>
        <div class="has-text-centered pa-lg">No deliveries were submitted for billing during this period.</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import DeliveryStatus from '@/components/Models/Delivery/Presenters/Status'

export default {
  components: {
    DeliveryStatus
  },
  props: {
    line: {
      type: Object,
      required: true
    }
  }
}
</script>
