<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ (this.transaction) ? 'Edit' : 'Add' }} Transaction
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Network Identifier">
          <b-input
            type="text"
            size="is-medium"
            v-model="networkIdentifier"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="!ready"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['api']),
    method () {
      return (this.transaction) ? 'patch' : 'post'
    },
    endpoint () {
      const base = this.api.route + '/admin/client-invoices'
      return (this.transaction) ? base + '/' + this.transaction.id : base
    },
    json () {
      const line = {
        network_identifier: this.networkIdentifier
      }
      return line
    },
    ready () {
      return Boolean(this.networkIdentifier)
    }
  },
  data () {
    return {
      networkIdentifier: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Transaction updated!' })
        this.$emit('transaction:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.networkIdentifier = (this.transaction) ? this.transaction.network_identifier : null
  },
  props: {
    transaction: {
      type: Object,
      required: true
    }
  }
}
</script>
