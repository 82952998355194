<template>
  <div>
    <b-select
      v-model="role"
      size="is-medium"
      placeholder="Select User"
      :loading="loadingRoles"
      @input="announce"
      expanded>
      <option v-for="role in roles" :key="role.id" :value="role">
        {{ role.label }}
      </option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingRoles: false,
      roles: [],
      role: null
    }
  },
  methods: {
    loadRoles () {
      this.loadingRoles = true
      const endpoint = this.api.route + '/roles/leadership'
      this.$http.get(endpoint).then(response => {
        this.roles = response.data
        this.loadingRoles = false
        if (this.value) {
          const role = this.roles.find(role => {
            // check roles
            if ('user_id' in this.value) {
              return role.user_id === this.value.user_id
            }
            // check user
            return role.user_id === this.value.id
          })
          if (role) {
            this.role = role
            this.announce()
          }
        }
      })
    },
    announce () {
      this.$emit('input', this.role)
    }
  },
  mounted () {
    this.loadRoles()
  },
  props: ['value']
}
</script>
