<template>
  <b-dropdown position="is-bottom-left">
    <template #trigger="{ active }">
      <b-button
        :label="label"
        :type="type"
        :size="size"
        :append-to-body="true"
        :icon-right="active ? 'menu-up' : 'menu-down'"
        outlined
        expanded
      ></b-button>
    </template>
    <!-- Always Actions -->
    <b-dropdown-item @click="workflowAction('project:settings')">Edit Project Settings</b-dropdown-item>
    <b-dropdown-item @click="workflowAction('project:identifiers')">Link to External Platforms</b-dropdown-item>
    <hr class="dropdown-divider">
    <b-dropdown-item @click="workflowAction('project:dates')">Change Project Dates</b-dropdown-item>
    <b-dropdown-item @click="workflowAction('project:lead')">Change Project Leader</b-dropdown-item>
    <b-dropdown-item @click="workflowAction('project:roles')">Share Project</b-dropdown-item>
    <!-- Funding Actions -->
    <!-- <template v-if="project.funding.length === 0">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('project:funding')">Fund Project</b-dropdown-item>
    </template> -->
    <!-- Active Actions -->
    <template v-if="project.status === 'active'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('project:pause')">Pause Project</b-dropdown-item>
      <b-dropdown-item @click="workflowAction('project:end')">Complete Project</b-dropdown-item>
    </template>
    <!-- Paused Actions -->
    <template v-if="project.status === 'paused'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('project:restart')">Restart Project</b-dropdown-item>
    </template>
    <!-- Pending Actions -->
    <template v-if="project.status === 'pending'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('project:start')">Start Project</b-dropdown-item>
      <b-dropdown-item @click="workflowAction('project:archive')" class="has-text-danger">Archive Project</b-dropdown-item>
    </template>
    <!-- Extra Actions -->
    <hr class="dropdown-divider">
    <!-- <b-dropdown-item has-link>
      <a href="#" @click.prevent="toggleBetaFeatures">Preview Beta Features</a>
    </b-dropdown-item> -->
    <b-dropdown-item has-link>
      <a href="#" @click.prevent="resummarize">Resummarize Project</a>
    </b-dropdown-item>
    <!-- IDs -->
    <template v-if="canCopy">
      <hr class="dropdown-divider">
      <b-dropdown-item  @click="copy(project.id)">Copy Project ID</b-dropdown-item>
    </template>
    <template>
      <hr class="dropdown-divider">
      <b-dropdown-item custom>
        <span class="has-text-grey-light">ID: {{ project.id }}</span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    canCopy () {
      return (navigator.clipboard && window.isSecureContext)
    }
  },
  methods: {
    workflowAction (action) {
      this.$emit(action, this.position)
    },
    toggleBetaFeatures (action) {
      this.$emit('preview')
    },
    resummarize () {
      const endpoint = this.api.route + '/projects/' + this.project.id + '/summarize/all'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.$emit('project:saved')
      })
    },
    copy (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: [String, null],
      default: 'is-ghost'
    },
    size: {
      type: [String, null],
      default: 'is-medium"'
    }
  }
}
</script>
