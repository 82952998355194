<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <p class="is-size-5 has-text-weight-bold mb-md">Start Project?</p>
          <div class="is-size-7">
            <p>
              Once you start this project, all contributors will be able to see and save
              hours against this project. Are you sure you're ready to start this project?
            </p>
          </div>
        </b-message>
      </section>
      <footer class="modal-card-foot has-background-white space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Start Project</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/actions/start'
      this.$http.put(endpoint).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project started!' })
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
