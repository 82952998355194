<template>
  <b-navbar wrapper-class="container">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ name: context.homeRoute, params: { code: context.code } }" style="padding:0px; background:transparent !important">
        <Logo :width="130" height="46" :context="context.type"   />
      </b-navbar-item>
    </template>
    <template #start>
      <!-- Network Tabs -->
      <template v-if="context.type === 'network'">
        <b-navbar-item tag="router-link" :to="{ name: 'network.projects', params: { code: context.code } }">
          <strong>Projects</strong>
        </b-navbar-item>
        <template v-if="role.type.code === 'administrator'">
          <b-navbar-item tag="router-link" :to="{ name: 'network.partners', params: { code: context.code } }">
            <strong>Partners</strong>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: 'network.clients', params: { code: context.code } }">
            <strong>Clients</strong>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: 'network.finance', params: { code: context.code } }">
            <strong>Finance</strong>
          </b-navbar-item>
        </template>
      </template>
      <!-- Partner Tabs -->
      <template v-if="context.type === 'partner'">
        <!-- Onboarding -->
        <!-- <template v-if="!context.isOnboarded">
          <b-navbar-item tag="router-link" :to="{ name: 'partner.onboarding', params: { code: context.code } }">
            <strong class="is-uppercase">Onboarding</strong>
          </b-navbar-item>
        </template> -->
        <!-- Active -->
        <template>
          <b-navbar-item tag="router-link" :to="{ name: 'partner.projects', params: { code: context.code } }">
            <strong>Projects</strong>
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ name: 'partner.reports', params: { code: context.code } }">
            <strong>Reports</strong>
          </b-navbar-item>
        </template>
      </template>
    </template>
    <template #end>
      <!-- Announcements -->
      <b-navbar-item style="background:transparent" tag="router-link" :to="{ name: 'announcements', params: { context: context.type, code: context.code } }">
        <b-icon icon="bell-outline" class="mr-xs"></b-icon>
        <span v-if="app.unreadAnnouncements > 0" class="has-text-danger has-text-weight-bold is-size-5">&bull;</span>
      </b-navbar-item>
      <!-- Context -->
      <b-navbar-dropdown tag="div" arrowless boxed right collapsible>
        <template #label>
          {{ context.name }} <b-icon icon="menu-down" />
        </template>
        <div class="px-md py-xs">
          <p>{{ context.name }}</p>
          <p class="has-text-grey-light">{{ $utils.upperFirst(context.type) }} ID: {{ context.id }}</p>
        </div>
        <hr class="dropdown-divider">
        <div class="px-md py-xs">
          <p>{{ (role.type.name) ? role.type.name : '' }}</p>
          <p class="has-text-grey-light">Role ID: {{ role.id }}</p>
        </div>
        <template v-if="role.type.code === 'administrator'">
          <hr class="dropdown-divider">
          <b-navbar-item v-if="context.type === 'network'" tag="router-link" :to="{ name: 'network.settings', params: { code: context.code }}">
            Network Settings
          </b-navbar-item>
          <b-navbar-item v-if="context.type === 'partner'" tag="router-link" :to="{ name: 'partner.settings', params: { code: context.code }}">
            Partner Settings
          </b-navbar-item>
        </template>
        <template v-if="auth.isStaff || auth.user.roles.length > 1">
          <hr class="dropdown-divider">
          <b-navbar-item tag="router-link" :to="{ name: 'context' }" exact>
            Change Context
          </b-navbar-item>
        </template>
      </b-navbar-dropdown>
      <!-- Auth -->
      <b-navbar-dropdown tag="div" arrowless boxed right collapsible>
        <template #label>
          <span class="">Hi, {{ auth.user.first_name }}</span>
          <b-icon icon="menu-down" />
        </template>
        <div class="px-md py-sm">
          <p class="has-text-weight-bold">{{ auth.user.full_name }}</p>
          <p class="has-text-grey-light">{{ auth.user.email }}</p>
        </div>
        <hr class="dropdown-divider">
        <b-navbar-item tag="router-link" :to="{ name: 'profile', params: { context: context.type, code: context.code} }">
          User Profile
        </b-navbar-item>
        <hr class="dropdown-divider">
        <b-navbar-item tag="a" @click.prevent="logout">Logout</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import Logo from '@/components/Logos/GatherWorks'
import { mapGetters } from 'vuex'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['app', 'api', 'auth', 'context', 'role'])
  },
  data () {
    return {
      demoMode: false,
      numInvitations: 0,
      loadingAnnouncements: false,
      announcements: []
    }
  },
  methods: {
    copied () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
    },
    logout () {
      this.$store.dispatch('logout')
    },
    loadAnnouncements () {
      this.loadingAnnouncements = true
      const endpoint = this.api.route + '/announcements'
      const query = {
        params: {
          unread: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.$store.dispatch('unreadAnnouncements', response.data.meta.total)
        if (response.data.meta.total > 0) {
          this.$emit('announcements:show')
        }
        this.loadingAnnouncements = false
      })
    },
    showInvitations () {
      this.$emit('invitations:show')
    }
  },
  mounted () {
    this.loadAnnouncements()
  }
}
</script>
