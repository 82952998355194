<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Create Transfer</p>
      </header>
      <section class="modal-card-body">
        <!-- Name & Purpose -->
        <div class="mb-lg">
          <b-field label="Transfer Description">
            <b-input
              type="text"
              size="is-medium"
              v-model="name"
            ></b-input>
          </b-field>
          <div class="mb-lg">
            <div class="mb-sm">
              <p><label class="label">Description</label></p>
              <p class="has-text-grey is-size-7">Please describe the reason & purpose for this transfer.</p>
            </div>
            <b-input
              type="textarea"
              v-model="description"
            ></b-input>
          </div>
        </div>
        <!-- Identifiers -->
        <div class="mb-lg">
          <b-field label="Organization">
            <OrganizationSelect v-model="organization" />
          </b-field>
          <b-field label="Funding Source">
            <b-select
              v-model="sourceFundingId"
              size="is-medium"
              placeholder="Select Source"
              :loading="loadingFunding"
              :disabled="organization === null"
              expanded>
              <option v-for="funding in fundings" :key="funding.id" :value="funding.id">
                {{ funding.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Funding Destination">
            <b-select
              v-model="destinationFundingId"
              size="is-medium"
              placeholder="Select Destination"
              :loading="loadingFunding || loadingSourceFundingDetail"
              :disabled="sourceFunding === null"
              expanded>
              <option v-for="funding in fundings" :key="funding.id" :value="funding.id">
                {{ funding.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <!-- Lines -->
        <div v-if="lines.length > 0">
          <p class="has-text-weight-bold border-b pb-sm">
            <label class="label">Transfer Line Items</label>
          </p>
          <table class="table is-fullwidth is-narrow is-size-7">
            <tr>
              <th>From</th>
              <th>To</th>
              <th class="has-text-right">Amount</th>
              <th></th>
            </tr>
            <tr v-for="(line, index) in lines" :key="index">
              <td class="has-text-weight-bold">{{ line.source_line_type.name }}</td>
              <td class="has-text-weight-bold">{{ line.destination_line_type.name }}</td>
              <td class="has-text-right" style="width:128px"><Currency :value="line.amount" /></td>
              <td class="has-text-right" style="width:128px"><a href="#" @click.prevent="removeLine(index)">Remove</a></td>
            </tr>
            <tr>
              <td class="has-text-weight-bold">TOTAL TRANSFER</td>
              <td></td>
              <td class="has-text-weight-bold has-text-right"><Currency :value="totalTransferAmount" /></td>
              <td></td>
            </tr>
          </table>
        </div>
        <!-- Form to Add a Line -->
        <div v-if="sourceFunding && destinationFunding">
          <LineForm :source="sourceFunding" :destination="destinationFunding" @line:added="addLine" />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
          @click="save"
        >Save Transfer</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrganizationSelect from '@/components/Models/Organization/SelectMenu'
import LineForm from '../LineForm'

export default {
  components: {
    OrganizationSelect,
    LineForm
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (
        this.loadingFunding ||
        this.loadingSourceFundingDetail ||
        this.loadingDestinationFundingDetail
      )
    },
    ready () {
      return this.name && this.description && this.sourceFunding && this.destinationFunding && this.lines.length > 0
    },
    json () {
      const json = {
        name: this.name,
        description: this.description,
        funding_transfer_lines: []
      }
      this.lines.forEach(line => {
        json.funding_transfer_lines.push({
          funding_line_type_id: line.type.id,
          amount: line.amount
        })
      })
      return json
    },
    totalTransferAmount () {
      let total = 0
      this.lines.forEach(line => {
        total += parseFloat(line.amount)
      })
      return total
    }
  },
  data () {
    return {
      loadingFundingLineTypes: true,
      loadingFunding: false,
      loadingSourceFundingDetail: false,
      loadingDestinationFundingDetail: false,
      fundingLineTypes: [],
      fundings: [],
      organization: null,
      sourceFundingId: null,
      sourceFunding: null,
      destinationFundingId: null,
      destinationFunding: null,
      name: null,
      description: null,
      lines: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadFundingLineTypes () {
      this.loadingFundingLineTypes = true
      const endpoint = this.api.route + '/funding-line-types'
      this.$http.get(endpoint).then(response => {
        this.fundingLineTypes = response.data.data
        this.loadingFundingLineTypes = false
      })
    },
    loadFunding () {
      this.loadingFunding = true
      const endpoint = this.api.route + '/funding'
      const query = {
        params: {
          organization: this.organization.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.fundings = response.data.data
        this.loadingFunding = false
      })
    },
    loadSourceFundingDetail () {
      this.loadingSourceFundingDetail = true
      const endpoint = this.api.route + '/funding/' + this.sourceFundingId
      this.$http.get(endpoint).then(response => {
        this.sourceFunding = response.data
        this.loadingSourceFundingDetail = false
      })
    },
    loadDestinationFundingDetail () {
      this.loadingDestinationFundingDetail = true
      const endpoint = this.api.route + '/funding/' + this.destinationFundingId
      this.$http.get(endpoint).then(response => {
        this.destinationFunding = response.data
        this.loadingDestinationFundingDetail = false
      })
    },
    addLine (line) {
      this.lines.push(line)
    },
    removeLine (index) {
      this.lines.splice(index, 1)
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/funding/' + this.funding.id + '/transfers'
      this.$http.post(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Transfer added!' })
        this.$emit('transfer:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  watch: {
    organization (organization) {
      if (organization) this.loadFunding()
    },
    sourceFundingId (id) {
      if (id) this.loadSourceFundingDetail()
    },
    destinationFundingId (id) {
      if (id) this.loadDestinationFundingDetail()
    }
  }
}
</script>
