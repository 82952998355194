<template>
<b-autocomplete
  field="label"
  placeholder="Type to search..."
  :data="results"
  :loading="loading"
  :clear-on-select="false"
  :size="size"
  @typing="search"
  @select="select">
  <template slot-scope="props">
    <div class="media">
      <div class="media-content">
        {{ props.option.label }}
      </div>
    </div>
  </template>
</b-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/' + this.resource
    }
  },
  data () {
    return {
      results: [],
      searchQuery: null,
      selected: null,
      loading: false
    }
  },
  methods: {
    select (option) {
      this.selected = option
      this.$emit('input', option)
    },
    search: debounce(function (search) {
      this.queryApi(search)
    }, 500),
    queryApi (search) {
      this.loading = true
      this.results = []
      const query = {
        search: search,
        paginate: 0
      }
      this.$http.get(this.endpoint, { params: query }).then(response => {
        response.data.forEach(result => {
          this.results.push({ id: result[this.valueField], label: result[this.labelField], value: result[this.valueField] })
        })
      }).finally(() => {
        this.loading = false
      })
    }
  },
  props: {
    resource: {
      type: String
    },
    labelField: {
      type: String
    },
    valueField: {
      type: String
    },
    size: {
      type: String,
      default: null
    }
  }
}
</script>
