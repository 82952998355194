<template>
  <span :class="output.textClass">{{ output.value }} </span>
</template>

<script>
export default {
  computed: {
    output () {
      if (this.hours > 0) {
        return {
          value: this.$numbers(this.hours).format({ mantissa: 1 }),
          textClass: 'has-text-weight-bold has-text-info'
        }
      } else if (this.hours < 0) {
        const hours = Math.abs(parseFloat(this.hours))
        return {
          value: this.$numbers(hours).format({ mantissa: 1 }),
          textClass: 'has-text-weight-bold has-text-warning'
        }
      } else if (this.hours === 0) {
        return {
          value: 0.0,
          textClass: 'has-text-weight-bold'
        }
      }
      return {
        value: '--',
        textClass: 'has-text-grey-light'
      }
    }
  },
  props: ['hours']
}
</script>
