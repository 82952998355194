<template>
  <div>
    <!-- Content -->
    <h1 class="has-text-weight-bold has-border-b pb-sm">Notes & Memos</h1>
    <div>
      <div>
        <!-- Memo Input -->
        <div v-if="connection !== null && memos.length > 0">
          <div>
            <Memo v-for="(memo, index) in memos"
              :key="memo.id" :memo="memo"
              :class="(index === 0) ? '' : 'border-t'"
              class="py-sm"
            />
          </div>
          <div class="border-t pt-md">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <p class="has-text-grey-light is-size-7">Connection notes are private and cannont be viewed by partners.</p>
              </div>
              <div class="column is-narrow">
                <div class="has-text-right">
                  <b-button outlined @click="showConnectionMemoModal = true">Add A Note</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- No Memos Yet -->
        <div v-else>
          <div class="pa-lg has-text-centered has-text-grey-light">
            <p class="">You haven't added any private connection notes yet.</p>
            <p class="mb-md">To add a private note only visible to your team, click the button below.</p>
            <p class="">
              <b-button outlined @click="showConnectionMemoModal = true">Add A Memo</b-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      v-if="connection !== null"
      has-modal-card
      scroll="keep"
      :active.sync="showConnectionMemoModal"
      :can-cancel="['x']"
      ><ConnectionMemoModal :connection="connection" @memo:saved="load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConnectionMemoModal from '@/components/Models/ConnectionMemo/ConnectionMemoModal'
import Memo from '@/components/Models/ConnectionMemo/Memo'

export default {
  components: {
    ConnectionMemoModal,
    Memo
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/connections/' + this.$route.params.connection + '/memos'
    }
  },
  data () {
    return {
      showConnectionMemoModal: false,
      memos: []
    }
  },
  methods: {
    load (refresh) {
      if (!refresh) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.memos = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: ['connection']
}
</script>
