import Index from './views/Index'
import List from './views/List'
import Invites from './views/Invites'
import Detail from './views/Detail'
import Summary from './views/Summary'
import Contributors from './views/Contributors'
import Contributor from './views/Contributor'
import Onboarding from './views/Onboarding'

export default [{
  path: '/network/:code/partners',
  component: Index,
  children: [{
    path: '',
    name: 'network.partners',
    component: List
  }, {
    path: 'invites',
    name: 'network.partner.invites',
    component: Invites
  }]
}, {
  path: '/network/:code/partners/:connection',
  component: Detail,
  children: [{
    path: 'summary',
    name: 'network.partner',
    component: Summary
  }, {
    path: 'contributors',
    name: 'network.partner.contributors',
    component: Contributors
  }, {
    path: 'contributors/:contributor',
    name: 'network.partner.contributor',
    component: Contributor
  }, {
    path: 'onboarding',
    name: 'network.partner.onboarding',
    component: Onboarding
  }]
}]
