<template>
  <div>
    <div class="box rounded-lg">
      <b-table
        :data="order.statements"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          width="96"
          cell-class="has-text-grey-light"
          v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          label="Billing Period"
          v-slot="props">
          <router-link :to="{ name: 'network.order.statement', params: { id: props.row.id }}" class="has-text-weight-bold">{{ props.row.billing_period.label }}</router-link>
        </b-table-column>
        <b-table-column
          label="Opening Balance"
          width="256"
          v-slot="props"
          numeric>
          <Currency :value="props.row.openingBalance" />
        </b-table-column>
        <b-table-column
          label="Closing Balance"
          width="256"
          v-slot="props"
          numeric>
          <Currency :value="props.row.closingBalance" />
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      order: {
        statements: [{
          id: 100,
          billing_period: {
            label: 'August 2022'
          },
          openingBalance: 50000,
          closingBalance: 45000
        }]
      }
    }
  }
}
</script>
