<template>
  <div class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-2">
          <div class="">
            <b-menu>
              <b-menu-list label="Settings">
                <b-menu-item
                  icon="cog"
                  label="Business"
                  tag="router-link"
                  :to="{ name: 'partner.settings.general', params: { code: context.code } }"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="city"
                  label="Address"
                  tag="router-link"
                  :to="{ name: 'partner.settings.billing', params: { code: context.code } }"
                  exact
                ></b-menu-item>
                <b-menu-item
                  icon="account-multiple"
                  label="Team"
                  tag="router-link"
                  :to="{ name: 'partner.settings.people', params: { code: context.code } }"
                  exact
                ></b-menu-item>
                <!-- <b-menu-item
                  icon="lan-connect"
                  label="Integrations"
                  tag="router-link"
                  :to="{ name: 'partner.settings.integrations', params: { code: context.code } }"
                  exact
                ></b-menu-item> -->
              </b-menu-list>
            </b-menu>
          </div>
        </div>
        <!-- Child Router View -->
        <div class="column is-10">
          <div v-if="!loading && partner !== null">
            <router-view :partner="partner" @partner:updated="load(true)" />
          </div>
          <div v-else>
            <Loading message="Loading partner details..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route
    }
  },
  data () {
    return {
      loading: true,
      partner: null
    }
  },
  methods: {
    load (refresh = false) {
      if (!refresh) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.partner = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
