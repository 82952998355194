<template>
  <div>
    <!-- Table -->
    <div>
      <div class="has-background-white rounded-lg mb-xxs pa-md">
        <b-dropdown disabled class="mr-md">
          <template #trigger>
            <b-button label="Actions" icon-right="menu-down" />
          </template>
        </b-dropdown>
        <b-button type="is-primary" outlined @click="showEditContactModal = true">Add Contact</b-button>
      </div>
      <div style="overflow-x:auto" class="mb-md">
        <b-table
          :data="contacts"
          :mobile-cards="false"
          :checked-rows.sync="selected"
          hoverable
          checkable
          class="is-size-7">
          <b-table-column
            label="Type"
            v-slot="props">
            <p>{{ props.row.contact_type }}</p>
          </b-table-column>
          <b-table-column
            label="Name"
            v-slot="props">
            <p>{{ props.row.name }}</p>
          </b-table-column>
          <b-table-column
            cell-class="nowrap"
            label="Email"
            v-slot="props">
            <p>{{ props.row.email }}</p>
          </b-table-column>
          <b-table-column
            cell-class="nowrap"
            label="Phone"
            v-slot="props">
            <p>{{ props.row.phone }}</p>
          </b-table-column>
          <b-table-column
            label=""
            centered
            width="140"
            v-slot="props">
            <b-button type="is-primary" outlined size="is-small" @click="focusContact(props.row)">Edit</b-button>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered pa-lg">No contacts have been added yet.</div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Modal: Add Contact -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditContactModal"
      :can-cancel="['x', 'esc']"
      @close="contact = null"
      ><EditContactModal :client="client" :seed="contact" @contact:saved="load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditContactModal from '@/components/Models/ClientContact/ClientContactModal'

export default {
  components: {
    EditContactModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/clients/' + this.client.id + '/contacts'
    }
  },
  data () {
    return {
      loading: true,
      contacts: [],
      contact: null,
      selected: [],
      showEditContactModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.contacts = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    focusContact (contact) {
      this.contact = contact
      this.showEditContactModal = true
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: ['client']
}
</script>
