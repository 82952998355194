<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Pause Funding</p>
      </header>
      <section class="modal-card-body">
        <div class="pa-xl has-text-centered">
          <p class="mb-sm">When you pause funding, billable expenses can not be applied until funding is resumed.</p>
          <p>Are you sure you want to pause funding?</p>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Pause Funding</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/purchase-orders/' + this.$route.params.id + '/pause'
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Funding paused!' })
        this.$emit('po:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: []
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
