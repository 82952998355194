<template>
  <div>
    <!-- Content -->
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <TimesheetFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
              <option value="updated_desc">Recently Updated</option>
              <option value="submitted_desc">Recently Submitted</option>
            </b-select>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 Timesheet</p>
              <p v-else>{{ total }} Timesheets</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="64"
            cell-class="has-text-grey-light"
            v-slot="props"
            centered>
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            label="Period"
            width="96"
            v-slot="props">
            {{ props.row.billing_period.label }}
          </b-table-column>
          <b-table-column
            label="Contributor"
            v-slot="props">
            {{ props.row.seat.contributor.user.full_name }}
          </b-table-column>
          <b-table-column
            label="Project"
            v-slot="props">
            <p>{{ props.row.seat.position.project.name }}</p>
            <p>{{ props.row.seat.position.project.client.organization.name }}</p>
          </b-table-column>
          <b-table-column
            label="Hours"
            width="80"
            centered
            v-slot="props">
            <p><Hours :hours="props.row.hours_reported" /></p>
          </b-table-column>
          <b-table-column
            label="Billable"
            width="80"
            centered
            v-slot="props">
            <p><Currency :value="props.row.billable_total" /></p>
          </b-table-column>
          <b-table-column
            label="Cost"
            width="80"
            centered
            v-slot="props">
            <p><Currency :value="props.row.cost" /></p>
          </b-table-column>
          <b-table-column
            label="Submitted"
            v-slot="props"
            width="196"
            centered>
            <div v-if="props.row.submitted_at">
              <p>{{ $dates.utc(props.row.submitted_at).local().format('MMM D, YYYY') }}</p>
              <p>{{ $dates.utc(props.row.submitted_at).local().format('h:mm A') }}</p>
            </div>
            <p v-else class="has-text-grey-light is-italic">Not Submitted</p>
          </b-table-column>
          <b-table-column
            label="Last Updated"
            width="164"
            v-slot="props"
            centered>
            <p>{{ $dates.utc(props.row.updated_at).local().format('MMM D, YYYY')}}</p>
            <p>{{ $dates.utc(props.row.updated_at).local().format('h:mm A')}}</p>
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import TimesheetFilters from '../components/Timesheets/TimesheetFilters'

export default {
  components: {
    TimesheetFilters
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Finance', name: 'network.finance', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/admin/timesheets'
    }
  },
  data () {
    return {
      key: 'admin.timesheets'
    }
  },
  methods: {
    redirect (id) {
      this.$router.push({ name: 'network.finance.revision', params: { code: this.context.code, revision: id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
