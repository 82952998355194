import Create from './views/Create'
import Detail from './views/Detail'
import Activities from './views/Activities'
import Health from './views/Health'
import Revisions from './views/Revisions'
import Revision from './views/Revision'
import Summary from './views/Summary'
import Transactions from './views/Transactions'
import Transfers from './views/Transfers'
import Transfer from './views/Transfer'

export default [{
  path: '/network/:code/work/funding/create',
  name: 'network.funding.create',
  component: Create
}, {
  path: '/network/:code/work/funding/:id',
  component: Detail,
  children: [{
    path: 'activities',
    name: 'network.funding.activities',
    component: Activities
  }, {
    path: 'health',
    name: 'network.funding.health',
    component: Health
  }, {
    path: 'revisions',
    name: 'network.funding.revisions',
    component: Revisions
  }, {
    path: 'revisions/:revision',
    name: 'network.funding.revision',
    component: Revision
  }, {
    path: 'transfers',
    name: 'network.funding.transfers',
    component: Transfers
  }, {
    path: 'transfers/:transfer',
    name: 'network.funding.transfer',
    component: Transfer
  }, {
    path: 'transactions',
    name: 'network.funding.transactions',
    component: Transactions
  }, {
    path: '',
    name: 'network.funding',
    component: Summary
  }]
}]
