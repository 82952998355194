<template>
  <div>
    <p class="has-text-weight-bold border-b pb-sm is-size-7">
      <span class="has-text-grey-light">Budget: </span>
      <span class="has-text-primary ">{{ line.role }}</span>
    </p>
    <div class="pt-sm buttons">
      <b-button
        size="is-small"
        type="is-primary"
        outlined
        :disabled="!selected.length"
        @click="approveTimesheets"
      >Approve</b-button>
      <b-button
        size="is-small"
        type="is-light"
        :disabled="!selected.length"
        @click="unapproveTimesheets"
      >Remove Approvals</b-button>
    </div>
    <b-table
      :data="showSeats"
      :mobile-cards="false"
      :checked-rows.sync="selected"
      :is-row-checkable="(row) => row.timesheets.length === 1"
      hoverable
      narrowed
      checkable
      class="is-size-7">
      <b-table-column
        label="Type"
        v-slot="props"
        header-class="is-size-8"
        cell-class="nowrap"
        width="96">
        <p :title="props.row.id">Timesheet</p>
      </b-table-column>
      <b-table-column
        label="Contributor"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <p v-if="props.row.timesheets.length === 1">
          <a href="#" @click.prevent="inspectTimesheet(props.row.timesheets[0])" class="has-text-weight-bold" >
            {{ (props.row.contributor) ? props.row.contributor.user.full_name : '- No Contributor -' }}
          </a>
        </p>
        <p v-else class="has-text-weight-bold">
          <a href="#" @click.prevent="createTimesheet(props.row)" class="has-text-weight-bold" >
            {{ props.row.contributor.user.full_name }}
          </a>
        </p>
        <div class="has-text-grey is-size-8">
          <p>{{ props.row.role }}</p>
        </div>
      </b-table-column>
      <b-table-column
        label="Allocated (Hr)"
        centered
        width="96"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <p v-if="'allocations' in props.row && props.row.allocations.length"><Hours :hours="Number(props.row.allocations[0].units)" /></p>
        <p v-else>--</p>
      </b-table-column>
      <b-table-column
        label="Billable (Hr)"
        centered
        width="96"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <p v-if="props.row.timesheets.length === 1">
          <Hours :hours="props.row.timesheets[0].hours_billable" />
        </p>
        <p v-else class="has-text-weight-grey">0.00</p>
      </b-table-column>
      <b-table-column
        label="Billable ($)"
        centered
        width="96"
        header-class="is-size-8"
        cell-class="nowrap"
        v-slot="props">
        <p v-if="props.row.timesheets.length === 1">
          <Currency :value="props.row.timesheets[0].billable_total" />
        </p>
        <p v-else class="has-text-weight-grey">0.00</p>
      </b-table-column>
      <b-table-column
        label="Cost"
        header-class="is-size-8"
        centered
        width="96"
        v-slot="props">
        <p v-if="props.row.timesheets.length === 1">
          <Currency :value="props.row.timesheets[0].cost" />
        </p>
        <p v-else class="has-text-weight-grey">$0.00</p>
      </b-table-column>
      <b-table-column
        label="Status"
        v-slot="props"
        centered
        header-class="is-size-8"
        width="96">
        <p v-if="props.row.timesheets.length === 1">
          <TimesheetStatus :timesheet="props.row.timesheets[0]" />
        </p>
        <p v-else>
          <a href="#" @click.prevent="createTimesheet(props.row)"><b-tag>Not Started</b-tag></a>
        </p>
      </b-table-column>
      <b-table-column
        label=""
        header-class="is-size-8"
        centered
        width="48"
        v-slot="props">
        <p class="has-text-danger">
          <a v-if="props.row.timesheets.length && props.row.timesheets[0].has_review_flag" class="has-text-danger" href="#" ><b-icon icon="flag"></b-icon></a>
        </p>
      </b-table-column>
      <template #footer>
        <th></th>
        <th></th>
        <th class="is-size-7">{{ line.seats.length }} {{ (line.seats.length === 1) ? 'Role' : 'Roles'}}</th>
        <th class="is-size-7 has-text-centered"><Hours :hours="summary.allocatedHours" /></th>
        <th class="is-size-7 has-text-centered"><Hours :hours="summary.pendingHours" /></th>
        <th class="is-size-7 has-text-centered"><Currency :value="summary.pendingAmount" /></th>
        <th class="is-size-7 has-text-centered"><Currency :value="summary.pendingCost" /></th>
        <th class="is-size-7 has-text-centered"></th>
        <th class="is-size-7 has-text-centered"></th>
      </template>
      <template #empty>
        <div class="has-text-centered py-md">No active contributors.</div>
      </template>
    </b-table>
    <!-- Modal: Approve -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showApprovalModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><ApproveTimesheetsModal
        :project="project"
        :seats="selected"
        @project:saved="resetAfterUpdate" />
    </b-modal>
    <!-- Modal: Remove Approval -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showRemoveApprovalModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UnapproveTimesheetsModal
        :project="project"
        :seats="selected"
        @project:saved="resetAfterUpdate" />
    </b-modal>
  </div>
</template>

<script>
import TimesheetStatus from '@/components/Models/Timesheet/Presenters/Status'
import ApproveTimesheetsModal from '@/components/Models/Timesheet/Modals/Approve'
import UnapproveTimesheetsModal from '@/components/Models/Timesheet/Modals/Unapprove'

export default {
  components: {
    TimesheetStatus,
    ApproveTimesheetsModal,
    UnapproveTimesheetsModal
  },
  computed: {
    summary () {
      const summary = {
        allocatedHours: 0,
        allocatedAmount: 0,
        pendingHours: 0,
        pendingAmount: 0,
        pendingCost: 0
      }
      this.line.seats.forEach(seat => {
        if ('allocations' in seat) {
          seat.allocations.forEach(allocation => {
            summary.allocatedHours += Number(allocation.units)
            summary.allocatedAmount += Number(allocation.billable)
          })
        }
        seat.timesheets.forEach(timesheet => {
          summary.pendingHours += Number(timesheet.hours_billable)
          summary.pendingAmount += Number(timesheet.billable_total)
          summary.pendingCost += Number(timesheet.cost)
        })
      })
      return summary
    },
    showSeats () {
      const seats = []
      this.line.seats.forEach(seat => {
        seats.push(seat)
      })
      return seats
    }
  },
  data () {
    return {
      selected: [],
      showCreateTimesheetModal: false,
      showApprovalModal: false,
      showRemoveApprovalModal: false
    }
  },
  methods: {
    createTimesheet (seat) {
      this.$emit('create:timesheet', seat)
    },
    inspectTimesheet (timesheet) {
      this.$emit('inspect:timesheet', timesheet)
    },
    approveTimesheets () {
      this.showApprovalModal = true
    },
    unapproveTimesheets () {
      this.showRemoveApprovalModal = true
    },
    flagTimesheet (timesheet) {
      const endpoint = this.api.route + '/timesheets/' + timesheet.id + '/actions/flag'
      this.$http.put(endpoint).then(response => {

      })
    },
    unflagTimesheet (timesheet) {
      const endpoint = this.api.route + '/timesheets/' + timesheet.id + '/actions/unflag'
      this.$http.put(endpoint).then(response => {

      })
    },
    reset () {
      this.showApprovalModal = false
      this.showRemoveApprovalModal = false
    },
    resetAfterUpdate () {
      this.selected = []
      this.$emit('project:saved')
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
