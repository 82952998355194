<template>
  <div>
    <div class="has-background-white pa-lg mb-xxs has-shadow">
      <div v-if="!loading && seat">
        <!-- Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="is-size-5">
                <p class="has-text-weight-bold mb-sm">
                  <span>{{ (seat.role) ? seat.role : seat.position.role }}: </span>
                  <span v-if="seat.contributor">{{ seat.contributor.user.full_name }}</span>
                  <span v-else class="has-text-grey-light">Unassigned</span>
                </p>
                <p class="has-text-grey-light is-size-7">
                  <StatusPresenter :seat="seat" size="is-medium" />
                </p>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'network.project.lines', params: { code: context.code }}">&lt; Back to Project Detail</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Tabs -->
        <div>
          <b-tabs v-model="activeTab" :animated="false" :destroy-on-hide="true" >
            <b-tab-item
              value="settings"
              headerClass="has-text-left has-text-weight-bold"
              label="Role">
              <Settings :project="project" :seat="seat" @seat:saved="reloadSeat" />
            </b-tab-item>
            <b-tab-item
              value="contributor"
              headerClass="has-text-left has-text-weight-bold"
              label="Contributor">
              <Contributor :project="project" :seat="seat" @seat:saved="reloadSeat" />
            </b-tab-item>
            <b-tab-item
              value="plan"
              headerClass="has-text-left has-text-weight-bold"
              label="Plan">
              <Plan :project="project" :seat="seat" @seat:saved="reloadSeat" />
            </b-tab-item>
            <b-tab-item
              value="rates"
              headerClass="has-text-left has-text-weight-bold"
              label="Rate History">
              <RateHistory :project="project" :seat="seat" @seat:saved="reloadSeat" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Seat/Presenters/Status'
import Settings from '../components/Seat/Settings'
import Contributor from '../components/Seat/Contributor'
import Plan from '../components/Seat/Plan'
import RateHistory from '../components/Seat/RateHistory'

export default {
  components: {
    Settings,
    Contributor,
    Plan,
    StatusPresenter,
    RateHistory
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingSeat || this.loadingContributors)
    },
    method () {
      return (this.seat) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.seat)
        ? this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id
        : this.api.route + '/projects/' + this.project.id + '/seats'
    },
    ready () {
      return (
        this.rate
      )
    },
    json () {
      if (this.seat.started_at) return {}
      const json = {
        partner_role_id: this.seat.contributor.id,
        position_id: this.seat.position.id,
        base_rate: this.rate,
        rate_is_confirmed: (this.rateIsConfirmed === 'confirmed') ? 1 : 0,
        start_date: this.startDate
      }
      return json
    }
  },
  data () {
    return {
      activeTab: 'settings',
      loadingSeat: true,
      seat: null,
      rate: null,
      startDate: null,
      rateIsConfirmed: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    targetMargin () {
      this.rate = this.$numbers(this.seat.position.client_rate.rate * (0.60)).format({ mantissa: 2 })
    },
    useProjectStartDate () {
      this.startDate = this.project.start_date
    },
    loadSeat (quiet = false) {
      if (!quiet) this.loadingSeat = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.$route.params.seat
      this.$http.get(endpoint).then(response => {
        this.seat = response.data
        this.loadingSeat = false
      })
    },
    reloadSeat () {
      this.loadSeat(true)
      this.$emit('project:saved')
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$emit('project:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$router.push({ name: 'network.project.detail', params: { code: this.context.code, id: this.project.id } })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadSeat()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
