<template>
  <div class="section">
    <div class="container">
      <!-- Crumbs -->
      <div class="mb-md">
        <Breadcrumbs current="Reports" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
