<template>
  <Currency :value="value" />
</template>

<script>
export default {
  computed: {
    value () {
      let total = 0
      this.invoice.lines.forEach(line => {
        total += Number(line.total)
      })
      return total
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
