<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  computed: {
    chartData () {
      if (!(this.stage in this.project.aggregates)) return []
      const burned = [0, this.project.aggregates.billable.billable_total]
      return {
        labels: ['Estimate', 'Remaining'],
        datasets: [{
          label: 'Budget',
          data: burned,
          fill: false,
          borderColor: '#fa4516',
          backgroundColor: '#fa4516',
          borderWidth: 2
        }]
      }
    }
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        drawTicks: true,
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        legend: { display: false },
        scales: {
          xAxes: [{
            offset: true,
            gridLines: { display: true },
            ticks: { display: true }
          }],
          yAxes: [{
            offset: true,
            gridLines: { display: false },
            ticks: { display: true }
          }]
        }
      }
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    stage: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
