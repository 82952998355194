<template>
  <b-tag :type="presenter.class" :size="this.size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.invoice.published_at) {
        presenter.class = 'is-success is-light'
        presenter.label = 'Published'
      } else if (this.invoice.approved_at) {
        presenter.class = 'is-success is-light'
        presenter.label = 'Approved'
      } else {
        presenter.class = 'is-grey is-light'
        presenter.label = 'Draft'
      }
      return presenter
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default () {
        return null
      }
    }
  }
}
</script>
