<template>
  <div class="section">
    <div class="container">
      Partners Index
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data: function () {
    return {}
  },
  methods: {

  },
  mounted () {

  }
}
</script>
