<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns">
        <!-- Actions -->
        <div class="column is-one-quarter" v-for="(action, index) in actions" :key="index">
          <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-light" expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
    <!-- Has No Actions -->
    <div v-else>
      <div class="columns">
        <div class="column is-one-quarter"><b-button :disabled="true" size="is-medium" expanded >No Actions</b-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasActions () {
      return (this.actions.length > 0 || this.additionalActions.length > 0)
    },
    actions () {
      const actions = []
      if (this.invoice.lines.length) {
        if (this.invoice.approved_at === null) {
          actions.push({ label: 'Approve', event: 'action:approve', type: 'primary' })
          if (this.invoice.published_at === null) {
            actions.push({ label: 'Archive', event: 'action:archive', type: 'secondary' })
          }
        }
        if (this.invoice.approved_at && this.invoice.published_at === null) {
          actions.push({ label: 'Export', event: 'action:publish', type: 'primary' })
          actions.push({ label: 'Remove Approval', event: 'action:unapprove', type: 'secondary' })
        }
      }
      return actions
    },
    additionalActions () {
      return []
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
