<template>
  <div>
    <!-- Onboarding -->
    <div class="has-background-white rounded-lg px-lg py-md mb-lg">
      <Onboarding :connection="connection" />
    </div>
  </div>
</template>

<script>
import Onboarding from '../components/Detail/Onboarding'

export default {
  components: {
    Onboarding
  },
  props: ['connection']
}
</script>
