var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"modal-card"},[_vm._m(0),_c('section',{staticClass:"modal-card-body"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Preview"}},[(_vm.img)?_c('div',[_c('Cropper',{ref:"cropper",staticStyle:{"max-width":"256px"},attrs:{"src":_vm.img,"canvas":{
                  height: 256,
                  width: 256
                },"stencil-size":{
                  width: 128,
                  height: 128
                },"stencil-props":{
                  handlers: {},
                  movable: false,
                  scalable: false,
                  aspectRatio: 1,
                },"resize-image":{
                  adjustStencil: false
                },"image-restriction":"stencil"}})],1):_c('div',{staticClass:"is-size-7"},[_vm._v(" Upload a photo to view a preview. ")])])],1),_vm._m(1)]),(_vm.file === null)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Select File","type":('file' in _vm.errors) ? 'is-danger' : '',"message":('file' in _vm.errors) ? _vm.errors['file'][0] : '',"expanded":""}},[_c('b-upload',{attrs:{"drag-drop":"","expanded":"","accept":"image/png, image/jpeg, image/jpg"},on:{"input":_vm.preview},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('div',{staticClass:"has-text-centered py-xxl"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your files here or click to upload")])])])],1)],1)]):_vm._e()]),_c('footer',{staticClass:"modal-card-foot space-between"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Close")]),_c('b-button',{attrs:{"type":"is-primary","native-type":"submit","loading":_vm.saving,"disabled":!_vm.ready || _vm.saving}},[_vm._v("Save Photo")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Upload Logo or Photo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('div',{},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v("Photo Tips")]),_c('ul',{staticClass:"is-size-7"},[_c('li',[_vm._v("- Upload a file at least 250x250 pixels")]),_c('li',[_vm._v("- Scroll or pinch to zoom in an out")])])])])
}]

export { render, staticRenderFns }