<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Role Rate</p>
      </header>
      <section class="modal-card-body">
        <!-- Change Rate -->
        <div class="mb-lg">
          <b-message type="is-info is-size-7">
            The current rate for the <b>{{ position.role }}</b> role is
            <b>
              <Currency :value="position.client_rate.rate" />
              {{ (position.client_rate.model === 'hourly') ? '/ hr' : ''}}
            </b>
            and was made effective <b>{{ $dates(position.client_rate.start_date).format("MMMM D, YYYY") }}</b>.
            Please enter a new rate and effective date below. All <u>open</u> timesheets
            will be automatically updated, but project leaders may need to adjust their project plans.
          </b-message>
          <b-field
            label="Effective Date"
            :type="('effective_date' in errors) ? 'is-danger' : ''"
            :message="('effective_date' in errors) ? errors['effective_date'][0] : ''">
            <b-input
              size="is-medium"
              type="date"
              :min="minimumDate"
              v-model="effectiveDate"
            ></b-input>
          </b-field>
          <!-- New Rate -->
          <b-field
            label="New Rate"
            :type="('rate' in errors) ? 'is-danger' : ''"
            :message="('rate' in errors) ? errors['rate'][0] : ''">
            <b-input
              size="is-medium"
              type="number"
              :step="0.01"
              v-model="newRate"
            ></b-input>
          </b-field>
        </div>
        <!-- View Rates -->
        <div v-if="!loadingRateHistory">
          <p class="is-uppercase has-text-weight-bold is-size-7 has-text-centered has-text-primary">Rate History</p>
          <table class="table is-fullwidth is-size-7 is-narrow">
            <thead>
              <tr>
                <th>Effective Date</th>
                <th class="has-text-centered">Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rate, index) in positionWithRates.rates" :key="index">
                <td>{{ $dates(rate.start_date).format('MMM D, YYYY') }}</td>
                <td class="has-text-centered">
                  <Currency :value="rate.rate" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save Rate</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    minimumDate () {
      return this.$dates(this.project.start_date).format('YYYY-MM-DD')
    },
    ready () {
      return (this.effectiveDate && this.newRate)
    },
    json () {
      return {
        effective_date: this.effectiveDate,
        rate: this.newRate
      }
    }
  },
  data () {
    return {
      loadingRateHistory: false,
      positionWithRates: null,
      effectiveDate: null,
      newRate: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadRateHistory () {
      this.loadingRateHistory = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id + '/rates'
      this.$http.get(endpoint).then(response => {
        this.positionWithRates = response.data
        this.loadingRateHistory = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id + '/rates'
      this.$http.post(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Rate changed!' })
        this.$emit('position:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadRateHistory()
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  }
}
</script>
