<template>
  <b-button
    :type="type"
    :size="size"
    :outlined="outlined"
    :disabled="saving || period.closed_at"
    :loading="saving"
    @click="save"
    expanded
  ><span class="is-size-7">{{ (period.closed_at) ? 'Period Closed' : 'Add Time' }}</span></b-button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/timesheets'
      const body = {
        seat_id: this.seat.id,
        billing_period_id: this.period.id
      }
      this.$http.post(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet created!' })
        this.$emit('timesheet:created', response.data)
      }).catch(error => {
        let message = 'Please review your input and try again.'
        if (error.response && error.response.status === 422 && error.response.data.message) {
          message = error.response.data.message
        }
        this.$buefy.toast.open({ type: 'is-danger', message })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'is-primary'
    },
    size: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    }
  }
}
</script>
