<template>
  <div>
    <b-table
      :data="project.invoices"
      :mobile-cards="false"
      hoverable
      class="is-size-7">
      <b-table-column
        label="ID"
        header-class="is-size-8"
        cell-class="has-text-grey-light"
        width="64"
        v-slot="props">
        <span class="has-text-grey-light">{{ props.row.id }}</span>
      </b-table-column>
      <b-table-column
        label="Date"
        width="128"
        header-class="is-size-8"
        v-slot="props">
        {{ $dates(props.row.invoice_date).format('MMM D, YYYY') }}
      </b-table-column>
      <b-table-column
        label="Type"
        header-class="is-size-8"
        cell-class="has-text-grey-light"
        width="98"
        v-slot="props">
        <span class="has-text-grey-light">{{ $utils.startCase(props.row.type) }}</span>
      </b-table-column>
      <b-table-column
        label="Transaction"
        header-class="is-size-8"
        v-slot="props">
        <div v-if="props.row.type === 'credit'">
          <span>Client Credit</span>
          <span v-if="props.row.network_identifier"> #{{ props.row.network_identifier }}</span>
          <span v-else class="has-text-grey">(No #)</span>
        </div>
        <div v-if="props.row.type === 'invoice'">
          <span>Client Invoice</span>
          <span v-if="props.row.network_identifier"> #{{ props.row.network_identifier }}</span>
          <span v-else class="has-text-grey-light"> (No Invoice #)</span>
        </div>
      </b-table-column>
      <b-table-column
        label="Transaction Amount"
        header-class="is-size-8"
        width="200"
        numeric
        v-slot="props">
        <div v-if="props.row.type === 'credit'">
          <Currency :value="props.row.total_amount" />
        </div>
        <div v-if="props.row.type === 'invoice'">
          (<Currency :value="props.row.total_amount" />)
        </div>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered has-text-grey-light pa-lg">No transactions have been created yet.</div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
