<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs mb-xxs">
      <div class="columns">
        <div class="column"></div>
        <div class="column is-narrow">
          <div class="is-size-7">
            <p v-if="!loading" >{{ billables.length }} {{ (billables.length === 1) ? 'Billable Item' : 'Billable Items' }}</p>
            <p v-else>Loading...</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div class="mb-md">
      <b-table
        :data="billables"
        :checked-rows.sync="selected"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <b-table-column
          field="id"
          label="ID"
          width="64"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          field="id"
          label="Resource"
          width="164"
          v-slot="props">
          <p v-if="props.row.resource_type === 'timesheet'">Timesheet #{{ props.row.resource_id }}</p>
          <p v-else-if="props.row.resource_type === 'delivery'">Delivery #{{ props.row.resource_id }}</p>
          <p v-else-if="props.row.resource_type === 'expense'">Expense #{{ props.row.resource_id }}</p>
        </b-table-column>
        <b-table-column
          field="id"
          label="Description"
          v-slot="props">
          <p v-if="props.row.resource_type === 'timesheet'">{{ props.row.resource.seat.contributor.user.full_name }}</p>
          <p v-else-if="props.row.resource_type === 'delivery'">{{ props.row.resource.title }}</p>
          <p v-else-if="props.row.resource_type === 'expense'">{{ props.row.resource.resource.title }}</p>
        </b-table-column>
        <b-table-column
          field="id"
          label="Line Item ID"
          width="128"
          v-slot="props">
          <p v-if="props.row.client_invoice_line_id">{{ props.row.client_invoice_line_id }}</p>
          <p v-else class="has-text-grey-light">- Not Set -</p>
        </b-table-column>
        <b-table-column
          field="id"
          label="Billable Amount"
          width="128"
          numeric
          v-slot="props">
          <Currency :value="props.row.billable_amount" />
        </b-table-column>
        <template slot="empty">
          <div class="py-xxl has-text-grey has-text-centered">
            {{ (loading) ? "Loading data..." : 'No records found!' }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingBillables)
    }
  },
  data () {
    return {
      selected: [],
      loadingBillables: true,
      billables: []
    }
  },
  methods: {
    loadBillables () {
      this.loadingBillables = true
      const endpoint = this.api.route + '/admin/billables/'
      const query = {
        params: {
          clientInvoice: this.invoice.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.billables = response.data.data
        this.loadingBillables = false
      })
    }
  },
  mounted () {
    this.loadBillables()
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
