import axios from 'axios'
import router from '@/router'

export default {
  namespaced: false,

  state: {
    authenticated: false,
    verified: false,
    intended: null,
    user: null,
    role: null,
    apiHost: process.env.VUE_APP_API_HOST,
    apiVersion: process.env.VUE_APP_API_VERSION
  },

  getters: {
    api (state) {
      if (state.role !== null) {
        const path = (state.role.resource_type === 'network') ? 'networks' : 'partners'
        return {
          host: state.apiHost,
          version: process.env.VUE_APP_API_VERSION,
          base: state.apiVersion + '/',
          route: state.apiVersion + '/' + path + '/' + state.role.resource_id
        }
      }
      return {
        host: state.apiHost,
        version: process.env.VUE_APP_API_VERSION
      }
    },
    auth (state) {
      return {
        authenticated: state.authenticated,
        verified: state.verified,
        user: state.user,
        intended: state.intended,
        isStaff: (state.user !== null && state.user.is_staff)
      }
    },
    context (state, getters, rootState) {
      if (state.role !== null) {
        return {
          resolved: true,
          type: state.role.resource_type,
          id: state.role.resource_id,
          code: state.role.resource.code,
          name: state.role.resource.name,
          homeRoute: state.role.resource_type + '.projects',
          isOnboarded: state.role.resource.is_onboarded
        }
      }
      return {
        resolved: false,
        path: null,
        type: null,
        id: null,
        code: null,
        homeRoute: null
      }
    },
    role (state, getters, rootState) {
      if (state.role !== null) {
        return {
          resolved: true,
          id: state.role.id,
          type: state.role.type
        }
      }
      return {
        resolved: false,
        id: null,
        type: null
      }
    },
    isAdministrator (state, getters, rootState) {
      if (state.role !== null) {
        return (state.role.code === 'admininstrator')
      }
      return false
    }
  },

  mutations: {
    SET_INTENDED_PATH (state, value) {
      state.intended = value
    },
    CLEAR_INTENDED_PATH (state, value) {
      state.intended = null
    },
    LOGIN (state, user) {
      state.authenticated = true
      state.user = user
      state.verified = user.email_verified_at !== null
    },
    CHANGED_PASSWORD (state) {
      state.user.requires_reset = 0
    },
    SET_ROLE (state, role) {
      state.role = role
      localStorage.setItem('gc', JSON.stringify({
        id: role.id,
        resource_type: role.resource_type,
        resource_id: role.resource_id
      }))
    },
    SET_CONTEXT_NAME (state, name) {
      state.role.resource.name = name
    },
    SET_USER (state, user) {
      state.user.first_name = user.first_name
      state.user.last_name = user.last_name
      state.user.full_name = user.first_name + ' ' + user.last_name
    },
    RESET (state) {
      state.authenticated = false
      state.verified = false
      state.user = null
      state.roles = []
      state.intended = null
    }
  },

  actions: {
    /**
     * Trigger state to be configured such that the user is redirected to or
     * shown a login prompt (and keep track of where the user was intending
     * to go so we can redirect after authentication)
     */
    redirectGuest ({ commit }, intendedPath) {
      commit('RESET')
      commit('SET_INTENDED_PATH', intendedPath)
      router.push({ name: 'auth.login' })
    },
    /**
     * Update state to reflect a global change to context data. This
     * action is typically triggered when a user update's their business.
     */
    setContext ({ commit }, name) {
      commit('SET_CONTEXT_NAME', name)
    },
    /**
     * Update state to reflect a global change to the user's profile data. This
     * action is typically triggered when a user update's their name.
     */
    setUser ({ commit }, user) {
      commit('SET_USER', user)
    },
    /**
     * Trigger state to be configured such that the user is redirected to or
     * shown a login prompt (and keep track of where the user was intending
     * to go so we can redirect after authentication)
     */
    setRole ({ commit }, role) {
      commit('SET_ROLE', role)
    },
    /**
     * After the user has completed a force password change, reset the flag
     * so that navigation guards allow user to pass.
     */
    changedPassword ({ commit }) {
      commit('CHANGED_PASSWORD')
    },
    /**
     * Trigger state to remove the intended url the user was attempting to
     * view before being redirected to
     */
    clearIntendedPath ({ commit }) {
      commit('CLEAR_INTENDED_PATH')
    },
    /**
     * Trigger state to remove the intended url the user was attempting to
     * view before being redirected to
     */
    logout ({ commit }) {
      axios.post('/logout').then(response => {
        commit('RESET')
        router.push({ name: 'auth.login' })
      })
    },
    /**
     * Attempt to identify the user by calling a profile endpoint. If the user
     * has only one available role, go ahead and set context.
     */
    async identify ({ commit }) {
      await axios.get('/sanctum/csrf-cookie')
      return axios.get('v1/profile').then((response) => {
        commit('LOGIN', response.data)
        // single context users
        if (response.data.roles.length === 1) {
          commit('SET_ROLE', response.data.roles[0])
        // multiple context users
        } else if (response.data.roles.length > 1) {
          // try {
          //   const local = JSON.parse(localStorage.getItem('gc'))
          //   const context = response.data.roles.find(role => {
          //     return (role.id === local.id && role.resource_type === local.resource_type && role.resource_id === local.resource_id)
          //   })
          //   if (context) {
          //     commit('SET_ROLE', context)
          //   }
          // } catch (e) {
          //   localStorage.setItem('gc', null)
          // }
        // no context users
        }
      }).catch(() => {
        commit('RESET')
      })
    },
    /**
     * Attempt to identify the user by calling a profile endpoint
     */
    async resolveRole ({ commit }) {
      return new Promise((resolve, reject) => {
        // if context is not required for the route, skip context
        if (router.currentRoute.meta.skipContext) {
          resolve()
          return
        }
        // grab key context identifiers from route
        const parts = router.currentRoute.path.split('/')
        const context = parts[1]
        const identifier = parts[2]
        if (typeof context === 'undefined' || typeof identifier === 'undefined') {
          reject(new Error('Context not found!'))
          return
        }
        // pass request to api to validate user can access this context
        const endpoint = 'v1/context/' + context + '/' + identifier
        axios.get(endpoint).then(response => {
          commit('SET_ROLE', response.data)
          resolve()
        }).catch(() => {
          reject(new Error('Context not found!'))
        })
      })
    }
  }
}
