<template>
  <span>{{ value }}</span>
</template>

<script>
export default {
  computed: {
    value () {
      let margin
      if (Number(this.cogs) === 0) {
        margin = 0
      }
      if (this.revenue && parseFloat(this.revenue) !== 0) {
        margin = (this.revenue - this.cogs) / this.revenue
      }
      return (margin)
        ? this.$numbers(margin).format({ output: 'percent', mantissa: 1, thousandSeparated: true })
        : '--'
    }
  },
  props: {
    revenue: {
      type: [String, Number],
      required: true
    },
    cogs: {
      type: [String, Number]
    },
    zeroValue: {
      type: String,
      default: 'dash' // 'dash' or 'zero'
    }
  }
}
</script>
