<template>
  <div>
    <div class="columns">
      <div v-if="canApprove" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('approve:connection')">Approve Partner</b-button>
      </div>
      <div v-if="canActivate" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('activate:connection')">Activate Partner</b-button>
      </div>
      <div v-if="canDeactivate" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('suspend:connection')">Deactivate Partner</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    canEdit () {
      return true
    },
    canApprove () {
      return (this.connection.approved_at === null)
    },
    canActivate () {
      return (this.connection.suspended_at !== null)
    },
    canDeactivate () {
      return (this.connection.approved_at !== null && this.connection.suspended_at === null)
    }
  },
  props: ['connection']
}
</script>
