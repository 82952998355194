<template>
  <div>
    <!-- Content -->
    <div>
      <b-table
        :data="deliveries"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="Client"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="py-lg"
          width="128">
          <cld-image :publicId="props.row.position.project.client.organization.logo_path" width="96" dpr="2.0" crop="scale" style="display:block;" />
        </b-table-column>
        <b-table-column
          label="Project"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="nowrap">
          <div>
            <p class="has-text-weight-bold">{{ props.row.title }}</p>
            <p>{{ props.row.position.project.name }}</p>
            <p class="has-text-grey-light is-size-8">
              {{ $dates(props.row.position.project.start_date).format('MMM YYYY') }}
              -
              {{ $dates(props.row.position.project.end_date).format('MMM YYYY') }}
            </p>
          </div>
        </b-table-column>
        <b-table-column
          label="Delivery Date"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          width="164"
          centered
          cell-class="nowrap">
          <p>{{ $dates(props.row.planned_delivery_date).format('MMM D, YYYY') }}</p>
        </b-table-column>
        <b-table-column
          label="Rate"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          centered
          width="96">
          <p><Currency :value="props.row.cost" zero-value="dash" /></p>
        </b-table-column>
        <b-table-column
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="nowrap"
          width="164"
          centered>
          <div>
            <!-- Approved -->
            <b-button
              v-if="props.row.approved_at"
              class="is-size-7"
              type="is-light is-success has-text-weight-bold"
              expanded
              disabled>
              Approved
            </b-button>
            <!-- Submitted -->
            <b-button
              v-else-if="props.row.submitted_at"
              class="is-size-7"
              type="is-info has-text-weight-bold"
              expanded
              outlined
              disabled>
              Submitted {{ $dates(props.row.submitted_at).format('MMM D') }}
            </b-button>
            <!-- On Hold -->
            <b-button
              v-else
              class="is-size-7"
              type="is-primary"
              expanded
              @click.prevent="submit(props.row)">
              Mark as Delivered
            </b-button>
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="nowrap"
          width="64"
          centered>
          <OptionsMenu
            :projectId="props.row.position.project_id"
            :delivery="props.row"
            @delivery:withdraw="withdraw"
          />
        </b-table-column>
        <template #empty>
          <div class="has-text-grey-light has-text-centered pt-lg">You don't have any active deliverables!</div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Fulfill -->
    <b-modal
      v-if="focusDelivery"
      has-modal-card
      scroll="keep"
      :active.sync="showFulfillDeliveryModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><FulfillDeliveryModal :delivery="focusDelivery" @delivery:saved="$emit('delivery:saved')" />
    </b-modal>
    <!-- Modal: Withdraw -->
    <b-modal
      v-if="focusDelivery"
      has-modal-card
      scroll="keep"
      :active.sync="showWithdrawDeliveryModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><WithdrawDeliveryModal :delivery="focusDelivery" @delivery:saved="$emit('delivery:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OptionsMenu from '@/components/Models/Delivery/PartnerOptions'
import FulfillDeliveryModal from '@/components/Models/Delivery/Modals/Fulfill'
import WithdrawDeliveryModal from '@/components/Models/Delivery/Modals/Withdraw'

export default {
  components: {
    OptionsMenu,
    FulfillDeliveryModal,
    WithdrawDeliveryModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      showFulfillDeliveryModal: false,
      showWithdrawDeliveryModal: false,
      focusDelivery: null,
      focusAction: null
    }
  },
  methods: {
    submit (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'submit'
      this.showFulfillDeliveryModal = true
    },
    withdraw (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'submit'
      this.showWithdrawDeliveryModal = true
    },
    reset () {
      this.focusDelivery = null
      this.focusAction = null
      this.showWorkflowModal = false
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    deliveries: {
      type: Array,
      required: true
    }
  }
}
</script>
