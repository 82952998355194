import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import packageInfo from '../../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    // app
    version: packageInfo.version,
    initialized: false,
    redirecting: false,
    hamburger: false,
    navbar: false,
    demoMode: false,
    serverError: null,
    unreadAnnouncements: 0
  },
  getters: {
    app (state) {
      return {
        name: 'GatherWorks',
        companyName: 'Gather Technologies, Inc',
        version: state.version,
        demoMode: state.demoMode,
        debug: process.env.VUE_APP_DEBUG,
        showErrorModal: (state.serverError !== null),
        serverError: state.serverError,
        supportEmail: 'support@gathertech.co',
        unreadAnnouncements: state.unreadAnnouncements
      }
    },
    assets (state) {
      return {
        marginSeats: 'https://res.cloudinary.com/sdoctech/image/upload/v1639445106/platform/Gather-Margin-Graphic_ixodzy.png'
      }
    }
  },
  mutations: {
    INITIALIZE (state) {
      state.initialized = true
    },
    NAVIGATE (state) {
      state.hamburger = false
    },
    ERROR (state, error) {
      state.serverError = error
    },
    UNREAD_ANNOUNCEMENTS (state, count) {
      state.unreadAnnouncements = count
    },
    CLEAR_ERROR (state) {
      state.serverError = null
    }
  },
  actions: {
    /**
     * Mark the app as initialized so that we have a marker to use to
     * know that the vue instance has been loaded
     */
    initialize ({ commit }) {
      commit('INITIALIZE')
    },
    /**
     * As the user navigates within the application, we might need to
     * clean up various pieces of state (e.g. hide open modals and menus).
     * Could also be used later to fire custom analytics.
     */
    navigate ({ commit }) {
      commit('NAVIGATE')
    },
    /**
     * Configure the state required to show the announcements indicator
     */
    unreadAnnouncements ({ commit }, count) {
      commit('UNREAD_ANNOUNCEMENTS', count)
    },
    /**
     * Configure the state required to gracefully handle application errors (e.g.
     * a 400 or 500 level server error from the API)
     */
    serverError ({ commit }, error) {
      commit('ERROR', error)
    },
    /**
     * Configure the state required to gracefully recover from applicaiton errors
     */
    clearServerError ({ commit }) {
      commit('CLEAR_ERROR')
    }
  }
})
