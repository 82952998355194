<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
          <p class="modal-card-title">View Expense Details</p>
        </header>
      <section class="modal-card-body">
        <!-- General -->
        <div class="">
          <p class="has-text-weight-bold mb-sm">Expense Details</p>
          <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
            <tbody>
              <tr>
                <th>GatherWorks ID</th>
                <td class="has-text-right">{{ expense.id }}</td>
              </tr>
              <tr>
                <th>Created At</th>
                <td class="has-text-right">{{ $dates(expense.created_at).format('MMM D, YYYY @ h:mm A') }}</td>
              </tr>
              <tr>
                <th>Last Updated At</th>
                <td class="has-text-right">{{ $dates(expense.updated_at).format('MMM D, YYYY @ h:mm A') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-lg" v-if="'indentifiers' in expense">
          <p class="has-text-weight-bold mb-sm">External Platform Identifiers</p>
          <div class="table-container">
            <table class="table is-fullwidth is-striped is-narrow is-size-7 border">
              <tbody>
                <template v-if="expense.identifiers.length">
                  <tr v-for="identifier in expense.identifiers" :key="identifier.id">
                    <th>{{ identifier.platform_account.platform.name }}</th>
                    <td class="has-text-right">{{ identifier.platform_resource }}: {{ identifier.platform_identifier }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr><td>This expense is not currently synced with any external platforms.</td></tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  props: {
    expense: {
      type: Object,
      required: true
    }
  }
}
</script>
