<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns">
        <div class="column is-one-quarter">
          <b-button type="is-light" size="is-medium" expanded>Upload Invoice</b-button>
        </div>
      </div>
    </div>
    <!-- Invoice -->
    <div class="mb-md">
      <b-table
        :data="expenses"
        :mobile-cards="false"
        :checked-rows.sync="selected"
        hoverable
        checkable
        class="is-size-7">
        <b-table-column
          label="Type"
          v-slot="props">
          <p>{{ props.row.contact_type }}</p>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered pa-lg">No invoices have been added yet.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      expenses: [],
      selected: []
    }
  }
}
</script>
