<template>
  <div>
    <!-- Page Header -->
    <div class="mb-sm">
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <breadcrumbs :history="breadcrumbs.history" :current="breadcrumbs.current" />
        </div>
      </div>
    </div>
    <!-- Account & Business ID -->
    <div v-if="!loading && account !== null" class="mb-xl pb-xl">
      <Account :account="account" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Account from '../components/Freshbooks/Account'

export default {
  components: {
    Account
  },
  data () {
    return {
      loading: true,
      account: null
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return {
        current: (this.loading) ? 'Loading...' : 'FreshBooks',
        history: [
          { label: 'Settings', name: 'partner.settings' },
          { label: 'Integrations', name: 'partner.settings.integrations' }
        ]
      }
    },
    endpoint () {
      return this.api.route + '/admin/platform-accounts/' + this.$route.params.id
    }
  },
  methods: {
    load (refresh = false) {
      this.loading = (!refresh)
      this.$http.get(this.endpoint).then(response => {
        this.account = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  props: []
}
</script>
