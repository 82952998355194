<template>
  <span class="has-text-weight-bold" :class="textClass">{{ status }}</span>
</template>

<script>
export default {
  computed: {
    status () {
      return (this.period.closed_at) ? 'Closed' : 'Open'
    },
    textClass () {
      return (this.period.closed_at) ? 'has-text-warning' : 'has-text-success'
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
