<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2">
            <div class="">
              <b-menu>
                <b-menu-list label="Projects Menu">
                  <b-menu-item
                    icon="checkbox-multiple-marked-outline"
                    label="Projects"
                    tag="router-link"
                    :to="{ name: 'network.projects', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="file-document-outline"
                    label="Funding"
                    tag="router-link"
                    :to="{ name: 'network.fundings', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
          </div>
          <!-- Child Router View -->
          <div class="column is-10">
            <router-view />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  }
}
</script>
