<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Contact Details</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Contact Type"
          :type="('contact_type' in errors) ? 'is-danger' : ''"
          :message="('contact_type' in errors) ? errors['contact_type'][0] : ''"
          expanded>
            <b-input
              size="is-medium"
              type="text"
              v-model="contactType"
            ></b-input>
        </b-field>
        <b-field
          label="Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="text"
              v-model="name"
            ></b-input>
        </b-field>
        <b-field
          label="Email"
          :type="('email' in errors) ? 'is-danger' : ''"
          :message="('email' in errors) ? errors['email'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="email"
              v-model="email"
            ></b-input>
        </b-field>
        <b-field
          label="Phone"
          :type="('phone' in errors) ? 'is-danger' : ''"
          :message="('phone' in errors) ? errors['phone'][0] : ''"
          expanded>
            <b-input
              autocomplete="off"
              size="is-medium"
              type="text"
              v-model="phone"
            ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return true
    },
    endpoint () {
      let route = this.api.route + '/clients/' + this.$route.params.id + '/contacts'
      if (this.seed !== null) {
        route += '/' + this.seed.id
      }
      return route
    },
    method () {
      return (this.seed === null) ? 'post' : 'patch'
    },
    json () {
      return {
        contact_type: this.contactType,
        name: this.name,
        email: this.email,
        phone: this.phone
      }
    }
  },
  data () {
    return {
      contactType: null,
      name: null,
      email: null,
      phone: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Contact saved!' })
        this.$parent.close()
        this.$emit('contact:saved')
      }).catch(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.seed !== null) {
      this.contactType = this.seed.contact_type
      this.name = this.seed.name
      this.email = this.seed.email
      this.phone = this.seed.phone
    }
  },
  props: ['client', 'seed']
}
</script>
