<template>
  <div>
    <div>
      <!-- Workflow -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <Workflow :order="order"
          @action:activate="showActivateModal = true"
          @action:pause="showPauseModal = true"
          @action:resume="showResumeModal = true"
          @action:end="showEndModal = true"
          @action:approve="showApproveModal = true"
          @action:archive="showArchiveModal = true"
        />
      </div>
      <div class="has-background-white rounded-lg pa-md mb-lg">
        <PoSummary :order="order"
          @edit:meta="showEditMetaModal = true"
          @edit:dates="showEditDatesModal = true"
          @attachment:removed="$emit('refresh')"
        />
      </div>
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="has-border-b pb-sm">
          <div class="columns is-vcentered">
            <div class="column">
              <h1 class="has-text-weight-bold">Attachments</h1>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-small" outlined @click="showUploadAttachmentModal = true">Upload Attachment</b-button>
            </div>
          </div>
        </div>
        <Attachments :order="order" @attachment:removed="$emit('refresh')"/>
      </div>
    </div>
    <!-- Modal: Edit Meta Data Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditMetaModal"
      :can-cancel="['x', 'esc']"
      ><EditMetaModal :order="order" @po:updated="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Edit Dates Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditDatesModal"
      :can-cancel="['x', 'esc']"
      ><EditDatesModal :order="order" @po:updated="$emit('refresh')" />
    </b-modal>
    <!-- Modal: Create Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateProjectModal"
      :can-cancel="['x', 'esc']"
      ><CreateProjectModal :order="order" @po:updated="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Activate Funding -->
    <b-modal
      v-if="!order.started_at"
      has-modal-card
      scroll="keep"
      :active.sync="showActivateModal"
      :can-cancel="['x', 'esc']"
      ><StartModal :order="order" @po:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Pause Funding -->
    <b-modal
      v-if="order.started_at"
      has-modal-card
      scroll="keep"
      :active.sync="showPauseModal"
      :can-cancel="['x', 'esc']"
      ><PauseModal :order="order" @po:saved="$emit('refresh')"  />
    </b-modal>
      <!-- Modal: Resume Funding -->
    <b-modal
      v-if="order.paused_at"
      has-modal-card
      scroll="keep"
      :active.sync="showResumeModal"
      :can-cancel="['x', 'esc']"
      ><ResumeModal :order="order" @po:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Approve PO -->
    <b-modal
      v-if="!order.started_at"
      has-modal-card
      scroll="keep"
      :active.sync="showApproveModal"
      :can-cancel="['x', 'esc']"
      ><ApproveModal :order="order" @po:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: End Funding -->
    <b-modal
      v-if="order.started_at"
      has-modal-card
      scroll="keep"
      :active.sync="showEndModal"
      :can-cancel="['x', 'esc']"
      ><EndModal :order="order" @po:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Archive PO -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveModal :order="order" @po:saved="redirect" />
    </b-modal>
    <!-- Modal: Add Attachment -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUploadAttachmentModal"
      :can-cancel="['x', 'esc']"
      ><UploadPoAttachmentModal :order="order" @attachment:uploaded="$emit('refresh')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PoSummary from '../components/Projects/Summary'
import Workflow from '../components/Summary/Workflow'
import Attachments from '../components/Summary/Attachments'
import EditMetaModal from '@/components/Models/PurchaseOrder/Modals/EditMetaModal'
import EditDatesModal from '@/components/Models/PurchaseOrder/Modals/EditDatesModal'
import StartModal from '@/components/Models/PurchaseOrder/Modals/Start'
import PauseModal from '@/components/Models/PurchaseOrder/Modals/Pause'
import ResumeModal from '@/components/Models/PurchaseOrder/Modals/Resume'
import ApproveModal from '@/components/Models/PurchaseOrder/Modals/Approve'
import EndModal from '@/components/Models/PurchaseOrder/Modals/End'
import ArchiveModal from '@/components/Models/PurchaseOrder/Modals/ArchiveModal'
import CreateProjectModal from '@/components/Models/Project/Modals/CreateProject'
import UploadPoAttachmentModal from '@/components/Models/Attachment/Modals/UploadPoAttachment'

export default {
  components: {
    Attachments,
    CreateProjectModal,
    EditMetaModal,
    EditDatesModal,
    ArchiveModal,
    StartModal,
    PauseModal,
    ResumeModal,
    EndModal,
    ApproveModal,
    PoSummary,
    Workflow,
    UploadPoAttachmentModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/projects/'
    },
    query () {
      return {
        params: {
          po: this.$route.params.id
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      projects: [],
      showCreateProjectModal: false,
      showEditMetaModal: false,
      showEditDatesModal: false,
      showActivateModal: false,
      showPauseModal: false,
      showResumeModal: false,
      showEndModal: false,
      showApproveModal: false,
      showArchiveModal: false,
      showUploadAttachmentModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.projects = response.data.data
        this.loading = false
      })
    },
    redirect () {
      this.$router.push({ name: 'network.orders' })
    }
  },
  mounted () {

  },
  props: ['order']
}
</script>
