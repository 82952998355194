<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-2">
            <div class="">
              <b-menu>
                <b-menu-list label="Settings Menu">
                  <b-menu-item
                    icon="cog"
                    label="General"
                    tag="router-link"
                    :to="{ name: 'network.settings.profile', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="account-multiple"
                    label="People"
                    tag="router-link"
                    :to="{ name: 'network.settings.roles', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="lan-connect"
                    label="Integrations"
                    tag="router-link"
                    :to="{ name: 'network.settings.integrations', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="bullhorn-outline"
                    label="Announcements"
                    tag="router-link"
                    :to="{ name: 'network.settings.announcements', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="bank"
                    label="Finance"
                    tag="router-link"
                    :to="{ name: 'network.settings.finance', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
          </div>
          <!-- Child Router View -->
          <div class="column is-10">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  }
}
</script>
