import Detail from './views/Detail'
import Projects from './views/Projects'
import Seats from './views/Seats'
import Summary from './views/Summary'

export default [{
  path: '/network/:code/finance/billing-periods/:id',
  component: Detail,
  children: [{
    path: 'projects',
    name: 'network.finance.period.projects',
    component: Projects
  }, {
    path: 'seats',
    name: 'network.finance.period.seats',
    component: Seats
  }, {
    path: '',
    name: 'network.finance.period.summary',
    component: Summary
  }]
}]
