<template>
  <div>
    <Seats
      v-if="line.billing_model === 'time' && line.seats.length"
      :project="project"
      :line="line"
      :period="period"
      @create:timesheet="createTimesheet"
      @inspect:timesheet="inspectTimesheet"
      @project:saved="$emit('project:saved')" />
    <Deliveries
      v-else-if="line.billing_model === 'time' && line.deliveries.length"
      :project="project"
      :line="line"
      :period="period"
      @inspect:delivery="inspectDelivery" />
    <Expenses v-else-if="line.billing_model === 'expenses' && line.expenses.length"
      :project="project"
      :line="line"
      :period="period"
      @project:saved="$emit('project:saved')" />
  </div>
</template>

<script>
import Seats from './Seats'
import Deliveries from './Deliveries'
import Expenses from './Expenses'

export default {
  components: {
    Seats,
    Deliveries,
    Expenses
  },
  computed: {
    billables () {
      if (this.line.billing_model === 'deliverables') {
        return this.line.deliveries
      }
      return this.line.seats
    }
  },
  methods: {
    createTimesheet (seat) {
      this.$emit('create:timesheet', seat)
    },
    inspectTimesheet (timesheet) {
      this.$emit('inspect:timesheet', timesheet)
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
