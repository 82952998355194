<template>
  <div>
    <!-- Vendors -->
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="has-background-white rounded-xl pa-lg">
          <p class="has-text-weight-bold has-text-centered mb-lg">Select Network</p>
          <b-autocomplete
            :data="networks"
            placeholder="Search for networks..."
            field="name"
            :loading="loadingNetworks"
            @typing="searchNetworks"
            @select="setNetwork">
            <template slot-scope="props">
              <p>{{ props.option.name }}</p>
            </template>
            <template slot="empty">
              <p v-if="loadingNetworks">Loading...</p>
              <p v-else>No results found.</p>
            </template>
          </b-autocomplete>
        </div>
      </div>
    </div>
    <!-- Partners -->
    <div class="columns is-centered">
      <div class="column is-half mx-auto">
        <div class="has-background-white rounded-xl pa-lg">
          <p class="has-text-weight-bold has-text-centered mb-lg">Select Partner</p>
          <b-autocomplete
            :data="partners"
            placeholder="Search for partners..."
            field="name"
            :loading="loadingPartners"
            @typing="searchPartners"
            @select="setPartner">
            <template slot-scope="props">
              <p>{{ props.option.name }} (ID: {{ props.option.id }})</p>
            </template>
            <template slot="empty">
              <p v-if="loadingPartners">Loading...</p>
              <p v-else>No results found.</p>
            </template>
          </b-autocomplete>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
export default {
  data: function () {
    return {
      loadingNetworks: false,
      loadingPartners: false,
      networks: [],
      network: null,
      partners: [],
      partner: null
    }
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    searchNetworks: debounce(function (name) {
      this.loadingNetworks = true
      const query = {
        search: name
      }
      this.$http.get('v1/context/networks', { params: query }).then(response => {
        this.networks = response.data
        this.loadingNetworks = false
      })
    }, 500),
    searchPartners: debounce(function (name) {
      this.loadingPartners = true
      const query = {
        search: name
      }
      this.$http.get('v1/context/partners', { params: query }).then(response => {
        this.partners = response.data
        this.loadingPartners = false
      })
    }, 500),
    setNetwork (network) {
      const role = {
        id: null,
        resource_id: network.id,
        resource_type: 'network',
        resource: network,
        role: 'administrator'
      }
      this.$store.dispatch('setRole', role).then(() => {
        this.$router.push({ name: 'network.projects', params: { code: network.code } })
      })
    },
    setPartner (partner) {
      const role = {
        id: null,
        resource_id: partner.id,
        resource_type: 'partner',
        resource: partner,
        role: 'administrator'
      }
      this.$store.dispatch('setRole', role).then(() => {
        this.$router.push({ name: 'partner.projects', params: { code: partner.code } })
      })
    }
  },
  mounted: function () {

  }
}
</script>
