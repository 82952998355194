<template>
  <div>
    <!-- Breadcrumbs -->
    <div class="mb-md">
      <Breadcrumbs :history="history" current="Team" />
    </div>
    <!-- Toolbar -->
    <div class="has-background-white pa-md has-shadow mb-xxs">
      <div class="columns is-vcentered space-bewtween">
        <div class="column">
          <p class="has-text-grey-light is-size-7">{{ roles.length }} {{ (roles.length === 1) ? 'Team Member' : 'Team Members' }}</p>
        </div>
        <div class="column has-text-right">
          <router-link class="button is-outlined is-primary" :to="{ name: 'partner.people.invite', params: { code: context.code }}" type="is-primary" outlined>New Team Member</router-link>
        </div>
      </div>
    </div>
    <!-- Users -->
    <div class="has-background-white pa-md has-shadow mb-xxs">
      <b-table
        :data="roles"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          cell-class="has-text-grey-light"
          width="96"
          v-slot="props">
          <p>{{ props.row.id }}</p>
        </b-table-column>
        <b-table-column
          label="Contributor"
          v-slot="props">
          <router-link class="has-text-weight-bold" :to="{ name: 'partner.people.general', params: { code: context.code, id: props.row.id }}">
            {{ props.row.user.full_name }}
          </router-link>
        </b-table-column>
        <b-table-column
          label="Role"
          width="128"
          v-slot="props"
          centered>
          {{ props.row.type.name }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingRoles)
    },
    history () {
      return [
        { label: 'Settings', name: 'partner.settings', params: { code: this.context.code } }
      ]
    }
  },
  data () {
    return {
      loadingRoles: true,
      roles: []
    }
  },
  methods: {
    loadRoles () {
      this.loadingRoles = true
      const endpoint = this.api.route + '/admin/roles'
      this.$http.get(endpoint).then(response => {
        this.roles = response.data.data
        this.loadingRoles = false
      })
    }
  },
  mounted () {
    this.loadRoles()
  }
}
</script>
