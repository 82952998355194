<template>
  <b-tag :type="presenter.class" :size="this.size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.project.ended_at) {
        presenter.class = 'is-grey'
        presenter.label = 'Completed'
      } else if (this.project.paused_at) {
        presenter.class = 'is-warning is-light'
        presenter.label = 'Paused'
      } else if (this.project.started_at) {
        presenter.class = 'is-success is-light'
        presenter.label = 'Active'
      } else {
        presenter.class = 'has-text-grey-light'
        presenter.label = 'Draft'
      }
      return presenter
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    size: {
      type: [String, null],
      default: 'is-small'
    }
  }
}
</script>
