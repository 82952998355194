<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  computed: {
    periods () {
      const periods = []
      this.project.periods.forEach(period => {
        periods.push(period.billing_period.label)
      })
      return periods
    },
    chartData () {
      const periods = this.periods
      this.project.periods.forEach(period => {
        periods.push(period.billing_period.label)
      })
      const start = (Number(this.project.amount_allocated > 0))
        ? Number(this.project.amount_allocated)
        : 0
      const data = [start]
      // burn down or up depending on estimate
      const direction = (start > 0) ? 'down' : 'up'
      let balance = start
      this.project.periods.forEach(period => {
        balance = (direction === 'down')
          ? Number(balance) - Number(period.amount_allocated)
          : Number(balance) + Number(period.amount_allocated)
        data.push(balance)
      })
      return {
        labels: periods,
        datasets: [{
          label: 'Burn',
          data: data,
          fill: false,
          borderColor: '#fa4516',
          backgroundColor: '#fa4516',
          borderWidth: 2
        }]
      }
    }
  },
  data () {
    return {
      options: {
        elements: { point: { radius: 2 } },
        scales: {
          yAxes: [{
            offset: false,
            ticks: { display: false },
            gridLines: { display: true }
          }],
          xAxes: [{
            offset: false,
            ticks: { display: true },
            gridLines: { display: false }
          }]
        },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
