<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <!-- Child Router View -->
          <div class="column is-12">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  }
}
</script>
