<template>
  <div>
    <div class="has-text-weight-bold border-t border-b py-sm is-size-7">
      <div class="columns is-vcentered">
        <div class="column">
          <p>Time & Materials</p>
        </div>
        <div class="column">
          <p v-if="hiddenSeats > 0" class="has-text-right is-size-8">
            <a href="#" @click.prevent="toggleHiddenRoles" class="has-text-primary">{{ (this.showActiveRolesOnly) ? 'Show' : 'Hide' }} Hidden Projects</a>
          </p>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div>
      <b-table
        :data="filteredSeats"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="Client"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="py-lg"
          width="128">
          <cld-image :publicId="props.row.position.project.client.organization.logo_path" width="96" dpr="2.0" crop="scale" style="display:block;" />
        </b-table-column>
        <b-table-column
          label="Project"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="nowrap">
          <div>
            <p v-if="props.row.role" class="has-text-weight-bold">{{ props.row.role }}</p>
            <p :class="(props.row.held_at) ? 'has-text-grey-light' : ''">
              {{ props.row.position.project.name }}:
              {{ props.row.position.role }}
              <span v-if="props.row.reporting_partner_role_id !== props.row.partner_role_id" class="is-size-7 has-text-grey-light">{{ props.row.contributor.user.full_name }}</span>
            </p>
            <p class="has-text-grey-light is-size-8">
              {{ $dates(props.row.position.project.start_date).format('MMM YYYY') }}
              -
              {{ $dates(props.row.position.project.end_date).format('MMM YYYY') }}
            </p>
            </div>
        </b-table-column>
        <b-table-column
          label="Duration"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="nowrap"
          centered
          width="164">
          <p :class="(props.row.held_at) ? 'has-text-grey-light' : ''">
            --
          </p>
        </b-table-column>
        <b-table-column
          label="Rate"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          centered
          width="96">
          <p v-if="props.row.timesheets.length"><Currency :value="props.row.timesheets[0].rate" zero-value="dash" /></p>
          <p v-else><Currency :value="0" /></p>
        </b-table-column>
        <b-table-column
          label="Timesheet"
          v-slot="props"
          header-class="has-text-grey-light has-text-weight-normal"
          cell-class="nowrap"
          width="164"
          centered>
          <div class="has-text-weight-bold">
            <div v-if="props.row.timesheets.length === 0" class="has-text-grey-light">
              <CreateTimesheetButton
                :seat="props.row"
                :period="period"
                @timesheet:created="goToTimesheet"  />
            </div>
            <div v-else-if="props.row.timesheets.length === 1">
              <!-- Closed -->
              <b-button
                v-if="props.row.closed_at"
                class="is-size-7"
                type="is-light"
                expanded
                disabled>
                Closed
              </b-button>
              <!-- On Hold -->
              <b-button
                v-else-if="props.row.held_at"
                class="is-size-7"
                type="is-light"
                expanded
                disabled>
                On Hold
              </b-button>
              <!-- Submitted -->
              <router-link
                v-else-if="props.row.timesheets[0].submitted_at"
                class="button is-info is-outlined is-fullwidth is-size-7"
                :to="{ name: 'partner.timesheet.hours', params: { code: context.code, seat: props.row.id, timesheet: props.row.timesheets[0].id } }">
                <p>Submitted</p>
              </router-link>
              <!-- Editing / In Progress -->
              <router-link
                v-else
                class="button is-info is-outlined is-fullwidth is-size-7"
                :to="{ name: 'partner.timesheet.hours', params: { code: context.code, seat: props.row.id, timesheet: props.row.timesheets[0].id } }">
                <p>Edit Time</p>
              </router-link>
            </div>
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="nowrap"
          width="64"
          centered>
          <OptionsMenu
            :projectId="props.row.position.project_id"
            :seat="props.row"
            @seat:hide="hideSeat"
            @seat:unhide="unhideSeat"
            @seat:hold="holdSeat"
            @seat:resume="resumeSeat"
          />
        </b-table-column>
        <template #empty>
          <div class="has-text-grey-light has-text-centered pt-lg">You don't have any active roles!</div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Seat Workflow -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatWorkflowModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UpdateSeatPartnerStatusModal :seat="focusSeat" :action="focusAction" @seat:saved="$emit('seat:saved')" />
    </b-modal>
  </div>
</template>

<script>
import OptionsMenu from '@/components/Models/Seat/PartnerOptions'
import CreateTimesheetButton from '@/components/Models/Timesheet/CreateButton'
import UpdateSeatPartnerStatusModal from '@/components/Models/Seat/Modals/UpdatePartnerStatus'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateTimesheetButton,
    OptionsMenu,
    UpdateSeatPartnerStatusModal
  },
  computed: {
    ...mapGetters(['context']),
    filteredSeats () {
      if (this.showActiveRolesOnly) {
        return this.seats.filter(seat => {
          return seat.partner_status !== 'hidden'
        })
      }
      return this.seats
    },
    hiddenSeats () {
      let hidden = 0
      this.seats.forEach(seat => {
        if (seat.partner_status === 'hidden' || seat.partner_status === 'hold') {
          hidden++
        }
      })
      return hidden
    }
  },
  data () {
    return {
      showActiveRolesOnly: true,
      saving: true,
      showSeatWorkflowModal: false,
      focusSeat: null
    }
  },
  methods: {
    toggleHiddenRoles () {
      this.showActiveRolesOnly = !this.showActiveRolesOnly
    },
    goToTimesheet (timesheet) {
      this.$router.push({ name: 'partner.timesheet.hours', params: { code: this.context.code, seat: timesheet.seat_id, timesheet: timesheet.id } })
    },
    hideSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'hide'
      this.showSeatWorkflowModal = true
    },
    unhideSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'unhide'
      this.showSeatWorkflowModal = true
    },
    holdSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'hold'
      this.showSeatWorkflowModal = true
    },
    resumeSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'resume'
      this.showSeatWorkflowModal = true
    },
    reset () {
      this.focusSeat = null
      this.focusAction = null
      this.showSeatWorkflowModal = false
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    seats: {
      type: Array,
      required: true
    }
  }
}
</script>
