<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Billable Items</p>
      </header>
      <section class="modal-card-body">
        <div>
          <p class="mb-md"><label class="label">Group By</label></p>
          <b-field>
            <b-radio v-model="groupBy" native-value="single">
              Consolidate all billable items into a single invoice line.
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="groupBy" native-value="consolidated">
              Consolidate all billable items by role.
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="groupBy" native-value="separate">
              Create an invoice line for each billable item.
            </b-radio>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="!ready"
        >Add Billable Items</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (this.groupBy)
    }
  },
  data () {
    return {
      groupBy: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/admin/client-invoices/' + this.invoice.id + '/convert-billables'
      const json = {
        group_by: this.groupBy
      }
      this.$http.post(endpoint, json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Invoice lines created!' })
        this.$emit('invoice:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
