<template>
<div>
  <!-- Content -->
  <div>
    <!-- Content -->
    <div class="">
      <div class="has-background-white pa-md rounded-lg mb-xs">
        <div class="columns is-vcentered space-between">
          <div class="column">
            <p class="has-text-grey-light">{{ accounts.length }} {{ (accounts.length) === 1 ? 'Integration' : 'Integrations' }}</p>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" outlined @click="showIntegrationModal = true">
              Add Integration
            </b-button>
          </div>
        </div>
      </div>
      <!-- Loaded -->
      <div v-if="!loading && accounts.length > 0">
        <div v-for="account in accounts" :key="account.id" class="has-background-white rounded-lg pa-lg my-xxs">
          <div>
            <div class="columns is-vcentered">
              <div class="column">
                <figure class="image is-3x1">
                  <img :src="account.platform.logo_url" style="width:120px" alt="returnly logo."/>
                </figure>
              </div>
              <div class="column is-narrow">
                <p class="has-text-weight-bold has-text-success">Installed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loaded (Empty) -->
      <div v-else-if="!loading && accounts.length === 0">
        <div class="has-background-white pa-md rounded-lg mb-xs">
          <div class="my-xxl has-text-grey-light has-text-centered">
            <p>No integrations added yet.</p>
            <p>Click "Add Integration above to add integration credentials.</p>
            <p class="py-lg">
              <b-button type="is-primary" @click="showIntegrationModal = true">
                Add Integration
              </b-button>
            </p>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-else class="has-background-white pa-md rounded-lg">
        <Loading />
      </div>
    </div>
  </div>
  <!-- Integration Modal -->
  <b-modal
    :active.sync="showIntegrationModal"
    :can-cancel="['escape', 'x']"
    has-modal-card
    scroll="keep"
    @close="account = null">
    <InstallModal @updated="loadPlatformAccounts" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import InstallModal from '@/components/Models/PlatformAccount/Modals/Install'
export default {
  components: {
    InstallModal
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return this.loadingPlatformAccounts
    }
  },
  data () {
    return {
      loadingPlatformAccounts: true,
      accounts: [],
      showIntegrationModal: false
    }
  },
  methods: {
    loadPlatformAccounts () {
      this.loadingPlatformAccounts = true
      const endpoint = this.api.route + '/admin/platform-accounts/'
      this.$http.get(endpoint).then(response => {
        this.accounts = response.data.data
        this.loadingPlatformAccounts = false
      })
    }
  },
  mounted () {
    this.loadPlatformAccounts()
  }
}
</script>
