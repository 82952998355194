<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Change Role</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Role"
          :type="('role' in errors) ? 'is-danger' : ''"
          :message="('role' in errors) ? errors['role'][0] : ''"
          expanded>
          <b-select
            placeholder="Select Role"
            size="is-medium"
            expanded
            v-model="roleType">
            <option value="administrator">Administrator</option>
            <option value="member">Member</option>
          </b-select>
        </b-field>
        <b-field
          label="Status"
          :type="('status' in errors) ? 'is-danger' : ''"
          :message="('status' in errors) ? errors['status'][0] : ''"
          expanded>
          <b-select
            placeholder="Select Status"
            size="is-medium"
            expanded
            v-model="status">
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </b-select>
        </b-field>
        <!-- <b-field
          label="Time Reported By"
          :type="('status' in errors) ? 'is-danger' : ''"
          :message="('status' in errors) ? errors['status'][0] : ''"
          expanded>
          <b-input
            size="is-medium"
            expanded
            v-model="timeReporter">
          </b-input>
        </b-field> -->
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingAdmins: true,
      roleType: null,
      status: null,
      timeReporter: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    loadAdministrators () {
      this.loadingAdmins = true
      const endpoint = this.api.route + '/admin/roles'
      const query = {
        params: {
          role_type_code: 'admininstrator'
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.administrators = response.data.data
        this.loadingAdmins = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/admin/roles/' + this.current.id
      const body = {
        role_type_id: this.roleType.id,
        status: this.status
        // reporting_partner_role_id: this.timeReporter.id
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Team member updated!' })
        this.$parent.close()
        this.$emit('role:saved', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadAdministrators()
    this.roleType = this.role.type.code
    this.status = (this.role.suspended_at) ? 'inactive' : 'active'
    this.timeReporter = this.role.reporter_role_id
  },
  props: {
    role: {
      type: Object,
      required: true
    }
  }
}
</script>
