<template>
<div>
  <!-- Funding Summary -->
  <!-- <div class="mb-lg">
    <div class="columns is-mobile">
      <div class="column">
        <div class="has-background-white has-shadow pa-md has-text-centered">
          <p class="has-text-weight-bold"><Currency :value="project.amount_funded" /></p>
          <p class="is-size-7 has-text-grey-light">Funding</p>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Funding Table -->
  <div class="has-background-white has-shadow pa-md mb-lg">
    <!-- Has Funding -->
    <div v-if="!loading && fundings.length">
      <p class="has-text-weight-bold is-size-5"> Funding Sources</p>
      <b-table
        :data="fundings"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          v-slot="props"
          cell-class="has-text-grey-light"
          width="64">
          <p>{{ props.row.id }}</p>
        </b-table-column>
        <b-table-column
          label="Identifier"
          cell-class="nowrap"
          v-slot="props">
          <router-link :to="{ name: 'network.funding', params: { id: props.row.id }}" class="has-text-primary has-text-weight-bold">
            <span>{{ props.row.funding_type.abbr }} </span>
            <span v-if="props.row.client_identifier">{{ props.row.client_identifier }}</span>
            <span v-else>{{ props.row.id }}</span>
          </router-link>
        </b-table-column>
        <b-table-column
          cell-class="nowrap"
          centered
          width="128">
          <template v-slot:header>
            <p>Funding Date</p>
          </template>
          <template v-slot="props">
            <p>{{ (props.row.activated_at) ? $dates(props.row.activated_at).format('MMM D, YYYY') : '--' }}</p>
          </template>
        </b-table-column>
        <b-table-column
          field="status"
          label="Status"
          v-slot="props"
          centered
          width="128">
          <StatusPresenter :funding="props.row" />
        </b-table-column>
      </b-table>
    </div>
    <!-- No Funding -->
    <div v-else-if="!loading && !fundings.length">
      <div class="py-xl">
        <div class="has-text-centered">
          <p>This project is not funded yet.</p>
        </div>
      </div>
    </div>
    <!-- Loading State -->
    <div v-else>
      <div class="py-xl">
        <Loading message="Loading funding..." />
      </div>
    </div>
  </div>
  <!-- Revisions -->
  <div v-if="revisions.length" class="has-background-white has-shadow pa-md">
    <!-- Has Revisions -->
    <div v-if="!loading">
      <p class="has-text-primary has-text-weight-bold">Original Funding</p>
      <b-table
        :data="fundings"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          v-slot="props"
          cell-class="has-text-grey-light"
          width="64">
          <p>{{ props.row.id }}</p>
        </b-table-column>
        <b-table-column
          cell-class="nowrap">
          <template v-slot:header>
            <p>Funding Date</p>
          </template>
          <template v-slot="props">
            <p>{{ $dates(props.row.activated_at).format('MMM D, YYYY') }}</p>
          </template>
        </b-table-column>
      </b-table>
    </div>
    <!-- Loading State -->
    <div v-else>
      <div class="py-xl">
        <Loading message="Loading revisions..." />
      </div>
    </div>
  </div>
  <!-- Distributions -->
  <div v-if="fundings.length" class="has-background-white has-shadow pa-md mb-lg">
    <div v-if="!loading">
      <FundingDistributions :fundings="fundings" />
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Funding/Presenters/Status'
import FundingDistributions from '../components/Funding/FundingDistributions'

export default {
  components: {
    StatusPresenter,
    FundingDistributions
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return Boolean(this.loadingFunding)
    }
  },
  data () {
    return {
      loadingFunding: false,
      fundings: [],
      revisions: []
    }
  },
  methods: {
    loadFunding (quiet = false) {
      if (!quiet) this.loadingFunding = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/funding'
      this.$http.get(endpoint).then(response => {
        this.fundings = response.data.funding
        this.loadingFunding = false
      })
    }
  },
  mounted () {
    return this.loadFunding()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
