<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-narrow">
            <div class="">
              <b-menu>
                <b-menu-list label="Clients Menu">
                  <b-menu-item
                    icon="account-multiple"
                    label="Clients"
                    tag="router-link"
                    :to="{ name: 'network.clients', params: { code: context.code } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="domain"
                    label="Organizations"
                    tag="router-link"
                    :to="{ name: 'network.organizations', params: { code: context.code } }"
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
          </div>
          <!-- Child Router View -->
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  }
}
</script>
