<template>
  <div>
    <div>
      <!-- Content -->
      <div class="mb-lg pb-lg">
        <General />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import General from '../components/Profile/General'
export default {
  components: {
    General
  },
  computed: {
    ...mapGetters(['context']),
    breadcrumbs () {
      return [
        { label: 'Settings', name: 'network.settings', params: { code: this.context.code } }
      ]
    }
  },
  data () {
    return {
      loading: true,
      network: null
    }
  }
}
</script>
