<template>
<div>
  <div>
    <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
      <Workflow
        :project="project"
        @manage:roles="showProjectRolesModal = true"
        @start:project="showStartProjectModal = true"
        @pause:project="showPauseProjectModal = true"
        @end:project="showEndProjectModal = true"
        @restart:project="showRestartProjectModal = true"
      />
    </div>
    <!-- Summary -->
    <div class="mb-sm">
      <ProjectProfile :project="project" @edit:dates="$emit('edit:dates')" />
    </div>
    <!-- Funding -->
    <div class="mb-lg">
      <FundingSummary :project="project" @action:link="showLinkOrderModal = true" @project:saved="$emit('refresh')" />
    </div>
    <!-- Resourcing -->
    <div>
      <p class="has-text-weight-bold is-uppercase has-text-centered mb-md">Resourcing</p>
      <!-- Fixed Fees -->
      <!-- <div class="mb-lg">
        <ProjectFees :project="project" @fee:saved="$emit('refresh')" />
      </div> -->
      <!-- Positions -->
      <div class="mb-lg">
        <Positions :project="project" @staff:saved="$emit('refresh')"  />
      </div>
      <!-- Seats -->
      <div>
        <Seats :project="project" @staff:saved="$emit('refresh')" />
      </div>
    </div>
  </div>
  <!-- Modal: Project Roles -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showProjectRolesModal"
    :can-cancel="['x', 'esc']"
    ><ManageProjectRolesModal :project="project" @roles:updated="$emit('refresh')" />
  </b-modal>
  <!-- Modal: Start Project -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showStartProjectModal"
    :can-cancel="['x', 'esc']"
    ><StartProjectModal :project="project" @project:saved="$emit('refresh')" />
  </b-modal>
  <!-- Modal: End Project -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showEndProjectModal"
    :can-cancel="['x', 'esc']"
    ><EndProjectModal :project="project" @project:saved="$emit('refresh')" />
  </b-modal>
  <!-- Modal: Pause Project -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showPauseProjectModal"
    :can-cancel="['x', 'esc']"
    ><PauseProjectModal :project="project" @project:saved="$emit('refresh')" />
  </b-modal>
  <!-- Modal: Restart Project -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showRestartProjectModal"
    :can-cancel="['x', 'esc']"
    ><RestartProjectModal :project="project" @project:saved="$emit('refresh')" />
  </b-modal>
  <!-- Modal: Link Funding -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showLinkOrderModal"
    :can-cancel="['x', 'esc']"
    ><LinkOrderModal :project="project" @project:linked="$emit('refresh')" />
  </b-modal>
  <!-- Modal: Edit Deliverable -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showDeliverableModal"
    :can-cancel="['x', 'esc']"
    @close="resetDeliverable"
    ><DeliverableModal :project="project" :deliverable="deliverable" @deliverable:saved="$emit('refresh')" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StartProjectModal from '@/components/Models/Project/Modals/StartProject'
import ManageProjectRolesModal from '@/components/Models/Project/Modals/ManageProjectRoles'
import EndProjectModal from '@/components/Models/Project/Modals/EndProject'
import PauseProjectModal from '@/components/Models/Project/Modals/PauseProject'
import RestartProjectModal from '@/components/Models/Project/Modals/RestartProject'
import DeliverableModal from '@/components/Models/Deliverable/EditDeliverable'
import LinkOrderModal from '@/components/Models/Project/Modals/LinkOrder'
import ProjectProfile from '../components/Staff/ProjectProfile'
import FundingSummary from '../components/Staff/Funding'
import Workflow from '../components/Staff/Workflow'
import Positions from '../components/Staff/Positions'
import Seats from '../components/Staff/Seats'

export default {
  components: {
    ManageProjectRolesModal,
    StartProjectModal,
    EndProjectModal,
    RestartProjectModal,
    PauseProjectModal,
    DeliverableModal,
    LinkOrderModal,
    ProjectProfile,
    FundingSummary,
    Positions,
    Seats,
    Workflow
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingBillingPeriods)
    }
  },
  data () {
    return {
      loadingBillingPeriods: false,
      deliverable: null,
      showProjectRolesModal: false,
      showDeliverableModal: false,
      showLinkOrderModal: false,
      showPositionRateHistoryModal: false,
      showProposeModal: false,
      showSeatModal: false,
      showSeatRateChangeModal: false,
      showSeatRateHistoryModal: false,
      showUpdateSeatStatusModal: false,
      showStartProjectModal: false,
      showPauseProjectModal: false,
      showEndProjectModal: false,
      showRestartProjectModal: false,
      selected: [],
      position: null,
      seat: null,
      seatAction: null,
      view: 'targets'
    }
  },
  methods: {
    editDeliverable (deliverable) {
      this.deliverable = deliverable
      this.showDeliverableModal = true
    },
    resetDeliverable () {
      this.deliverable = null
      this.showDeliverableModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
