<template>
  <b-dropdown position="is-bottom-left" class="has-text-left">
    <template #trigger>
      <b-button
        type="is-ghost"
        size="is-small"
        :append-to-body="true"
        outlined
      ><b-icon icon="dots-vertical" /></b-button>
    </template>
    <!-- Always Actions -->
    <b-dropdown-item @click="workflowAction('seat:review')">View Performance</b-dropdown-item>
    <hr class="dropdown-divider">
    <b-dropdown-item has-link>
      <router-link
        :to="{ name: 'network.project.seat', params: { code: context.code, id: projectId, seat: seat.id } }"
        >Manage Partner
      </router-link>
    </b-dropdown-item>
    <!-- <b-dropdown-item has-link>
      <router-link
        :to="{ name: 'network.project.seat.plans', params: { code: context.code, id: projectId, seat: seat.id } }"
        >Manage Partner Plan
      </router-link>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item @click="workflowAction('seat:view-rates')">View Rate History</b-dropdown-item>
    <b-dropdown-item @click="workflowAction('seat:change-rate')">Change Rates</b-dropdown-item> -->
    <!-- Active Actions -->
    <template v-if="seat.status === 'active'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('seat:pause')">Pause Contributor</b-dropdown-item>
    </template>
    <!-- Paused Actions -->
    <template v-if="seat.status === 'paused'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('seat:restart')">Restart Contributor</b-dropdown-item>
    </template>
    <!-- Pending Actions -->
    <template v-if="seat.status === 'pending'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="workflowAction('seat:activate')">Activate Contributor</b-dropdown-item>
      <b-dropdown-item @click="workflowAction('seat:archive')" class="has-text-danger">Remove Contributor</b-dropdown-item>
    </template>
    <!-- IDs -->
    <template v-if="canCopy">
      <hr class="dropdown-divider">
      <b-dropdown-item  @click="copy(seat.id)">Copy Role ID</b-dropdown-item>
    </template>
    <template>
      <hr class="dropdown-divider">
      <b-dropdown-item custom>
        <span class="has-text-grey-light">ID: {{ seat.id }}</span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    canCopy () {
      return (navigator.clipboard && window.isSecureContext)
    }
  },
  methods: {
    workflowAction (action) {
      this.$emit(action, this.seat)
    },
    copy (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
      })
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
