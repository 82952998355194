<template>
  <div>
    <!-- Alerts -->
    <!-- <div v-if="project.status !== 'pending'" class="mb-lg has-shadow">
      <PacingAlert :project="project" />
    </div> -->
    <!-- Project Summary -->
    <div v-if="project.positions.length" class="mb-md">
      <div class="columns is-multiline">
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <AllocationSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <UtilizationSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <BillingSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <ExpenseSummary :project="project" />
          </div>
        </div>
      </div>
    </div>
    <!-- Project Health -->
    <div v-if="project.positions.length && preview" class="mb-xl">
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="has-background-white has-shadow pa-lg is-fullheight">
            <ProjectBurnChart :project="project" @project:saved="$emit('project:saved')" />
          </div>
        </div>
        <div class="column is-one-third">
          <div class="has-background-white has-shadow pa-lg is-fullheight" style="max-height:500px">
            <CurrentPeriod :project="project" />
          </div>
        </div>
      </div>
    </div>
    <!-- Project Budgets -->
    <div class="has-background-white has-shadow px-lg py-md mb-xs ">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold is-size-5">Budgets</p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary rounded-lg" outlined @click="showCreateLineModal = true">Add Project Budget</b-button>
        </div>
      </div>
    </div>
    <!-- Project Resources -->
    <div>
      <div v-if="budgets.length">
        <div v-for="line in budgets" :key="line.id">
          <div class="has-background-white has-shadow pa-lg mb-xs">
            <ProjectLine :project="project" :line="line" @line:edit="editLine" @line:saved="$emit('refresh')" />
          </div>
        </div>
      </div>
      <!-- No Resources Yet -->
      <div v-else class="has-background-white pa-xxl has-text-centered is-size-4 has-text-grey-light">
        <p>You're not tracking any budgets for this project yet. </p>
        <p class="mb-lg">Click 'Add Project Budget' below to add your first budget.</p>
        <p><b-button type="is-primary rounded-lg" outlined @click="showCreateLineModal = true">Add Project Budget</b-button></p>
      </div>
    </div>
    <!-- Modal: Create/Edit Line -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateLineModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><EditLineModal :project="project" :position="focusLine" @position:saved="$emit('project:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AllocationSummary from '../components/Lines/AllocationSummary'
import BillingSummary from '../components/Lines/BillingSummary'
import ExpenseSummary from '../components/Lines/ExpenseSummary'
import UtilizationSummary from '../components/Lines/UtilizationSummary'
import ProjectBurnChart from '../components/Lines/ProjectBurnChart'
import ProjectLine from '../components/Lines/Line'
import EditLineModal from '@/components/Models/Position/Modals/Edit'
import CurrentPeriod from '../components/Lines/CurrentPeriod'
// import PacingAlert from '../components/Lines/PacingAlert'

export default {
  components: {
    // PacingAlert,
    ProjectLine,
    AllocationSummary,
    BillingSummary,
    ExpenseSummary,
    CurrentPeriod,
    UtilizationSummary,
    ProjectBurnChart,
    EditLineModal
  },
  computed: {
    ...mapGetters(['api']),
    budgets () {
      const budgets = []
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          budgets.push(budget)
        }
      })
      return budgets
    }
  },
  data () {
    return {
      focusLine: null,
      focusAction: null,
      showCreateLineModal: false
    }
  },
  methods: {
    editLine (line) {
      this.focusLine = line
      this.showCreateLineModal = true
    },
    // updateLineStatus (payload) {
    //   this.focusAction = payload.action
    //   this.focusLine = payload.line
    //   this.showPositionWorkflowModal = true
    // },
    reset () {
      this.focusLine = null
      this.focusAction = null
      this.showCreateLineModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    preview: {
      type: Boolean
    }
  }
}
</script>
