<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  computed: {
    periods () {
      const periods = []
      const end = this.$dates(this.project.end_date).startOf('month')
      let start = this.$dates(this.project.start_date).startOf('month')
      while (start <= end) {
        periods.push(start.format('MMM YYYY'))
        start = start.add(1, 'month')
      }
      return periods
    },
    chartData () {
      if (!(this.stage in this.project.aggregate_periods)) return []
      const actuals = []
      // loop through data
      this.project.aggregate_periods[this.stage].forEach(aggregate => {
        actuals.push(aggregate.billable_total)
      })
      // push datasets
      // datasets.push({ type: 'bar', label: 'Planned', borderColor: '#fa4516', backgroundColor: '#fa4516', data: actual })
      // datasets.push({ type: 'bar', label: 'Burned', borderColor: '#CCC', backgroundColor: '#CCC', data: burned })
      return {
        labels: this.periods,
        datasets: [{
          label: 'Burn',
          data: actuals,
          fill: false,
          borderColor: '#fa4516',
          backgroundColor: '#fa4516',
          borderWidth: 2
        }]
      }
    }
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        drawTicks: true,
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        legend: { display: false },
        scales: {
          xAxes: [{
            offset: true,
            gridLines: { display: true },
            ticks: { display: false }
          }],
          yAxes: [{
            offset: true,
            gridLines: { display: false },
            ticks: { display: false }
          }]
        }
      }
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    stage: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
