<template>
  <span :class="textColor">Q{{ presenter.quarter }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const parts = this.sequence.split('.')
      return {
        year: Number(parts[0]),
        quarter: Number(parts[1]),
        month: Number(parts[2])
      }
    },
    textColor () {
      if (this.useColors) {
        if (this.presenter.quarter === 1) return 'has-text-grey'
        if (this.presenter.quarter === 2) return 'has-text-grey'
        if (this.presenter.quarter === 3) return 'has-text-grey'
        if (this.presenter.quarter === 4) return 'has-text-grey'
      }
      return null
    }
  },
  props: {
    period: {
      type: Object
    },
    sequence: {
      type: String
    },
    useColors: {
      type: Boolean,
      default: true
    }
  }
}
</script>
