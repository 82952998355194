<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold is-uppercase is-size-8">
            Funding <span class="has-text-grey-light">[ {{ project.funding.length }} ]</span>
          </p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="$emit('action:link')">Link Funding</b-button>
        </div>
      </div>
    </div>
    <div>
      <b-table
        :data="project.funding"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          v-slot="props"
          label="Type"
          width="96"
          header-class="is-size-8"
          cell-class="nowrap">
          {{ props.row.funding_type.abbr }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Name"
          header-class="is-size-8"
          cell-class="nowrap">
          <router-link
            class="has-text-weight-bold"
            :to="{ name: 'network.funding', params: { code: context.code, id: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Identifier"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="128">
          {{ props.row.client_identifier }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Total Funding"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="164" >
          <Currency :value="props.row.total_funding" />
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="nowrap"
          width="164"
          numeric>
          <Options :project="project" :funding="props.row" @project:saved="$emit('project:saved')" />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered is-size-7 pa-md">
            <p class="has-text-grey-light">Funding has not been added to this project yet.</p>
            <!-- <p><a href="#" @click.prevent="showCreateOrderModal = true">Add PO</a></p> -->
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Options from '@/components/Models/Funding/Options'

import { mapGetters } from 'vuex'
export default {
  components: {
    Options
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
