<template>
  <div>
    <b-table
      :data="deliveries"
      :mobile-cards="false"
      hoverable
      narrowed
      class="is-size-7">
      <!-- Title -->
      <b-table-column
        v-slot="props"
        label="Fixed Fee"
        class="is-size-7"
        header-class="has-text-primary"
        cell-class="nowrap">
        <p>
          <span class="has-text-weight-bold">{{ props.row.title }}</span>
          <StatusPresenter :delivery="props.row" class="ml-sm" />
        </p>
        <!-- <p class="has-text-grey-light is-italic is-size-8">{{ $dates(props.row.billing_period.start_date).format('MMM YYYY') }}</p> -->
      </b-table-column>
      <!-- Delivery -->
      <!-- Money -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Allocated</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p><Currency :value="props.row.billable_total" :mantissa="0" /></p>
        </template>
      </b-table-column>
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Delivered</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p><Currency :value="(props.row.approved_at) ? props.row.billable_total : 0" :mantissa="0" /></p>
        </template>
      </b-table-column>
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Remaining</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p><Currency :value="(props.row.approved_at) ? 0 : props.row.billable_total" :mantissa="0" /></p>
        </template>
      </b-table-column>
      <!-- Margin -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Margin</p>
          <p class="has-text-grey">(%)</p>
        </template>
        <template v-slot="props">
          <Margin :revenue="props.row.billable_total" :cogs="props.row.cost" />
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        cell-class="nowrap"
        width="64"
        numeric>
        <OptionsMenu
          :projectId="project.id"
          :delivery="props.row"
          @delivery:duplicate="duplicateDelivery"
          @delivery:activate="activateDelivery"
          @delivery:pause="pauseDelivery"
          @delivery:restart="restartDelivery"
          @delivery:archive="archiveDelivery"
        />
      </b-table-column>
      <template #empty>
        <div class="has-text-centered is-size -7 pa-md">
          <b-button type="is-primary rounded-lg" size="is-small" outlined @click="$emit('delivery:add')">Add Delivery</b-button>
        </div>
      </template>
    </b-table>
    <!-- Modal: Delivery Workflow -->
    <b-modal
      v-if="focusDelivery"
      has-modal-card
      scroll="keep"
      :active.sync="showDeliveryWorkflowModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UpdateDeliveryStatusModal :project="project" :delivery="focusDelivery" :action="focusAction" @delivery:saved="$emit('delivery:saved')" />
    </b-modal>
  </div>
</template>

<script>
import StatusPresenter from '@/components/Models/Delivery/Presenters/Status'
import OptionsMenu from '@/components/Models/Delivery/Options'
import UpdateDeliveryStatusModal from '@/components/Models/Delivery/Modals/UpdateStatus'

export default {
  components: {
    StatusPresenter,
    OptionsMenu,
    // modals
    UpdateDeliveryStatusModal
  },
  data () {
    return {
      focusDelivery: null,
      sourceDelivery: null,
      showDeliveryWorkflowModal: false
    }
  },
  methods: {
    duplicateDelivery (delivery) {
      this.sourceDelivery = delivery
      this.showEditDeliveryModal = true
    },
    activateDelivery (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'activate'
      this.showDeliveryWorkflowModal = true
    },
    pauseDelivery (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'pause'
      this.showDeliveryWorkflowModal = true
    },
    restartDelivery (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'restart'
      this.showDeliveryWorkflowModal = true
    },
    archiveDelivery (delivery) {
      this.focusDelivery = delivery
      this.focusAction = 'archive'
      this.showDeliveryWorkflowModal = true
    },
    reset () {
      this.focusDelivery = null
      this.focusAction = null
      this.showDeliveryWorkflowModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    deliveries: {
      type: Array,
      required: true
    }
  }
}
</script>
