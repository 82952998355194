<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      options: {
        drawTicks: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: true
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    }
  },
  props: ['chartdata'],
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>
