<template>
  <span :class="output.textClass">{{ output.value }} </span>
</template>

<script>
export default {
  computed: {
    output () {
      if (this.variance > 0) {
        return {
          value: this.$numbers(this.variance).format({ output: 'percent', mantissa: 1 }),
          textClass: 'has-text-weight-bold has-text-warning'
        }
      } else if (this.variance < 0) {
        return {
          value: this.$numbers(this.variance).format({ output: 'percent', mantissa: 1 }),
          textClass: 'has-text-weight-bold has-text-info'
        }
      }
      return {
        value: '--',
        textClass: 'has-text-grey-light'
      }
    }
  },
  props: ['variance']
}
</script>
