<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Order Loaded -->
        <div v-if="!loading && order !== null">
          <Breadcrumbs :history="breadcrumbs" current="Funding Details" />
          <!-- Header Loaded -->
          <div class="mb-lg">
            <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <p class="is-size-5 has-text-weight-bold">{{ order.name }}</p>
                  <p class="has-text-grey has-text-grey-light">
                    {{ order.client.organization.name }} &bull;
                    {{ order.client.name }}
                  </p>
                </div>
                <div class="column is-narrow has-text-right">
                  <p class="is-size-5 has-text-weight-bold">
                    <StatusPresenter :order="order" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Layout -->
          <div class="columns">
            <!-- Order Menu -->
            <div class="column is-narrow">
              <b-menu class="pr-lg">
                <b-menu-list label="Menu">
                  <b-menu-item
                    icon="checkbox-multiple-marked-outline"
                    label="Summary"
                    tag="router-link"
                    :to="{ name: 'network.order', params: { code: context.code, id: order.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="pulse"
                    label="Activity"
                    tag="router-link"
                    :to="{ name: 'network.order.activities', params: { code: context.code, id: order.id } }"
                    exact
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
            <!-- PO Menu -->
            <div class="column">
              <div>
                <router-view v-if="order !== null" :order="order" @refresh="load(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/PurchaseOrder/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [{ label: 'Funding', name: 'network.orders', params: { code: this.context.code } }]
    },
    endpoint () {
      return this.api.route + '/purchase-orders/' + this.$route.params.id
    }
  },
  data () {
    return {
      loading: true,
      order: null,
      showAccessModal: false
    }
  },
  methods: {
    load (refreshOnly) {
      if (!refreshOnly) this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.order = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  }
}
</script>
