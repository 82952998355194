<template>
  <div>
    <form @submit.prevent="update">
      <!-- Validation Helper -->
      <div class="has-text-centered has-text-grey-light mb-xl">
        <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
        <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
      </div>
      <!-- Form Inputs -->
      <div class="mb-xxl">
        <b-field
          label="Email"
          :type="('email' in errors) ? 'is-danger' : ''"
          :message="('email' in errors) ? errors['email'][0] : ''">
          <b-input
            type="email"
            name="email"
            size="is-large"
            v-model="email"
            @input="isDirty = 1"
          ></b-input>
        </b-field>
        <b-field
          label="New Password"
          :type="('new_password' in errors) ? 'is-danger' : ''"
          :message="('new_password' in errors) ? errors['new_password'][0] : ''">
          <password
            autocomplete="new-password"
            v-model="newPassword"
            defaultClass="input is-large"
            placeholder=""
            :toggle="false"
            :badge="false"
            @score="setScore"
          ></password>
        </b-field>
        <b-field label="Confirm Password">
          <b-input
            v-model="confirmPassword"
            type="password"
            name="confirmPassword"
            size="is-large"
            password-reveal
            @input="isDirty = 1"
          ></b-input>
        </b-field>
      </div>
      <!-- Submit -->
      <div>
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-large"
          :disabled="saving || !ready"
          expanded>
          Update
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'

export default {
  components: {
    Password
  },
  computed: {
    endpoint () {
      return '/reset-password'
    },
    json () {
      return {
        email: this.email,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
        token: this.$route.query.token
      }
    },
    ready () {
      return (
        this.email &&
        this.newPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-warning',
        body: null
      }
      if (!this.email) {
        message.body = 'Please add your email address to reset your password.'
      } else if (!this.newPassword) {
        message.body = 'Please enter a new password.'
      } else if (this.newPassword !== this.confirmPassword) {
        message.body = 'Please make sure to confirm your new password.'
      } else if (this.passwordScore < 2) {
        message.body = 'Please strengthen your password until you see a green score.'
      } else {
        message.class = 'has-text-success'
        message.body = 'You are ready to submit your new password!'
      }
      return message
    }
  },
  data () {
    return {
      saving: false,
      email: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 2,
      error: null,
      errors: {}
    }
  },
  methods: {
    setScore (score) {
      this.isDirty = true
      this.passwordScore = score
    },
    update () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Password changed!' })
        this.$router.push({ name: 'auth.login' })
        this.email = localStorage.removeItem('resetEmail')
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
        } else {
          this.errors = { general: ['An unknown error occurred. Please contact support@gathertech.co'] }
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    reset () {
      this.newPassword = null
      this.oldPassword = null
      this.confirmPassword = null
      this.passwordScore = 0
      this.error = null
      this.errors = {}
    }
  },
  mounted () {
    this.email = localStorage.getItem('resetEmail')
  }
}
</script>
