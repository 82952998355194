<template>
  <div>
    <div>
      <div>
        <div class="columns">
          <div class="column">
            <!-- Filters Toolbar -->
            <div class="has-background-white rounded-lg pa-md mb-xxs">
              <div class="columns">
                <div class="column">
                  <RoleFilters :applied-filters="filters" @filter="filter" />
                </div>
                <div class="column is-narrow">
                  <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                    <option value="name_asc">Name (A-Z)</option>
                    <option value="name_desc">Name (Z-A)</option>
                    <option value="id_desc">Most Recent</option>
                    <option value="id_asc">Oldest</option>
                  </b-select>
                </div>
                <div class="column is-narrow">
                  <b-button type="is-primary" size="is-medium" class="is-fullwidth" outlined @click.prevent="showCreateRoleModal = true">Add Person</b-button>
                </div>
              </div>
            </div>
            <!-- Actions Toolbar -->
            <div class="has-background-white rounded-lg px-md py-md mb-xxs">
              <div class="columns is-vcentered is-mobile">
                <div class="column">
                  <div class="has-text-right is-size-7">
                    <p v-if="loading">Loading...</p>
                    <p v-else-if="total === 1">1 member found</p>
                    <p v-else>{{ total }} members found</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Results -->
            <div style="overflow-x:auto" class="mb-md">
              <b-table
                :data="results"
                :checked-rows.sync="selected"
                :mobile-cards="false"
                :loading="loading"
                hoverable
                class="is-size-7">
                <b-table-column
                  field="id"
                  label="ID"
                  width="64"
                  v-slot="props">
                  {{ props.row.id }}
                </b-table-column>
                <b-table-column
                  field="name"
                  label="Name"
                  width="200"
                  v-slot="props">
                  <router-link
                    class="has-text-weight-bold"
                    :to="{ name: 'network.settings.role', params: { code: context.code, id: props.row.id } }">
                    {{ props.row.user.list_name }}
                  </router-link>
                </b-table-column>
                <b-table-column
                  field="email"
                  label="Email"
                  v-slot="props">
                  {{ props.row.user.email }}
                </b-table-column>
                <b-table-column
                  field="role"
                  label="Role"
                  width="128"
                  v-slot="props">
                  {{ $utils.upperFirst(props.row.type.name ) }}
                </b-table-column>
                <b-table-column
                  field="status"
                  label="Status"
                  width="128"
                  centered
                  v-slot="props">
                  <StatusPresenter :role="props.row" />
                </b-table-column>
                <template slot="empty">
                  <div class="py-xxl has-text-grey has-text-centered">
                    {{ (loading) ? "Loading data..." : 'No records found!' }}
                  </div>
                </template>
              </b-table>
            </div>
            <b-pagination
              :total="total"
              :current.sync="currentPage"
              :rounded="true"
              :per-page="perPage"
              @change="paginate" />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Create -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateRoleModal"
      :can-cancel="['x', 'esc']"
      ><CreateRoleModal @role:saved="load" />
    </b-modal>
  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import RoleFilters from '../components/Roles/RoleFilters'
import CreateRoleModal from '@/components/Models/Role/CreateRoleModal'
import StatusPresenter from '@/components/Presenters/Role/Status'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateRoleModal,
    RoleFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/admin/roles'
    }
  },
  data: function () {
    return {
      key: 'people',
      alwaysAttach: {},
      showCreateRoleModal: false
    }
  },
  methods: {
    redirect () {
      return {}
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
