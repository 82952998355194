<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-md">
            <div>
              <!-- Business ID -->
              <div>
                <p class="has-text-weight-bold mb-sm">FreshBooks Business ID</p>
                <p class="mb-sm">
                  <span v-if="businessId">{{ businessId }}</span>
                  <span v-else class="has-text-grey-light">Not Set</span>
                </p>
              </div>
              <!-- Account ID -->
              <div>
                <p class="has-text-weight-bold mb-sm">FreshBooks Account ID</p>
                <p class="mb-sm">
                  <span v-if="businessId">{{ accountId }}</span>
                  <span v-else class="has-text-grey-light">Not Set</span>
                </p>
              </div>
            </div>
          </div>
          <div class="border-t pa-md">
            <b-button @click="showUpdateBusinessModal = true">Edit Settings</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Update Account -->
    <b-modal
      has-modal-card
      :active.sync="showUpdateBusinessModal"
      :can-cancel="['escape', 'x']"
      >
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context']),
    businessId () {
      const credential = this.account.credentials.find(credential => {
        return credential.platform_credential.code === 'business_id'
      })
      return (credential) ? credential.value : null
    },
    accountId () {
      const credential = this.account.credentials.find(credential => {
        return credential.platform_credential.code === 'account_id'
      })
      return (credential) ? credential.value : null
    }
  },
  data () {
    return {
      showUpdateBusinessModal: false,
      saving: false,
      errors: {}
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
