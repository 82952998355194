<template>
  <div>
    <p class="has-text-weight-bold is-uppercase is-size-7">Project Health</p>
    <div>
      <BurnLineChart :project="project" :stage="'billable'" :height="136" />
    </div>
    <div>
      <BurnBarChart :project="project" :stage="'billable'" :height="64" />
    </div>
  </div>
</template>

<script>
import BurnLineChart from './BurnLineChart'
import BurnBarChart from './BurnBarChart'

export default {
  components: {
    BurnLineChart,
    BurnBarChart
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
