<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add A Note</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field>
                <b-input
                  placeholder="Type your message here..."
                  type="textarea"
                  v-model="message"
                ></b-input>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Save Note
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  data () {
    return {
      message: null,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      const base = this.api.route + '/connections/' + this.connection.id + '/memos'
      return (this.memo)
        ? base + this.memo.id + '/'
        : base
    },
    method () {
      return (this.memo === null) ? 'post' : 'patch'
    },
    json () {
      return {
        memo: this.message
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(repsonse => {
        this.$emit('memo:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Note saved!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.memo) {
      this.message = this.memo.memo
    }
  },
  props: {
    connection: {
      type: Object
    },
    memo: {
      type: Object,
      default: null
    }
  }
}
</script>
