<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Overview -->
        <div class="mb-lg">
          <Overview @period:changed="setPeriod" :deliveries="deliveries" :seats="seats" />
        </div>
        <!-- Roles -->
        <div class="has-background-white has-shadow pa-xl">
          <!-- Header -->
          <p class="is-size-4">Projects</p>
          <div v-if="period">
            <!-- Roles -->
            <div v-if="seats.length" class="mt-lg">
              <Roles :period="period" :seats="seats" @seat:saved="loadSeats(true)" />
            </div>
            <!-- Fixed Fees -->
            <div v-if="deliveries.length" class="mt-lg">
              <p class="has-text-weight-bold border-t border-b py-sm is-size-7">Deliverables</p>
              <Deliveries :period="period" :deliveries="deliveries" @delivery:saved="loadDeliveries(true)" />
            </div>
            <!-- Nuthin' -->
            <div v-if="!seats.length && !deliveries.length">
              <p class="has-text-grey-light has-text-centered py-xl">
                You aren't currently working on any active projects yet.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Overview from '../components/Index/Overview'
import Roles from '../components/Index/Roles'
import Deliveries from '../components/Index/Deliveries'

export default {
  components: {
    Overview,
    Roles,
    Deliveries
  },
  computed: {
    ...mapGetters(['api', 'app', 'auth', 'context', 'role']),
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    },
    loading () {
      return (this.loadingSeats || this.loadingDeliveries)
    }
  },
  data () {
    return {
      loadingBillingPeriod: true,
      loadingSeats: true,
      loadingDeliveries: true,
      period: null,
      seats: [],
      deliveries: []
    }
  },
  methods: {
    setPeriod (period) {
      this.period = period
      this.loadSeats()
      this.loadDeliveries()
    },
    loadSeats (silent = false) {
      if (!silent) this.loadingSeats = true
      const endpoint = this.api.route + '/contributors/' + this.role.id + '/seats'
      const query = {
        params: {
          billing_period: (this.period) ? this.period.id : null
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.seats = response.data
        this.loadingSeats = false
      })
    },
    loadDeliveries (silent = false) {
      if (!silent) this.loadingDeliveries = true
      const endpoint = this.api.route + '/deliveries'
      const query = {
        params: {
          'filter[planned_billing_period_id]': (this.period) ? this.period.id : null
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.deliveries = response.data
        this.loadingDeliveries = false
      })
    }
  }
}
</script>
