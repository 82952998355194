<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ (this.deliverable) ? 'Edit' : 'Add' }} Deliverable</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Description"
          :type="('description' in errors) ? 'is-danger' : ''"
          :message="('description' in errors) ? errors['description'][0] : ''">
          <b-input
            size="is-medium"
            type="text"
            v-model="description"
            expanded
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.description)
    },
    method () {
      return (this.deliverable) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.seat)
        ? this.api.route + '/projects/' + this.project.id + '/deliverables/' + this.deliverable.id
        : this.api.route + '/projects/' + this.project.id + '/deliverables'
    },
    json () {
      return {
        description: this.description,
        completed: (this.completed)
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Deliverable saved!' })
        this.$emit('deliverable:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      description: null,
      completed: false,
      saving: false,
      errors: {}
    }
  },
  mounted () {
    if (this.deliverable) {
      this.description = this.deliverable.description
      this.completed = (this.deliverable.completed_at)
    }
  },
  props: {
    project: {
      type: Object
    },
    deliverable: {
      type: Object
    }
  }
}
</script>
