<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <b-message type="is-warning">
            <p class="is-size-5 has-text-weight-bold mb-sm">Archive Expense?</p>
            <p class="is-size-7">
              Are you sure you want to archive this expense? It will be removed
              from the project. This action cannot be reversed.
            </p>
          </b-message>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Archive Expense
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/expenses/' + this.expense.id
      this.$http.delete(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Expense archived!' })
        this.$parent.close()
        this.$router.push({ name: 'network.project.expenses', params: { code: this.context.code } })
      })
    }
  },
  mounted () {
    this.tag = this.type
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    expense: {
      type: Object,
      required: true
    }
  }
}
</script>
