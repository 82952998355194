<template>
  <div>
    <form @submit.prevent novalidate>
      <div class="has-background-white rounded-lg pa-xl">
        <!-- Sign in with Google -->
        <div class="mb-lg">
          <p class="has-text-centered has-text-grey-light mb-lg">Please sign in</p>
          <b-button
            size="is-medium"
            expanded
            outlined
            icon-left="google"
            type="is-primary"
            @click="redirectToGoogle"
          >Google</b-button>
        </div>
        <!-- Inputs -->
        <div v-if="$route.query.showForm === '1'" class="mb-lg">
          <p class="has-text-centered has-text-grey-light mb-sm">...or sign in with email...</p>
          <!-- Email -->
          <div class="mb-lg">
            <b-field label="Email" custom-class="has-text-primary">
              <b-input
                v-model="email"
                type="email"
                size="is-medium"
                placeholder="Email Address"
              ></b-input>
            </b-field>
          </div>
          <!-- Password -->
          <div class="mb-lg">
            <div class="columns">
              <div class="column py-xs">
                <p class="has-text-primary has-text-weight-bold">Password</p>
              </div>
              <!-- <div class="column py-xs">
                <p class="has-text-right">
                  <router-link v-if="email" :to="{ name: 'auth.forgot', query: { email: this.email } }">Reset Password</router-link>
                  <router-link v-else :to="{ name: 'auth.forgot' }">Reset Password</router-link>
                </p>
              </div> -->
            </div>
            <b-field label="" custom->
              <b-input
                v-model="password"
                type="password"
                size="is-medium"
                placeholder="Password"
                password-reveal
              ></b-input>
            </b-field>
          </div>
          <!-- Button -->
          <div>
            <b-button
              type="is-primary"
              native-type="submit"
              size="is-medium"
              expanded
              outlined
              @click="attempt"
              :disabled="processing"
            >Sign In</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    json () {
      return {
        email: this.email,
        password: this.password
      }
    }
  },
  data: function () {
    return {
      processing: false,
      email: null,
      password: null,
      message: 'Please sign in.'
    }
  },
  methods: {
    attempt () {
      this.processing = true
      // get a csrf token from sanctum first, then try the login
      this.$http.get('/sanctum/csrf-cookie').then(() => {
        this.$http.post('/login', this.json).then(response => {
          this.processing = false
          // TODO handle 2FA authentication
          // verify the profile and dispatch to intended path
          this.$store.dispatch('identify').then(response => {
            if (this.auth.user.roles.length === 1) {
              this.$store.dispatch('setRole', this.auth.user.roles[0]).then(() => {
                this.$router.push({ name: this.context.homeRoute, params: { code: this.context.code } })
              })
            // finally, if we can't resolve a context, direct the user to the
            // context selection view
            } else {
              this.$router.push({ name: 'context' })
            }
          })
        }).catch(() => {
          this.processing = false
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Invalid username and password.',
            type: 'is-danger'
          })
        })
      })
    },
    redirectToGoogle () {
      window.location.href = this.api.host + '/auth/google'
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.email = decodeURIComponent(this.$route.query.email)
      this.password = null
    }
  }
}
</script>
