<template>
  <div>
    <filter-toolbar
      :hasChanges="hasChanges"
      :appliedFilters="appliedFilters"
      :allowFilters="false"
      @search="search"
      @update="update"
      @clear="clear">
    </filter-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as filterOptions from '../../filters/clients.js'
import FilterToolbar from '@/components/Filters/FilterToolbar'
// import BooleanFilter from '@/components/Filters/BooleanFilter'
// import DateFilter from '@/components/Filters/DateFilter'

export default {
  components: {
    // DateFilter,
    FilterToolbar
  },
  data () {
    return {
      hasChanges: false,
      filters: null,
      // filters
      filterOptions: filterOptions
    }
  },
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    change () {
      this.hasChanges = true
    },
    search (searchQuery) {
      this.filters.search = {
        parameter: searchQuery.param,
        query: searchQuery.query
      }
      this.$emit('filter', this.filters)
    },
    update () {
      this.hasChanges = false
      this.filters.preset = 'custom'
      this.$emit('filter', this.filters)
    },
    clear () {
      this.filters = {
        preset: 'all',
        facets: {},
        search: {},
        timestamps: {}
      }
      this.$emit('filter', this.filters)
    }
  },
  mounted () {

  },
  props: {
    appliedFilters: {
      type: Object,
      required: true
    }
  },
  watch: {
    appliedFilters: {
      deep: true,
      immediate: true,
      handler (filters) {
        this.filters = filters
      }
    }
  }
}
</script>
