<template>
  <div class="section">
    <div class="container">
      <div class="columns">
        <!-- Child Router View -->
        <div class="column">
          <div v-if="!loading && role">
            <router-view :role="role" />
          </div>
          <div v-else>
            <Loading message="Loading partner details..." />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Settings', name: 'partner.settings', params: { code: this.context.code } },
        { label: 'People', name: 'partner.settings.people', params: { code: this.context.code } }
      ]
    },
    loading () {
      return (this.loadingRoles)
    }
  },
  data () {
    return {
      loadingRole: true,
      role: null
    }
  },
  methods: {
    loadRole () {
      this.loadingRole = true
      const endpoint = this.api.route + '/admin/roles/' + this.$route.params.id
      this.$http.get(endpoint).then(response => {
        this.role = response.data
        this.loadingRole = false
      })
    }
  },
  mounted () {
    this.loadRole()
  }
}
</script>
