var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-md"},[_c('div',{staticClass:"has-background-white pa-md has-shadow"},[_c('b-table',{staticClass:"is-size-7",attrs:{"data":_vm.contributors,"mobile-cards":false,"hoverable":""}},[_c('b-table-column',{attrs:{"label":"Contributor"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',[_c('router-link',{staticClass:"has-text-weight-bold",attrs:{"to":{
                name: 'network.partner.contributor',
                params: {
                  code: _vm.context.code,
                  connection: _vm.connection.id,
                  contributor: props.row.id
                }
              }}},[_vm._v(_vm._s(props.row.role.user.full_name))])],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"nowrap","label":"Approved On","centered":"","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.approved_at)?_c('div',[_c('p',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(_vm.$dates.utc(props.row.approved_at).local().format('MMM D, YYYY')))])]):_c('div',[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("--")])])]}}])}),_c('b-table-column',{attrs:{"cell-class":"nowrap","label":"Status","centered":"","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.approved_at)?_c('span',{staticClass:"has-text-weight-bold has-text-success"},[_vm._v("Approved")]):_c('span',{staticClass:"has-text-weight-bold has-text-danger"},[_vm._v("Pending")])]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }