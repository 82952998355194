<template>
  <div>
    <!-- Table Header -->
    <div class="has-border-b pb-md">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-5 mb-sm">Billing Adjustments</p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="createBillingAdjustment">Add Billing Adjustment</b-button>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div v-if="project">
      <b-table
        :data="project.adjustments"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          header-class="is-size-8"
          cell-class="has-text-grey-light"
          width="64"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          label="Date"
          header-class="is-size-8"
          width="128"
          v-slot="props">
          <Date :value="props.row.date" />
        </b-table-column>
        <b-table-column
          label="Memo"
          header-class="is-size-8"
          v-slot="props">
          {{ props.row.memo }}
        </b-table-column>
        <b-table-column
          label="Adjustment Amount"
          header-class="is-size-8"
          width="164"
          centered
          v-slot="props">
          <Currency :value="props.row.amount" />
        </b-table-column>
        <b-table-column
          header-class="is-size-8"
          width="48"
          centered
          v-slot="props">
          <a href="#" class="is-size-8" @click.prevent="editBillingAdjustment(props.row)">Edit</a>
        </b-table-column>
        <template #footer v-if="project.adjustments.length">
          <td></td>
          <td></td>
          <td></td>
          <td class="has-text-centered has-text-weight-bold">
            <Currency :value="adjustments.total" :mantissa="2" />
          </td>
          <td></td>
        </template>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">
            <p>No billing adjustments.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Update Adjustment -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUpdateAdjustmentModal"
      :can-cancel="['x', 'esc']"
      @close="resetModal"
      ><EditBillingAdjustmentModal :project="project" :billingAdjustment="focusAdjustment" @project:saved="$emit('project:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditBillingAdjustmentModal from '@/components/Models/BillingAdjustment/Modals/EditBillingAdjustment'

export default {
  components: {
    EditBillingAdjustmentModal
  },
  computed: {
    ...mapGetters(['context']),
    adjustments () {
      const adjustments = {
        count: 0,
        total: 0
      }
      this.project.adjustments.forEach(adjustment => {
        adjustments.count += 1
        adjustments.total += Number(adjustment.amount)
      })
      return adjustments
    }
  },
  data () {
    return {
      focusAdjustment: null,
      showUpdateAdjustmentModal: false
    }
  },
  methods: {
    createBillingAdjustment () {
      this.showUpdateAdjustmentModal = true
    },
    editBillingAdjustment (billingAdjustment) {
      this.focusAdjustment = billingAdjustment
      this.showUpdateAdjustmentModal = true
    },
    resetModal () {
      this.billingAdjustment = null
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
