import Index from './views/Index'
import Announcements from './views/Announcements'

export default [{
  path: '/:context/:code/profile',
  name: 'profile',
  component: Index
}, {
  path: '/:context/:code/announcements',
  name: 'announcements',
  component: Announcements
}]
