<template>
  <b-dropdown position="is-bottom-left" class="has-text-left" >
    <template #trigger>
      <b-button
        type="is-ghost"
        :append-to-body="true"
        outlined
      ><b-icon icon="dots-vertical" /></b-button>
    </template>
    <!-- Active Actions -->
    <template v-if="seat.partner_status === 'active'">
      <b-dropdown-item @click="workflowAction('seat:hold')">Pause Role</b-dropdown-item>
      <b-dropdown-item @click="workflowAction('seat:hide')">Hide Role</b-dropdown-item>
    </template>
    <!-- Hidden Actions -->
    <template v-if="seat.partner_status === 'hidden'">
      <b-dropdown-item @click="workflowAction('seat:unhide')">Unhide Role</b-dropdown-item>
    </template>
    <!-- Pending Actions -->
    <template v-if="seat.partner_status === 'hold'">
      <b-dropdown-item @click="workflowAction('seat:resume')">Resume Role</b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    workflowAction (action) {
      this.$emit(action, this.seat)
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
