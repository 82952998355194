<template>
  <div>
    <!-- Title -->
    <div class="mb-lg">
      <p class="has-text-primary has-text-weight-bold is-size-5">{{ announcement.title }}</p>
      <p>
        Published {{ $dates.utc(announcement.published_at).local().format('MMM D, YYYY') }}
        by
        {{ announcement.author.full_name }}
      </p>
    </div>
    <!-- Content -->
    <div class="content">
      <div v-html="compiledMarkdown"></div>
    </div>
    <!-- Acknowledgement -->
    <div>
      <div v-if="announcement.receipts.length === 0 && ! recentlyAcknowledged">
        <b-button type="is-primary" size="is-small" outlined @click="acknowledgeAnnouncement">Mark as Read</b-button>
      </div>
      <div v-else-if="recentlyAcknowledged">
        <p class="has-text-grey-light is-size-8 is-italic">Acknowledged on {{ $dates().format('MMM D, YYYY') }}</p>
      </div>
      <div v-else>
        <p class="has-text-grey-light is-size-8 is-italic">Acknowledged on {{ $dates.utc(announcement.receipts[0].created_at).local().format('MMM D, YYYY') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    compiledMarkdown () {
      if (this.announcement.markdown !== null && this.announcement.markdown !== '') {
        return marked(this.announcement.markdown)
      }
      return null
    }
  },
  data () {
    return {
      recentlyAcknowledged: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    acknowledgeAnnouncement () {
      this.saving = true
      const endpoint = this.api.route + '/announcements/' + this.announcement.id + '/actions/acknowledge'
      this.$http.put(endpoint).then(response => {
        this.$emit('announcement:acknowledged')
        this.recentlyAcknowledged = true
        this.saving = false
      })
    }
  },
  props: {
    announcement: {
      type: Object,
      required: true
    }
  }
}
</script>
