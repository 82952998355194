<template>
  <div>

    <!-- Seat Burn Chart -->
    <div class="mb-sm">
      <div class="has-background-white pa-md rounded-lg has-text-centered">
        <div v-if="!loading">
          <BurnChart :chartdata="chartData" :height="80" />
        </div>
      </div>
    </div>

    <div class="box mb-xxs">
      <p class="has-text-weight-bold is-size-8 is-uppercase">Transactions</p>
    </div>

    <!-- Has Attachments -->
    <b-table
      :data="invoices"
      :mobile-cards="false"
      :loading="loading"
      hoverable
      narrowed
      class="is-size-7">
      <b-table-column
        label="ID"
        header-class="is-uppercase is-size-8"
        width="64"
        v-slot="props">
        <span class="has-text-grey-light">{{ props.row.id }}</span>
      </b-table-column>
      <b-table-column
        label="Date"
        width="128"
        header-class="is-uppercase is-size-8"
        v-slot="props">
        {{ $dates(props.row.invoice_date).format('MMM D, YYYY') }}
      </b-table-column>
      <b-table-column
        label="Transaction"
        header-class="is-uppercase is-size-8"
        v-slot="props">
        Client Invoice {{ props.row.id }}
      </b-table-column>
      <b-table-column
        label="Transaction Amount"
        header-class="is-uppercase is-size-8"
        width="200"
        numeric
        v-slot="props">
        -<Currency :value="props.row.total_amount" />
      </b-table-column>
      <template #empty>
        <div class="has-text-centered has-text-grey-light pa-lg">No billing transactions have been added yet.</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BurnChart from './BurnChart'

export default {
  components: {
    BurnChart
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return (this.loadingInvoices)
    },
    chartData () {
      // summarize invoices by date
      const summarized = {}
      this.invoices.forEach(invoice => {
        const key = this.$dates(invoice.invoice_date).format('YYYY-MM-DD')
        summarized[key] = (key in summarized)
          ? summarized[key] + Number(invoice.total_amount)
          : Number(invoice.total_amount)
      })
      // construct the first data point
      const dates = [this.$dates.utc(this.funding.activated_at).local().format('MMM D')]
      const burns = [Number(this.funding.total_funding)]
      // calculate the burn from the starting value
      let balance = Number(this.funding.total_funding)
      const sortedKeys = Object.keys(summarized).sort(function (a, b) {
        return new Date(a) - new Date(b)
      })
      sortedKeys.forEach(date => {
        balance = balance - Number(summarized[date])
        dates.push(this.$dates(date).format('MMM D'))
        burns.push(balance)
      })
      // push datasets
      const datasets = []
      datasets.push({ type: 'line', label: 'Funding Balance', borderColor: '#D32308', backgroundColor: 'transparent', data: burns })
      return {
        labels: dates,
        datasets: datasets
      }
    }
  },
  data () {
    return {
      loadingInvoices: false,
      invoices: []
    }
  },
  methods: {
    loadInvoices () {
      this.loadingInvoices = true
      const endpoint = this.api.route + '/funding/' + this.funding.id + '/invoices'
      this.$http.get(endpoint).then(response => {
        this.invoices = response.data.invoices
        this.loadingInvoices = false
      })
    }
  },
  mounted () {
    this.loadInvoices()
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
