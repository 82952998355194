<template>
  <div>
    <div class="has-background-white has-shadow pa-md">
      <p class="has-text-weight-bold is-size-6 has-text-centered">Plan</p>
      <table class="table is-fullwidth is-really-narrow is-size-8 is-paddingless is-striped">
        <tbody>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Budget</th>
            <td class="has-text-right">
              <Currency
                :value="planOnlyBudget"
                :compare="false"
                :mantissa="0"
              />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Funding</th>
            <td class="has-text-right">
              <Currency
                :value="planOnlyFunding"
                :compare="false"
                :mantissa="0"
              />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Allocated</th>
            <td class="has-text-right">
              <Currency
                :value="planOnlyAllocations"
                :compare="false"
                :mantissa="0"
              />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Unallocated</th>
            <td class="has-text-right">
              <Currency
                :value="planOnlyUnallocated"
                :compare="false"
                :mantissa="0"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    planOnlyBudget () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_budgeted)
        }
      })
      return total
    },
    planOnlyFunding () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_funded)
        }
      })
      return total
    },
    planOnlyAllocations () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_allocated)
        }
      })
      return total
    },
    planOnlyUnallocated () {
      return Number(this.planOnlyBudget - this.planOnlyAllocations)
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
