<template>
  <b-dropdown position="is-bottom-left" class="has-text-left" >
    <template #trigger>
      <b-button
        type="is-ghost"
        size="is-small"
        :append-to-body="true"
        outlined
        icon-right="chevron-right"
      >More</b-button>
    </template>
    <!-- Manage -->
    <b-dropdown-item has-link>
      <router-link
        :to="{ name: 'network.project.delivery', params: { code: context.code, id: projectId, delivery: delivery.id } }">
        Manage Delivery
      </router-link>
      <!-- <b-dropdown-item @click="duplicateDelivery">Duplicate Delivery</b-dropdown-item> -->
    </b-dropdown-item>
    <!-- Active Actions -->
    <template v-if="delivery.status === 'active'">
      <hr class="dropdown-divider">
      <b-dropdown-item has-link>
        <router-link
          :to="{ name: 'network.project.delivery', params: { code: context.code, id: projectId }, query: { delivery_id: this.delivery.id} }"
        >Mark as Delivered</router-link>
      </b-dropdown-item>
      <hr class="dropdown-divider">
      <b-dropdown-item @click="pauseDelivery">Pause Delivery</b-dropdown-item>
    </template>
    <!-- Paused Actions -->
    <template v-if="delivery.status === 'paused'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="restartDelivery">Restart Delivery</b-dropdown-item>
    </template>
    <!-- Pending Actions -->
    <template v-if="delivery.status == 'pending'">
      <hr class="dropdown-divider">
      <b-dropdown-item @click="archiveDelivery" class="has-text-danger">Archive Delivery</b-dropdown-item>
    </template>
    <!-- IDs -->
    <template v-if="canCopy">
      <hr class="dropdown-divider">
      <b-dropdown-item  @click="copy(delivery.id)">Copy Delivery ID</b-dropdown-item>
    </template>
    <template>
      <hr class="dropdown-divider">
      <b-dropdown-item custom>
        <span class="has-text-grey-light">ID: {{ delivery.id }}</span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    canCopy () {
      return (navigator.clipboard && window.isSecureContext)
    }
  },
  methods: {
    deliver () {
      this.$emit('delivery:deliver', this.delivery)
    },
    duplicateDelivery () {
      this.$emit('delivery:duplicate', this.delivery)
    },
    activateDelivery () {
      this.$emit('delivery:activate', this.delivery)
    },
    pauseDelivery () {
      this.$emit('delivery:pause', this.delivery)
    },
    restartDelivery () {
      this.$emit('delivery:restart', this.delivery)
    },
    archiveDelivery () {
      this.$emit('delivery:archive', this.delivery)
    },
    copy (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
      })
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
