<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <!-- Header -->
          <div class="mb-lg">
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Export Expense?</p>
              <p class="is-size-7">
                If you have a connected accounting platform, you can export this
                expense to your connected account.
              </p>
            </b-message>
          </div>
          <div class="mb-lg">
            <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
              {{ errors.general[0] }}
            </b-message>
            <b-field label="Platform">
              <PlatformAccountSelect v-model="account" />
            </b-field>
          </div>
          <!-- <div>
            <b-field label="Account">
              <AccountSelect v-model="accountRef" />
            </b-field>
          </div> -->
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Export Expense
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/Models/PlatformAccount/SelectMenu'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      if (!this.account) return null
      switch (this.account.platform.code) {
        case 'quickbooks':
          return this.api.route + '/admin/quickbooks/export-expense'
        default:
          return null
      }
    },
    json () {
      return {
        expense_id: this.expense.id
      }
    },
    ready () {
      return Boolean(this.account)
    }
  },
  data () {
    return {
      account: null,
      accountRef: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.errors = {}
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Expense exported!' })
        this.$emit('expense:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    expense: {
      type: Object,
      required: true
    }
  }
}
</script>
