<template>
  <div>
    <div class="has-background-white has-shadow pa-md">
      <p class="has-text-weight-bold is-size-6 has-text-centered">Expenses</p>
      <table class="table is-fullwidth is-really-narrow is-size-8  is-striped">
        <tbody>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Budget</th>
            <td class="has-text-right">
              <Currency
                :value="(budget) ? budget.estimate : 0"
                :compare="false"
                :compareTo="0"
                :mantissa="2"
              />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Pending</th>
            <td class="has-text-right">
              <Currency :value="project.amount_expenses_pending" :mantissa="2" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Approved</th>
            <td class="has-text-right">
              <Currency :value="project.amount_expenses_billable" :mantissa="2" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Remaining</th>
            <td class="has-text-right">
              <Currency :value="remaining" :mantissa="2" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    budget () {
      let expenses = null
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'expenses') {
          expenses = budget
        }
      })
      return expenses
    },
    remaining () {
      if (this.budget) {
        return Number(this.budget.estimate) - Number(this.project.amount_expenses_pending) - Number(this.project.amount_expenses_billable)
      }
      return 0
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
