<template>
  <form @submit.prevent="addLine">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Transfer Line</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-lg" >
          <div class="columns is-vcentered is-multiline">
            <div class="column is-half">
              <b-field label="Source Funding Type">
                <b-select
                  v-model="sourceFundingLineType"
                  placeholder="Select Funding Type"
                  :loading="loadingFundingLineTypes"
                  expanded>
                  <option v-for="type in fundingLineTypes" :key="type.id" :value="type">{{ type.name }}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Available">
                <Currency :value="availableBalance" />
                &nbsp; &nbsp; <a v-if="availableBalance" href="#" @click.prevent="amount = availableBalance"> Use Balance</a>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Destination Funding Type">
                <b-select
                  v-model="destinationFundingLineType"
                  placeholder="Select Funding Type"
                  :loading="loadingFundingLineTypes"
                  expanded>
                  <option v-for="type in fundingLineTypes" :key="type.id" :value="type">{{ type.name }}</option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Amount">
                <b-input
                  type="number"
                  v-model="amount"
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="!ready"
        >Add Transfer</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['api']),
    availableBalance () {
      if (this.sourceFundingLineType) {
        const line = this.source.line_summary.find(type => {
          return type.code === this.sourceFundingLineType.code
        })
        return parseFloat(line.amount)
      }
      return null
    },
    ready () {
      return this.sourceFundingLineType && this.destinationFundingLineType && this.amount
    }
  },
  data () {
    return {
      loadingFundingLineTypes: true,
      fundingLineTypes: [],
      sourceFundingLineType: null,
      destinationFundingLineType: null,
      amount: null
    }
  },
  methods: {
    loadFundingLineTypes () {
      this.loadingFundingLineTypes = true
      const endpoint = this.api.route + '/funding-line-types'
      this.$http.get(endpoint).then(response => {
        this.fundingLineTypes = response.data.data
        this.loadingFundingLineTypes = false
      })
    },
    addLine () {
      const line = {
        source_line_type: this.sourceFundingLineType,
        destination_line_type: this.destinationFundingLineType,
        amount: this.amount
      }
      this.$emit('line:added', line)
      this.$parent.close()
    }
  },
  mounted () {
    this.loadFundingLineTypes()
  },
  props: {
    source: {
      type: Object,
      required: true
    },
    destination: {
      type: Object,
      required: true
    }
  }
}
</script>
