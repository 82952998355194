<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Upload Photo</p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Preview">
              <div v-if="img">
                <Cropper
                  style="max-width:256px"
                  ref="cropper"
                  :src="img"
                  :canvas="{
                    height: 256,
                    width: 256
                  }"
                  :stencil-size="{
                    width: 128,
                    height: 128
                  }"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                  }"
                  :resize-image="{
                    adjustStencil: false
                  }"
                  image-restriction="stencil" />
              </div>
              <div v-else class="is-size-7">
                Upload a photo to view a preview.
              </div>
            </b-field>
          </div>
          <div class="column">
            <div class="">
              <p class="has-text-weight-bold">Photo Tips</p>
              <ul class="is-size-7">
                <li>- Upload a file at least 250x250 pixels</li>
                <li>- Scroll or pinch to zoom in an out</li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="file === null" class="columns">
          <div class="column">
            <b-field
              label="Select File"
              :type="('file' in errors) ? 'is-danger' : ''"
              :message="('file' in errors) ? errors['file'][0] : ''"
              expanded>
              <b-upload
                v-model="file"
                drag-drop
                expanded
                accept="image/png, image/jpeg, image/jpg"
                @input="preview">
                <div class="has-text-centered py-xxl">
                  <p><b-icon icon="upload" size="is-large"></b-icon></p>
                  <p>Drop your files here or click to upload</p>
                </div>
              </b-upload>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save Photo</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (
        this.file
      )
    }
  },
  methods: {
    preview () {
      try {
        this.img = URL.createObjectURL(this.file)
      } catch {
        this.img = null
      }
    },
    save () {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        this.saving = true
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('file', blob)
          this.$http.post(this.api.host + '/v1/profile/photo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            this.$emit('user:updated')
            this.$buefy.toast.open({ type: 'is-success', message: 'Photo saved!' })
            this.$parent.close()
          }).catch(error => {
            this.errors = error.response.data.errors
          }).finally(() => {
            this.saving = false
          })
        }, this.file.type)
      }
    }
  },
  data () {
    return {
      file: null,
      img: null,
      saving: false,
      errors: {}
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
