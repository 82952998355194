<template>
  <div>
    <!-- Has Attachments -->
    <table v-if="order.attachments.length > 0" class="table is-narrow is-size-7 is-fullwidth is-striped is-vcentered">
      <tbody>
        <tr v-for="attachment in order.attachments" :key="attachment.id">
          <td>
            <p class="has-text-weight-bold">{{ attachment.label }}</p>
            <p><span class="has-text-grey-light">{{ attachment.filename }}</span></p>
          </td>
          <td class="has-text-right">
            <OptionsMenu :attachment="attachment" @attachment:removed="$emit('attachment:removed')" />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- No Attachments -->
    <div v-else class="py-md">
      <p class="is-size-7 has-text-grey-light">No attachments have been uploaded yet.</p>
    </div>
  </div>
</template>

<script>
import OptionsMenu from '@/components/Models/Attachment/DownloadLink'

export default {
  components: {
    OptionsMenu
  },
  props: ['order']
}
</script>
