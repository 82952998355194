<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Link Funding</p>
      </header>
      <section class="modal-card-body">
        <div class="mb-sm">
          <b-field
            label="Funding Source"
            :type="('funding_id' in errors) ? 'is-danger' : ''"
            :message="('funding_id' in errors) ? errors['funding_id'][0] : ''">
            <b-select v-model="funding" size="is-medium" expanded placeholder="Select funding...">
              <option v-for="funding in fundings" :key="funding.id" :value="funding">
                {{ funding.client_identifier }}: {{ funding.name }} ({{ $utils.startCase(funding.status) }})
              </option>
            </b-select>
          </b-field>
        </div>
        <p>Need to <router-link :to="{ name: 'network.fundings', params: { code: context.code } }">add funding?</router-link></p>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Link Funding</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.funding)
    }
  },
  data () {
    return {
      loadingFundings: true,
      fundings: [],
      funding: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadFundings () {
      this.loadingFundings = true
      const endpoint = this.api.route + '/funding/'
      const query = {
        params: {
          client: this.project.client_id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.fundings = response.data.data
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/link'
      const body = {
        funding_id: this.funding.id
      }
      this.$http.post(endpoint, body).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project linked!' })
        this.$emit('project:linked')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadFundings()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
