<template>
  <div>
    <form @submit.prevent="save">
      <div class="py-sm">
        <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
          {{ errors.general[0] }}
        </b-message>
        <b-message v-else-if="Object.keys(errors).length" type="is-danger" class="is-size-7">
          Please review your input and try again.
        </b-message>
        <b-message v-else-if="seat.status === 'active'" type="is-info" class="is-size-7">
          <b>Note:</b> This role has already started, so certain fields can no longer be edited.
        </b-message>
        <div class="columns">
          <div class="column is-full-tablet is-two-thirds-desktop">
            <!-- Role Description -->
            <div class="mb-lg">
              <b-field
                label="Title / Level"
                :type="('client_title_id' in errors) ? 'is-danger' : ''"
                :message="('client_title_id' in errors) ? errors['client_title_id'][0] : ''">
                <ClientTitleSelect :client="project.client" v-model="clientTitle" />
              </b-field>
            </div>
            <!-- Role Description -->
            <div class="mb-sm">
              <p class="mb-sm">
                <span class="has-text-weight-bold">Role Description </span>
                <span class="is-size-7 has-text-grey-light">(e.g. Creative Director, Copywriter, etc)</span>
              </p>
              <div class="columns is-mobile is-vcentered">
                <div class="column">
                  <b-field
                    :type="('role' in errors) ? 'is-danger' : ''"
                    :message="('role' in errors) ? errors['role'][0] : ''">
                    <b-input
                      size="is-medium"
                      type="text"
                      v-model="role"
                      expanded
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-narrow">
                  <a href="#" @click.prevent="useTitle">Use Title</a>
                </div>
              </div>
            </div>
            <!-- Rate -->
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <b-field
                    label="Billable Rate"
                    :type="('billable_rate' in errors) ? 'is-danger' : ''"
                    :message="('billable_rate' in errors) ? errors['billable_rate'][0] : ''">
                    <p class="control">
                      <span class="button is-medium is-static" :class="(readonly) ? 'border-none' : ''">$</span>
                    </p>
                    <b-input
                      size="is-medium"
                      type="number"
                      v-model="billRate"
                      :min="0"
                      expanded
                      :disabled="readonly || saving"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <!-- Start Date -->
            <div class="mb-lg">
              <p class="mb-xs"><label class="label">Start Date</label></p>
              <div class="columns is-vcentered">
                <div class="column is-half">
                  <b-field
                    :type="('start_date' in errors) ? 'is-danger' : ''"
                    :message="('start_date' in errors) ? errors['start_date'][0] : ''">
                    <b-input
                      v-if="!readonly"
                      size="is-medium"
                      type="date"
                      v-model="startDate"
                      :min="project.start_date"
                      :max="project.end_date"
                      expanded
                    ></b-input>
                    <b-input
                      v-else
                      size="is-medium"
                      type="text"
                      :value="$dates(project.start_date).format('MMM D, YYYY')"
                      disabled
                      readonly
                      expanded
                    ></b-input>
                  </b-field>
                </div>
                <div v-if="!readonly" class="column is-narrow">
                  <p><a href="#" @click.prevent="useProjectStartDate">Use Project Start Date</a></p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-hidden-mobile">
            <!-- Help Content -->
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="pt-lg">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-button
              native-type="submit"
              type="is-primary"
              size="is-medium"
              expanded
              :loading="saving"
              :disabled="saving || !ready"
            >Save</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientTitleSelect from '@/components/Models/ClientTitle/SelectMenu'

export default {
  components: {
    ClientTitleSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(this.clientTitle && this.role && this.startDate && this.billRate)
    },
    readonly () {
      return Boolean(this.seat.status !== 'pending')
    },
    json () {
      const json = {
        role: this.role,
        client_title_id: this.clientTitle.id
      }
      if (this.seat.status === 'pending') {
        json.billable_rate = this.billRate
        json.start_date = this.startDate
      }
      return json
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      calculate: 'margin',
      role: null,
      startDate: null,
      clientTitle: null,
      estimate: null,
      billRate: null
    }
  },
  methods: {
    save () {
      this.errors = {}
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id
      this.$http.patch(endpoint, this.json).then(response => {
        this.$emit('seat:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.saving = false
      }).catch(error => {
        this.errors = ('response' in error) ? error.response.data.errors : {}
      })
    },
    useTitle () {
      this.role = this.clientTitle.title
    },
    useProjectStartDate () {
      this.startDate = this.project.start_date
    }
  },
  mounted () {
    this.role = this.seat.role
    this.clientTitle = this.seat.client_title
    this.startDate = this.seat.start_date
    this.estimate = Number(this.seat.estimate).toFixed(2)
    this.billRate = Number(this.seat.plan_bill_rate).toFixed(2)
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  },
  watch: {
    clientTitle (value) {
      if (!this.billRate && this.seat.status === 'pending') {
        this.billRate = parseFloat(value.rate_card).toFixed(2)
      }
    }
  }
}
</script>
