<template>
  <div>
    <form @submit.prevent="save">
      <div class="">
        <!-- Summary -->
        <div class="mt-sm mb-lg">
          <div class="columns">
            <div class="column">
              <div class=" rounded-lg pa-md has-text-centered">
                <p class="is-size-3"><Hours :hours="summary.units" /></p>
                <p class="is-size-7">Hours</p>
              </div>
            </div>
            <div class="column">
              <div class="rounded-lg pa-md has-text-centered">
                <p class="is-size-3"><Currency :value="summary.billRate" /></p>
                <p class="is-size-7">Bill Rate</p>
              </div>
            </div>
            <div class="column">
              <div class="rounded-lg pa-md has-text-centered">
                <p class="is-size-3"><Currency :value="summary.costRate" /></p>
                <p class="is-size-7">Cost Rate</p>
              </div>
            </div>
            <div class="column">
              <div class="rounded-lg pa-md has-text-centered">
                <p class="is-size-3"><Currency :value="summary.billable" :mantissa="0" /></p>
                <p class="is-size-7">Bilable</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Seat Plan Details -->
        <div>
          <b-table
            :data="periodAllocations"
            :mobile-cards="false"
            hoverable
            narrowed
            class="is-size-7 border-b">
            <b-table-column
              label="Period"
              cell-class="nowrap"
              v-slot="props">
              <p class="has-text-weight-bold">
                {{ $dates(props.row.period.start_date).format('MMM YYYY') }}
                <span class="is-size-8 is-uppercase"><PeriodStatus :period="props.row.period" /></span>
              </p>
            </b-table-column>
            <b-table-column
              header-class="is-size-8"
              label="Total Days"
              v-slot="props"
              width="128"
              centered>
              {{ props.row.period.days_total }}
            </b-table-column>
            <b-table-column
              header-class="is-size-8"
              label="Business Days"
              v-slot="props"
              width="128"
              centered>
              {{ props.row.period.days_business }}
            </b-table-column>
            <b-table-column
              header-class="is-size-8"
              label="US Holidays"
              v-slot="props"
              width="128"
              centered>
              {{ props.row.period.days_holidays }}
            </b-table-column>
            <b-table-column
                label="Hours Allocated"
                centered
                width="128"
                header-class="is-size-8"
                v-slot="props">
                <b-input v-model="props.row.allocation.units" type="number" step="0.25" />
              </b-table-column>
            <template #empty>
              <div class="has-text-centered pa-lg">No plans found.</div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="py-lg px-sm">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
          :disabled="saving"
          :loading="saving"
        >Save Allocations</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PeriodStatus from '@/components/Models/BillingPeriod/Presenters/Status'

export default {
  components: {
    PeriodStatus
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingSeat || this.loadingBillingPeriods)
    },
    summary () {
      const summary = {
        days: 0,
        units: 0,
        billable: 0,
        cost: 0,
        billRate: Number(this.seat.plan_bill_rate),
        costRate: Number(this.seat.plan_cost_rate)
      }
      this.periodAllocations.forEach(periodAllocation => {
        summary.days += Number(periodAllocation.period.days_business - periodAllocation.period.days_holidays)
        if (periodAllocation.allocation) {
          summary.units += Number(periodAllocation.allocation.units)
        }
      })
      summary.billable = summary.units * summary.billRate
      summary.cost = summary.units * summary.costRate
      return summary
    },
    json () {
      const json = {
        seats: [{
          seat_id: this.seat.id,
          allocations: []
        }]
      }
      this.periodAllocations.forEach(period => {
        if (period.period.closed_at === null) {
          json.seats[0].allocations.push({
            billing_period_id: period.period.id,
            units: (period.allocation.units) ? period.allocation.units : 0
          })
        }
      })
      return json
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      periodAllocations: []
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.seat.position_id + '/allocate'
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Plan saved!' })
        this.$emit('seat:saved')
      }).catch(() => {

      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.project.periods.forEach((period, index) => {
      const allocation = {
        fiscalSequence: null,
        billRate: null,
        costRate: null,
        units: null,
        billable: null,
        cost: null
      }
      const match = this.seat.allocations.find((allocation) => allocation.billing_period_id === period.billing_period_id)
      if (typeof match !== 'undefined') {
        allocation.fiscalSequence = match.fiscal_month_sequence
        allocation.billRate = Number(match.plan_bill_rate).toFixed(2)
        allocation.costRate = Number(match.plan_cost_rate).toFixed(2)
        allocation.units = Number(match.units).toFixed(2)
      }
      this.periodAllocations.push({
        period: period.billing_period,
        allocation: allocation
      })
    })
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
