<template>
  <div>
    <div class="has-background-white has-shadow pa-md is-size-7 mb-xxs">
      <div class="columns is-mobile">
        <div class="column">
          <p class="has-text-weight-bold">Activity Feed</p>
        </div>
        <div class="column is-narrow">
          <span v-if="!oading" class="has-text-grey-light">
            {{ count }} {{ (count === 1) ? 'activity' : 'activities'}}
          </span>
          <span v-else class="has-text-grey-light">Loading...</span>
        </div>
        <div class="column is-narrow">
          <a href="#" @click.prevent="loadFeed(false)">Refresh</a>
        </div>
      </div>
    </div>
    <!-- Has Activity -->
    <div v-if="!loading && activities.length">
      <div v-for="activity in activities" :key="activity.id" class="mb-xxs">
        <div class="has-background-white has-shadow pa-md is-size-7">
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <span class="has-text-weight-bold">
                {{ $dates.utc(activity.created_at).local().format('MMM D, YYYY') }}
              </span>
            </div>
            <div class="column">
              <ActionPresenter :activity="activity" />
            </div>
            <div class="column is-narrow">
              <span class="has-text-weight-bold has-text-grey-light">
                {{ $dates.utc(activity.created_at).local().format('h:mm A') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- No Activity -->
    <div v-else-if="!loading && activities.length === 0">
      <div class="has-background-white has-shadow has-text-centered has-text-grey-light is-size-7 py-xxl">
        No activity found...yet!
      </div>
    </div>
    <!-- Loading -->
    <div v-else>
      <div class="has-background-white has-shadow has-text-centered has-text-grey-light">
        <Loading message="Loading activity feed..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ActionPresenter from '@/components/Models/Activity/Presenters/Action'

export default {
  components: {
    ActionPresenter
  },
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingFeed: true,
      activities: [],
      count: null
    }
  },
  methods: {
    loadFeed (quiet = false) {
      if (!quiet) this.loadingFeed = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/activities'
      this.$http.get(endpoint).then(response => {
        this.count = response.data.meta.total
        this.activities = response.data.data
        this.loadingFeed = false
      })
    }
  },
  mounted () {
    this.loadFeed()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
