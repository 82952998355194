<template>
  <div>
    <!-- Header -->
    <div class="has-border-b pb-md">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-5 mb-sm">Funding Distributions</p>
        </div>
      </div>
    </div>
    <!-- Datatable -->
    <div>
      <b-table
        :data="distributions"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="ID"
          header-class="is-size-8"
          width="64"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          label="From Funding Line"
          header-class="is-size-8"
          v-slot="props">
          <span>{{ props.row.funding_line.description }}</span>
        </b-table-column>
        <b-table-column
          label="To Budget"
          header-class="is-size-8"
          v-slot="props">
          <span>{{ props.row.budget.role }}</span>
        </b-table-column>
        <b-table-column
          label="Amount"
          header-class="is-size-8"
          width="128"
          v-slot="props"
          centered>
          <Currency :value="props.row.amount" />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">
            <p>No funding assigned to this project yet.</p>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    distributions () {
      const distributions = []
      this.fundings.forEach(funding => {
        funding.distributions.forEach(distribution => {
          distributions.push(distribution)
        })
      })
      return distributions
    }
  },
  methods: {

  },
  props: {
    fundings: {
      type: Array,
      required: true
    }
  }
}
</script>
