<template>
  <div>
    <div>
      <div>
        <!-- Filters Toolbar -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <ClientFilters :applied-filters="filters" @filter="filter" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="id_desc">Most Recent</option>
                <option value="id_asc">Oldest</option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-medium" class="is-fullwidth" outlined @click.prevent="showCreateClientModal = true">Add Client</b-button>
            </div>
          </div>
        </div>
        <!-- Actions Toolbar -->
        <div class="has-background-white rounded-lg px-md py-md mb-xxs">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <div class="has-text-right is-size-7">
                <p v-if="loading">Loading...</p>
                <p v-else-if="total === 1">1 client found</p>
                <p v-else>{{ total }} clients found</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div style="overflow-x:auto" class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <b-table-column
              field="id"
              label="ID"
              width="64"
              v-slot="props">
              <span class="has-text-grey-light">{{ props.row.id }}</span>
            </b-table-column>
            <b-table-column
              field="id"
              label="Organization"
              width="160"
              v-slot="props">
              <span v-if="props.row.organization">{{ props.row.organization.name }}</span>
              <span v-else class="has-text-grey-light is-italic">Not Set</span>
            </b-table-column>
            <b-table-column
              field="name"
              label="Client Name"
              v-slot="props">
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.client', params: { code: context.code, id: props.row.id } }">
                {{ props.row.name }}
              </router-link>
            </b-table-column>
            <b-table-column
              field="status"
              label="Status"
              centered
              width="128"
              v-slot="props">
              <StatusPresenter :status="props.row.status" />
            </b-table-column>
            <template slot="empty">
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>

      </div>
    </div>

    <!-- Modal: Change Address -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateClientModal"
      :can-cancel="['x', 'esc']"
      ><CreateClientModal @client:updated="redirect" />
    </b-modal>

  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import ClientFilters from '../components/Clients/ClientFilters'
import CreateClientModal from '@/components/Models/Client/CreateClientModal'
import StatusPresenter from '@/components/Presenters/Client/Status'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateClientModal,
    ClientFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Clients', name: 'network.clients', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/clients'
    }
  },
  data: function () {
    return {
      key: 'clients',
      showCreateClientModal: false
    }
  },
  methods: {
    redirect (id) {
      this.$router.push({ name: 'network.client', params: { code: this.context.code, id: id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
