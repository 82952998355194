import Detail from './views/Detail'
import General from './views/General'
import Invite from './views/Invite'

export default [{
  path: '/partner/:code/people/invite',
  name: 'partner.people.invite',
  component: Invite
}, {
  path: '/partner/:code/people/:id',
  component: Detail,
  children: [{
    path: 'general',
    name: 'partner.people.general',
    component: General
  }]
}]
