<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns">
        <div class="column is-one-quarter">
          <router-link
            :to="{ name: 'partner.timesheet.expense.create' }"
            class="button is-light is-medium is-fullwidth"
          >Add Expense</router-link>
        </div>
      </div>
    </div>
    <!-- Expenses -->
    <div class="mb-md" v-if="expenses.length">
      <b-table
        :data="expenses"
        :mobile-cards="false"
        :checked-rows.sync="selected"
        :loading="loading"
        hoverable
        checkable
        class="is-size-7">
        <b-table-column
          label="Date"
          width="164px"
          v-slot="props">
          <router-link
            class="has-text-weight-bold"
            :to="{ name: 'partner.timesheet.expense', params: { expense: props.row.id }  }">
            {{ $dates(props.row.expense_date).format('MMM D, YYYY') }}
          </router-link>
        </b-table-column>
        <b-table-column
          label="Vendor"
          v-slot="props">
          <p>{{ props.row.vendor }}</p>
        </b-table-column>
        <b-table-column
          label="Description"
          v-slot="props">
          <p>{{ $utils.truncate(props.row.description, { length: 24 }) }}</p>
        </b-table-column>
        <b-table-column
          label="Amount"
          numeric
          v-slot="props">
          <Currency :value="props.row.amount"  />
        </b-table-column>
        <b-table-column
          label="Status"
          width="164px"
          centered
          v-slot="props">
          <StatusPresenter :expense="props.row"  />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered pa-lg">No expenses have been added yet.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Expense/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return (this.loadingExpenses)
    }
  },
  data () {
    return {
      loadingExpenses: false,
      expenses: [],
      selected: []
    }
  },
  methods: {
    loadExpenses () {
      this.loadingExpenses = true
      const endpoint = this.api.route + '/expenses/'
      const query = {
        params: {
          billingPeriod: this.period.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.expenses = response.data.data
        this.loadingExpenses = false
      })
    }
  },
  mounted () {
    this.loadExpenses()
  },
  props: {
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
