<template>
  <span >{{ presenter }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      if (this.abbreviate) {
        if (this.order.funding_type === 'po') return 'PO'
        if (this.order.funding_type === 'sow') return 'SoW'
      } else {
        if (this.order.funding_type === 'po') return 'Purchase Order'
        if (this.order.funding_type === 'sow') return 'Statement of Work'
      }
      return '--'
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    abbreviate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
