<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold is-uppercase is-size-7">
            Roles <span class="has-text-grey-light">[ {{ project.positions.length }} ]</span>
          </p>
        </div>
        <div class="column is-narrow">
          <router-link
            :to="{ name: 'network.project.position', params: { code: 'gather', project: project.id } }"
            class="button is-primary is-outlined is-small"
          >Add Staff Role</router-link>
        </div>
      </div>
    </div>
    <!-- Table -->
    <div>
      <b-table
        :data="project.positions"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          v-slot="props"
          label="ID"
          width="64"
          header-class="is-size-8"
          cell-class="has-text-grey-light">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Role"
          header-class="is-size-8"
          cell-class="nowrap">
          <router-link
            :to="{ name: 'network.project.position', params: { code: context.code, position: props.row.id } }"
            class="has-text-weight-bold"
          >{{ props.row.role }}</router-link>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Contributors"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="96">
          {{ props.row.seats.length }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Status"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="96">
          <StatusPresenter :position="props.row" />
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="nowrap"
          width="96"
          numeric>
          <OptionsMenu
            :projectId="project.id"
            :position="props.row"
            @position:seat="addContributor"
            @position:view-rates="showPositionRates"
            @position:change-rate="changePositionRate"
            @position:activate="activatePosition"
            @position:pause="pausePosition"
            @position:restart="restartPosition"
            @position:archive="archivePosition"
          />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered is-size-7 pa-md">
            <p class="has-text-grey-light">This project doesn't have any roles yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Activate Position -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showActivatePositionModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><ActivatePositionModal :project="project" :position="focusPosition" @position:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: Pause Position -->
    <b-modal
      v-if="focusPosition"
      has-modal-card
      scroll="keep"
      :active.sync="showPausePositionModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><PausePositionModal :project="project" :position="focusPosition" @position:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: Restart Position -->
    <b-modal
      v-if="focusPosition"
      has-modal-card
      scroll="keep"
      :active.sync="showRestartPositionModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><RestartPositionModal :project="project" :position="focusPosition" @position:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: Archive -->
    <b-modal
      v-if="focusPosition"
      has-modal-card
      scroll="keep"
      :active.sync="showArchivePositionModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><ArchivePositionModal :project="project" :position="focusPosition" @position:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: Create Seat -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showSeatModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><CreateSeatModal :project="project" :position="focusPosition" @seat:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: View Position Rate History -->
    <b-modal
      v-if="focusPosition"
      has-modal-card
      scroll="keep"
      :active.sync="showPositionRateHistoryModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><PositionRateHistory :project="project" :position="focusPosition" />
    </b-modal>
    <!-- Modal: Change Position Rate -->
    <b-modal
      v-if="focusPosition"
      has-modal-card
      scroll="keep"
      :active.sync="showPositionRateChangeModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><PositionRateChangeModal :project="project" :position="focusPosition" @position:saved="$emit('staff:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OptionsMenu from '@/components/Models/Position/Options'
import StatusPresenter from '@/components/Models/Position/Presenters/Status'
import ActivatePositionModal from '@/components/Models/Position/Modals/Activate'
import PausePositionModal from '@/components/Models/Position/Modals/Pause'
import RestartPositionModal from '@/components/Models/Position/Modals/Restart'
import ArchivePositionModal from '@/components/Models/Position/Modals/Archive'
import PositionRateChangeModal from '@/components/Models/Position/Modals/RateChange'
import PositionRateHistory from '@/components/Models/Position/Modals/RateHistory'
import CreateSeatModal from '@/components/Models/Seat/Modals/Create'

export default {
  components: {
    OptionsMenu,
    StatusPresenter,
    ActivatePositionModal,
    PausePositionModal,
    RestartPositionModal,
    ArchivePositionModal,
    CreateSeatModal,
    PositionRateChangeModal,
    PositionRateHistory
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      focusPosition: null,
      showSeatModal: false,
      showActivatePositionModal: false,
      showPausePositionModal: false,
      showRestartPositionModal: false,
      showArchivePositionModal: false,
      showPositionRateChangeModal: false,
      showPositionRateHistoryModal: false
    }
  },
  methods: {
    activatePosition (position) {
      this.focusPosition = position
      this.showActivatePositionModal = true
    },
    pausePosition (position) {
      this.focusPosition = position
      this.showPausePositionModal = true
    },
    restartPosition (position) {
      this.focusPosition = position
      this.showRestartPositionModal = true
    },
    archivePosition (position) {
      this.focusPosition = position
      this.showArchivePositionModal = true
    },
    addContributor (position) {
      this.focusPosition = position
      this.showSeatModal = true
    },
    showPositionRates (position) {
      this.focusPosition = position
      this.showPositionRateHistoryModal = true
    },
    changePositionRate (position) {
      this.focusPosition = position
      this.showPositionRateChangeModal = true
    },
    reset () {
      this.focusPosition = null
      this.showSeatModal = false
      this.showActivatePositionModal = false
      this.showPausePositionModal = false
      this.showRestartPositionModal = false
      this.showArchivePositionModal = false
      this.showPositionRateHistoryModal = false
      this.showPositionRateChangeModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
