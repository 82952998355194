<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <div>
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Remove Approval?</p>
              <p class="is-size-7">
                You can unlock timesheets for editing by removing their approvals.
                Are you sure you want to proceed?
              </p>
            </b-message>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Remove Approval
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    timesheets () {
      const timesheets = []
      this.seats.forEach(seat => {
        if (seat.timesheets.length === 1) {
          timesheets.push(seat.timesheets[0])
        }
      })
      return timesheets
    }
  },
  data () {
    return {
      period: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/timesheets/unapprove'
      const json = {
        timesheets: []
      }
      this.timesheets.forEach(timesheet => {
        json.timesheets.push({ id: timesheet.id })
      })
      this.$http.put(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheets updated!' })
        this.$parent.close()
        this.$emit('project:saved')
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seats: {
      type: Array,
      required: true
    }
  }
}
</script>
