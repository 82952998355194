import Vue from 'vue'
import VueRouter from 'vue-router'

// import universal routes
import AuthRoutes from '@/modules/Auth/routes.js'
import ContextRoutes from '@/modules/Context/routes.js'
import ProfileRoutes from '@/modules/Profile/routes.js'
import LegalRoutes from '@/modules/Legal/routes.js'

// import network app routes
import NetworkAccountRoutes from '@/modules/Networks/Accounts/routes.js'
import NetworkBillingPeriodRoutes from '@/modules/Networks/BillingPeriods/routes.js'
import NetworkClientInvoiceRoutes from '@/modules/Networks/ClientInvoices/routes.js'
import NetworkFinanceRoutes from '@/modules/Networks/Finance/routes.js'
import NetworkProjectRoutes from '@/modules/Networks/Projects/routes.js'
import NetworkSettingsRoutes from '@/modules/Networks/Settings/routes.js'
import NetworkPartnerRoutes from '@/modules/Networks/Partners/routes.js'
import NetworkReportsRoutes from '@/modules/Networks/Reports/routes.js'
import NetworkFundingRoutes from '@/modules/Networks/Funding/routes.js'
import NetworkPurchaseOrderRoutes from '@/modules/Networks/PurchaseOrders/routes.js'
import NetworkWorkRoutes from '@/modules/Networks/Work/routes.js'

// import partner app routes
import PartnerDashboardRoutes from '@/modules/Partners/Dashboard/routes.js'
import PartnerProjectRoutes from '@/modules/Partners/Projects/routes.js'
import PartnerOnboardingRoutes from '@/modules/Partners/Onboarding/routes.js'
import PartnerReportsRoutes from '@/modules/Partners/Reports/routes.js'
import PartnerSettingsRoutes from '@/modules/Partners/Settings/routes.js'
import PartnerStaffRoutes from '@/modules/Partners/Staff/routes.js'

// import vuex store
import store from '@/store'

// install router
Vue.use(VueRouter)

// compile module routes
const baseRoutes = [{
  path: '/', redirect: '/context'
}]
const routes = baseRoutes.concat(
  AuthRoutes,
  ContextRoutes,
  ProfileRoutes,
  LegalRoutes,
  // network routes
  NetworkAccountRoutes,
  NetworkBillingPeriodRoutes,
  NetworkClientInvoiceRoutes,
  NetworkFinanceRoutes,
  NetworkFundingRoutes,
  NetworkPartnerRoutes,
  NetworkProjectRoutes,
  NetworkPurchaseOrderRoutes,
  NetworkReportsRoutes,
  NetworkSettingsRoutes,
  NetworkWorkRoutes,
  // partner routes
  PartnerDashboardRoutes,
  PartnerOnboardingRoutes,
  PartnerProjectRoutes,
  PartnerReportsRoutes,
  PartnerSettingsRoutes,
  PartnerStaffRoutes
)

// improve scroll behavior
// https://vuejsdevelopers.com/2017/04/16/vue-js-browser-button-ux/
// consider this:
// https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

// instantiate router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

// Vue Router Navigation Guards

// Clean up and/or Fire Analytics
//
// With each route change, we might need to clean up state (e.g. the hamburger
// menu) or maybe fire off analytics
router.beforeEach((to, from, next) => {
  store.dispatch('navigate')
  next()
})

// Redirect if Authenticated
//
// If we detect that the user is authenticated (generally by successfully
// passing the profile request) and they are trying to revisit the authentication
// view, just redirect them to the dashboard view.
router.beforeEach((to, from, next) => {
  if (store.getters.auth.authenticated && (to.path === '/auth/login' || to.path === '/auth/register')) {
    if (store.getters.context.resolved) {
      next({ path: store.getters.context.homeRoute })
      return
    }
    next({ path: '/context' })
    return
  }
  next()
})

// Redirect if Not Authenticated
//
// If we detect that the user is authenticated (generally by successfully
// passing the profile request) or if the requested route doesn't require
// auth, let the user pass. Otherwise, redirect to the login view.
router.beforeEach((to, from, next) => {
  if (store.getters.auth.authenticated || to.matched.some(record => record.meta.skipAuth)) {
    next()
  } else {
    store.dispatch('redirectGuest', to.name)
    next(false)
  }
})

// Require Resets
//
// If we detect that the user is authenticated but requires a password reset
// redirect them to the 'change password'  page.
router.beforeEach((to, from, next) => {
  if (
    store.getters.auth.authenticated &&
    store.getters.auth.user.requires_reset &&
    to.path !== '/auth/change-password'
  ) {
    next({ name: 'auth.change' })
  }
  next()
})

// Context Check (Network)
//
// If the user is attempting to visit a network route, verify that the user
// has the correct role
//
//
router.beforeEach((to, from, next) => {
  next()
})

export default router
