<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body py-xl">
        <!-- Header -->
        <div class="mb-lg">
          <b-message type="is-info">
            <p class="is-size-5 has-text-weight-bold mb-sm">
              <span> {{ (position) ? 'Edit' : 'Add' }}</span>
              Budget
            </p>
            <p class="is-size-7">GatherWorks can help you track multiple budgets as part of a single project.
            To track a budget, simply assign a name and, if you have one, a budget estimate. You'll be able
            to change your estimates up until your project is activated.</p>
          </b-message>
        </div>
        <b-field
          label="Budget Name"
          :type="('title' in errors) ? 'is-danger' : ''"
          :message="('title' in errors) ? errors['title'][0] : ''"
          class="mb-lg">
          <b-input
            type="text"
            v-model="title"
            size="is-medium"
            :disabled="billing_model === 'expenses'"
            placeholder="Enter a descriptive title..." />
        </b-field>
        <b-field
          label="Budget Estimate"
          :type="('estimate' in errors) ? 'is-danger' : ''"
          :message="('estimate' in errors) ? errors['estimate'][0] : ''">
          <p class="control"><span class="button is-medium is-static">$</span></p>
          <b-input
            type="number"
            v-model="estimate"
            size="is-medium"
            placeholder="Enter a budget estimate..."
            expanded/>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save Budget</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(this.title)
    },
    mode () {
      return (this.position) ? 'edit' : 'create'
    },
    hasStarted () {
      return Boolean(this.position && this.position.started_at)
    },
    method () {
      return (this.position) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.position)
        ? this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id
        : this.api.route + '/projects/' + this.project.id + '/positions'
    },
    json () {
      const json = {
        role: this.title,
        estimate: this.estimate,
        billing_model: this.billing_model
      }
      return json
    }
  },
  data () {
    return {
      title: null,
      estimate: null,
      billing_model: 'time',
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.$emit('position:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.position !== null) {
      this.title = this.position.role
      this.billing_model = this.position.billing_model
      this.estimate = Number(this.position.estimate).toFixed(2)
    } else if (this.isExpenseBudget) {
      this.title = 'Expenses'
      this.billing_model = 'expenses'
    }
  },
  props: {
    project: {
      type: Object
    },
    position: {
      type: [Object, null]
    },
    isExpenseBudget: {
      type: Boolean,
      default: false
    }
  }
}
</script>
