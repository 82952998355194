<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-half">
        <router-link :to="{ name: 'partner.settings.general', params: { code: context.code } }">
          <div class="has-background-white has-shadow px-xl py-md">
            <p class="has-text-weight-bold">General</p>
            <p class="has-text-grey-light is-size-7">Manage your profile.</p>
          </div>
        </router-link>
      </div>
      <div class="column is-half">
        <router-link :to="{ name: 'partner.settings.billing', params: { code: context.code } }">
          <div class="has-background-white has-shadow px-xl py-md">
            <p class="has-text-weight-bold">Address</p>
            <p class="has-text-grey-light is-size-7">Set your billing address.</p>
          </div>
        </router-link>
      </div>
      <div class="column is-half">
        <router-link :to="{ name: 'partner.settings.people', params: { code: context.code } }">
          <div class="has-background-white has-shadow px-xl py-md">
            <p class="has-text-weight-bold">Team</p>
            <p class="has-text-grey-light is-size-7">Manage your team of contributors.</p>
          </div>
        </router-link>
      </div>
      <!-- <div class="column is-half">
        <router-link :to="{ name: 'partner.settings.integrations', params: { code: context.code } }">
          <div class="has-background-white rounded-lg px-xl py-md">
            <p class="has-text-weight-bold">Integrations</p>
            <p class="has-text-grey-light is-size-7">Manage integrations to GatherWorks.</p>
          </div>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  }
}
</script>
