<template>
  <div>
    <form @submit.prevent novalidate class='px-6'>
      <div class="mb-xl">
        <b-field
          label="Email"
          custom-class="has-text-primary"
          :type="('email' in errors) ? 'is-danger' : ''"
          :message="('email' in errors) ? errors['email'][0] : ''">
          <b-input
            placeholder="Email Address"
            size="is-large"
            type="email"
            v-model="email"
          ></b-input>
        </b-field>
      </div>
      <div>
        <button class="button is-block is-primary is-large is-fullwidth mb-6" @click="attempt" :disabled="processing">Reset</button>
        <div class="has-text-centered">
          <p class="mb-sm"><router-link :to="{ name: 'auth.login' }">Return to Sign In</router-link></p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['auth']),
    json () {
      return {
        email: this.email
      }
    }
  },
  data: function () {
    return {
      processing: false,
      email: null,
      errors: {}
    }
  },
  methods: {
    attempt () {
      this.errors = {}
      this.processing = true
      // get a csrf token from sanctum first, then try the login
      this.$http.get('/sanctum/csrf-cookie').then(() => {
        this.$http.post('/forgot-password', this.json).then(response => {
          this.$buefy.toast.open({ message: 'Check your email for a reset link!', type: 'is-success', duration: 5000 })
          localStorage.setItem('resetEmail', this.email)
        }).catch(error => {
          this.errors = error.response.data.errors
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Invalid email address.',
            type: 'is-danger'
          })
        }).finally(() => {
          this.processing = false
        })
      })
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
  }
}
</script>
