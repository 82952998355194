<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Funding</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading">
          <!-- Identifiers -->
          <div class="mb-md">
            <b-field
              label="Client Name"
              :type="('client' in errors) ? 'is-danger' : ''"
              :message="('client' in errors) ? errors['client'][0] : ''">
              <Autocomplete
                v-model="client"
                resource="clients"
                labelField="name"
                valueField="id"
                size="is-medium" />
            </b-field>
            <b-field
              label="Funding Type"
              :type="('funding_type' in errors) ? 'is-danger' : ''"
              :message="('funding_type' in errors) ? errors['name'][0] : ''" >
              <b-select
                size="is-medium"
                type="text"
                v-model="fundingType"
                expanded>
                <option v-for="type in fundingTypes" :key="type.id" :value="type">{{ type.name }} ({{ type.abbr}})</option>
              </b-select>
            </b-field>
            <b-field
              :label="fundingType.abbr + ' Name'"
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''">
              <b-input
                size="is-medium"
                type="text"
                v-model="name"
              ></b-input>
            </b-field>
            <b-field
              :label="fundingType.abbr + ' #'"
              :type="('client_identifier' in errors) ? 'is-danger' : ''"
              :message="('client_identifier' in errors) ? errors['client_identifier'][0] : ''">
              <b-input
                size="is-medium"
                type="text"
                v-model="clientIdentifier"
                placeholder="Optional"
              ></b-input>
            </b-field>
          </div>
          <!-- Staff Budget -->
          <div class="mb-md">
            <div class="mb-sm">
              <label class="label mb-0">Staff Budget</label>
              <p class="is-size-7 has-text-grey">Enter the budget that can be used for hourly or retainer contributions.</p>
            </div>
            <b-field
              :type="('staff_budget' in errors) ? 'is-danger' : ''"
              :message="('staff_budget' in errors) ? errors['staff_budget'][0] : ''">
              <p class="control"><span class="button is-static is-medium">$</span></p>
              <b-input
                size="is-medium"
                type="number"
                v-model="staffBudget"
                :min="0"
                expanded
              ></b-input>
            </b-field>
          </div>
          <!-- Project Feee=s -->
          <div class="mb-md">
            <div class="mb-sm">
              <label class="label mb-0">Project Fee Allowance</label>
              <p class="is-size-7 has-text-grey">Enter the allowance for billable project fees.</p>
            </div>
            <b-field
              :type="('project_fees' in errors) ? 'is-danger' : ''"
              :message="('project_fees' in errors) ? errors['project_budget'][0] : ''">
              <p class="control"><span class="button is-static is-medium">$</span></p>
              <b-input
                size="is-medium"
                type="number"
                v-model="projectExpenses"
                :min="0"
                expanded
              ></b-input>
            </b-field>
          </div>
          <!-- Reimbursable Expenses -->
          <div class="mb-md">
            <div class="mb-sm">
              <label class="label mb-0">Reimbursable Expense Allowance</label>
              <p class="is-size-7 has-text-grey">Enter the allowance for reimbursable project expenses.</p>
            </div>
            <b-field
              :type="('reimbursable_expenses' in errors) ? 'is-danger' : ''"
              :message="('reimbursable_expenses' in errors) ? errors['project_budget'][0] : ''">
              <p class="control"><span class="button is-static is-medium">$</span></p>
              <b-input
                size="is-medium"
                type="number"
                v-model="reimbursableExpenses"
                :min="0"
                expanded
              ></b-input>
            </b-field>
          </div>
          <!-- Total Value -->
          <div class="mb-md">
            <div class="mb-sm">
              <label class="label mb-0">Total Funding Value</label>
            </div>
            <div class="has-background-light pa-md">
              <Currency :value="totalFundingValue" zeroValue="dash" />
            </div>
          </div>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/Forms/Autocomplete'

export default {
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingFundingTypes)
    },
    ready () {
      return (
        this.fundingType &&
        this.name &&
        this.client &&
        this.staffBudget &&
        this.projectExpenses &&
        this.reimbursableExpenses
      )
    },
    json () {
      return {
        name: this.name,
        funding_type_id: this.fundingType.id,
        client_id: this.client.value,
        client_identifier: this.clientIdentifier,
        funding_lines: [{
          funding_line_type_code: 'staff_budget',
          amount: this.staffBudget
        }, {
          funding_line_type_code: 'project_fees',
          amount: this.projectExpenses
        }, {
          funding_line_type_code: 'reimbursable_expenses',
          amount: this.reimbursableExpenses
        }]
      }
    },
    minStartDate () {
      return this.$dates().subtract(2, 'month').startOf('month').format('YYYY-MM-DD')
    },
    minEndDate () {
      return (this.startDate) ? this.startDate : this.$dates().format('YYYY-MM-DD')
    },
    totalFundingValue () {
      let value = 0
      if (!isNaN(parseFloat(this.staffBudget))) {
        value += parseFloat(this.staffBudget)
      }
      if (!isNaN(parseFloat(this.projectExpenses))) {
        value += parseFloat(this.projectExpenses)
      }
      if (!isNaN(parseFloat(this.reimbursableExpenses))) {
        value += parseFloat(this.reimbursableExpenses)
      }
      return value
    }
  },
  data () {
    return {
      loadingFundingTypes: true,
      fundingTypes: [],
      fundingType: null,
      name: null,
      client: null,
      clientIdentifier: null,
      staffBudget: null,
      projectExpenses: null,
      reimbursableExpenses: null,
      startDate: null,
      endDate: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    loadFundingTypes () {
      this.loadingFundingTypes = true
      const endpoint = this.api.route + '/funding-types'
      this.$http.get(endpoint).then(response => {
        this.fundingTypes = response.data.data
        this.fundingType = response.data.data[0]
        this.loadingFundingTypes = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/funding'
      this.$http.post(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'New funding added!' })
        this.$emit('funding:created', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadFundingTypes()
    }, 500)
  }
}
</script>
