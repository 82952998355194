<template>
  <div>
    <div>
      <!-- Workflow -->
      <div class="has-background-white  pa-md mb-xxs">
        <Workflow :funding="funding"
          @action:activate="showActivateModal = true"
          @action:pause="showPauseModal = true"
          @action:resume="showResumeModal = true"
          @action:end="showEndModal = true"
          @action:approve="showApproveModal = true"
          @action:archive="showArchiveModal = true"
        />
      </div>
      <div class="has-background-white has-shadow pa-md mb-lg">
        <FundingProfile :funding="funding"
          @edit:meta="showEditMetaModal = true"
          @edit:dates="showEditDatesModal = true"
          @funding:saved="$emit('refresh')"
        />
      </div>
      <!-- Funding Lines -->
      <div class="has-background-white has-shadow pa-md mb-lg">
        <FundingLines :funding="funding" @funding:updated="$emit('refresh')"/>
      </div>
      <!-- Funding Distributions -->
      <div class="has-background-white has-shadow pa-md mb-lg">
        <FundingDistributions :funding="funding" @funding:updated="$emit('refresh')" />
      </div>
      <!-- Attachments -->
      <div class="has-background-white has-shadow pa-md mb-lg">
        <div class="has-border-b pb-sm">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">Attachments</p>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-small" outlined @click="showUploadAttachmentModal = true">Upload Attachment</b-button>
            </div>
          </div>
        </div>
        <Attachments :funding="funding" @attachment:removed="$emit('refresh')"/>
      </div>
      <!-- Linked Projects -->
      <div class="has-background-white has-shadow pa-md mb-xxs">
        <div class="has-border-b pb-sm">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">Linked Projects</p>
            </div>
          </div>
        </div>
        <Projects :funding="funding" />
      </div>
    </div>
    <!-- Modal: Edit Meta Data Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditMetaModal"
      :can-cancel="['x', 'esc']"
      ><EditMetaModal :funding="funding" @funding:updated="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Edit Dates Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showReviseBudgetModal"
      :can-cancel="['x', 'esc']"
      ><ReviseBudgetModal :funding="funding" @funding:updated="$emit('refresh')" />
    </b-modal>
    <!-- Modal: Create Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateProjectModal"
      :can-cancel="['x', 'esc']"
      ><CreateProjectModal :funding="funding" @po:updated="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Activate Funding -->
    <b-modal
      v-if="!funding.activated_at"
      has-modal-card
      scroll="keep"
      :active.sync="showActivateModal"
      :can-cancel="['x', 'esc']"
      ><ActivateModal :funding="funding" @funding:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Pause Funding -->
    <b-modal
      v-if="funding.activated_at"
      has-modal-card
      scroll="keep"
      :active.sync="showPauseModal"
      :can-cancel="['x', 'esc']"
      ><PauseModal :funding="funding" @funding:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Resume Funding -->
    <b-modal
      v-if="funding.paused_at"
      has-modal-card
      scroll="keep"
      :active.sync="showResumeModal"
      :can-cancel="['x', 'esc']"
      ><ResumeModal :funding="funding" @funding:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Approve PO -->
    <b-modal
      v-if="!funding.activated_at"
      has-modal-card
      scroll="keep"
      :active.sync="showApproveModal"
      :can-cancel="['x', 'esc']"
      ><ApproveModal :funding="funding" @funding:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: End Funding -->
    <b-modal
      v-if="funding.activated_at"
      has-modal-card
      scroll="keep"
      :active.sync="showEndModal"
      :can-cancel="['x', 'esc']"
      ><EndModal :funding="funding" @funding:saved="$emit('refresh')"  />
    </b-modal>
    <!-- Modal: Archive PO -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveModal :funding="funding" @funding:saved="redirect" />
    </b-modal>
    <!-- Modal: Add Attachment -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUploadAttachmentModal"
      :can-cancel="['x', 'esc']"
      ><UploadFundingAttachmentModal :funding="funding" @attachment:uploaded="$emit('refresh')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FundingProfile from '../components/Summary/FundingProfile'
import FundingLines from '../components/Summary/FundingLines'
import FundingDistributions from '../components/Summary/FundingDistributions'
import Projects from '../components/Summary/Projects'
import Workflow from '../components/Summary/Workflow'
import Attachments from '../components/Summary/Attachments'
import EditMetaModal from '@/components/Models/Funding/Modals/EditMetaModal'
import ReviseBudgetModal from '@/components/Models/Funding/Modals/ReviseBudget'
import ActivateModal from '@/components/Models/Funding/Modals/Activate'
import PauseModal from '@/components/Models/Funding/Modals/Pause'
import ResumeModal from '@/components/Models/Funding/Modals/Resume'
import ApproveModal from '@/components/Models/Funding/Modals/Approve'
import EndModal from '@/components/Models/Funding/Modals/End'
import ArchiveModal from '@/components/Models/Funding/Modals/ArchiveModal'
import CreateProjectModal from '@/components/Models/Project/Modals/CreateProject'
import UploadFundingAttachmentModal from '@/components/Models/Attachment/Modals/UploadFundingAttachment'

export default {
  components: {
    Attachments,
    CreateProjectModal,
    EditMetaModal,
    ReviseBudgetModal,
    ArchiveModal,
    ActivateModal,
    PauseModal,
    ResumeModal,
    EndModal,
    ApproveModal,
    Workflow,
    FundingProfile,
    FundingLines,
    Projects,
    FundingDistributions,
    UploadFundingAttachmentModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/projects/'
    },
    query () {
      return {
        params: {
          po: this.$route.params.id
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      projects: [],
      showCreateProjectModal: false,
      showEditMetaModal: false,
      showReviseBudgetModal: false,
      showActivateModal: false,
      showPauseModal: false,
      showResumeModal: false,
      showEndModal: false,
      showApproveModal: false,
      showArchiveModal: false,
      showUploadAttachmentModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.projects = response.data.data
        this.loading = false
      })
    },
    redirect () {
      this.$router.push({ name: 'network.fundings' })
    }
  },
  mounted () {

  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
