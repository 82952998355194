<template>
  <form @submit.prevent="update" novalidate>
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Security Credentials</p>
        <p class="has-text-grey is-size-7">Update the password to your {{ app.name }} account.</p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <div class="columns">
              <div class="column">
                <b-field label="Email" expanded>
                  <b-input type="text" v-model="email" disabled autocomplete="username"></b-input>
                </b-field>
                <b-field
                  label="Old Password"
                  :type="('old_password' in errors) ? 'is-danger' : ''"
                  :message="('old_password' in errors) ? errors['old_password'][0] : ''">
                  <b-input
                    autocomplete="current-password"
                    type="password"
                    name="oldPassword"
                    v-model="oldPassword"
                    @input="isDirty = 1"
                  ></b-input>
                </b-field>
                <b-field
                  label="New Password"
                  :type="('new_password' in errors) ? 'is-danger' : ''"
                  :message="('new_password' in errors) ? errors['new_password'][0] : ''">
                  <password
                    autocomplete="new-password"
                    v-model="newPassword"
                    defaultClass="input"
                    placeholder=""
                    :toggle="false"
                    :badge="false"
                    @score="setScore"
                  ></password>
                </b-field>
                <b-field
                  label="Confirm Password"
                  expanded>
                    <b-input
                      autocomplete="new-password"
                      v-model="confirmPassword"
                      type="password"
                      name="confirmPassword"
                      @input="isDirty = 1"
                    ></b-input>
                </b-field>
              </div>
              <div class="column">
                <div class="has-background-white-ter mt-md pa-md rounded-xl content is-size-7">
                  <p class="has-text-weight-bold">Tips for a strong password</p>
                  <ul>
                    <li class="mb-lg">
                      Longer passwords are better. Symbols and numbers help,
                      but the number of characters matters more.
                    </li>
                    <li class="mb-lg">
                      Use the meter to gauge your password strength. You must
                      score at least in the yellow to update your password (but
                      green is highly recommended).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
                  <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !ready">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    Password
  },
  computed: {
    ...mapGetters(['api', 'app', 'auth', 'context']),
    endpoint () {
      return 'v1/profile/credentials'
    },
    json () {
      return {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.confirmPassword
      }
    },
    ready () {
      return (!this.updating &&
        this.isDirty &&
        this.oldPassword !== null &&
        this.oldPassword !== '' &&
        this.newPassword !== null &&
        this.newPassword !== '' &&
        this.newPassword !== this.oldPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-grey',
        body: ''
      }
      if (this.isDirty) {
        if (this.oldPassword === null || this.oldPassword === '') {
          message.body = 'Please enter your old password.'
        } else if (this.newPassword === null || this.newPassword === '') {
          message.body = 'Please enter a new password.'
        } else if (this.newPassword !== this.confirmPassword) {
          message.body = 'Please make sure to confirm your new password.'
        } else if (this.newPassword === this.oldPassword) {
          message.body = 'Please make sure your new password is different than your old password.'
        } else if (this.passwordScore < 2) {
          message.body = 'Please strengthen your password until you see a green score.'
        }
      }
      return message
    }
  },
  data () {
    return {
      isDirty: false,
      password: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      email: null,
      error: null,
      errors: {},
      updating: false
    }
  },
  methods: {
    setScore (score) {
      this.isDirty = true
      this.passwordScore = score
    },
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Password updated.' })
        this.reset()
        this.updating = false
      }).catch(error => {
        this.updating = false
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
        } else {
          this.errors = { general: ['An unknown error occurred. Please contact support@gather.com'] }
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      })
    },
    reset () {
      this.newPassword = null
      this.oldPassword = null
      this.confirmPassword = null
      this.passwordScore = 0
      this.error = null
      this.errors = {}
    }
  },
  mounted () {
    this.email = this.user.email
  },
  props: ['user']
}
</script>
