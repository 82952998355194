<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- Header -->
        <div class="mb-lg">
          <b-message type="is-info">
            <p class="is-size-5 has-text-weight-bold mb-sm">Add Billable Expense</p>
          </b-message>
        </div>
        <!-- Billing Period -->
        <div class="mb-lg">
          <b-field
            label="Billing Period"
            :type="('billing_period_id' in errors) ? 'is-danger' : ''"
            :message="('billing_period_id' in errors) ? errors['billing_period_id'][0] : ''">
            <BillingPeriodSelect status="open" v-model="period" />
          </b-field>
        </div>
        <!-- Title -->
        <div class="mb-lg">
          <b-field
            label="Description"
            :type="('description' in errors) ? 'is-danger' : ''"
            :message="('description' in errors) ? errors['description'][0] : ''">
            <b-input v-model="description" size="is-medium" expanded />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'

export default {
  components: {
    BillingPeriodSelect
  },
  data () {
    return {
      period: null,
      description: null,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api']),
    method () {
      return 'post'
    },
    endpoint () {
      return this.api.route + '/projects/' + this.project.id + '/expenses'
    },
    json () {
      return {
        position_id: this.line.id,
        description: this.description,
        billing_period_id: this.period.id
      }
    },
    ready () {
      return Boolean(
        this.period && this.description
      )
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Expense saved!' })
        this.$emit('expense:saved')
        this.$parent.close()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    }
  },
  mounted () {
    if (this.edit) {
      this.title = this.edit.title
    } else if (this.source) {
      this.title = this.source.title
    }
  },
  watch: {

  }
}
</script>
