<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Invite Member</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading && roleTypes.length > 0">
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="First Name"
                :type="('first_name' in errors) ? 'is-danger' : ''"
                :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="firstName"
                    v-model="firstName"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Last Name"
                :type="('last_name' in errors) ? 'is-danger' : ''"
                :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                expanded>
                  <b-input
                    size="is-medium"
                    type="text"
                    name="lastName"
                    v-model="lastName"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
            </div>
          </div>
          <div class="mb-lg">
            <b-field
              label="Email"
              :type="('email' in errors) ? 'is-danger' : ''"
              :message="('email' in errors) ? errors['email'][0] : ''"
              expanded>
              <b-input
                size="is-medium"
                type="email"
                v-model="email"
              ></b-input>
            </b-field>
          </div>
          <b-field
            label="Role"
            :type="('role_id' in errors) ? 'is-danger' : ''"
            :message="('role_id' in errors) ? errors['role_id'][0] : ''"
            expanded>
            <b-select
              placeholder="Select a role"
              size="is-medium"
              expanded
              v-model="roleType">
              <option v-for="role in roleTypes" :key="role.id" :value="role" >{{ role.name }}</option>
            </b-select>
          </b-field>
          <!-- Permissions -->
          <div v-if="roleType">
            <p class="has-text-weight-bold mb-sm">Permissions</p>
            <div v-if="roleType.permissions.length > 0">
              <div v-for="permission in roleType.permissions" :key="permission">
                <div class="has-background-light py-sm px-md mb-xxs rounded-lg ">
                  {{ $utils.startCase(permission) }}
                </div>
              </div>
              <div class="has-background-light py-sm px-md mb-xxs rounded-lg ">
                All Standard View Permissions
              </div>
            </div>
            <div v-else>
              <div class="has-background-light py-sm px-md mb-xxs rounded-lg ">
                All Standard View Permissions
              </div>
            </div>
          </div>
        </div>
        <!-- Loading -->
        <div v-else>
          <Loading message="Loading roles..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (this.firstName && this.lastName && this.email && this.roleType)
    },
    endpoint () {
      return this.api.route + '/admin/roles'
    },
    json () {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        role_type_id: this.roleType.id
      }
    }
  },
  data () {
    return {
      loading: true,
      roleTypes: [],
      firstName: null,
      lastName: null,
      email: null,
      roleType: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.api.route + '/admin/role-types'
      this.$http.get(endpoint).then(response => {
        this.roleTypes = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Team member added!' })
        this.$parent.close()
        this.$emit('role:saved', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
