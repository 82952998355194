<template>
  <div>
    <!-- Header -->
    <div class="has-border-b pb-sm">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-5">Funding Line Items</p>
        </div>
      </div>
    </div>
    <!-- Datatable -->
    <div>
      <b-table
        :data="funding.lines"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="ID"
          header-class="is-uppercase is-size-8"
          width="48"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          label="Description"
          header-class="is-size-8"
          v-slot="props">
          <a href="#" @click.prevent="editLine(props.row)" class="has-text-weight-bold" >
            {{ props.row.description }}
          </a>
        </b-table-column>
        <b-table-column
          label="Available"
          header-class="is-size-8"
          centered
          width="128"
          v-slot="props">
          <Currency :value="props.row.amount" />
        </b-table-column>
        <b-table-column
          label="Distributed"
          header-class="is-size-8"
          centered
          width="128"
          v-slot="props">
          <Currency :value="props.row.amount_distributed" />
        </b-table-column>
        <b-table-column
          header-class="is-size-8"
          centered
          width="64"
          v-slot="props">
          <a v-if="funding.status === 'draft'" href="#" @click.prevent="editLine(props.row)" class="is-size-8">Edit</a>
          <span v-else class="has-text-grey-light is-size-8">Edit</span>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">No funding lines added yet.</div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Update Line -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUpdateLineModal"
      :can-cancel="['x', 'esc']"
      @close="resetModal"
      ><EditFundingLineModal :funding="funding" :fundingLine="focusLine" @funding:updated="$emit('funding:updated')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditFundingLineModal from '@/components/Models/Funding/Modals/EditLine'

export default {
  components: {
    EditFundingLineModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      focusLine: null,
      showUpdateLineModal: false
    }
  },
  methods: {
    editLine (line) {
      this.focusLine = line
      this.showUpdateLineModal = true
    },
    resetModal () {
      this.focusLine = null
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
