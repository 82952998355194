<template>
  <div>
    <div v-if="billingPeriods.length">
      <!-- Header -->
      <div class="mb-lg">
        <p class="is-size-5 has-text-weight-bold mb-sm">Monthly Activity</p>
        <b-select v-model="period" expanded placeholder="Select...">
          <option
            v-for="availablePeriod in billingPeriods"
            :key="availablePeriod.id"
            :value="availablePeriod">
            {{ availablePeriod.billing_period.label }}
          </option>
        </b-select>
      </div>
      <!-- Report -->
      <div v-if="!loading">
        <div v-if="period" >
          <div v-if="activeBudgets.length" >
            <table class="table is-fullwidth is-narrow is-size-7">
              <thead>
                <tr>
                  <th class="is-size-8">Resource</th>
                  <th class="is-size-8 has-text-centered" style="width:25%">Allocated</th>
                  <th class="is-size-8 has-text-centered" style="width:25%">Reported</th>
                </tr>
              </thead>
            </table>
            <div style="height:300px; width:100%; overflow:auto" class="pt-sm">
              <table v-for="budget in activeBudgets" :key="budget.id" class="table is-fullwidth is-really-narrow is-size-7 is-borderless is-paddingless ">
                <tr><th colspan="3" class="has-text-primary is-italic is-size-8">{{ budget.role }}</th></tr>
                <tbody>
                  <CurrentPeriodSeat v-for="seat in budget.seats" :key="'s-' + seat.id" :seat="seat" />
                  <CurrentPeriodDelivery v-for="delivery in budget.deliveries" :key="'d-' + delivery.id" :delivery="delivery" />
                </tbody>
              </table>
            </div>
          </div>
          <!-- No Budgets -->
          <div v-else class="has-text-centered pt-3xl has-text-grey-light">
            <p><b-icon icon="calendar" size="is-large" /></p>
            <p class=" has-text-centered">No activity for {{ period.billing_period.label }}.</p>
          </div>
        </div>
        <!-- No Period -->
        <div v-else class="has-text-centered pt-xxl has-text-grey-light">
          <p><b-icon icon="calendar" size="is-large" /></p>
          <p class=" has-text-centered">Select a billing period to view activity.</p>
        </div>
      </div>
      <!-- Loading State -->
      <div v-else>
        <p class="py-xl has-text-centered has-text-grey-light is-size-7">Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentPeriodSeat from './CurrentPeriodSeat'
import CurrentPeriodDelivery from './CurrentPeriodDelivery'

export default {
  components: {
    CurrentPeriodSeat,
    CurrentPeriodDelivery
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return Boolean(this.loadingReporting)
    },
    currentPeriodMonth () {
      return this.$dates().startOf('month').format('MMMM YYYY')
    },
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    },
    isLiveData () {
      return Boolean(this.period.billing_period.start_date === this.currentPeriod)
    },
    billingPeriods () {
      return this.project.periods
    },
    activeBudgets () {
      const budgets = []
      this.fulfillments.positions.forEach(budget => {
        if (budget.status === 'active' && (budget.seats.length || budget.deliveries.length)) {
          budgets.push(budget)
        }
      })
      return budgets
    }
  },
  data () {
    return {
      loadingReporting: true,
      fulfillments: null,
      period: null
    }
  },
  methods: {
    loadPeriodReporting (quiet = false) {
      if (!this.period) {
        this.loadingReporting = false
        return
      }
      if (!quiet) this.loadingReporting = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/fulfillments'
      const query = {
        params: {
          billing_period_id: this.period.billing_period.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.fulfillments = response.data
        this.loadingReporting = false
      })
    }
  },
  mounted () {
    const today = this.$dates().startOf('month').format('YYYY-MM-DD')
    this.project.periods.forEach(period => {
      if (period.billing_period.start_date === today) {
        this.period = period
      }
    })
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    period (period) {
      if (period) {
        this.loadingReporting = true
        setTimeout(() => {
          this.loadPeriodReporting()
        }, 500)
      }
    }
  }
}
</script>
