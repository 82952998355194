<template>
  <div>
    <div>
      <div class="columns is-vcentered">
        <div class="column">
          <div class="mb-md">
            <p class="has-text-weight-bold is-size-6">Sales Terms</p>
            <p class="has-text-grey is-size-7">
              Customize the Sales Term options that can be used for invoices
              and expenses.
            </p>
          </div>
        </div>
        <div class="column is-narrow">
          <b-button native-type="submit" type="is-primary" :disabled="saving" @click="showEditTermModal = true">New Terms</b-button>
        </div>
      </div>
      <div class="has-background-white has-shadow">
        <b-table
          :data="terms"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            field="id"
            label="ID"
            width="64"
            cell-class="has-text-grey-light"
            v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column
            field="name"
            label="Name"
            v-slot="props">
            <a href="#"
              class="has-text-weight-bold"
              @click.prevent="focus(props.row)">
              {{ props.row.name  }}
            </a>
          </b-table-column>
          <b-table-column
            field="id"
            label="Platform Links"
            width="96"
            centered
            v-slot="props">
            {{ props.row.identifiers_count }}
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Modal: Create/Update -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditTermModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><EditSalesTermModal :term="focusTerm" @term:saved="loadSalesTerms(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditSalesTermModal from '@/components/Models/SalesTerm/Modals/Edit'
export default {
  components: {
    EditSalesTermModal
  },
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loading: true,
      terms: [],
      focusTerm: null,
      showEditTermModal: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadSalesTerms (silent = false) {
      if (!silent) this.loading = true
      const endpoint = this.api.route + '/sales-terms'
      this.$http.get(endpoint).then(response => {
        this.terms = response.data.data
        this.loading = false
      })
    },
    focus (term) {
      this.focusTerm = term
      this.showEditTermModal = true
    },
    reset () {
      this.showEditTermModal = false
      this.focusTerm = null
    }
  },
  mounted () {
    this.loadSalesTerms()
  }
}
</script>
