import Index from './views/Index'
import Clients from './views/Clients'
import Client from './views/Client'
import ClientSummary from './views/ClientSummary'
import ClientContacts from './views/ClientContacts'
import ClientRateCard from './views/ClientRateCard'
import Organizations from './views/Organizations'
import Organization from './views/Organization'
import OrganizationSummary from './views/OrganizationSummary'

export default [{
  path: '/network/:code/accounts',
  component: Index,
  children: [{
    path: 'organizations',
    name: 'network.organizations',
    component: Organizations
  }, {
    path: '',
    name: 'network.clients',
    component: Clients
  }]
}, {
  path: '/network/:code/accounts/organizations/:id',
  component: Organization,
  children: [{
    path: '',
    name: 'network.organization',
    component: OrganizationSummary
  }]
}, {
  path: '/network/:code/accounts/clients/:id',
  component: Client,
  children: [{
    path: '',
    name: 'network.client',
    component: ClientSummary
  }, {
    path: 'contacts',
    name: 'network.client.contacts',
    component: ClientContacts
  }, {
    path: 'rates',
    name: 'network.client.rates',
    component: ClientRateCard
  }]
}]
