<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-half">
          <router-link :to="{ name: 'network.settings.profile', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">General</p>
              <p class="has-text-grey-light is-size-7">Manage your profile and billing settings</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.settings.roles', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">People</p>
              <p class="has-text-grey-light is-size-7">Manage the team that can access your account.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.settings.integrations', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Integrations</p>
              <p class="has-text-grey-light is-size-7">Manage connected services.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.settings.announcements', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Announcements</p>
              <p class="has-text-grey-light is-size-7">Broadcast announcements to the network.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.settings.finance', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Finance</p>
              <p class="has-text-grey-light is-size-7">Manage invoice & expense settings.</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {}
  }
}
</script>
