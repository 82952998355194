import Detail from './views/Detail'
import Summary from './views/Summary'
import Billables from './views/Billables'

export default [{
  path: '/network/:code/finance/client-invoices/:invoice',
  component: Detail,
  children: [{
    path: 'summary',
    name: 'network.client.invoice',
    component: Summary
  }, {
    path: 'billables',
    name: 'network.client.invoice.billables',
    component: Billables
  }]
}]
