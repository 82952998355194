<template>
  <div>
    <div v-if="!loadingRateHistory">
      <b-message type="is-info" class="is-size-7">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            The rates below are applied to time activites logged during a billing period.
          </div>
          <!-- <div class="column is-narrow">
            <b-button type="is-primary" outlined>Change Rates</b-button>
          </div> -->
        </div>
      </b-message>
      <table class="table is-fullwidth is-size-7 is-narrow">
        <thead>
          <tr>
            <th>Effective Date</th>
            <th class="has-text-centered">Cost Rate</th>
            <th class="has-text-centered">Bill Rate</th>
            <th class="has-text-centered">Margin</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rate, index) in seatWithRates.rates" :key="index">
            <td>{{ $dates(rate.start_date).format('MMM D, YYYY') }}</td>
            <td class="has-text-centered" style="width:128px">
              <Currency :value="rate.rate" />
            </td>
            <td class="has-text-centered" style="width:128px">
              <Currency :value="rate.rate" />
            </td>
            <td class="has-text-centered" style="width:128px">
              <Margin :revenue="rate.bill_rate" :cogs="rate.rate" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api'])
  },
  methods: {
    loadRateHistory () {
      this.loadingRateHistory = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/rates'
      this.$http.get(endpoint).then(response => {
        this.seatWithRates = response.data
        this.loadingRateHistory = false
      })
    }
  },
  data () {
    return {
      loadingRateHistory: true,
      seatWithRates: null
    }
  },
  mounted () {
    this.loadRateHistory()
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
