<template>
  <div>
    <div v-if="platform === null">
      <SelectPlatform @platform:selected="setPlatform" />
    </div>
    <div v-else>
      <component :is="platform.component"
        :platform="platform"
        @canceled="platform = null"
        @account:saved="refreshAccounts"
      />
    </div>
  </div>
</template>

<script>
import SelectPlatform from './SelectPlatform'
import Ariba from './Ariba'
import Freshbooks from './Freshbooks'
import Quickbooks from './Quickbooks'

export default {
  components: {
    SelectPlatform,
    Ariba,
    Freshbooks,
    Quickbooks
  },
  data () {
    return {
      platform: null
    }
  },
  methods: {
    close () {
      this.$parent.close()
    },
    refreshAccounts () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Integration credentials added!' })
      this.$parent.close()
      this.$emit('updated')
    },
    setPlatform (platform) {
      this.platform = platform
    }
  }
}
</script>
