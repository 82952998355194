<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (fundingLine) ? 'Update' : 'Add' }} Funding Line</p>
        </header>
        <section class="modal-card-body">
          <!-- Description -->
          <b-field
            label="Description"
            :type="('description' in errors) ? 'is-danger' : ''"
            :message="('description' in errors) ? errors['description'][0] : ''"
            class="mb-lg">
            <b-input
              v-model="description"
              type="text"
              size="is-medium"
              expanded />
          </b-field>
          <!-- Amount -->
          <b-field
            label="Amount"
            :type="('amount' in errors) ? 'is-danger' : ''"
            :message="('amount' in errors) ? errors['amount'][0] : ''"
            class="mb-lg">
            <b-input
              v-model="amount"
              type="number"
              size="is-medium"
              expanded />
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <div class="buttons">
            <b-button @click="$parent.close()">Close</b-button>
            <ConfirmButton
              v-if="fundingLine"
              type="is-danger"
              :outlined="true"
              @action:confirmed="remove"
              initialLabel="Delete?" />
          </div>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Update Funding Line
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmButton from '@/components/Forms/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(this.description)
    },
    method () {
      return (this.fundingLine) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.fundingLine)
        ? this.api.route + '/funding/' + this.$route.params.id + '/lines/' + this.fundingLine.id
        : this.api.route + '/funding/' + this.$route.params.id + '/lines/'
    },
    json () {
      if (!this.fundingLine) {
        return {
          funding_id: this.funding.id,
          description: this.description,
          amount: this.amount
        }
      }
      return {
        description: this.description,
        amount: this.amount
      }
    }
  },
  data () {
    return {
      description: null,
      amount: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Funding line saved!' })
        this.$parent.close()
        this.$emit('funding:updated')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    remove () {
      this.saving = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Funding line archived!' })
        this.$parent.close()
        this.$emit('funding:updated')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.fundingLine) {
      this.amount = Number(this.fundingLine.amount).toFixed(2)
      this.description = (this.fundingLine.description)
        ? this.fundingLine.description
        : this.fundingLine.funding_line_type.name
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    },
    fundingLine: {
      type: Object,
      default: null
    }
  }
}
</script>
