<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="has-text-centered mb-xl">
          Partner Onboarding Tasks
        </div>
        <div class="has-text-centered">
          <b-button
            type="is-primary"
            @click="completeOnboarding"
            outlined
            :disabled="saving"
          >Complete Onboarding</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.route
    },
    json () {
      return {
        is_onboarded: 1
      }
    }
  },
  data () {
    return {
      saving: false
    }
  },
  methods: {
    completeOnboarding () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('resolveRole')
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
