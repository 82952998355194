<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <div>
            <b-message type="is-info">
              <div>
                <p class="is-size-5 has-text-weight-bold mb-sm">
                  <span v-if="expenses.length === 1"> Approve Expense?</span>
                  <span v-else>Approve Expenses?</span>
                </p>
                <p class="is-size-7">
                  When you approve expenses, they will no longer be editable and will
                  will be summarized using the billing period specified below.
                </p>
              </div>
            </b-message>
            <b-select
              v-model="selectedPeriodId"
              placeholder="Select a Billing Period"
              size="is-medium"
              expanded>
              <option v-for="period in project.periods" :key="period.id" :value="period.billing_period.id">
                {{ $dates(period.billing_period.start_date).format('MMMM YYYY') }}
                {{ (period.billing_period.closed_at) ? '(Closed)' : '(Open)' }}
              </option>
            </b-select>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            <span v-if="expenses.length === 1">Approve Expense</span>
            <span v-else>Approve Expenses</span>
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      selectedPeriodId: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/expenses/approve'
      const json = {
        billing_period_id: this.selectedPeriodId,
        expenses: []
      }
      this.expenses.forEach(expense => {
        json.expenses.push({ id: expense.id })
      })
      this.$http.put(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Expenses updated!' })
        this.$parent.close()
        this.$emit('project:saved')
      })
    }
  },
  mounted () {
    this.selectedPeriodId = this.period.id
  },
  props: {
    project: {
      type: Object
    },
    expenses: {
      type: Array,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
