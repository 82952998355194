<template>
  <div>
    <!-- Has Attachments -->
    <table v-if="connection.attachments.length > 0" class="table is-narrow is-size-7 is-fullwidth is-striped">
      <tbody>
        <tr v-for="attachment in connection.attachments" :key="attachment.id">
          <td>
            {{ attachment.label }}
            <span class="has-text-grey-light">({{ attachment.filename }})</span>
          </td>
          <td class="has-text-right">
            <OptionsMenu :attachment="attachment" @attachment:removed="$emit('attachment:removed')" />
          </td>
        </tr>
      </tbody>
    </table>
    <!-- No Attachments -->
    <div v-else class="py-md">
      <p class="is-size-7 has-text-grey-light">No attachments have been uploaded yet.</p>
    </div>
  </div>
</template>

<script>
import OptionsMenu from '@/components/Models/ConnectionAttachment/DownloadLink'

export default {
  components: {
    OptionsMenu
  },
  props: ['connection']
}
</script>
