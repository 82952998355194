<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="modal-card-title">
          <div v-if="!loading && timesheet !== null" class="columns is-mobile">
            <div class="column">
              <strong>
                {{ timesheet.seat.contributor.user.full_name }}:
                {{ timesheet.billing_period.label }}
              </strong>
            </div>
            <div class="column is-narrow">
              <span class="has-text-grey-light">ID: {{ timesheet.id }}</span>
            </div>
          </div>
          <div v-else>Loading...</div>
        </div>
      </header>
      <section class="modal-card-body">
        <!-- Errors -->
        <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
          {{ errors.general[0] }}
        </b-message>
        <div v-if="!loading && timesheet !== null">
          <!-- Reported Hours -->
          <div class="mb-lg">
            <div class="pb-sm border-b">
              <div class="columns is-mobile">
                <div class="column">
                  <p class="has-text-primary has-text-weight-bold">Time Entry Status</p>
                </div>
                <div class="column is-narrow">
                  <b-button
                    v-if="!timesheet.submitted_at"
                    type="is-primary"
                    size="is-small"
                    outlined
                    :loading="saving"
                    :disabled="saving"
                    @click="submitHours">
                    Mark as Submitted
                  </b-button>
                  <b-button
                    v-else-if="!timesheet.approved_at"
                    type="is-primary"
                    size="is-small"
                    outlined
                    :loading="saving"
                    :disabled="saving"
                    @click="approveHours">
                    Approve &amp; Lock Timesheet
                  </b-button>
                  <b-button
                    v-else
                    type="is-primary"
                    size="is-small"
                    outlined
                    :loading="saving"
                    :disabled="saving"
                    @click="unapproveHours">
                    Unlock Timesheet
                  </b-button>
                </div>
              </div>
            </div>
            <!-- Summary -->
            <div class="mb-xl">
              <table class="table is-fullwidth is-size-7 is-narrow mb-sm">
                <tr>
                  <th>Client</th>
                  <td class="has-text-right">
                    {{ timesheet.seat.position.project.client.organization.name }}
                  </td>
                </tr>
                <tr>
                  <th>Project</th>
                  <td class="has-text-right">
                    {{ timesheet.seat.position.project.name }}
                  </td>
                </tr>
                <tr>
                  <th>Contributor</th>
                  <td class="has-text-right">
                    {{ timesheet.seat.contributor.user.full_name }} /
                    {{ timesheet.seat.position.role }}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="has-text-right"><StatusPresenter :timesheet="timesheet" /></td>
                </tr>
                <tr>
                  <th>Flag for Further Review</th>
                  <td class="has-text-right">
                    <b-checkbox v-model="timesheet.has_review_flag" @input="hasChanges = true" :true-value="1" :false-value="0"></b-checkbox>
                  </td>
                </tr>
                <tr>
                  <th>Review Notes</th>
                  <td class="has-text-right">
                    <div class="mb-lg">
                      <b-input type="textarea" size="is-small" v-model="timesheet.review_memo" @input="hasChanges = true" />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- Billable -->
          <div class="mb-lg">
            <div class="pb-sm border-b">
              <p class="has-text-primary has-text-weight-bold">Billable Hours</p>
              <p class="has-text-grey-light is-size-7 ">The values below reflect the hours reported by the partner.</p>
            </div>
            <table class="table is-fullwidth is-size-7 is-narrow ">
              <tr>
                <th>Billable Rate / Partner Rate</th>
                <td class="has-text-right">
                  <Currency :value="summary.billableTotal / summary.hoursBillable" />
                  /
                  <Currency :value="summary.costTotal / summary.hoursReported" />
                </td>
              </tr>
              <tr>
                <th>Billable Subtotal</th>
                <td class="has-text-right">
                  <Hours :hours="summary.hoursReported" /> Hours /
                  <Currency :value="summary.billableSubtotal" />
                </td>
              </tr>
              <tr>
                <th>Billable Adjustment</th>
                <td class="has-text-right">
                  <Hours :hours="summary.hoursAdjusted" /> Hours /
                  <Currency :value="summary.billableAdjusted" />
                </td>
              </tr>
              <tr>
                <th>Billable Hours</th>
                <td class="has-text-right"><Hours :hours="summary.hoursBillable" /> Hours</td>
              </tr>
              <tr>
                <th>Billable Amount</th>
                <td class="has-text-right"><Currency :value="summary.billableTotal" /></td>
              </tr>
              <tr>
                <th>Partner Cost</th>
                <td class="has-text-right"><Currency :value="summary.costTotal" /></td>
              </tr>
              <tr>
                <th>Margin</th>
                <td class="has-text-right">
                  <Currency :value="summary.billableTotal - summary.costTotal" />
                  (<Margin :cogs="summary.costTotal" :revenue="summary.billableTotal" />)
                </td>
              </tr>
            </table>
          </div>
          <!-- Weekly -->
          <div class="mb-lg">
            <div class="pb-sm border-b">
              <p class="has-text-primary has-text-weight-bold">Weekly Summary</p>
            </div>
            <table class="table is-fullwidth is-size-7 is-narrow mb-sm">
              <tr>
                <th>Week Ending</th>
                <th class="has-text-centered" v-for="week in weeklyTotals" :key="week.label">
                  {{ week.label }}
                  <span class="has-text-weight-normal has-text-grey-light">({{ week.days_business }})</span>
                </th>
              </tr>
              <tr>
                <td>Total Hours</td>
                <td class="has-text-centered" v-for="week in weeklyTotals" :key="week.label">
                  <Hours :hours="week.hours" />
                </td>
              </tr>
              <tr>
                <td>Daily Average</td>
                <td class="has-text-centered" v-for="week in weeklyTotals" :key="week.label">
                  <Hours :hours="week.average" />
                </td>
              </tr>
            </table>
          </div>
          <!-- Activity -->
          <div class="mb-lg">
            <div class="pb-sm border-b">
              <p class="has-text-primary has-text-weight-bold">Activity Detail</p>
              <p class="has-text-grey-light is-size-7 ">The values below reflect the hours reported by the partner for each date.</p>
            </div>
            <table class="table is-narrow is-size-7 is-fullwidth is-vcentered">
              <tr>
                <th style="width:48px"></th>
                <th style="width:128px" class="has-text-left">Date</th>
                <th class="has-text-centered">Rates</th>
                <th style="width:96px" class="has-text-centered">Hours</th>
                <th style="width:96px" class="has-text-centered">Adjustment</th>
                <th style="width:96px" class="has-text-centered">Billable Hrs</th>
              </tr>
              <tr v-for="activity in timesheet.activities" :key="activity.id">
                <td class="has-text-centered">
                  <b-icon v-if="activity.is_unavailable" icon="close" custom-class="has-text-danger" />
                  <b-icon v-else-if="activity.hours" icon="check" custom-class="has-text-success" />
                  <b-icon v-else icon="check" custom-class="has-text-grey-lighter" />
                </td>
                <td>
                  <p class="has-text-weight-bold">{{ $dates(activity.date).format('MMM D') }}</p>
                  <p class="has-text-grey is-size-8">
                    {{ $dates(activity.date).format('ddd') }}
                    &bull;
                    Week #{{ activity.week.week_in_month + 1 }}
                  </p>
                </td>
                <td class="has-text-centered">
                  <Currency :value="activity.client_rate" /> / <Currency :value="activity.partner_rate" />
                </td>
                <!-- <td class="has-text-grey-light">
                  <span v-if="activity.notes">
                    {{ activity.notes.substring(0,127) }}
                  </span>
                </td> -->
                <td class="has-text-centered"><Hours :hours="activity.hours_reported" /></td>
                <td class="has-text-centered"><Hours :hours="activity.hours_billable - activity.hours_reported" zeroValue="dash" /></td>
                <td class="has-text-centered">
                  <b-input
                    v-if="!readonly"
                    type="number"
                    v-model="activity.hours_billable"
                    step="0.25"
                    @input="hasChanges = true"
                    expanded
                  ></b-input>
                  <Hours v-else :hours="activity.hours_billable" />
                </td>
              </tr>
            </table>
          </div>
          <!-- Monthly Note -->
          <div class="mb-lg">
            <div class="pb-sm">
              <p class="has-text-primary has-text-weight-bold">Billing Period Notes</p>
              <p class="has-text-grey-light is-size-7 ">The notes below were added by {{ timesheet.seat.contributor.user.full_name }}.</p>
            </div>
            <b-input type="textarea" size="is-small" readonly :value="timesheet.notes" />
          </div>
          <!-- Daily Notes -->
          <div v-if="dailyNotes.length" class="mb-lg">
            <table class="table is-narrow is-size-7 is-fullwidth is-vcentered">
              <tr>
                <th style="width:96px" class="has-text-left">Date</th>
                <th>Note</th>
              </tr>
              <tr v-for="note in dailyNotes" :key="note.id">
                <td>{{ $dates(note.date).format('MMM D') }}</td>
                <td>{{ note.notes }}</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- Loading -->
        <div v-else class="py-xxl">
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <!-- Readonly (close only) -->
        <template v-if="readonly">
            <b-button @click="$parent.close()">Close</b-button>
        </template>
        <!-- Adjustable (save, etc) -->
        <template v-else>
          <div class="buttons">
            <b-button @click="$parent.close()">Close</b-button>
            <b-button
              type="is-primary"
              :loading="saving"
              :disabled="saving || !hasChanges"
              outlined
              @click="save(false)"
              >Save & Reload</b-button>
          </div>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving || !hasChanges"
          >Save &amp; Close</b-button>
        </template>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Timesheet/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return this.loadingTimesheet
    },
    readonly () {
      if (!this.timesheet) return true
      if (this.timesheet.approved_at) return true
      return false
    },
    weeklyTotals () {
      const weeks = []
      this.timesheet.billing_period.weeks.forEach(week => {
        const presenter = {
          label: this.$dates(week.end_date).format('M/D'),
          days_business: parseInt(week.days_business),
          days_total: parseInt(week.days_total),
          hours: 0,
          average: 0
        }
        this.timesheet.activities.forEach(activity => {
          if (activity.hours_billable && activity.week_id === week.id) {
            presenter.hours += parseFloat(activity.hours_billable)
          }
        })
        if (presenter.days_business) {
          presenter.average = Number(presenter.hours) / Number(presenter.days_business)
        }
        weeks.push(presenter)
      })
      return weeks
    },
    summary () {
      if (!this.timesheet) return null
      const summary = {
        hoursReported: 0,
        hoursAdjusted: 0,
        hoursBillable: 0,
        billableSubtotal: 0,
        billableTotal: 0,
        billableAdjusted: 0,
        costTotal: 0
      }
      this.timesheet.activities.forEach(activity => {
        // hours
        summary.hoursReported += Number(activity.hours_reported)
        summary.hoursAdjusted += Number(activity.hours_adjustment)
        summary.hoursBillable += Number(activity.hours_billable)
        // value
        summary.billableSubtotal += Number(activity.hours_reported) * Number(activity.client_rate)
        summary.billableAdjusted += Number(activity.hours_adjustment) * Number(activity.client_rate)
        summary.billableTotal += Number(activity.hours_billable) * Number(activity.client_rate)
        summary.costTotal += Number(activity.hours_reported) * Number(activity.partner_rate)
      })
      return summary
    },
    profit () {
      return Number(this.summary.billableTotal) - Number(this.summary.costTotal)
    },
    dailyNotes () {
      if (!this.timesheet) return []
      const notes = []
      this.timesheet.activities.forEach(activity => {
        if (activity.notes) {
          notes.push(activity)
        }
      })
      return notes
    }
  },
  data () {
    return {
      loadingTimesheet: false,
      timesheet: null,
      adjustments: {},
      hasChanges: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadDetails (quiet = false) {
      if (!quiet) this.loadingTimesheet = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheetId
      this.$http.get(endpoint).then(response => {
        this.timesheet = response.data
        this.timesheet.activities.forEach(activity => {
          activity.hours_billable = Number(activity.hours_billable).toFixed(2)
        })
        this.loadingTimesheet = false
      })
    },
    submitHours () {
      this.saving = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheet.id + '/actions/submit'
      this.$http.put(endpoint).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet updated!' })
        this.loadDetails(true)
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    },
    approveHours () {
      this.saving = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheet.id + '/actions/approve'
      this.$http.put(endpoint).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet updated!' })
        this.loadDetails(true)
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    },
    unapproveHours () {
      this.saving = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheet.id + '/actions/unapprove'
      this.$http.put(endpoint).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet unlocked!' })
        this.loadDetails(true)
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    },
    addReviewFlag () {
      this.saving = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheet.id + '/actions/flag'
      this.$http.put(endpoint).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet updated!' })
        this.loadDetails(true)
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    },
    removeReviewFlag () {
      this.saving = true
      const endpoint = this.api.route + '/admin/timesheets/' + this.timesheet.id + '/actions/unflag'
      this.$http.put(endpoint).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Timesheet updated!' })
        this.loadDetails(true)
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    },
    save (closeAfterSave = true) {
      if (this.timesheet.closed_at) {
        this.$parent.close()
      }
      this.errors = {}
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.timesheet.seat.position.project_id + '/timesheets/' + this.timesheet.id + '/adjust'
      const json = {
        has_review_flag: this.timesheet.has_review_flag,
        review_memo: this.timesheet.review_memo,
        adjustments: []
      }
      this.timesheet.activities.forEach(activity => {
        if (activity.hours_reported === activity.hours_billable) return
        const adjustment = {
          date: activity.date,
          adjusted_hours: activity.hours_billable
        }
        json.adjustments.push(adjustment)
      })
      this.$http.put(endpoint, json).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Billable Hours Saved!' })
        if (closeAfterSave) {
          this.$parent.close()
        } else {
          this.loadDetails(true)
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadDetails()
  },
  props: {
    timesheetId: {
      type: Number,
      required: true
    }
  }
}
</script>
