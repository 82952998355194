<template>
  <div>
    <!-- Summary -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <p class="has-text-weight-bold has-text-primary is-size-5 mb-sm">August 2022</p>
      <div>
        <table class="table is-narrow is-size-7 is-fullwidth is-striped">
          <tbody>
            <tr>
              <td>GatherWorks ID</td>
              <td class="has-text-right">100</td>
            </tr>
            <tr>
              <td>Opening Balance </td>
              <td class="has-text-right">
                <Currency :value="50000" />
              </td>
            </tr>
            <tr>
              <td>Closing Balance </td>
              <td class="has-text-right">
                <Currency :value="45000" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Activity -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <div class="mb-sm">
        <p class="has-text-weight-bold has-text-primary is-size-5 mb-sm">Activity</p>
      </div>
      <div>
        <b-table
          :data="activities"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="96"
            cell-class="has-text-grey-light"
            v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column
            label="Transaction"
            v-slot="props">
            {{ props.row.label }}
          </b-table-column>
          <b-table-column
            label="Amount"
            width="256"
            v-slot="props"
            numeric>
            <Currency :value="props.row.amount" />
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activities: [{
        id: 100,
        label: 'Prebill Payment',
        amount: 50000
      }, {
        id: 101,
        label: 'Client Invoice #343 - August Delivery',
        amount: 5000
      }]
    }
  }
}
</script>
