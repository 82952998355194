<template>
  <div>
    <!-- Summary Panel -->
    <div v-if="!loading && budget">
      <!-- Budget Summary -->
      <div class="has-background-white pa-md has-shadow mb-lg ">
        <div class="columns">
          <div class="column">
            <div class="">
              <div class="columns">
                <!-- Funding Allocation -->
                <div class="column">
                  <Statistic label="Funding Allocation" type="currency" :value="budget.amount_funded" :mantissa="0" />
                </div>
                <!-- Current Budget -->
                <div class="column">
                  <Statistic label="Expense Budget" type="currency" :value="budget.amount_budgeted" :mantissa="0" />
                </div>
                <!-- Pending Expenses -->
                <div class="column">
                  <Statistic label="Pending Expenses" type="currency" :value="budget.amount_expenses_pending" :mantissa="2" />
                </div>
                <!-- Approved Expenses -->
                <div class="column">
                  <Statistic label="Billable Expenses" type="currency" :value="budget.amount_expenses_billable" :mantissa="2" />
                </div>
                <!-- Target -->
                <div class="column">
                  <Statistic label="Non-Billable Expenses" type="currency" :value="budget.amount_expenses_nonbillable" :mantissa="2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- List View -->
    <div v-if="!loading && budget">
      <div class="has-background-white pa-md has-shadow mb-xxs">
        <div class="columns space-between is-vcentered">
          <div class="column">
            <div>
              <b-select
                v-model="period"
                placeholder="Select a Billing Period"
                size="is-medium"
                expanded>
                <option v-for="period in project.periods" :key="period.id" :value="period">
                  {{ $dates(period.billing_period.start_date).format('MMMM YYYY') }}
                  {{ (period.billing_period.closed_at) ? '(Closed)' : '(Open)' }}
                </option>
              </b-select>
            </div>
          </div>
          <div class="column is-narrow">
            <b-button
              @click.prevent="addExpense"
              type="is-primary"
              size="is-medium"
              outlined
              >New Expense</b-button>
          </div>
          <div class="column is-narrow">
            <b-dropdown position="is-bottom-left">
              <template #trigger>
                <b-button size="is-medium" outlined><b-icon icon="dots-vertical" /></b-button>
              </template>
              <b-dropdown-item @click="showEditBudgetModal = true">Manage Expense Budget</b-dropdown-item>
          </b-dropdown>
          </div>
        </div>
      </div>
      <div class="mb-md">
        <b-table
          :data="expenses"
          :mobile-cards="false"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="48"
            centered
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            label="Date"
            width="108"
            v-slot="props">
            <router-link
              class="has-text-weight-bold"
              :to="{ name: 'network.project.expense', params: { expense: props.row.id }  }">
              {{ $dates(props.row.expense_date).format('MMM D, YYYY') }}
            </router-link>
          </b-table-column>
          <b-table-column
            label="Partner"
            v-slot="props">
            <div v-if="props.row.partner">
              <p>{{ props.row.partner.name }}</p>
              <p class="has-text-grey-light is-size-8">{{ props.row.partner.billing_contact }}</p>
            </div>
            <div v-else>
              <span class="has-text-grey-light">--</span>
            </div>
          </b-table-column>
          <b-table-column
            label="Vendor"
            v-slot="props">
            <p>{{ props.row.vendor }}</p>
          </b-table-column>
          <!-- <b-table-column
            label="Billable?"
            centered
            width="64"
            v-slot="props">
            <span v-if="props.row.is_billable">Yes</span>
            <span v-else>No</span>
          </b-table-column> -->
          <b-table-column
            label="Cost ($)"
            centered
            width="96"
            v-slot="props">
            <Currency :value="props.row.cost"  />
          </b-table-column>
          <b-table-column
            label="Billable ($)"
            centered
            width="96"
            v-slot="props">
            <Currency :value="props.row.billable_total"  />
          </b-table-column>
          <b-table-column
            label="Status"
            width="96"
            centered
            v-slot="props">
            <StatusPresenter :expense="props.row"  />
          </b-table-column>
          <b-table-column
            label="Exported?"
            width="64"
            centered
            v-slot="props">
            <b-icon v-if="props.row.exported_at" icon="check"  type="is-success"  />
            <span v-else class="has-text-grey-light">--</span>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered pa-lg">No expenses found.</div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- No Budget -->
    <div v-else-if="!loading && !budget">
      <div class="has-background-white py-xl has-shadow mb-lg has-text-centered">
        <p class="mb-md">This project doesn't have an expense budget yet.</p>
        <b-button type="is-primary" @click="showEditBudgetModal = true">Add Budget</b-button>
      </div>
    </div>
    <!-- Modal: Create/Edit Expense Budget -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditBudgetModal"
      :can-cancel="['x', 'esc']"
      ><EditLineModal :project="project" :position="budget" :isExpenseBudget="true" @position:saved="loadExpenseBudget" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Statistic from '@/components/Global/Statistic'
import EditLineModal from '@/components/Models/Position/Modals/Edit'
import StatusPresenter from '@/components/Models/Expense/Presenters/Status'

export default {
  components: {
    EditLineModal,
    StatusPresenter,
    Statistic
  },
  computed: {
    ...mapGetters(['api', 'context']),
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    },
    loading () {
      return Boolean(this.loadingBudget || this.loadingExpenses)
    }
  },
  data () {
    return {
      loadingBudget: true,
      loadingExpenses: true,
      budget: null,
      periods: [],
      period: null,
      expenses: [],
      showEditBudgetModal: false,
      selected: []
    }
  },
  methods: {
    loadExpenseBudget () {
      this.loadingBudget = true
      const endpoint = this.api.route + '/projects/' + this.$route.params.id + '/expenses/budget'
      this.$http.get(endpoint).then(response => {
        if (response.data) {
          this.budget = response.data
        }
        this.loadingBudget = false
      })
    },
    loadExpenses (refresh = false) {
      if (!refresh) this.loadingExpenses = true
      const endpoint = this.api.route + '/projects/' + this.$route.params.id + '/expenses'
      const params = {
        params: {
          'filter[billing_period_id]': (this.period) ? this.period.billing_period_id : null
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.expenses = response.data
        this.loadingExpenses = false
      })
    },
    addExpense () {
      this.$router.push({ name: 'network.project.expense' })
    }
  },
  mounted () {
    this.loadExpenseBudget()
    this.loadExpenses()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    period (value) {
      if (value) {
        this.loadExpenses()
      }
    }
  }
}
</script>
