<template>
  <div>
    <div class="has-background-white has-shadow pa-md">
      <p class="has-text-weight-bold is-size-6 has-text-centered">Delivery</p>
      <table class="table is-fullwidth is-really-narrow is-size-8 is-paddingless is-striped">
        <tbody>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Delivered</th>
            <td class="has-text-right">
              <Currency :value="project.amount_fulfilled" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Cost</th>
            <td class="has-text-right">
              <Currency :value="project.amount_fulfilled_cost" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Remaining</th>
            <td class="has-text-right">
              <Currency :value="planOnlyBudget - Number(project.amount_fulfilled)" :mantissa="0" />
            </td>
          </tr>
          <tr>
            <th class="has-text-grey has-text-weight-normal">Margin</th>
            <td class="has-text-right">
              <Margin :revenue="project.amount_fulfilled" :cogs="project.amount_fulfilled_cost" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    planOnlyBudget () {
      let total = 0
      this.project.positions.forEach(budget => {
        if (budget.billing_model === 'time') {
          total += Number(budget.amount_budgeted)
        }
      })
      return total
    },
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
