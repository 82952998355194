<template>
  <div class="is-fullheight">
    <div class="columns is-mobile">
      <div class="column">
        <p class="has-text-weight-bold is-size-5">Project Health</p>
      </div>
      <div class="column">
        <div class="has-text-right">
          <a href="#" @click.prevent="toggleView" class="has-text-grey is-size-7 is-underlined">
            <span v-if="view === 'chart'">View as Table</span>
            <span v-else>View as Chart</span>
          </a>
        </div>
      </div>
    </div>
    <!-- Has Project Billing Periods -->
    <div v-if="project.periods.length">
      <!-- Chart View -->
      <div v-if="view === 'chart'">
        <BurnLineChart :project="project" />
      </div>
      <!-- Table View -->
      <div v-if="view === 'table'">
        <div>
          <b-table
            :data="project.periods"
            :mobile-cards="false"
            hoverable
            narrowed
            class="is-size-7">
            <b-table-column
              header-class="is-size-8"
              cell-class="nowrap has-text-weight-bold has-text-grey">
              <template v-slot:header>
                <p><span class="is-hidden-mobile">Billing</span> Period</p>
              </template>
              <template v-slot="props">
                {{ $dates(props.row.billing_period.start_date).format('MMM YYYY') }}
                (<FiscalQuarter :period="props.row.billing_period" :sequence="props.row.fiscal_quarter_sequence" />)
              </template>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Allocated"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_allocated" :mantissa="0" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Pending"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_pending" :mantissa="0" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Delivered"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_fulfilled" :mantissa="0" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="+/-"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="Number(props.row.amount_allocated) - Number(props.row.amount_fulfilled)" :mantissa="0" />
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Margin"
              width="72"
              header-class="is-size-8"
              centered>
              <Margin :revenue="props.row.amount_fulfilled" :cogs="props.row.amount_fulfilled_cost" :mantissa="0" />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
    <!-- No Periods Yet -->
    <div v-else>
      <div class="py-xxl has-text-centered">
        <p class="has-text-grey is-size-7 px-lg mb-lg">
          This project has not been configured to track billing periods.
          Please click the button below to track this project by month.
        </p>
        <b-button type="is-primary" @click="migrateProject" :loading="migrating">Setup Project</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BurnLineChart from './BurnLineChart'
import FiscalQuarter from '@/components/Models/BillingPeriod/Presenters/FiscalQuarter'

export default {
  components: {
    BurnLineChart,
    FiscalQuarter
  },
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      view: 'table',
      migrating: false
    }
  },
  methods: {
    toggleView () {
      this.view = (this.view === 'chart') ? 'table' : 'chart'
    },
    migrateProject () {
      this.migrating = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/summarize/all'
      this.$http.put(endpoint).then(response => {
        this.$emit('project:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Project setup!' })
      }).finally(() => {
        this.migrating = false
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
