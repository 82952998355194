<template>
  <div>
    <div class="section">
      <div class="container">
        <div v-if="!loading && organization !== null">
          <!-- Header -->
          <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="is-size-5 has-text-weight-bold">{{ organization.name }}</p>
                <p class="has-text-grey is-size-7 has-text-grey-light">ID: {{ organization.id }}</p>
              </div>
            </div>
          </div>
          <!-- Actions -->
          <div class="has-background-white rounded-lg px-lg py-md mb-lg">
            <div class="columns">
              <div class="column is-one-quarter">
                <b-button type="is-light" size="is-medium" expanded @click="showEditModal = true">Edit</b-button>
              </div>
              <!-- <div class="column is-one-quarter">
                <b-button type="is-light" size="is-medium" expanded @click="showLogoModal = true">Upload Logo</b-button>
              </div> -->
            </div>
          </div>
          <!-- Profile -->
          <div class="has-background-white rounded-lg px-lg py-md mb-lg">
            <div class="mb-lg">
              <div class="columns is-multiline">
                <div class="column is-half">
                  <h1 class="has-text-weight-bold has-border-b pb-sm">Profile</h1>
                  <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                    <tbody>
                      <tr>
                        <td>ID</td>
                        <td class="has-text-right">{{ organization.id }}</td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td class="has-text-right">{{ organization.name }}</td>
                      </tr>
                      <tr>
                        <td>Owner</td>
                        <td class="has-text-right">
                          <p v-if="organization.owner">{{ organization.owner.full_name }}</p>
                          <p v-else class="has-text-grey-light">- Not Set -</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-half">
                  <h1 class="has-text-weight-bold has-border-b pb-sm">Settings</h1>
                  <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                    <tbody>
                      <tr>
                        <td>Fiscal Year Start</td>
                        <td class="has-text-right">{{ $dates().month(organization.fiscal_start_month - 1).format('MMMM') }}</td>
                      </tr>
                      <tr>
                        <td>Pre-approves Expenses?</td>
                        <td class="has-text-right">{{ (organization.preapproves_expenses) ? 'Yes' : 'No' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="column is-half">
                  <h1 class="has-text-weight-bold has-border-b pb-sm">Assets</h1>
                  <table class="table is-narrow is-size-7 is-fullwidth is-striped is-vcentered">
                    <tbody>
                      <tr>
                        <td>Logo</td>
                        <td class="has-text-right">
                          <div v-if="organization.logo_path">
                            <cld-image :publicId="organization.logo_path" width="120" dpr="2.0" crop="scale" />
                          </div>
                          <span v-else>No Logo</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          Loading...
        </div>
      </div>
    </div>
    <!-- Modal: Edit Form -->
    <b-modal
      v-if="organization !== null"
      has-modal-card
      scroll="keep"
      :active.sync="showEditModal"
      :can-cancel="[]"
      ><EditModal :seed="organization" @organization:saved="load" />
    </b-modal>
    <!-- Modal: Logo -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showLogoModal"
      :can-cancel="[]"
      ><UploadLogoModal/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadLogoModal from '../components/Organization/UploadLogoModal'
import EditModal from '@/components/Models/Organization/CreateOrganizationModal'

export default {
  components: {
    UploadLogoModal,
    EditModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Organizations', name: 'network.organizations', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/organizations/' + this.$route.params.id
    }
  },
  data: function () {
    return {
      loading: true,
      organization: null,
      showLogoModal: false,
      showEditModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.organization = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
