<template>
  <div class="py-xxl">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-two-thirds-tablet is-half-widescreen">
          <div class="box has-shadow mx-md">
            <div class="has-text-centered mt-lg">
              <p class="has-text-weight-bold">Welcome to</p>
              <Logo :height="67"  />
            </div>
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logos/GatherWorks'

export default {
  components: {
    Logo
  },
  data: function () {
    return {}
  },
  methods: {
    hardReload () {
      window.location.reload(true)
    }
  }
}
</script>
