import Index from './views/Index'
import Announcements from './views/Announcements'
import Announcement from './views/Announcement'
import Finance from './views/Finance'
import Integrations from './views/Integrations'
import Roles from './views/Roles'
import Role from './views/Role'
import Profile from './views/Profile'
import Settings from './views/Settings'

export default [{
  path: '/network/:code/settings',
  component: Index,
  children: [{
    path: 'finance',
    name: 'network.settings.finance',
    component: Finance
  }, {
    path: 'integrations',
    name: 'network.settings.integrations',
    component: Integrations
  }, {
    path: 'people',
    name: 'network.settings.roles',
    component: Roles
  }, {
    path: 'announcements',
    name: 'network.settings.announcements',
    component: Announcements
  }, {
    path: 'announcements/:announcement?/edit',
    name: 'network.settings.announcement',
    component: Announcement
  }, {
    path: 'people/:id',
    name: 'network.settings.role',
    component: Role
  }, {
    path: 'profile',
    name: 'network.settings.profile',
    component: Profile
  }, {
    path: '',
    name: 'network.settings',
    component: Settings
  }]
}]
