<template>
  <b-tag :type="presenter.type">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      // active
      if (this.connection.status === 'active') {
        presenter.type = 'is-success'
        presenter.label = 'Active'
      // onboarding
      } else if (this.connection.status === 'onboarding') {
        presenter.type = 'is-info is-light'
        presenter.label = 'Onboarding'
      // inactive
      } else if (this.connection.status === 'inactive') {
        presenter.type = 'is-danger'
        presenter.label = 'Inactive'
      } else {
        presenter.type = 'is-grey-light'
        presenter.label = 'Pending'
      }
      return presenter
    }
  },
  props: ['connection']
}
</script>
