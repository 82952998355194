<template>
  <div>
    <!-- Summary -->
    <div v-if="project.positions.length" class="mb-lg">
      <div class="columns is-multiline">
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <AllocationSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <UtilizationSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <BillingSummary :project="project" />
          </div>
        </div>
        <div class="column is-half is-one-quarter-widescreen">
          <div class="has-background-white">
            <ExpenseSummary :project="project" />
          </div>
        </div>
      </div>
    </div>
    <!-- Progress -->
    <div v-if="!loading" class="has-background-white has-shadow py-md px-xl mb-xxs">
      <div class="mb-sm">
        <p class="has-text-weight-bold has-text-centered">Project Progress</p>
      </div>
      <div class="mb-sm">
        <b-progress size="is-medium" type="is-light">
          <template #bar>
            <b-progress-bar :value="progress.fulfilled_pct" type="is-primary"></b-progress-bar>
            <b-progress-bar :value="progress.pending_pct" type="is-info"></b-progress-bar>
            <b-progress-bar :value="progress.remaining_pct" type="is-light"></b-progress-bar>
          </template>
        </b-progress>
      </div>
      <div class="columns is-mobile is-centered is-size-7">
        <!-- <div class="column">
          <p class="has-text-centered">
            <span class="has-text-black has-text-weight-bold mr-sm">Funded</span>
            <span><Currency :value="progress.funded" /></span>
          </p>
        </div> -->
        <div class="column">
          <p class="has-text-centered">
            <span class="has-text-black has-text-weight-bold mr-sm">Budgeted</span>
            <span><Currency :value="progress.budgeted" /></span>
          </p>
        </div>
        <div class="column">
          <p class="has-text-centered">
            <span class="has-text-primary has-text-weight-bold mr-sm">Delivered</span>
            <span><Currency :value="progress.fulfilled" /></span>
          </p>
        </div>
        <div class="column">
          <p class="has-text-centered">
            <span class="has-text-info has-text-weight-bold mr-sm">Pending</span>
            <span><Currency :value="progress.pending" /></span>
          </p>
        </div>
        <div class="column">
          <p class="has-text-centered">
            <span class="has-text-grey-light has-text-weight-bold mr-sm">Remaining</span>
            <span><Currency :value="progress.remaining" /></span>
          </p>
        </div>
      </div>
    </div>
    <!-- Date Selector -->
    <div class="has-background-white has-shadow pa-md mb-xxs">
      <div class="columns is-vcentered">
        <div class="column is-half">
          <BillingPeriodSelect v-model="period" :start="project.start_date" :end="project.end_date" />
        </div>
      </div>
    </div>
    <div>
      <div v-if="!loading">

        <!-- Activities -->
        <div class="has-background-white has-shadow py-md px-xl mb-xxs">
          <ApproveProject :project="detail" :period="period" @project:saved="loadFulfillments(true)" />
        </div>
      </div>
      <div v-else>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'
import ApproveProject from '../../Finance/components/ProjectTimesheets/Project'
import AllocationSummary from '../components/Lines/AllocationSummary'
import BillingSummary from '../components/Lines/BillingSummary'
import ExpenseSummary from '../components/Lines/ExpenseSummary'
import UtilizationSummary from '../components/Lines/UtilizationSummary'

export default {
  components: {
    BillingPeriodSelect,
    ApproveProject,
    AllocationSummary,
    BillingSummary,
    ExpenseSummary,
    UtilizationSummary
  },
  computed: {
    ...mapGetters(['api']),
    loading () {
      return Boolean(this.loadingFulfillments)
    },
    progress () {
      if (this.loading) return null
      const progress = {
        funded: 0,
        budgeted: 0,
        fulfilled: 0,
        fulfilled_pct: 0,
        pending: 0,
        pending_pct: 0,
        remaining: 0,
        remaining_pct: 0
      }
      progress.funded = Number(this.detail.amount_funded)
      progress.budgeted = Number(this.detail.amount_budgeted)
      progress.fulfilled = Number(this.detail.amount_fulfilled)
      progress.pending = Number(this.detail.amount_pending)
      progress.remaining = Number(this.detail.amount_remaining)

      if (Number(this.detail.amount_budgeted > 0)) {
        progress.fulfilled_pct = Number(Number(this.detail.amount_fulfilled) / Number(this.detail.amount_budgeted) * 100)
        progress.pending_pct = Number(Number(this.detail.amount_pending) / Number(this.detail.amount_budgeted) * 100)
        progress.remaining_pct = Number(100 - Number(progress.fulfilled_pct) - Number(progress.pending_pct))
      }

      return progress
    },
    periodSummary () {
      if (this.loading) return null
      const summary = {
        allocated: 0, fulfilled: 0, pending: 0, remaining: 0
      }
      this.project.periods.forEach(period => {
        if (period.billing_period.id === this.period.id) {
          summary.allocated = period.amount_allocated
          summary.fulfilled = period.amount_fulfilled
          summary.pending = period.amount_pending
          summary.remaining = period.amount_remaining
        }
      })
      return summary
    },
    thruSummary () {
      if (this.loading) return null
      const summary = {
        allocated: 0, fulfilled: 0, pending: 0, remaining: 0
      }
      this.project.periods.forEach(period => {
        if (period.billing_period.id <= this.period.id) {
          summary.allocated += Number(period.amount_allocated)
          summary.fulfilled += Number(period.amount_fulfilled)
          summary.pending += Number(period.amount_pending)
          summary.remaining += Number(period.amount_remaining)
        }
      })
      return summary
    }
  },
  data () {
    return {
      period: null,
      loadingFulfillments: true,
      detail: null
    }
  },
  methods: {
    loadFulfillments (quiet = false) {
      if (!quiet) this.loadingFulfillments = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/fulfillments'
      const query = {
        params: {
          short: 1,
          billing_period_id: this.period.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.detail = response.data
        this.loadingFulfillments = false
      })
    }
  },
  mounted () {

  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    period (period) {
      if (period) this.loadFulfillments()
    }
  }
}
</script>
