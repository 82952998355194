<template>
  <div>
    <form v-if="!loading" @submit.prevent="save">
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <!-- Back Link -->
        <div class="border-b pb-md mb-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-weight-bold">
                <span v-if="mode === 'edit'" class="">Edit Expense</span>
                <span v-else class="">Add an Expense</span>
              </p>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'partner.timesheet.hours', params: { code: context.code, id: timesheet.id } }">&lt; Back to Hours</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Expense Details -->
        <div v-if="!loading" class="mb-lg">
          <div class="mb-lg">
            <div class="columns is-multiline space-between">
              <div class="column is-half">
                <b-field
                  label="Vendor"
                  :type="('vendor' in errors) ? 'is-danger' : ''"
                  :message="('vendor' in errors) ? errors['vendor'][0] : ''"
                  expanded>
                  <b-input
                    size="is-medium"
                    v-model="vendor"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field
                  label="Expense Date"
                  :type="('expense_date' in errors) ? 'is-danger' : ''"
                  :message="('expense_date' in errors) ? errors['role'][0] : ''"
                  expanded>
                  <b-input
                    size="is-medium"
                    type="date"
                    v-model="date"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field
                  label="Description"
                  :type="('description' in errors) ? 'is-danger' : ''"
                  :message="('description' in errors) ? errors['description'][0] : ''"
                  expanded>
                  <b-input
                    size="is-medium"
                    v-model="description"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field
                  label="Amount"
                  :type="('amount' in errors) ? 'is-danger' : ''"
                  :message="('amount' in errors) ? errors['amount'][0] : ''"
                  expanded>
                  <p class="control"><span class="button is-medium is-static">$</span></p>
                  <b-input
                    size="is-medium"
                    type="number"
                    step="0.01"
                    v-model="amount"
                    expanded
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Expense Type -->
          <div class="mb-lg">
            <p class="mb-md"><label class="label">Expense Type</label></p>
            <b-field>
              <b-radio v-model="type" native-value="direct">This expense was incurred as a direct result of a project deliverable.</b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="type" native-value="indirect">This expense is reimbursable, but was not incurred as direct result of a project deliverable  (e.g. Meals &amp; Entertainment)</b-radio>
            </b-field>
          </div>
          <!-- Reimbursable -->
          <div class="mb-lg">
            <p class="mb-md"><label class="label">Reimbursable</label></p>
            <b-field>
              <b-checkbox v-model="reimbursable" true-value="1" false-value="0">{{ context.name }} should be reimbursed for this expense.</b-checkbox>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Pre Approval Attachments -->
      <div v-if="seat.position.project.client.organization.preapproves_expenses" class="has-background-white rounded-lg pa-lg mb-xxs">
        <div class="columns is-vcentered">
          <div class="column">
            <div class="mb-sm">
              <label class="label">Expense Approval</label>
              <p class="mb-md">
                {{ seat.position.project.client.organization.name }} requires pre-approval of all expenses.
                Please upload at least one document that demonstrates you had client approval.
              </p>
            </div>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" @click="uploadPreapproval">Upload File</b-button>
          </div>
        </div>
        <!-- Tempfiles -->
        <div v-if="approvalTempfiles.length">
          <p class="mb-md"><label class="label">Pending Uploads</label></p>
          <div v-for="tempfile in approvalTempfiles" :key="tempfile.id" class="has-background-light px-md rounded-lg mb-md">
            <div class="columns">
              <div class="column">{{ tempfile.original_name }}</div>
              <div class="column">
                <p class="has-text-right">
                  <a href="#" @click.prevent="removeFile(tempfile.id)">remove</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Attachments -->
        <div v-if="expense && expense.attachments.length">
          <ul>
            <li v-for="attachment in expense.attachments" :key="attachment.id">Attachment</li>
          </ul>
        </div>
      </div>
      <!-- Evidence Attachments -->
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <div class="columns is-vcentered">
          <div class="column">
            <div class="mb-sm">
              <label class="label">Attachments</label>
              <p class="mb-md">Please upload at least one receipt.</p>
            </div>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" @click="uploadAttachment">Upload File</b-button>
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
          :loading="saving"
          :disabled="saving || !ready"
        >Save Expense</b-button>
      </div>
    </form>
    <div v-else>
      <Loading message="Loading..." />
    </div>
    <!-- Modal: Add Attachment -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showAddAttachmentModal"
      :can-cancel="['x', 'esc']"
      @close="attachmentType = null"
      ><UploadExpenseAttachmentModal :type="attachmentType" @tempfile:saved="attachFile" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadExpenseAttachmentModal from '@/components/Models/Expense/Modals/UploadAttachment'

export default {
  components: {
    UploadExpenseAttachmentModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingExpense)
    },
    mode () {
      return (this.expense) ? 'edit' : 'create'
    },
    method () {
      return (this.expense) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.expense)
        ? this.api.route + '/expenses/' + this.expense.id
        : this.api.route + '/expenses/'
    },
    requiresApproval () {
      return (this.seat.position.project.client.organization.preapproves_expenses)
    },
    approvalAttachment () {
      return this.$utils.find(this.expense.attachments, ['tag', 'preapproval'])
    },
    ready () {
      return (
        this.date &&
        this.vendor &&
        this.description &&
        this.amount &&
        this.type
      )
    },
    json () {
      const json = {
        project_id: this.seat.position.project_id,
        seat_id: this.seat.id,
        billing_period_id: this.period.id,
        expense_date: this.date,
        vendor: this.vendor,
        description: this.description,
        amount: this.amount,
        is_project_expense: (this.type === 'direct'),
        is_reimbursable: (this.reimbursable) ? 1 : 0,
        tempfiles: this.approvalTempfiles.concat(this.attachmentTempfiles)
      }
      return json
    }
  },
  data () {
    return {
      loadingExpense: true,
      expense: null,
      date: null,
      vendor: null,
      description: null,
      type: null,
      reimbursable: 0,
      amount: null,
      approvalTempfiles: [],
      attachmentTempfiles: [],
      saving: false,
      errors: {},
      attachmentType: null,
      showAddAttachmentModal: false
    }
  },
  methods: {
    loadExpense () {
      this.loadingExpense = true
      const endpoint = this.api.route + '/expenses/' + this.$route.params.expense
      this.$http.get(endpoint).then(response => {
        this.expense = response.data
        this.date = this.expense.expense_date
        this.vendor = this.expense.vendor
        this.description = this.expense.description
        this.type = (this.expense.is_project_expense) ? 'direct' : 'indirect'
        this.reimbursable = (this.expense.is_reimbursable) ? 1 : 0
        this.amount = this.expense.amount
        this.loadingExpense = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.expense = response.data
        this.$buefy.toast.open({ type: 'is-success', message: 'Expense saved!' })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    uploadPreapproval () {
      this.attachmentType = 'preapproval'
      this.showAddAttachmentModal = true
    },
    uploadAttachment () {
      this.attachmentType = 'attachment'
      this.showAddAttachmentModal = true
    },
    attachFile (tempfile) {
      if (tempfile.tag === 'preapproval') {
        this.approvalTempfiles.push(tempfile)
      } else {
        this.attachmentTempfiles.push(tempfile)
      }
    }
  },
  mounted () {
    if (typeof this.$route.params.expense !== 'undefined') {
      this.loadExpense()
    } else {
      this.loadingExpense = false
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    },
    timesheet: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    }
  }
}
</script>
