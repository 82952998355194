<template>
  <b-tag :type="presenter.class" :size="this.size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.funding.status === 'active') {
        presenter.class = 'is-success is-light'
        presenter.label = 'Active'
      } else if (this.funding.status === 'approved') {
        presenter.class = 'is-success is-light'
        presenter.label = 'Approved'
      } else if (this.funding.status === 'paused') {
        presenter.class = 'is-warning'
        presenter.label = 'Paused'
      } else if (this.funding.status === 'completed') {
        presenter.class = 'is-info'
        presenter.label = 'Completed'
      } else {
        presenter.class = 'has-text-grey-light'
        presenter.label = 'Draft'
      }
      return presenter
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'is-small'
    }
  }
}
</script>
