<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <!-- General -->
        <div class="column is-half">
          <h1 class="has-text-weight-bold has-border-b pb-sm">PO Information</h1>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>GatherWorks ID</td>
                <td class="has-text-right">{{ order.id }}</td>
              </tr>
              <tr>
                <td>Funding Type</td>
                <td class="has-text-right">
                  <FundingType :order="order" />
                </td>
              </tr>
              <tr>
                <td>Client</td>
                <td class="has-text-right">
                  {{ order.client.name }}
                </td>
              </tr>
              <tr>
                <td>Client <FundingType :order="order" :abbreviate="true" /> #</td>
                <td class="has-text-right has-text-weight-bold">
                  <a href="#" @click.prevent="$emit('edit:meta')">
                    <span v-if="order.client_po_number">{{ order.client_po_number }}</span>
                    <span v-else>Edit</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Contact & Billing -->
        <div class="column is-half">
          <h1 class="has-text-weight-bold has-border-b pb-sm">Dates</h1>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Started On</td>
                <td class="has-text-right">
                  <span v-if="order.started_at !== null">{{ $dates.utc(order.started_at).format('MMM DD, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Started Yet</span>
                </td>
              </tr>
              <tr v-if="order.paused_at">
                <td>Paused On</td>
                <td class="has-text-right">
                  <span>{{ $dates.utc(order.paused_at).format('MMM DD, YYYY') }}</span>
                </td>
              </tr>
              <tr>
                <td>Ended On</td>
                <td class="has-text-right">
                  <span v-if="order.ended_at !== null">{{ $dates.utc(order.ended_at).format('MMM DD, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Ended Yet</span>
                </td>
              </tr>
              <tr>
                <td>Approved On</td>
                <td class="has-text-right">
                  <span v-if="order.approved_at !== null">{{ $dates.utc(order.approved_at).format('MMM DD, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                </td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td class="has-text-right">{{ $dates.utc(order.updated_at).local().format('MMM DD, YYYY') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Contract Value -->
        <div class="column is-full">
          <h1 class="has-text-weight-bold has-border-b pb-sm">Budget</h1>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Staff Budget</td>
                <td class="has-text-right"><Currency :value="order.project_budget" /></td>
              </tr>
              <tr>
                <td>Project Expense Allowance</td>
                <td class="has-text-right"><Currency :value="order.project_expenses" /></td>
              </tr>
              <tr>
                <td>Reimbursable Expense Allowance</td>
                <td class="has-text-right"><Currency :value="order.reimbursable_expenses" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FundingType from '@/components/Models/PurchaseOrder/Presenters/FundingType'

export default {
  components: {
    FundingType
  },
  props: ['order']
}
</script>
