<template>
  <div class="section">
    <div class="container">
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <p class="has-text-weight-bold is-uppercase is-size-7">Recent Announcements</p>
          </div>
          <div class="column is-narrow">
            <b-select v-model="range">
              <option value="last_90">Last 90 Days</option>
            </b-select>
          </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg pa-md">
        <!-- Has Announcements -->
        <div v-if="!loading && announcements.length">
          <div v-for="(announcement, index) in announcements" :key="announcement.id"  :class="(index !== 0) ? 'border-t pt-md mt-md': ''">
            <AnnouncementPost :announcement="announcement" @announcement:acknowledged="loadAnnouncements" />
          </div>
        </div>
        <!-- No Announcments -->
        <div v-else-if="!loading && announcements.length === 0" class="pa-3xl has-text-centered">
          <p><b-icon icon="check" custom-class="has-text-primary" size="is-large" /></p>
          <p>You're all caught up on announcements!</p>
        </div>
        <!-- Loading -->
        <div v-else class="pa-3xl has-text-centered">
          <Loading message="Checking for new announcements..." />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnnouncementPost from '@/components/Models/Announcement/Presenters/Post'

export default {
  components: {
    AnnouncementPost
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return Boolean(this.loadingAnnouncements)
    }
  },
  data: function () {
    return {
      range: 'last_90',
      loadingAnnouncements: true,
      announcements: []
    }
  },
  methods: {
    loadAnnouncements () {
      this.loadingAnnouncements = true
      const endpoint = this.api.route + '/announcements'
      this.$http.get(endpoint).then(response => {
        this.announcements = response.data.data
        this.loadingAnnouncements = false
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadAnnouncements()
    }, 500)
  }
}
</script>
