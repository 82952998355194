<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ seat.contributor.user.full_name }}</p>
      </header>
      <section class="modal-card-body">
        <b-message v-if="pacing.status === 'hot'" type="is-warning">
          <p class="is-size-7">
            Through {{ pacing.lastMonthLabel }}, {{ seat.contributor.user.full_name }} was allocated <Hours :hours="pacing.target" class="has-text-weight-bold"/>
            hours, but has used <Hours :hours="pacing.actual" class="has-text-weight-bold" /> hours and
            is pacing ahead of expectations. You should consider decreasing
            {{ seat.contributor.user.first_name }}'s remaining allocations by
            {{ Number(pacing.actual - pacing.target) }} hours.
          </p>
        </b-message>
        <b-message v-else-if="pacing.status === 'cold'" type="is-info">
          <p class="is-size-7">
            Through {{ pacing.lastMonthLabel }}, {{ seat.contributor.user.full_name }} was allocated <Hours :hours="pacing.target" class="has-text-weight-bold"/>
            hours, but has only used <Hours :hours="pacing.actual" class="has-text-weight-bold" /> hours and
            is pacing below expectations. You should consider increasing
            {{ seat.contributor.user.first_name }}'s remaining allocations by
            <Hours :hours="Number(pacing.target - pacing.actual)" class="has-text-weight-bold" /> hours to get back on track.
          </p>
        </b-message>
        <b-message v-else type="is-success">
          <p>
            {{ seat.contributor.user.full_name }} was allocated <Hours :hours="pacing.target" class="has-text-weight-bold" />
            hours and has used <Hours :hours="pacing.actual" class="has-text-weight-bold" /> hours.
          </p>
        </b-message>
        <!-- View Select -->
        <div class="py-md border-t border-b">
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <label class="label">View By</label>
            </div>
            <div class="column">
              <b-select v-model="view" expanded>
                <option value="hours">Hours</option>
                <option value="money">Billable ($)</option>
              </b-select>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div>
          <b-table
            :data="seat.periods"
            :mobile-cards="false"
            hoverable
            narrowed
            class="is-size-7">
            <b-table-column
              header-class="is-size-8"
              cell-class="nowrap">
              <template v-slot:header>
                <p><span class="is-hidden-mobile">Billing</span> Period</p>
              </template>
              <template v-slot="props">
                <p class="has-text-weight-bold">
                  {{ $dates(props.row.billing_period.start_date).format('MMM YYYY') }}
                  (<FiscalQuarter :period="props.row.billing_period" :sequence="props.row.fiscal_quarter_sequence" />)
                </p>
                <p class="is-italic has-text-grey is-size-8">
                  <span v-if="props.row.billing_period.closed_at">Closed</span>
                  <span v-else>Open</span>
                </p>
              </template>
            </b-table-column>
            <!-- Allocated -->
            <b-table-column
              :visible="view === 'hours'"
              v-slot="props"
              label="Allocated (Hr)"
              width="90"
              header-class="is-size-8"
              centered>
              <Hours :hours="props.row.units_allocated" :mantissa="2" />
            </b-table-column>
            <b-table-column
              :visible="view === 'money'"
              v-slot="props"
              label="Allocated ($)"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_allocated" :mantissa="0" />
            </b-table-column>
            <!-- Pending -->
            <b-table-column
              :visible="view === 'hours'"
              v-slot="props"
              label="Pending (Hr)"
              width="90"
              header-class="is-size-8"
              centered>
              <Hours :hours="props.row.units_pending" :mantissa="2" />
            </b-table-column>
            <b-table-column
              :visible="view === 'money'"
              v-slot="props"
              label="Pending ($)"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_pending" :mantissa="0" />
            </b-table-column>
            <!-- Delivered -->
            <b-table-column
              :visible="view === 'hours'"
              v-slot="props"
              label="Delivered (Hr)"
              width="90"
              header-class="is-size-8"
              centered>
              <Hours :hours="props.row.units_fulfilled" :mantissa="2" />
            </b-table-column>
            <b-table-column
              :visible="view === 'money'"
              v-slot="props"
              label="Delivered ($)"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="props.row.amount_fulfilled" :mantissa="0" />
            </b-table-column>
            <!-- Diff -->
            <b-table-column
              :visible="view === 'hours'"
              v-slot="props"
              label="+/- (Hr)"
              width="90"
              header-class="is-size-8"
              centered>
              <Hours :hours="Number(props.row.units_allocated) - Number(props.row.units_fulfilled)" :mantissa="2" :vsZero="true" />
            </b-table-column>
            <b-table-column
              :visible="view === 'money'"
              v-slot="props"
              label="+/- ($)"
              width="90"
              header-class="is-size-8"
              centered>
              <Currency :value="Number(props.row.amount_allocated) - Number(props.row.amount_fulfilled)" :mantissa="0" :vsZero="true" />
            </b-table-column>
          </b-table>
        </div>

      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import FiscalQuarter from '@/components/Models/BillingPeriod/Presenters/FiscalQuarter'

export default {
  components: {
    FiscalQuarter
  },
  computed: {
    ...mapGetters(['api']),
    pacing () {
      const pacing = {
        status: null,
        alertClass: 'is-success',
        target: 0,
        actual: 0,
        lastMonthLabel: this.$dates().format('MMMM YYYY')
      }
      const closed = []
      this.seat.periods.forEach(period => {
        if (period.billing_period.closed_at) {
          closed.push(period)
        }
      })
      if (closed.length) {
        closed.forEach(period => {
          pacing.target += Number(period.units_allocated)
          pacing.actual += Number(period.units_fulfilled)
          pacing.lastMonthLabel = this.$dates(period.billing_period.start_date).format('MMMM YYYY')
        })
      }
      if (pacing.target < pacing.actual) {
        pacing.status = 'hot'
        pacing.alertClass = 'is-info'
      }
      if (pacing.target > pacing.actual) {
        pacing.status = 'cold'
        pacing.alertClass = 'is-warning'
      }
      return pacing
    }
  },
  data () {
    return {
      view: 'hours',
      saving: false,
      errors: {}
    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
