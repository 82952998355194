import Privacy from './views/Privacy'

export default [{
  path: '/privacy-policy',
  name: 'privacy',
  component: Privacy,
  meta: {
    skipContext: true,
    skipAuth: true,
    hideNavigation: true
  }
}]
