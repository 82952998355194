<template>
  <div>
    <p class="is-size-5 has-text-weight-bold mb-lg">Finance Settings</p>
    <div class="mb-lg pb-lg has-border-b">
      <SalesTerms/>
    </div>
    <div class="mb-lg">
      <ExpenseCategories/>
    </div>
  </div>
</template>

<script>
import SalesTerms from '../components/Finance/SalesTerms'
import ExpenseCategories from '../components/Finance/ExpenseCategories'

export default {
  components: {
    SalesTerms,
    ExpenseCategories
  }
}
</script>
