<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (distribution) ? 'Update' : 'Add' }} Funding Distribution</p>
        </header>
        <section class="modal-card-body">
          <!-- Funding Line -->
          <b-field
            label="Select Funding Line to Distribute"
            :type="('funding_line_id' in errors) ? 'is-danger' : ''"
            :message="('funding_line_id' in errors) ? errors['funding_line_id'][0] : ''"
            class="mb-lg">
            <b-select
              v-model="fundingLine"
              placeholder="Select funding line..."
              size="is-medium"
              expanded
              :disabled="distribution"
              :readonly="distribution">
              <option v-for="line in availableLines" :key="line.id" :value="line">
                {{ line.description }} (ID: {{ line.id }})
              </option>
            </b-select>
            <p v-if="fundingLine" class="mt-sm">
              Available to Distribute:
              <span class="has-text-primary has-text-weight-bold">
                <Currency :value="Number(fundingLine.amount) - Number(fundingLine.amount_distributed)" />
              </span>
            </p>
          </b-field>
          <!-- Project -->
          <b-field
            label="Select Project to Fund"
            class="mb-lg">
            <b-select
              v-model="selectedProjectId"
              placeholder="Select..."
              size="is-medium"
              expanded
              :disabled="distribution"
              :readonly="distribution">
              <option v-for="project in availableProjects" :key="project.id" :value="project.id">{{ project.label }}</option>
            </b-select>
          </b-field>
          <!-- Budget -->
          <b-field
            label="Select Budget to Fund"
            :type="('budget_id' in errors) ? 'is-danger' : ''"
            :message="('budget_id' in errors) ? errors['budget_id'][0] : ''"
            class="mb-lg">
            <b-select
              v-model="budget"
              placeholder="Select budget..."
              size="is-medium"
              expanded
              :disabled="distribution"
              :readonly="distribution">
              <option v-for="budget in availableBudgets" :key="budget.id" :value="budget">
                {{ budget.role }} (ID: {{ budget.id }})
              </option>
            </b-select>
            <p v-if="budget" class="mt-sm">
              Current Budget:
              <span class="has-text-primary has-text-weight-bold">
                <Currency :value="Number(budget.amount_budgeted)" />
              </span>,
              Current Funding:
              <span class="has-text-primary has-text-weight-bold">
                <Currency :value="Number(budget.amount_funded)" />
              </span>
            </p>
          </b-field>
          <!-- Amount -->
          <b-field
            label="Distribution Amount"
            :type="('amount' in errors) ? 'is-danger' : ''"
            :message="('amount' in errors) ? errors['amount'][0] : ''"
            class="mb-lg">
            <b-input
              v-model="amount"
              type="number"
              size="is-medium"
              expanded />
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <div class="buttons">
            <b-button @click="$parent.close()">Close</b-button>
            <ConfirmButton
              v-if="distribution"
              type="is-danger"
              :outlined="true"
              @action:confirmed="deleteDistribution"
              initialLabel="Delete?" />
          </div>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Save Distribution
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmButton from '@/components/Forms/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(this.fundingLine && this.budget && this.amount)
    },
    method () {
      return (this.distribution) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.distribution)
        ? this.api.route + '/funding/' + this.$route.params.id + '/distributions/' + this.distribution.id
        : this.api.route + '/funding/' + this.$route.params.id + '/distributions/'
    },
    availableLines () {
      const lines = []
      this.funding.lines.forEach(line => {
        lines.push(line)
      })
      return lines
    },
    availableProjects () {
      const projects = []
      this.funding.projects.forEach(project => {
        projects.push({
          id: project.id,
          label: project.name + ' (ID: ' + project.id + ')'
        })
      })
      return projects
    },
    availableBudgets () {
      if (!this.selectedProjectId) return []
      const budgets = []
      this.funding.projects.forEach(project => {
        if (project.id === this.selectedProjectId) {
          project.positions.forEach(position => {
            budgets.push(position)
          })
        }
      })
      return budgets
    },
    json () {
      return {
        funding_id: this.funding.id,
        funding_line_id: this.fundingLine.id,
        budget_id: this.budget.id,
        amount: this.amount
      }
    }
  },
  data () {
    return {
      saving: false,
      errors: {},
      fundingLine: null,
      selectedProjectId: null,
      budget: null,
      amount: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Distribution saved!' })
        this.$parent.close()
        this.$emit('funding:updated')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    deleteDistribution () {
      this.saving = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Distribution deleted!' })
        this.$parent.close()
        this.$emit('funding:updated')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.distribution) {
      this.selectedProjectId = this.distribution.budget.project_id
      this.budget = this.distribution.budget
      this.fundingLine = this.distribution.funding_line
      this.amount = Number(this.distribution.amount).toFixed(2)
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    },
    distribution: {
      type: Object,
      default: null
    }
  }
}
</script>
