<template>
  <span :class="textClass">{{ textValue }}</span>
</template>

<script>
export default {
  computed: {
    textValue () {
      if (this.value === 0 || this.value === '0' || this.value === null || typeof this.value === 'undefined' || isNaN(this.value)) {
        if (this.zeroValue === 'dash') return '--'
        if (this.zeroValue === 'na') return 'N/A'
        if (this.zeroValue === 'number') return '0.00%'
      }
      return this.$numbers(this.value).format({ output: 'percent', mantissa: this.mantissa, thousandSeparated: true })
    },
    textClass () {
      if (this.value === 0 && (this.zeroValue === 'dash' || this.zeroValue === 'na')) {
        return 'has-text-grey-light'
      }
      if (this.temperatureReference && parseFloat(this.value) > parseFloat(this.temperatureReference)) {
        return 'has-text-warning'
      }
      if (this.temperatureReference && this.value < this.temperatureReference) {
        return 'has-text-info'
      }
      return null
    }
  },
  props: {
    mantissa: {
      type: Number,
      default: 2
    },
    temperatureReference: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number]
    },
    zeroValue: {
      type: String,
      default: 'number' // 'dash' or 'na'
    }
  }
}
</script>
