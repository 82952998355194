<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ (this.line) ? 'Edit' : 'Add' }} Client Invoice Line
        </p>
      </header>
      <section class="modal-card-body">
        <b-field label="Service">
          <b-input
            type="text"
            size="is-medium"
            v-model="service"
          ></b-input>
        </b-field>
        <b-field label="Description">
          <b-input
            type="text"
            size="is-medium"
            v-model="description"
          ></b-input>
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field label="Quantity">
              <b-input
                type="number"
                size="is-medium"
                v-model="quantity"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Rate">
              <b-input
                type="number"
                size="is-medium"
                :step="0.01"
                v-model="rate"
              ></b-input>
            </b-field>
          </div>
        </div>
        <!-- Total -->
        <b-field label="Line Total">
          <p class="control"><span class="button is-static is-medium border-none">$</span></p>
          <b-input
            type="text"
            size="is-medium"
            :value="lineTotal"
            :disabled="true"
            :readonly="true"
            expanded
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="!ready"
        >Save Invoice Line</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['api']),
    method () {
      return (this.line) ? 'patch' : 'post'
    },
    endpoint () {
      const base = this.api.route + '/admin/client-invoice-lines'
      return (this.line) ? base + '/' + this.line.id : base
    },
    json () {
      const line = {
        service: this.service,
        description: this.description,
        quantity: this.quantity,
        rate: this.rate,
        total: this.lineTotal
      }
      if (this.line === null) {
        return {
          client_invoice_id: this.invoice.id,
          invoice_lines: [line]
        }
      }
      return line
    },
    availableBalance () {
      return null
    },
    lineTotal () {
      if (!this.quantity || !this.rate) {
        return '0.00'
      }
      return this.$numbers(parseFloat(this.quantity) * parseFloat(this.rate)).format({ mantissa: 2 })
    },
    ready () {
      return (this.service && this.lineTotal > 0)
    }
  },
  data () {
    return {
      service: null,
      description: null,
      quantity: null,
      rate: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Invoice lines saved!' })
        this.$emit('invoice:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    line: {
      type: [Object, null]
    }
  },
  watch: {
    line: {
      immediate: true,
      handler (line) {
        if (line !== null) {
          this.service = line.service
          this.description = line.description
          this.quantity = this.$numbers(line.quantity).format({ mantissa: 2 })
          this.rate = this.$numbers(line.rate).format({ mantissa: 2 })
        }
      }
    }
  }
}
</script>
