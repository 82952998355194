<template>
  <div>
    <!-- Transfer -->
    <div class="has-background-white rounded-lg pa-lg mb-xxs">
      <!-- Back Link -->
      <div class="border-b pb-md mb-lg">
        <div class="columns">
          <div class="column">
            <p class="has-text-weight-bold has-text-primary is-uppercase">Log Transaction</p>
          </div>
          <div class="column is-narrow">
            <div class="has-text-right">
              <router-link :to="{ name: 'network.finance.client.invoices', params: { code: context.code }}">&lt; Back to Invoices</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Form -->
      <div class="columns space-between">
        <div class="column is-two-thirds">
          <div>
            <b-field label="Transaction Type">
              <b-select
                v-model="type"
                size="is-medium"
                placeholder="Select Type"
                expanded>
                <option value="invoice">Invoice</option>
                <option value="credit">Credit</option>
              </b-select>
            </b-field>
            <b-field
              label="Client Name"
              :type="('client' in errors) ? 'is-danger' : ''"
              :message="('client' in errors) ? errors['client'][0] : ''">
              <ClientAutocomplete
                v-model="client"
                resource="clients"
                labelField="name"
                valueField="id"
                size="is-medium" />
            </b-field>
            <b-field label="Project">
              <b-select
                v-model="projectId"
                size="is-medium"
                placeholder="Select Project"
                :loading="loadingProjects"
                :disabled="client === null"
                expanded>
                <option v-for="project in projects" :key="project.id" :value="project.id">
                  {{ project.name }} (ID: {{ project.id }})
                </option>
              </b-select>
            </b-field>
            <b-field label="Billing Period">
              <b-select
                v-model="billingPeriod"
                size="is-medium"
                placeholder="Select Billing Period"
                :loading="loadingProjectDetail"
                :disabled="client === null || projectId === null"
                expanded>
                <template v-if="project">
                  <option v-for="period in project.periods" :key="period.billing_period_id" :value="period.billing_period">
                    {{ period.billing_period.label }}
                  </option>
                </template>
              </b-select>
            </b-field>
            <b-field
              label="Transaction Date"
              :type="('invoice_date' in errors) ? 'is-danger' : ''"
              :message="('invoice_date' in errors) ? errors['invoice_date'][0] : ''"
              expanded>
              <b-datepicker
                v-if="billingPeriod"
                size="is-medium"
                v-model="invoiceDate"
                placeholder="Select transaction date..."
                :disabled="!billingPeriod"
                :min-date="$dates(billingPeriod.start_date).toDate()"
                :max-date="$dates(billingPeriod.end_date).toDate()"
                :focused-date="$dates(billingPeriod.start_date).toDate()">
              </b-datepicker>
              <b-input
                v-else
                size="is-medium"
                placeholder="Select transaction date..."
                :disabled="true"
                :readonly="true"
              />
            </b-field>
            <b-field
              :label="(type === 'invoice') ? 'Invoice Number' :  'Credit Number'"
              :type="('invoice_number' in errors) ? 'is-danger' : ''"
              :message="('invoice_number' in errors) ? errors['invoice_number'][0] : ''"
              expanded>
              <b-input
                size="is-medium"
                v-model="invoiceNumber"
                :placeholder="(type === 'invoice') ? 'Enter invoice number...' : 'Enter credit number...'"
                :disabled="!billingPeriod">
              </b-input>
            </b-field>
          </div>
        </div>
        <!-- Billabkes -->
        <!-- <div class="column">
          <div class="has-background-light rounded-lg pa-md mt-xl has-text-centered">
            <p class="has-text-weight-bold my-sm">Pending Billable Items</p>
            <div v-if="funding">
              <div class="mb-lg has-text-centered">
                <p class="has-text-weight-bold is-size-4">
                  <span v-if="!loadingBillables">{{ billables.count }}</span>
                  <span v-else class="has-text-grey">Loading...</span>
                </p>
                <p>Pending Billables </p>
              </div>
              <div class="has-text-centered">
                <p class="has-text-weight-bold is-size-4">
                  <span v-if="!loadingBillables"><Currency :value="billables.amount" /></span>
                  <span v-else class="has-text-grey">Loading...</span>
                </p>
                <p>Pending $ </p>
              </div>
            </div>
            <div v-else>
              <p class="has-text-grey-light pa-md has-text-centered">Select funding to view pending billables.</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- Invoice Save -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <b-button
        type="is-primary"
        native-type="submit"
        size="is-medium"
        :loading="saving"
        :disabled="!ready || saving"
        @click="save"
      >Log Transaction</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientAutocomplete from '@/components/Forms/Autocomplete'

export default {
  components: {
    ClientAutocomplete
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (
        this.loadingProjects ||
        this.loadingProjectDetail ||
        this.loadingBillables
      )
    },
    ready () {
      return Boolean(
        this.type &&
        this.project &&
        this.billingPeriod &&
        this.invoiceDate &&
        this.invoiceNumber
      )
    },
    json () {
      if (!this.ready) return null
      const json = {
        type: this.type,
        client_id: this.client.id,
        project_id: this.project.id,
        billing_period_id: this.billingPeriod.id,
        invoice_date: this.invoiceDate,
        network_identifier: this.invoiceNumber,
        add_outstanding_billables: this.addBillables
      }
      return json
    }
  },
  data () {
    return {
      loadingProjects: false,
      loadingProjectDetail: false,
      loadingBillables: false,
      projects: [],
      billingPeriod: null,
      organization: null,
      type: 'invoice',
      client: null,
      invoiceDate: null,
      invoiceNumber: null,
      projectId: null,
      project: null,
      billingEvent: null,
      billables: null,
      addBillables: 0,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadProjects () {
      this.loadingProjects = true
      const endpoint = this.api.route + '/projects'
      const query = {
        params: {
          client: this.client.id,
          limit: 25
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.projects = response.data.data
        this.loadingProjects = false
      })
    },
    loadProjectDetail () {
      this.loadingProjectDetail = true
      const endpoint = this.api.route + '/projects/' + this.projectId
      this.$http.get(endpoint).then(response => {
        this.project = response.data
        this.loadingProjectDetail = false
      })
    },
    loadBillables () {
      this.loadingBillables = true
      const endpoint = this.api.route + '/admin/billables'
      const query = {
        params: {
          project_id: this.project.id,
          is_invoiced: 0,
          summarize: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.billables = response.data
        this.loadingBillables = false
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/admin/client-invoices'
      this.$http.post(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Invoice added!' })
        this.$router.push({ name: 'network.client.invoice', params: { code: this.context.code, invoice: response.data.id } })
      }).catch(error => {
        this.errors = error.response.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  watch: {
    client (client) {
      if (client) this.loadProjects()
    },
    projectId (id) {
      if (id) this.loadProjectDetail()
    }
    // project (project) {
    //   if (project) this.loadBillables()
    // }
  }
}
</script>
