export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter announcements by status.',
  options: [{
    value: 'published',
    label: 'Published'
  }, {
    value: 'draft',
    label: 'Draft'
  }]
}
