<template>
<div>
  <div>
    <div class="columns">
      <div class="column">
        <div class="py-md mb-md border-t border-b">
          <div class="columns">
            <!-- Funding -->
            <div class="column">
              <Statistic label="Funded" type="currency" :value="line.amount_funded" :mantissa="0" />
            </div>
            <!-- Target -->
            <div class="column">
              <Statistic label="Budget" type="currency" :value="line.amount_budgeted" :mantissa="0" />
            </div>
            <!-- Planned -->
            <div class="column">
              <Statistic label="Allocated" type="currency" :value="line.amount_allocated" :mantissa="0" />
            </div>
            <!-- Pending -->
            <div class="column">
              <Statistic label="Unallocated" type="currency" :value="Number(line.amount_budgeted) - Number(line.amount_allocated)" :mantissa="0" />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="py-md mb-md border-t border-b">
          <div class="columns">
            <!-- Funding -->
            <div class="column">
              <Statistic label="Pending" type="currency" :value="line.amount_pending" :mantissa="0" />
            </div>
            <!-- Target -->
            <div class="column">
              <Statistic label="Delivered" type="currency" :value="line.amount_fulfilled" :mantissa="0" />
            </div>
            <!-- Planned -->
            <div class="column">
              <Statistic v-if="Number(line.amount_allocated)" label="Remaining" type="currency" :value="Number(line.amount_allocated) - Number(line.amount_fulfilled)" :mantissa="0" />
              <div v-else class="has-text-centered">
                <p class="is-size-5">--</p>
                <p class="is-size-8 has-text-grey">Remaining</p>
              </div>
            </div>
            <!-- Pending -->
            <div class="column">
              <Statistic label="Margin" type="margin" :revenue="line.amount_fulfilled" :cogs="line.amount_fulfilled_cost" :mantissa="0"  />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Statistic from '@/components/Global/Statistic'

export default {
  components: {
    Statistic
  },
  props: {
    line: {
      type: Object,
      required: true
    }
  }
}
</script>
