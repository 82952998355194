export const status = {
  label: 'Status',
  key: 'status',
  icon: 'pulse',
  tooltip: 'Filter connections by status',
  options: [{
    value: 'active',
    label: 'Active'
  }, {
    value: 'onboarding',
    label: 'Onboarding'
  }, {
    value: 'inactive',
    label: 'Inactive'
  }]
}
