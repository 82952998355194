<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Allocate Margin Seats</p>
      </header>
      <section class="modal-card-body">
        <!-- Allocation Instructions -->
        <div class="mb-md has-background-light rounded-lg pa-md">
          <div class="has-text-centered mb-lg">
            <p class="has-text-weight-bold mb-sm">Margin Allocation</p>
            <div class="is-size-7">
              <p class="mb-sm">
                You may allocate up 50% of the gross margin from this project to margin
                seat holders, but no single individual can earn more than 25%. Please
                adjust your allocations below.
              </p>
              <p>
                For more information, please consult <a :href="assets.marginSeats" target="_blank">our guide</a>
                on margin seat allocation.
              </p>
            </div>
          </div>
          <div class="mb-lg">
            <b-progress size="is-large" :max="100" show-value>
              <template #bar>
                <b-progress-bar
                  v-for="seat in allocationProgressBars"
                  :key="seat.label"
                  :value="seat.value"
                  :type="seat.type"
                >{{ seat.label }}</b-progress-bar>
              </template>
            </b-progress>
          </div>
          <div class="mb-md has-text-centered is-size-7">
            <div class="has-text-weight-bold mb-sm">
              <p v-if="totalAllocated === 50" class="has-text-success">Allocations are full!</p>
              <p v-else-if="totalAllocated > 50" class="has-text-danger">You have assigned too much margin. All seats combined must be less than 50%</p>
              <p v-else class="has-text-warning">There is still margin available to assign!</p>
            </div>
          </div>
        </div>
        <!-- Allocations -->
        <table class="table is-narrow is-fullwidth is-vcentered">
          <tr>
            <th>Seat Holder</th>
            <th width="50%">Rate</th>
          </tr>
          <tr v-for="seat in seats" :key="seat.id">
            <td><p class="has-text-weight-bold" :class="seat.textClass">{{ seat.user }}</p></td>
            <td>
              <b-numberinput
                size="is-medium"
                v-model="seat.rate"
                expanded
                :min="0"
                :max="25"
                step=".01">
              </b-numberinput>
            </td>
          </tr>
          <tr>
            <th>Allocated</th>
            <th :class="(totalAllocated === 50) ? 'has-text-success' : 'has-text-grey'">
              <p class="has-text-right py-sm">{{ $numbers(totalAllocated).format({ mantissa: 2 }) }}%</p>
            </th>
          </tr>
          <tr>
            <th>Remaining</th>
            <th :class="(remainingAllocation === 50) ? 'has-text-success' : 'has-text-grey'">
              <p class="has-text-right py-sm">{{ $numbers(remainingAllocation).format({ mantissa: 2 }) }}%</p>
            </th>
          </tr>
        </table>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'assets']),
    endpoint () {
      return this.api.route + '/projects/' + this.project.id + '/margin-seats/allocate'
    },
    json () {
      const seats = []
      this.seats.forEach(seat => {
        seats.push({
          id: seat.id,
          role_id: seat.role_id,
          rate: seat.rate
        })
      })
      return {
        allocations: seats
      }
    },
    ready () {
      return this.totalAllocated <= 50
    },
    allocationProgressBars () {
      const bars = []
      let total = 0
      this.seats.forEach(seat => {
        const rate = parseFloat(seat.rate)
        total += rate
        if (rate > 0) {
          bars.push({
            label: seat.initials,
            value: parseFloat(rate),
            type: seat.typeClass
          })
        }
      })
      const remaining = 50 - total
      if (remaining > 0) {
        bars.push({
          value: remaining,
          type: 'is-warning',
          label: 'Open'
        })
      }
      // push the constant Gather bar
      bars.push({
        value: 50,
        type: null,
        label: 'Gather'
      })
      return bars
    },
    totalAllocated () {
      let allocation = 0
      this.seats.forEach(seat => {
        allocation += (seat.rate) ? parseFloat(seat.rate) : 0
      })
      return allocation
    },
    remainingAllocation () {
      return 100 - 50 - this.totalAllocated
    }
  },
  data () {
    return {
      seats: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Allocations saved!' })
        this.$emit('seat:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const barColors = ['is-primary', 'is-info', 'is-success', 'is-danger']
    const textColors = ['has-text-primary', 'has-text-info', 'has-text-success', 'has-text-danger']
    this.marginSeats.forEach((seat, index) => {
      this.seats.push({
        id: seat.id,
        user: seat.role.user.full_name,
        initials: seat.role.user.initials,
        role_id: seat.role.id,
        rate: parseFloat(seat.rate),
        typeClass: barColors[index],
        textClass: textColors[index]
      })
    })
  },
  props: {
    project: {
      type: Object
    },
    marginSeats: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
