<template>
  <div>
    <div class="mb-md">
      <b-table
        :data="results"
        :mobile-cards="false"
        :checked-rows.sync="selected"
        hoverable
        checkable
        class="is-size-7">
        <b-table-column
          label="Partner"
          v-slot="props"
          width="164">
          <p>
            {{ props.row.timesheet.seat.contributor.user.first_name }}
            {{ props.row.timesheet.seat.contributor.user.last_name }}
          </p>
        </b-table-column>
        <b-table-column
          label="Date"
          v-slot="props"
          width="164">
          <p><Date :value="props.row.date" /></p>
        </b-table-column>
        <b-table-column
          label="Hours"
          v-slot="props"
          width="164">
          <p><Hours :hours="props.row.hours" /></p>
        </b-table-column>
        <b-table-column
          label="Notes"
          v-slot="props"
          width="164">
          <p>{{ props.row.notes }}</p>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loading: true,
      results: [],
      selected: []
    }
  },
  methods: {
    load () {
      this.loading = true
      const endpoint = this.api.route + '/projects/' + this.$route.params.id + '/timesheet-activities'
      this.$http.get(endpoint).then(response => {
        this.results = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    groupBy (value) {
      this.load()
    }
  }
}
</script>
