<template>
<div>
  <Breadcrumbs :history="history" current="People" />
  <form @submit.prevent="update">
    <div class="columns">
      <div class="column">
        <div class="has-background-white has-shadow">
          <div class="pa-lg pb-xl">
            <!-- Name -->
            <div class="mb-lg">
              <b-field
                label="Business Name"
                :type="('name' in errors) ? 'is-danger' : ''"
                :message="('name' in errors) ? errors['name'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    type="text"
                    v-model="name"
                    @input="isDirty=1"
                  ></b-input>
              </b-field>
            </div>
            <!-- Primary Contact -->
            <div class="mb-lg">
              <div class="columns">
                <div class="column is-half">
                  <b-field
                    label="Primary Contact Name"
                    :type="('contact' in errors) ? 'is-danger' : ''"
                    :message="('contact' in errors) ? errors['contact'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="contact"
                        v-model="primaryContact"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field
                    label="Primary Contact Email"
                    :type="('billing_email' in errors) ? 'is-danger' : ''"
                    :message="('billing_email' in errors) ? errors['billing_email'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="email"
                        name="email"
                        v-model="primaryEmail"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <!-- Photo -->
            <div>
              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <p class="has-text-weight-bold mb-sm has-text-grey-darker">Photo or Logo</p>
                  <div class="border pa-sm">
                    <cld-image v-if="partner.logo_path" :publicId="partner.logo_path" width="128" dpr="2.0" crop="scale" style="display:block"/>
                    <b-skeleton v-else width="128" height="128" :animated="false" />
                  </div>
                </div>
                <div class="column is-narrow">
                  <a href="#" @click.prevent="showUploadLogoModal = true">Upload Photo</a>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="saving || !ready">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Modal: Change Address -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showUploadLogoModal"
    :can-cancel="['x', 'esc']"
    ><UploadLogoModal :partner="partner" @partner:updated="$emit('partner:updated')" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadLogoModal from '@/components/Models/Partner/UploadLogo'

export default {
  components: {
    UploadLogoModal
  },
  computed: {
    ...mapGetters(['auth', 'api', 'context']),
    endpoint () {
      return this.api.route
    },
    history () {
      return [
        { label: 'Settings', name: 'partner.settings', params: { code: this.context.code } }
      ]
    },
    body () {
      return {
        name: this.name,
        billing_contact: this.primaryContact,
        billing_email: this.primaryEmail
      }
    },
    ready () {
      return (
        this.name &&
        this.primaryContact &&
        this.primaryEmail
      )
    }
  },
  data () {
    return {
      loading: true,
      name: null,
      primaryContact: null,
      primaryEmail: null,
      file: null,
      isDirty: false,
      showUploadLogoModal: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    update () {
      this.saving = true
      this.$http.patch(this.endpoint, this.body).then(response => {
        this.$emit('partner:updated')
        this.$store.dispatch('setContext', response.data.name)
        this.$buefy.toast.open({ type: 'is-success', message: 'Partner updated!' })
        this.isDirty = false
        this.errors = []
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There were problems with your input. Please review and try again.' })
        this.errors = error.response.data.errors
        this.isDirty = false
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.name = this.partner.name
    this.primaryContact = this.partner.billing_contact
    this.primaryEmail = this.partner.billing_email
    this.photo = this.partner
  },
  props: {
    partner: {
      type: Object,
      required: true
    }
  }
}
</script>
