import Activities from './views/Activities'
import Allocate from './views/Allocate'
import Approve from './views/Approve'
import Attachments from './views/Attachments'
import Delivery from './views/Delivery'
import Detail from './views/Detail'
import Expenses from './views/Expenses'
import Funding from './views/Funding'
import Invoices from './views/Invoices'
import MarginSeats from './views/MarginSeats'
import Notes from './views/Notes'
import Billing from './views/Billing'
import Position from './views/Position'
import PositionPlan from './views/PositionPlan'
import ProjectFee from './views/ProjectFee'
import Seat from './views/Seat'
import SeatPlan from './views/SeatPlan'
import Staff from './views/Staff'
import Summary from './views/Summary'
import TimeSheets from './views/TimeSheets'
import Hours from './views/Hours'
import Expense from './views/Expense'
import Lines from './views/Lines'

export default [{
  path: '/network/:code/work/projects/:id',
  component: Detail,
  children: [{
    path: '',
    name: 'network.project.detail',
    component: Staff
  }, {
    path: 'summary',
    name: 'network.project.summary',
    component: Summary
  }, {
    path: 'lines',
    name: 'network.project.lines',
    component: Lines
  }, {
    path: 'allocate',
    name: 'network.project.allocate',
    component: Allocate
  }, {
    path: 'billing',
    name: 'network.project.billing',
    component: Billing
  }, {
    path: 'fees/:fee?',
    name: 'network.project.fee',
    component: ProjectFee
  }, {
    path: 'expenses',
    name: 'network.project.expenses',
    component: Expenses
  }, {
    path: 'expenses/:expense?/edit',
    name: 'network.project.expense',
    component: Expense
  }, {
    path: 'deliveries/:delivery?',
    name: 'network.project.delivery',
    component: Delivery
  }, {
    path: 'position/:position?',
    name: 'network.project.position',
    component: Position
  }, {
    path: 'position/:position/plans',
    name: 'network.project.position.plans',
    component: PositionPlan
  }, {
    path: 'seat/:seat?',
    name: 'network.project.seat',
    component: Seat
  }, {
    path: 'seat/:seat/plans',
    name: 'network.project.seat.plans',
    component: SeatPlan
  }, {
    path: 'approve',
    name: 'network.project.approve',
    component: Approve
  }, {
    path: 'activities',
    name: 'network.project.activities',
    component: Activities
  }, {
    path: 'attachments',
    name: 'network.project.attachments',
    component: Attachments
  }, {
    path: 'time',
    name: 'network.project.timesheets',
    component: TimeSheets
  }, {
    path: 'hours',
    name: 'network.project.hours',
    component: Hours
  }, {
    path: 'funding',
    name: 'network.project.funding',
    component: Funding
  }, {
    path: 'margin-seats',
    name: 'network.project.marginseats',
    component: MarginSeats
  }, {
    path: 'invoices',
    name: 'network.project.invoices',
    component: Invoices
  }, {
    path: 'notes',
    name: 'network.project.notes',
    component: Notes
  }]
}]
