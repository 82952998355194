<template>
  <div>
    <!-- Content -->
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <ClientInvoiceFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <router-link :to="{ name: 'network.finance.client.invoices.create' }" class="button is-primary is-outlined is-medium is-fullwidth">Log Client Transaction</router-link>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 transaction</p>
              <p v-else>{{ total }} transactions</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            field="id"
            label="ID"
            width="64"
            centered
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            field="type"
            label="Type"
            width="98"
            v-slot="props">
            <span class="has-text-grey-light">{{ $utils.startCase(props.row.type) }}</span>
          </b-table-column>
          <b-table-column
            label="Date"
            width="128"
            v-slot="props">
            <router-link
              class="has-text-weight-bold"
              :to="{ name: 'network.client.invoice', params: { code: context.code, invoice: props.row.id  } }">
              {{ $dates(props.row.invoice_date).format('MMM D, YYYY') }}
            </router-link>
          </b-table-column>
          <b-table-column
            label="Client"
            width="200"
            v-slot="props">
            <span v-if="props.row.client">{{ props.row.client.name }}</span>
            <span v-else class="has-text-grey-light is-italic">Not Set</span>
          </b-table-column>
          <b-table-column
            label="Project"
            v-slot="props">
            {{ props.row.project.name }}
          </b-table-column>
          <b-table-column
            label="#"
            width="128"
            v-slot="props">
            <p v-if="props.row.network_identifier">{{ props.row.network_identifier }}</p>
            <p v-else class="has-text-grey-light">- Not Set -</p>
          </b-table-column>
          <b-table-column
            label="Amount"
            centered
            width="128"
            v-slot="props">
            <ClientInvoiceLineTotal :invoice="props.row" metric="subtotal" />
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            centered
            width="128"
            v-slot="props">
            <StatusPresenter :invoice="props.row" />
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import ClientInvoiceFilters from '../components/Revisions/RevisionFilters'
import ClientInvoiceLineTotal from '@/components/Models/ClientInvoice/Presenters/InvoiceLineTotal'
import StatusPresenter from '@/components/Models/ClientInvoice/Presenters/Status'

export default {
  components: {
    ClientInvoiceFilters,
    ClientInvoiceLineTotal,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Finance', name: 'network.finance', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/admin/client-invoices'
    }
  },
  data: function () {
    return {
      key: 'admin.client.invoices',
      showCreateRevisionModal: false
    }
  },
  methods: {
    redirect (id) {
      this.$router.push({ name: 'network.finance.revision', params: { code: this.context.code, revision: id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
