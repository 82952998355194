<template>
  <div>
    <div class="mb-xl">
      <b-message type="is-info">
        Notifications are controlled by partners.
      </b-message>
      <table class="table is-fullwidth">
        <tr>
          <th>Notification</th>
          <th class="has-text-centered" style="width:128px">Email</th>
          <th class="has-text-centered" style="width:128px">SMS</th>
        </tr>
        <tr v-for="notification in contributor.notifications" :key="notification.id">
          <td>{{ notification.push_notification.name }}</td>
          <td class="has-text-centered">
            <b-icon icon="check" custom-class="has-text-success" />
          </td>
          <td class="has-text-centered">
            <b-icon icon="cancel" custom-class="has-text-danger" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    contributor: {
      type: Object,
      required: true
    }
  }
}
</script>
