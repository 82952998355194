<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <div class="columns is-vcentered is-mobile">
        <div class="column">
          <p class="has-text-weight-bold is-uppercase is-size-7">
            Team <span class="has-text-grey-light">[ {{ seats.length }} ]</span>
          </p>
        </div>
        <!-- <div class="column is-narrow">
          <router-link
            :to="{ name: 'network.project.seat', params: { code: 'gather', project: project.id } }"
            class="button is-primary is-outlined is-small"
          >Add Contributor</router-link>
        </div> -->
      </div>
    </div>
    <div>
      <b-table
        :data="seats"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          v-slot="props"
          label="ID"
          width="64"
          header-class="is-size-8"
          cell-class="has-text-grey-light">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Contributor"
          header-class="is-size-8"
          cell-class="nowrap">
          <p class="has-text-weight-bold">{{ props.row.contributor.user.full_name }}</p>
          <p class="is-size-7 has-text-grey">{{ props.row.position.role }}</p>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Client Rate"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="128">
          <Currency :value="props.row.rate.bill_rate" /> / hr
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Partner Rate"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="128">
          <Currency :value="props.row.rate.rate" /> / hr
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Margin"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="128">
          <Margin :revenue="props.row.rate.bill_rate" :cogs="props.row.rate.rate" />
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Status"
          header-class="is-size-8"
          cell-class="nowrap"
          centered
          width="96">
          <StatusPresenter :seat="props.row" />
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="nowrap"
          width="96"
          numeric>
          <OptionsMenu
            :projectId="project.id"
            :seat="props.row"
            @seat:view-rates="showSeatRates"
            @seat:change-rate="changeSeatRate"
            @seat:activate="activateSeat"
            @seat:pause="pauseSeat"
            @seat:restart="restartSeat"
            @seat:archive="archiveSeat"
          />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered is-size-7 pa-md">
            <p class="has-text-grey-light">This project doesn't have any team members yet.</p>
            <!-- <p><a href="#" @click.prevent="showCreateOrderModal = true">Add PO</a></p> -->
          </div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Workflow -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatWorkflowModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UpdateSeatStatusModal :project="project" :seat="focusSeat" :action="focusAction" @seat:saved="$emit('staff:saved')" />
    </b-modal>
    <!-- Modal: View Seat Rate History -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatRateHistoryModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><SeatRateHistory :project="project" :seat="focusSeat" />
    </b-modal>
    <!-- Modal: Change Seat Rate -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatRateChangeModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><SeatRateChangeModal :project="project" :seat="focusSeat" @seat:saved="$emit('staff:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OptionsMenu from '@/components/Models/Seat/Options'
import StatusPresenter from '@/components/Models/Seat/Presenters/Status'
import SeatRateHistory from '@/components/Models/Seat/Modals/RateHistory'
import SeatRateChangeModal from '@/components/Models/Seat/Modals/RateChange'
import UpdateSeatStatusModal from '@/components/Models/Seat/Modals/UpdateStatus'

export default {
  components: {
    OptionsMenu,
    StatusPresenter,
    UpdateSeatStatusModal,
    SeatRateChangeModal,
    SeatRateHistory
  },
  computed: {
    ...mapGetters(['context']),
    seats () {
      const seats = []
      this.project.positions.forEach(position => {
        const basic = {
          position: {
            role: position.role
          }
        }
        position.seats.forEach(seat => {
          seats.push({
            ...seat,
            ...basic
          })
        })
      })
      return seats
    }
  },
  data () {
    return {
      focusSeat: null,
      focusAction: null,
      showSeatWorkflowModal: false,
      showSeatRateHistoryModal: false,
      showSeatRateChangeModal: false
    }
  },
  methods: {
    activateSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'activate'
      this.showSeatWorkflowModal = true
    },
    pauseSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'pause'
      this.showSeatWorkflowModal = true
    },
    restartSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'restart'
      this.showSeatWorkflowModal = true
    },
    archiveSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'archive'
      this.showSeatWorkflowModal = true
    },
    showSeatRates (seat) {
      this.focusSeat = seat
      this.showSeatRateHistoryModal = true
    },
    changeSeatRate (seat) {
      this.focusSeat = seat
      this.showSeatRateChangeModal = true
    },
    reset () {
      this.focusSeat = null
      this.showSeatWorkflowModal = false
      this.showSeatRateHistoryModal = false
      this.showSeatRateChangeModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
