<template>
  <p v-html="presenter.action" />
</template>

<script>
export default {
  computed: {
    presenter () {
      const actor = '<span class="has-text-weight-bold has-text-primary">' + this.activity.actor.short_name + '</span>'
      return {
        action: this.activity.action.replace('::actor::', actor)
      }
    }
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  }
}
</script>
