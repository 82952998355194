<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ (seat === null ? 'Add' : 'Edit' ) }} Margin Seat</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loadingRoles">
          <!-- Role & Title -->
          <div class="columns">
            <div class="column">
              <b-field
                label="Role"
                :type="('role_id' in errors) ? 'is-danger' : ''"
                :message="('role_id' in errors) ? errors['role_id'][0] : ''">
                <b-select
                  size="is-medium"
                  v-model="roleId"
                  placeholder="Select a teammate..."
                  expanded>
                  <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.label }}</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div v-else>
          <Loading message="Loading..." />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return this.roleId
    },
    method () {
      return (this.seat) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.seat)
        ? this.api.route + '/projects/' + this.project.id + '/margin-seats/' + this.seat.id
        : this.api.route + '/projects/' + this.project.id + '/margin-seats'
    },
    json () {
      return {
        role_id: this.roleId
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$emit('seat:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    loadRoles () {
      const endpoint = this.api.route + '/roles/leadership'
      this.loadingRoles = true
      this.$http.get(endpoint).then(response => {
        this.roles = response.data
      }).finally(() => {
        this.loadingRoles = false
      })
    }
  },
  data () {
    return {
      loadingRoles: true,
      roles: [],
      saving: false,
      errors: {},
      roleId: null,
      marginRate: null
    }
  },
  mounted () {
    setTimeout(() => {
      this.loadRoles()
      if (this.seat !== null) {
        this.role = this.seat.role.id
        this.marginRate = this.seat.margin_rate
      }
    }, 250)
  },
  props: {
    project: {
      type: Object
    },
    seat: {
      type: Object,
      default: null
    }
  },
  watch: {

  }
}
</script>
