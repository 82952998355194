<template>
  <div>
    <div>
      <!-- Header -->
      <div class="">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <a href="#" @click.prevent="isExpanded = !isExpanded"><b-icon :icon="isExpanded ? 'menu-down' : 'menu-right'"></b-icon></a>
          </div>
          <div class="column">
            <div>
              <p class="has-text-weight-bold">
                <a href="#" @click.prevent="isExpanded = !isExpanded">{{ project.name }}</a>
              </p>
              <p class="has-text-grey-light is-size-7">{{ project.client.organization.name }}</p>
            </div>
          </div>
          <div class="column is-narrow" >
            <b-dropdown position="is-bottom-left" >
              <template #trigger="{ active }">
                <b-button
                  :label="reviewStatus.label"
                  class="is-size-7"
                  :type="reviewStatus.class"
                  :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="updateReview('not_started')">Not Started</a>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="updateReview('pending')">In Progress</a>
              </b-dropdown-item>
              <b-dropdown-item has-link>
                <a href="#" @click.prevent="updateReview('completed')">Complete Review</a>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="column is-narrow">
            <a href="#" @click.prevent="openProject(project.id)"><b-icon icon="open-in-new" size="is-small"></b-icon></a>
          </div>
        </div>
      </div>
      <!-- Lines -->
      <div v-if="isExpanded" class="mt-md border-t">
        <div v-for="position in project.positions" :key="position.id" class="mt-sm">
          <BudgetApprovals
            :project="project"
            :line="position"
            :period="period"
            @create:timesheet="createTimesheet"
            @inspect:timesheet="inspectTimesheet"
            @project:saved="$emit('project:saved')"
          />
        </div>
      </div>
    </div>
    <!-- Modal: Bill Timesheets -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showBillTimesheetsModal"
      :can-cancel="['x', 'esc']"
      ><BillTimesheetsModal
        :period="period"
        :project="project"
        @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Inspect/Adjust -->
    <b-modal
      v-if="focusTimesheet !== null"
      has-modal-card
      scroll="keep"
      :active.sync="showInspectTimesheetModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><InspectTimesheet
        :period="period"
        :project="project"
        :timesheetId="focusTimesheet.id"
        @timesheet:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Create Timesheet -->
    <b-modal
      v-if="focusSeat !== null"
      has-modal-card
      scroll="keep"
      :active.sync="showCreateTimesheetModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><CreateTimesheet
        :period="period"
        :project="project"
        :seat="focusSeat"
        @timesheet:saved="$emit('project:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BudgetApprovals from './ProjectLine'
import BillTimesheetsModal from './BillTimesheetsModal'
import InspectTimesheet from '@/components/Models/Timesheet/Modals/Inspect'
import CreateTimesheet from '@/components/Models/Timesheet/Modals/Create'

export default {
  components: {
    BillTimesheetsModal,
    BudgetApprovals,
    CreateTimesheet,
    InspectTimesheet
  },
  computed: {
    ...mapGetters(['api', 'context']),
    reviewStatus () {
      const presenter = {
        label: 'Not Started',
        class: 'is-light'
      }
      if (this.project.reviews.length) {
        switch (this.project.reviews[0].status) {
          case 'pending':
            presenter.label = 'In Progress'
            presenter.class = 'is-warning'
            break
          case 'completed':
            presenter.label = 'Completed'
            presenter.class = 'is-success'
            break
        }
      }
      return presenter
    },
    presenter () {
      const presenter = {
        billableTotal: 0,
        cost: 0
      }
      this.project.positions.forEach(position => {
        // time and materials
        if (position.billing_model === 'time') {
          position.seats.forEach(seat => {
            if (seat.timesheets.length === 1) {
              presenter.billableTotal += Number(seat.timesheets[0].billable_total)
              presenter.cost += Number(seat.timesheets[0].cost)
            }
          })
        // deliveries
        } else if (position.billing_model === 'deliverables') {
          position.deliveries.forEach(delivery => {
            presenter.billableTotal += Number(delivery.billable_total)
            presenter.cost += Number(delivery.cost)
          })
        // expenses
        } else if (position.billing_model === 'expenses') {
          position.expenses.forEach(expense => {
            presenter.billableTotal += Number(expense.billable_total)
            presenter.cost += Number(expense.cost)
          })
        }
      })
      return presenter
    }
  },
  data () {
    return {
      isExpanded: this.expanded,
      selected: [],
      focusSeat: null,
      focusTimesheet: null,
      focusDelivery: null,
      focusExpense: null,
      showCreateTimesheetModal: false,
      showInspectTimesheetModal: false,
      showInspectDeliveryModal: false,
      showInspectExpenseModal: false,
      showBillTimesheetsModal: false
    }
  },
  methods: {
    openProject (id) {
      const route = this.$router.resolve({ name: 'network.project.approve', params: { code: this.context.code, id: id } })
      window.open(route.href, '_blank')
    },
    createTimesheet (seat) {
      this.focusSeat = seat
      this.showCreateTimesheetModal = true
    },
    inspectDelivery (delivery) {
      this.focusDelivery = delivery
      this.showInspectDeliveryModal = true
    },
    inspectTimesheet (timesheet) {
      this.focusTimesheet = timesheet
      this.showInspectTimesheetModal = true
    },
    inspectExpense (expense) {
      this.focusExpense = expense
      this.showInspectExpenseModal = true
    },
    updateReview (status) {
      const endpoint = this.api.route + '/projects/' + this.project.id + '/reviews'
      const body = {
        billing_period_id: this.period.id,
        status: status
      }
      this.$http.post(endpoint, body).then(response => {
        this.$emit('project:saved')
      })
    },
    reset () {
      this.focusSeat = null
      this.focusTimesheet = null
      this.focusDelivery = null
      this.focusExpense = null
      this.showCreateTimesheetModal = false
      this.showInspectDeliveryModal = false
      this.showInspectExpenseModal = false
      this.showInspectTimesheetModal = false
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    showProgressBar: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    }
  }
}
</script>
