<template>
  <span>{{ output }} </span>
</template>

<script>
export default {
  computed: {
    output () {
      return (this.hours) ? this.$numbers(this.hours).format({ mantissa: 1 }) : '--'
    }
  },
  props: ['hours']
}
</script>
