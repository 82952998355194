<template>
  <div>
    <form @submit.prevent="save">
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <!-- Back Link -->
        <div class="border-b pb-md mb-lg">
          <div class="columns">
            <div class="column">
              <div v-if="!loading">
                <p class="has-text-primary has-text-weight-bold is-size-5">
                  <span>{{ position.role }}</span>
                </p>
                <p class="has-text-weight-bold">
                  Role Planner &bull;
                  <span class="has-text-grey-light">
                    {{ $dates(position.project.start_date).format('MMM D, YYYY') }} -
                    {{ $dates(position.project.end_date).format('MMM D, YYYY') }}
                  </span>
                </p>
              </div>
              <div v-else>
                <b-skeleton height="20" width="50%" />
                <b-skeleton height="20" width="25%" />
              </div>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <p><router-link :to="{ name: 'network.project.detail', params: { code: context.code }}">&lt; Back to Project Detail</router-link></p>
                <p class="has-text-grey-light has-text-weight-normal is-size-7">
                  <span v-if="!loading">Role ID: {{ position.id }}</span>
                  <span v-else>Loading...</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Position Details -->
        <div v-if="!loading">
         <div class="columns">
            <div class="column">
              <!-- Toolbar -->
              <div class="border-b pb-md">
                <div class="columns">
                  <!-- <div class="column">
                    <b-radio v-model="planBy" native-value="hours">Plan by hours</b-radio>
                  </div> -->
                  <div class="column">
                    <b-checkbox v-model="showClosedPlans">Show Closed Periods</b-checkbox>
                  </div>
                </div>
              </div>
              <!-- Closed Table -->
              <div v-if="closedPlans.length && showClosedPlans">
                <b-table
                  :data="closedPlans"
                  :mobile-cards="false"
                  hoverable
                  class="is-size-7 border-b">
                  <b-table-column
                    label="Period"
                    cell-class="nowrap"
                    v-slot="props">
                    <p class="has-text-weight-bold">
                      {{ $dates(props.row.period.start_date).format('MMM YYYY') }}
                      <span class="is-size-8 is-uppercase"><PeriodStatus :period="props.row.period" /></span>
                    </p>
                    <p class="is-size-8 has-text-grey-light">
                      {{ $dates(props.row.period.start_date).format('MMM D') }}
                      -
                      {{ $dates(props.row.period.end_date).format('MMM D') }}
                    </p>
                    <div class="is-size-8 has-text-grey-light">
                      <p>Total Days: {{ props.row.period.days_total }}, Business Days: {{ props.row.period.days_business }}, Holidays: {{ props.row.period.days_holidays }}</p>
                    </div>
                  </b-table-column>
                  <b-table-column
                    label="Planned Hours"
                    header-class="nowrap"
                    cell-class="nowrap"
                    width="128"
                    centered
                    v-slot="props">
                    <Hours :hours="props.row.plan.hours_planned" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Billed Hours"
                    header-class="nowrap"
                    cell-class="nowrap"
                    width="128"
                    centered
                    v-slot="props">
                    <Hours :hours="props.row.plan.hours_billed" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Billed Budget"
                    header-class="nowrap"
                    width="132"
                    centered
                    v-slot="props">
                    <Currency :value="props.row.plan.budget_billed" zeroValue="dash" />
                  </b-table-column>
                </b-table>
              </div>
              <!-- Table -->
              <b-table
                :data="openPlans"
                :mobile-cards="false"
                hoverable
                class="is-size-7 border-b">
                <b-table-column
                  label="Period"
                  header-class="nowrap"
                  cell-class="nowrap"
                  v-slot="props">
                  <p class="has-text-weight-bold">
                    {{ $dates(props.row.period.start_date).format('MMM YYYY') }}
                    <span class="is-size-8 is-uppercase"><PeriodStatus :period="props.row.period" /></span>
                  </p>
                  <p class="is-size-8 has-text-grey-light">
                    {{ $dates(props.row.period.start_date).format('MMM D') }}
                    -
                    {{ $dates(props.row.period.end_date).format('MMM D') }}
                    ({{ props.row.period.days_total }} Days)
                  </p>
                  <div class="is-size-8 has-text-grey-light">
                    <p>{{ props.row.period.days_business }} Business Days, {{ props.row.period.days_holidays }} Holidays</p>
                  </div>
                </b-table-column>
                <b-table-column
                  label="Planned Hours"
                  header-class="is-size-7"
                  cell-class="nowrap"
                  width="128"
                  centered
                  v-slot="props">
                  <b-input
                    type="number"
                    v-model="openPlans[props.index].plan.hours_planned"
                    expanded
                    :disabled="props.row.plan !== null && props.row.plan.closed_at"
                  ></b-input>
                </b-table-column>
                <b-table-column
                  label="Planned Budget"
                  header-class="is-size-7"
                  width="132"
                  centered
                  v-slot="props">
                  <Currency :value="openPlans[props.index].plan.hours_planned * position.client_rate.rate" zeroValue="dash" />
                </b-table-column>
              </b-table>
              <!-- Totals -->
              <div class="pa-md">
                <div class="columns">
                  <div class="column">
                    <p class="is-uppercase has-text-weight-bold">Totals</p>
                  </div>
                  <div class="column is-2">
                    <p class="has-text-weight-bold">
                      <Hours :hours="openSummary.hours" />
                    </p>
                  </div>
                  <div class="column is-2">
                    <p class="has-text-weight-bold">
                      <Currency :value="openSummary.budget" :temperatureReference="remainingBudget" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Summmary -->
            <div class="column is-one-third">
              <p class="has-text-primary has-text-weight-bold">Plan Summary</p>
              <table class="table is-fullwidth is-narrow is-size-7">
                <tbody>
                  <tr>
                    <th>Plan Rate</th>
                    <td class="has-text-right"><Currency :value="position.client_rate.rate" zeroValue="dash" /> / hour</td>
                  </tr>
                  <tr>
                    <th>Target Budget</th>
                    <td class="has-text-right"><Currency :value="position.client_target_budget" zeroValue="dash" /></td>
                  </tr>
                  <tr>
                    <th>Target Hours</th>
                    <td class="has-text-right"><Hours :hours="position.client_target_hours" /></td>
                  </tr>
                  <tr>
                    <th>Completed Budget <span class="has-text-grey-light has-text-weight-normal">(to date)</span></th>
                    <td class="has-text-right"><Currency :value="closedSummary.budget_billed" /></td>
                  </tr>
                  <tr>
                    <th>Completed Hours <span class="has-text-grey-light has-text-weight-normal">(to date)</span></th>
                    <td class="has-text-right"><Hours :hours="closedSummary.hours_billed" /></td>
                  </tr>
                  <tr>
                  <tr v-if="position.client_target_budget">
                    <th>Remaining Budget</th>
                    <td class="has-text-right"><Currency :value="remainingBudget" /></td>
                  </tr>
                  <tr v-if="position.client_target_budget">
                    <th>Remaining Hours</th>
                    <td class="has-text-right"><Hours :hours="remainingHours" /></td>
                  </tr>
                  <tr>
                    <th>Planned Budget</th>
                    <td class="has-text-right"><Currency :value="openSummary.budget" /></td>
                  </tr>
                  <tr>
                    <th>Planned Hours</th>
                    <td class="has-text-right"><Hours :hours="openSummary.hours" /></td>
                  </tr>
                  <tr v-if="position.client_target_budget">
                    <th>Remaining vs Planned Hours</th>
                    <td class="has-text-right"><Hours :hours="-(remainingHours - openSummary.hours)" /></td>
                  </tr>
                </tbody>
              </table>
              <div v-if="position.client_target_budget" class="has-background-light rounded-lg pa-md">
                <p class="has-text-weight-bold is-size-6 mb-sm">Allocate</p>
                <div class="is-size-7">
                  <p class="mb-sm">
                    There are <span class="has-text-weight-bold">{{ remainingHours }}</span> hours remaining
                    for this role over <span class="has-text-weight-bold">{{ remainingBusinessDays }}</span>
                    business days.
                  </p>
                  <p class="mb-md">
                    If you'd like to evenly allocate (based on business days) remaining hours over
                    the <span class="has-text-success has-text-weight-bold is-uppercase">open</span>
                    billing periods, click the button below:
                  </p>
                </div>
                <p>
                  <b-button
                    type="is-primary"
                    outlined
                    expanded
                    @click="allocateRemainingBudget"
                  >Allocate</b-button>
                </p>
              </div>
            </div>
         </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
        >Save &amp; Return to Project</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PeriodStatus from '@/components/Models/BillingPeriod/Presenters/Status'

export default {
  components: {
    PeriodStatus
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingPosition || this.loadingBillingPeriods)
    },
    closedSummary () {
      const summary = {
        hours_billed: 0,
        budget_billed: 0
      }
      this.closedPlans.forEach(plan => {
        summary.hours_billed += parseFloat(plan.plan.hours_billed)
        summary.budget_billed += parseFloat(plan.plan.budget_billed)
      })
      return summary
    },
    openSummary () {
      const summary = {
        hours: 0,
        budget: 0
      }
      this.openPlans.forEach(plan => {
        if (plan.plan.hours_planned) {
          summary.hours += parseFloat(plan.plan.hours_planned)
          summary.budget += parseFloat(plan.plan.hours_planned) * parseFloat(this.position.client_rate.rate)
        }
      })
      return summary
    },
    remainingHours () {
      const recent = this.$utils.findLast(this.closedPlans, plan => {
        return plan.plan.closed_at !== null
      })
      return (typeof recent !== 'undefined') ? this.position.client_target_hours - recent.plan.hours_at_close : this.position.client_target_hours
    },
    remainingBudget () {
      const recent = this.$utils.findLast(this.closedPlans, plan => {
        return plan.plan.closed_at !== null
      })
      return (typeof recent !== 'undefined') ? this.position.client_target_budget - recent.plan.budget_at_close : this.position.client_target_budget
    },
    remainingBusinessDays () {
      return this.$utils.sumBy(this.openPlans, plan => {
        return (plan.plan.closed_at === null) ? plan.period.days_business : 0
      })
    },
    json () {
      const plans = []
      this.openPlans.forEach(plan => {
        plans.push({
          billing_period_id: plan.period.id,
          hours_planned: (plan.plan.hours_planned) ? plan.plan.hours_planned : 0
        })
      })
      const json = {
        plans: plans
      }
      return json
    }
  },
  data () {
    return {
      loadingPosition: true,
      loadingBillingPeriods: true,
      position: null,
      periods: [],
      openPlans: [],
      closedPlans: [],
      planBy: 'hours',
      saving: false,
      errors: {},
      targetBudget: null,
      showClosedPlans: false
    }
  },
  methods: {
    loadPosition (refresh) {
      if (!refresh) this.loadingPosition = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.$route.params.position
      this.$http.get(endpoint).then(response => {
        this.position = response.data
        // merge positio
        if (this.position.plans.length) {
          this.position.plans.forEach(plan => {
            if (plan.closed_at == null) {
              const index = this.openPlans.findIndex(element => element.period.id === plan.billing_period_id)
              if (index !== -1) {
                this.openPlans[index].plan = plan
              }
            } else {
              const index = this.closedPlans.findIndex(element => element.period.id === plan.billing_period_id)
              if (index !== -1) {
                this.closedPlans[index].plan = plan
              }
            }
          })
        }
        this.loadingPosition = false
      })
    },
    loadBillingPeriods (refresh) {
      if (!refresh) this.loadingBillingPeriods = true
      const endpoint = this.api.base + 'billing-periods'
      const params = {
        params: {
          start: this.project.start_date,
          end: this.project.end_date
        }
      }
      this.$http.get(endpoint, params).then(response => {
        response.data.forEach(period => {
          if (period.closed_at === null) {
            this.openPlans.push({
              period: period,
              plan: {
                hours_planned: null,
                budget_planned: null,
                hours_at_close: null,
                closed_at: null
              }
            })
          } else {
            this.closedPlans.push({
              period: period,
              plan: {
                hours_planned: 0,
                hours_billed: 0,
                budget_billed: 0,
                hours_at_close: 0,
                budget_at_close: 0,
                closed_at: null
              }
            })
          }
        })
        this.loadingBillingPeriods = false
        this.loadPosition()
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id + '/plans'
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$router.push({ name: 'network.project.detail', params: { code: this.context.code, id: this.project.id } })
      })
    },
    allocateRemainingBudget () {
      // calculate remaining hours
      const remainingHours = parseFloat(this.remainingHours)

      // loop through each plan. if period is closed, skip it in order to summarize the business days remaining
      const businessDays = this.$utils.sumBy(this.openPlans, plan => {
        return plan.period.days_business
      })

      // calculate average daily hours
      const dailyHours = remainingHours / businessDays

      // calculate the forecasted hours for each open month
      this.openPlans.forEach(plan => {
        plan.plan.hours_planned = Math.round((dailyHours * plan.period.days_business * 2) / 2)
        plan.plan.budget_planned = plan.plan.hours_planned * this.position.client_rate.rate
      })

      // if we're not right on the money with hours, re-cast the final forecast
      const totalPlannedHours = this.$utils.sumBy(this.openPlans, plan => {
        return plan.plan.hours_planned
      })
      const remainderHours = remainingHours - totalPlannedHours
      if (remainderHours !== 0) {
        const index = this.openPlans.length - 1
        this.openPlans[index].plan.hours_planned = this.openPlans[index].plan.hours_planned + remainderHours
        this.openPlans[index].plan.budget_planned = this.openPlans[index].plan.budget_planned + (remainderHours * this.position.client_rate.rate)
      }
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
