<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns">
        <!-- Actions -->
        <div class="column is-one-quarter" v-for="(action, index) in actions" :key="index">
          <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-light" expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
    <!-- Has No Actions -->
    <div v-else>
      <div class="columns">
        <div class="column is-one-quarter"><b-button :disabled="true" size="is-medium" expanded >No Actions</b-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context']),
    hasActions () {
      return (this.actions.length > 0 || this.additionalActions.length > 0)
    },
    actions () {
      const actions = []
      // if (this.order.approved_at === null) {
      //   actions.push({ label: 'Approve Funding', event: 'action:approve', type: 'secondary' })
      // }
      if (this.order.started_at === null) {
        actions.push({ label: 'Activate Funding', event: 'action:activate', type: 'secondary' })
      }
      if (this.order.started_at !== null && this.order.ended_at === null && this.order.paused_at === null) {
        actions.push({ label: 'Pause Funding', event: 'action:pause', type: 'secondary' })
      }
      if (this.order.started_at !== null && this.order.paused_at !== null) {
        actions.push({ label: 'Resume Funding', event: 'action:resume', type: 'secondary' })
      }
      if (this.order.started_at !== null && this.order.ended_at === null) {
        actions.push({ label: 'End Funding', event: 'action:end', type: 'secondary' })
      }
      return actions
    },
    additionalActions () {
      return []
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
