import dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isoWeek from 'dayjs/plugin/isoWeek'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(dayjsPluginUTC)
dayjs.extend(isoWeek)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(advancedFormat)

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$dates', { value: dayjs })
  }
}
