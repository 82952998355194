<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg pb-xl">
            <div class="mb-md">
              <!-- Content Loaded -->
              <div v-if="!loading && network !== null">
                <!-- Name -->
                <div class="mb-lg">
                  <div class="columns">
                    <div class="column is-half">
                      <b-field
                        label="Network Name"
                        :type="('name' in errors) ? 'is-danger' : ''"
                        :message="('name' in errors) ? errors['name'][0] : ''"
                        expanded>
                          <b-input
                            autocomplete="off"
                            type="text"
                            v-model="network.name"
                            @input="isDirty=1"
                          ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
                <!-- Billing Contact -->
                <div class="mb-lg">
                  <div class="columns">
                    <div class="column is-half">
                      <b-field
                        label="Billing Contact Name"
                        :type="('contact' in errors) ? 'is-danger' : ''"
                        :message="('contact' in errors) ? errors['contact'][0] : ''"
                        expanded>
                          <b-input
                            autocomplete="off"
                            type="text"
                            name="contact"
                            v-model="network.billing_contact"
                            @input="isDirty = 1"
                          ></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field
                        label="Billing Contact Email"
                        :type="('email' in errors) ? 'is-danger' : ''"
                        :message="('email' in errors) ? errors['email'][0] : ''"
                        expanded>
                          <b-input
                            autocomplete="off"
                            type="email"
                            name="email"
                            v-model="network.billing_email"
                            @input="isDirty = 1"
                          ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
                <!-- Billing Address -->
                <div class="mb-lg">
                  <b-field
                    label="Billing Address"
                    :type="('street1' in errors) ? 'is-danger' : ''"
                    :message="('street1' in errors) ? errors['street1'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="street1"
                        v-model="network.billing_street1"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                  <b-field
                    :type="('street2' in errors) ? 'is-danger' : ''"
                    :message="('street2' in errors) ? errors['street2'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="street2"
                        v-model="network.billing_street2"
                        placeholder="Suite, etc (optional)"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                  <b-field
                    :type="('street3' in errors) ? 'is-danger' : ''"
                    :message="('street3' in errors) ? errors['street2'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="street2"
                        v-model="network.billing_street3"
                        placeholder="Attn: (optional)"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
                <!-- City -->
                <div class="mb-md">
                  <b-field
                    label="City"
                    :type="('city' in errors) ? 'is-danger' : ''"
                    :message="('city' in errors) ? errors['city'][0] : ''"
                    expanded>
                      <b-input
                        autocomplete="off"
                        type="text"
                        name="city"
                        v-model="network.billing_city"
                        @input="isDirty = 1"
                      ></b-input>
                  </b-field>
                </div>
                <!-- Country, Province, Zip -->
                <div class="columns">
                  <div class="column is-one-third">
                    <b-field
                      label="Country"
                      :type="('country' in errors) ? 'is-danger' : ''"
                      :message="('country' in errors) ? errors['country'][0] : ''"
                        expanded>
                        <b-select
                          v-model="network.billing_country_code"
                          name="country"
                          placeholder="Select Country"
                          @input="isDirty = 1"
                          expanded>
                          <option value="US">United States</option>
                          <option value="CA">Canada</option>
                          <option value="UK">United Kingdom</option>
                        </b-select>
                    </b-field>
                  </div>
                  <div class="column is-one-third">
                    <b-field
                      label="State / Province"
                      :type="('province' in errors) ? 'is-danger' : ''"
                      :message="('province' in errors) ? errors['province'][0] : ''"
                      expanded>
                        <b-input
                          maxlength="2"
                          autocomplete="off"
                          type="text"
                          name="province"
                          v-model="network.billing_province"
                          @input="isDirty = 1"
                        ></b-input>
                    </b-field>
                  </div>
                  <div class="column is-one-third">
                    <b-field
                      label="Postal Code"
                      :type="('postal_code' in errors) ? 'is-danger' : ''"
                      :message="('postal_code' in errors) ? errors['postal_code'][0] : ''"
                      expanded>
                        <b-input
                          maxlength="12"
                          autocomplete="off"
                          type="text"
                          name="postalCode"
                          v-model="network.billing_postal_code"
                          @input="isDirty = 1"
                        ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
              <!-- Loading Skeleton -->
              <div v-else>
                <div class="mb-lg">
                  <b-skeleton width="20%" height="10" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                </div>
                <div class="mb-lg">
                  <b-skeleton width="20%" height="10" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                </div>
                <div class="mb-lg">
                  <b-skeleton width="20%" height="10" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                </div>
                <div class="mb-lg">
                  <b-skeleton width="20%" height="10" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                  <b-skeleton width="80%" height="30" :animated="true"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="saving || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.route
    },
    json () {
      return {
        name: this.network.name,
        identifier: this.network.identifier,
        billing_contact: this.network.billing_contact,
        billing_email: this.network.billing_email,
        billing_street1: this.network.billing_street1,
        billing_street2: this.network.billing_street2,
        billing_street3: this.network.billing_street3,
        billing_city: this.network.billing_city,
        billing_province: this.network.billing_province,
        billing_postal_code: this.network.billing_postal_code,
        billing_country_code: this.network.billing_country_code
      }
    }
  },
  data () {
    return {
      isDirty: false,
      loading: true,
      saving: false,
      network: null,
      errors: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.network = response.data
        this.loading = false
      })
    },
    update () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.network = response.data
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Network updated!' })
        this.isDirty = false
        this.errors = []
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'There were problems with your input. Please review and try again.' })
        this.saving = false
        this.isDirty = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
