<template>
  <div>
    <GeneralInfo :period="period" />
  </div>
</template>

<script>
import GeneralInfo from '../components/Summary/Info'
export default {
  components: {
    GeneralInfo
  },
  props: ['period']
}
</script>
