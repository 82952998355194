<template>
  <div>
    <div>
      <!-- Actions -->
      <div class="has-background-white has-shadow pa-md mb-xxs">
        <Workflow :connection="connection"
          approve:connection="showApprovalModal = true"
          @suspend:connection="showSuspensionModal = true"
          @activate:connection="showActivationModal = true"
        />
      </div>
      <!-- Profile -->
      <div class="has-background-white has-shadow px-lg py-md mb-lg">
        <div class="columns is-multiline">
          <!-- General -->
          <div class="column is-half">
            <div class="has-border-b pb-sm">
              <div class="columns">
                <div class="column">
                  <p class="has-text-weight-bold">General Information</p>
                </div>
                <div class="column">
                  <p class="has-text-right">
                    <a href="#" @click.prevent="showEditModal = true" class="has-text-weight-bold">Edit</a>
                  </p>
                </div>
              </div>
            </div>
            <table class="table is-narrow is-size-7 is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>ID</td>
                  <td class="has-text-right">{{ connection.id }}</td>
                </tr>
                <tr>
                  <td>Accounting ID</td>
                  <td class="has-text-right">
                    <span v-if="connection.network_identifier !== null">{{ connection.network_identifier }}</span>
                    <span v-else class="has-text-grey-light is-italic">--</span>
                  </td>
                </tr>
                <tr>
                  <td>Approved At</td>
                  <td class="has-text-right">
                    <span v-if="connection.approved_at !== null">{{ $dates.utc(connection.approved_at).format('MMM D, YYYY') }}</span>
                    <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                  </td>
                </tr>
                <tr v-if="connection.suspended_at !== null">
                  <td>Suspended On</td>
                  <td class="has-text-right">
                    {{ $dates.utc(connection.suspended_at).format('MMM D, YYYY') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Contact & Billing -->
          <div class="column is-half">
            <h1 class="has-text-weight-bold has-border-b pb-sm">Billing</h1>
            <table class="table is-narrow is-size-7 is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>Contact</td>
                  <td class="has-text-right">{{ connection.partner.billing_contact }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td class="has-text-right">{{ connection.partner.billing_email }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td class="has-text-right">
                    <p>{{ connection.partner.billing_street1 }}</p>
                    <p v-if="connection.partner.billing_street2">
                      {{ connection.partner.billing_street2 }}
                    </p>
                    <p v-if="connection.partner.billing_street3">
                      {{ connection.partner.billing_street3 }}
                    </p>
                    <p>
                      {{ connection.partner.billing_city }}
                      {{ connection.partner.billing_province }}
                      {{ connection.partner.billing_postal_code }}
                      {{ connection.partner.billing_country_code }}
                    </p>
                    <p></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Attachments -->
          <div class="column">
            <div class="has-border-b pb-sm">
              <div class="columns is-vcentered">
                <div class="column">
                  <h1 class="has-text-weight-bold">Attachments</h1>
                </div>
                <div class="column is-narrow">
                  <b-button type="is-primary" size="is-small" outlined @click="showUploadAttachmentModal = true">Upload Attachment</b-button>
                </div>
              </div>
            </div>
            <Attachments :connection="connection" @attachment:removed="$emit('connection:updated')"/>
          </div>
        </div>
      </div>
      <!-- Memos -->
      <div class="has-background-white has-shadow px-lg py-md mb-lg">
        <Memos :connection="connection" />
      </div>
    </div>
    <!-- Modal: Edit Partner -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditModal"
      :can-cancel="['x', 'esc']"
      ><EditConnectionModal :connection="connection" @connection:saved="$emit('connection:updated')" />
    </b-modal>
    <!-- Modal: Approve Partner -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showApprovalModal"
      :can-cancel="['x', 'esc']"
      ><ApproveConnectionModal :connection="connection" @connection:approved="$emit('connection:updated')" />
    </b-modal>
    <!-- Modal: Suspend Partner -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showSuspensionModal"
      :can-cancel="['x', 'esc']"
      ><SuspendConnectionModal :connection="connection" @connection:suspended="$emit('connection:updated')" />
    </b-modal>
    <!-- Modal: Activate Partner -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showActivationModal"
      :can-cancel="['x', 'esc']"
      ><ActivateConnectionModal :connection="connection" @connection:activated="$emit('connection:updated')" />
    </b-modal>
    <!-- Modal: Add Attachment -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUploadAttachmentModal"
      :can-cancel="['x', 'esc']"
      ><UploadConnectionAttachmentModal :connection="connection" @attachment:uploaded="$emit('connection:updated')" />
    </b-modal>
  </div>
</template>

<script>
import Attachments from '../components/Detail/Attachments'
import Memos from '../components/Summary/Memos'
import Workflow from '../components/Summary/Workflow'
import EditConnectionModal from '@/components/Models/Connection/Modals/Edit'
import ActivateConnectionModal from '@/components/Models/Connection/ActivateConnectionModal'
import ApproveConnectionModal from '@/components/Models/Connection/ApproveConnectionModal'
import SuspendConnectionModal from '@/components/Models/Connection/SuspendConnectionModal'
import UploadConnectionAttachmentModal from '@/components/Models/ConnectionAttachment/UploadConnectionAttachment'

export default {
  components: {
    Attachments,
    Memos,
    Workflow,
    EditConnectionModal,
    ActivateConnectionModal,
    ApproveConnectionModal,
    UploadConnectionAttachmentModal,
    SuspendConnectionModal
  },
  data () {
    return {
      showApprovalModal: false,
      showSuspensionModal: false,
      showActivationModal: false,
      showUploadAttachmentModal: false,
      showEditModal: false
    }
  },
  props: ['connection']
}
</script>
