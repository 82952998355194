<template>
  <div>
    <!-- Has Actions -->
    <div v-if="hasActions">
      <div class="columns">
        <!-- Actions -->
        <div class="column is-one-quarter" v-for="(action, index) in actions" :key="index">
          <b-button v-if="action.type === 'primary'" type="is-primary" outlined expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
          <b-button v-else type="is-light" expanded size="is-medium" @click="$emit(action.event)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
    <!-- Has No Actions -->
    <div v-else>
      <div class="columns">
        <div class="column is-one-quarter"><b-button :disabled="true" size="is-medium" expanded >No Actions</b-button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context', 'role']),
    hasActions () {
      return (this.actions.length > 0 || this.additionalActions.length > 0)
    },
    actions () {
      const actions = []
      // projects that have not started can be activted
      if (this.project.started_at === null) {
        actions.push({
          id: 'start-project',
          type: 'primary',
          label: 'Start Project',
          event: 'start:project'
        })
      }
      // projects that have started, but are not ended or paused can be paused
      if (this.project.started_at !== null && this.project.paused_at === null && this.project.ended_at === null) {
        actions.push({
          id: 'pause-project',
          type: 'secondary',
          label: 'Pause Project',
          event: 'pause:project'
        })
      }
      // projects that have not started can be ended
      if (this.project.started_at !== null && this.project.ended_at === null) {
        actions.push({
          id: 'end-project',
          type: 'secondary',
          label: 'End Project',
          event: 'end:project'
        })
      }
      // projects that have not started can be ended
      if (this.project.started_at !== null && (this.project.ended_at || this.project.paused_at)) {
        actions.push({
          id: 'restart-project',
          type: 'secondary',
          label: 'Restart Project',
          event: 'restart:project'
        })
      }
      // projects can always have roles managed
      actions.push({
        id: 'manage-roles',
        type: 'secondary',
        label: 'Manage Project Roles',
        event: 'manage:roles'
      })
      return actions
    },
    additionalActions () {
      const actions = []
      return actions
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
