import Index from './views/Index'
import Settings from './views/Settings'
import General from './views/General'
import Billing from './views/Billing'
import People from './views/People'
import Integrations from './views/Integrations'
import Freshbooks from './views/Freshbooks'

export default [{
  path: '/partner/:code/settings',
  component: Index,
  children: [{
    path: 'general',
    name: 'partner.settings.general',
    component: General
  }, {
    path: 'billing',
    name: 'partner.settings.billing',
    component: Billing
  }, {
    path: 'integrations',
    name: 'partner.settings.integrations',
    component: Integrations
  }, {
    path: 'integrations/freshbooks/:id',
    name: 'partner.settings.integrations.freshbooks',
    component: Freshbooks
  }, {
    path: 'people',
    name: 'partner.settings.people',
    component: People
  }, {
    path: '',
    name: 'partner.settings',
    component: Settings
  }]
}]
