<template>
  <div>
    <div class="has-background-white has-shadow pa-md">
      <!-- Header -->
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-size-4 px-md">Overview</p>
        </div>
        <!-- <div class="column is-one-fifth">
          <b-select v-model="organization" placeholder="- All Clients -" expanded>
            <option v-for="organization in summary.organizations" :key="organization.id" :value="organization">{{ organization.name }}</option>
          </b-select>
        </div> -->
        <div class="column is-one-fifth">
          <b-select
            v-model="period"
            placeholder="- All Periods -"
            :loading="loadingBillingPeriods"
            expanded
            @input="announce">
            <option v-for="period in periods" :key="period.id" :value="period">
              {{ $dates(period.start_date).format('MMMM YYYY') }}
            </option>
          </b-select>
        </div>
      </div>
      <!-- Summary -->
      <div class="py-md">
        <div class="columns">
          <div class="column">
            <div class="has-text-centered has-border-r">
              <p class="is-size-3">{{ summary.activeSeatCount }}</p>
              <p class="has-text-grey-light is-size-7">Active Roles</p>
            </div>
          </div>
          <div v-if="deliveries.length" class="column">
            <div class="has-text-centered has-border-r">
              <p class="is-size-3">{{ deliveries.length }}</p>
              <p class="has-text-grey-light is-size-7">Active Deliverables</p>
            </div>
          </div>
          <div class="column">
            <div class="has-text-centered has-border-r">
              <p class="is-size-3"><Currency :value="summary.rate"/></p>
              <p class="has-text-grey-light is-size-7">Average Hourly Rate</p>
            </div>
          </div>
          <div class="column">
            <div class="has-text-centered has-border-r">
              <p class="is-size-3"><Hours :hours="summary.hours"/></p>
              <p class="has-text-grey-light is-size-7">Hours Tracked</p>
            </div>
          </div>
          <div class="column">
            <div class="has-text-centered">
              <p class="is-size-3"><Currency :value="summary.earnings"/></p>
              <p class="has-text-grey-light is-size-7">Current Earnings</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'auth']),
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    },
    loading () {
      return Boolean(this.loadingBillingPeriods)
    },
    summary () {
      const summary = {
        activeSeatCount: 0,
        hours: 0,
        earnings: 0,
        rate: 0,
        organizationIds: [],
        organizations: []
      }
      this.seats.forEach(seat => {
        // count active seats first...
        if (!seat.hidden_at && !seat.held_at) {
          summary.activeSeatCount++
        }
        // build clients array
        if (!summary.organizationIds.includes(seat.position.project.client.organization_id)) {
          summary.organizationIds.push(seat.position.project.client.organization_id)
          summary.organizations.push(seat.position.project.client.organization)
        }
        // sum earnings
        if (seat.timesheets.length) {
          summary.hours += Number(seat.timesheets[0].hours_reported)
          summary.earnings += Number(seat.timesheets[0].cost)
        }
      })
      if (summary.hours > 0) {
        summary.rate = Number(summary.earnings / summary.hours)
      }
      this.deliveries.forEach(delivery => {
        if (delivery.submitted_at) {
          summary.earnings += Number(delivery.cost)
        }
      })
      return summary
    }
  },
  data () {
    return {
      loadingBillingPeriods: true,
      organization: null,
      periods: [],
      period: null
    }
  },
  methods: {
    announce () {
      if (this.period) {
        this.$emit('period:changed', this.period)
      }
    },
    loadBillingPeriods () {
      this.loadingBillingPeriods = true
      const minStart = this.$dates().subtract(36, 'month').startOf('month')
      const usrStart = this.$dates(this.auth.user.joined).startOf('month')
      const start = (minStart.isBefore(usrStart)) ? usrStart : minStart
      const endpoint = this.api.base + 'billing-periods'
      const params = {
        params: {
          start: start.format('YYYY-MM-DD'),
          end: this.$dates().add(1, 'month').startOf('month').format('YYYY-MM-DD')
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.periods = response.data
        this.periods.forEach(period => {
          if (this.$dates(period.start_date).startOf('month').format('YYYY-MM-DD') === this.currentPeriod) {
            this.period = period
            this.announce()
          }
        })
        this.loadingBillingPeriods = false
      })
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  props: {
    seats: {
      type: Array,
      required: true
    },
    deliveries: {
      type: Array,
      required: true
    }
  }
}
</script>
