<template>
  <div>
    <b-select
      v-model="organization"
      :size="size"
      placeholder="Select Organization"
      :loading="loadingOrganizations"
      @input="announce"
      expanded>
      <option v-for="organization in organizations" :key="organization.id" :value="organization">
        {{ organization.name }}
      </option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingOrganizations: false,
      organizations: [],
      organization: null
    }
  },
  methods: {
    loadOrganizations () {
      this.loadingOrganizations = true
      const endpoint = this.api.route + '/organizations?paginate=0'
      this.$http.get(endpoint).then(response => {
        this.organizations = response.data
        this.loadingOrganizations = false
      })
    },
    announce () {
      this.$emit('input', this.organization)
    }
  },
  mounted () {
    this.loadOrganizations()
  },
  props: {
    size: {
      type: String,
      default: null
    }
  }
}
</script>
