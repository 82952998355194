<template>
  <div>
    <div class="columns is-vcentered is-mobile">
      <div class="column is-narrow is-hidden-mobile">
        <div :class="authorClass" class="has-text-white rounded-full has-text-centered is-size-8" style="width:32px; height:32px; padding-top:6px">
          {{ memo.author.initials }}
        </div>
      </div>
      <div class="column">
        <p class="has-text-grey-light is-size-8">
          On {{ $dates.utc(memo.created_at).local().format('MMM D') }},
          {{ memo.author.full_name }} added...
        </p>
        <p class="mb-xs is-size-7">{{ memo.memo }}</p>
      </div>
      <div class="column is-narrow" v-if="canEdit">
        <b-dropdown position="is-bottom-left" :disabled="busy" :loading="busy">
          <template #trigger="{ active }">
            <b-button
              label="Options"
              type="is-primary"
              size="is-small"
              outlined
              :icon-right="active ? 'menu-up' : 'menu-down'" />
          </template>
          <b-dropdown-item :disabled="!canEdit" @click="edit(memo)">Edit</b-dropdown-item>
          <b-dropdown-item :disabled="!canDelete">Delete</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    busy () {
      return (this.saving || this.refreshing)
    },
    authorClass () {
      return (this.memo.created_by_context === 'network') ? 'has-background-primary' : 'has-background-success'
    },
    canEdit () {
      return this.context.type === this.memo.author_context_type
    },
    canDelete () {
      return this.context.type === this.memo.created_by_context
    }
  },
  data () {
    return {
      saving: false,
      refreshing: false
    }
  },
  methods: {
    edit (memo) {
      this.$emit('trigger:edit', memo)
    },
    refresh () {
      this.refreshing = true
      this.$http.put(this.readEndpoint).then(response => {

      })
    }
  },
  mounted () {

  },
  props: ['memo']
}
</script>
