<template>
  <b-tag :type="presenter.class" size="is-small">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.delivery.approved_at) {
        presenter.class = 'is-success is-light'
        presenter.label = 'Completed'
      } else if (this.delivery.submitted_at) {
        presenter.class = 'is-info is-light'
        presenter.label = 'In Review'
      } else {
        presenter.class = 'is-grey is-light'
        presenter.label = 'Pending'
      }
      return presenter
    }
  },
  props: {
    delivery: {
      type: Object,
      required: true
    },
    size: {
      type: [String, null],
      default: 'is-small'
    }
  }
}
</script>
