<template>
  <tr>
    <td>{{ delivery.title }}</td>
    <td class="has-text-centered" style="width:25%">
      <Currency :value="delivery.billable_total" :mantissa="0" />
    </td>
    <td class="has-text-centered" style="width:25%">
      <Currency v-if="delivery.submitted_at" :value="delivery.billable_total" :mantissa="0" />
      <span class="has-text-grey">--</span>
    </td>
  </tr>
</template>

<script>
export default {
  computed: {
    hasAllocation () {
      return this.delivery.allocations.length
    },
    warningClass () {
      return null
    }
  },
  props: {
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
