<template>
<div>
  <form @submit.prevent="update">
    <div class="columns">
      <div class="column">
        <div class="has-background-white rounded-lg">
          <div class="pa-lg pb-xl">
            <!-- Billing Address -->
            <div class="mb-lg">
              <b-field
                label="Billing Address"
                :type="('billing_street1' in errors) ? 'is-danger' : ''"
                :message="('billing_street1' in errors) ? errors['billing_street1'][0] : ''"
                expanded>
                  <b-input
                    type="text"
                    v-model="street1"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
              <b-field
                :type="('billing_street2' in errors) ? 'is-danger' : ''"
                :message="('billing_street2' in errors) ? errors['billing_street2'][0] : ''"
                expanded>
                  <b-input
                    type="text"
                    v-model="street2"
                    placeholder="Suite, etc (optional)"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
              <b-field
                :type="('billing_street3' in errors) ? 'is-danger' : ''"
                :message="('billing_street3' in errors) ? errors['billing_street3'][0] : ''"
                expanded>
                  <b-input
                    type="text"
                    v-model="street3"
                    placeholder="Attn: (optional)"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
            </div>
            <!-- City -->
            <div class="mb-md">
              <b-field
                label="City"
                :type="('city' in errors) ? 'is-danger' : ''"
                :message="('city' in errors) ? errors['city'][0] : ''">
                <b-input
                  type="text"
                  v-model="city"
                  @input="isDirty = 1"
                ></b-input>
              </b-field>
            </div>
            <!-- Country, Province, Zip -->
            <div class="columns">
              <div class="column is-one-third">
                <b-field
                  label="Country"
                  :type="('billing_country_code' in errors) ? 'is-danger' : ''"
                  :message="('billing_country_code' in errors) ? errors['billing_country_code'][0] : ''"
                    expanded>
                    <b-select
                      v-model="countryCode"
                      placeholder="Select Country"
                      @input="isDirty = 1"
                      expanded>
                      <option value="US">United States</option>
                      <option value="" disabled>--</option>
                      <option value="BR">Brazil</option>
                      <option value="CA">Canada</option>
                      <option value="ES">Spain</option>
                      <option value="NL">Netherlands</option>
                      <option value="UK">United Kingdom</option>
                      <option value="US">United States</option>
                    </b-select>
                </b-field>
              </div>
              <div class="column is-one-third">
                <b-field
                  label="State / Province"
                  :type="('province' in errors) ? 'is-danger' : ''"
                  :message="('province' in errors) ? errors['province'][0] : ''">
                  <b-input
                    maxlength="2"
                    type="text"
                    v-model="province"
                    @input="isDirty = 1"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-one-third">
                <b-field
                  label="Postal Code"
                  :type="('postal_code' in errors) ? 'is-danger' : ''"
                  :message="('postal_code' in errors) ? errors['postal_code'][0] : ''">
                    <b-input
                      maxlength="12"
                      type="text"
                      v-model="postalCode"
                      @input="isDirty = 1"
                    ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="saving || !ready">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route
    },
    body () {
      return {
        billing_street1: this.street1,
        billing_street2: this.street2,
        billing_street3: this.street3,
        billing_city: this.city,
        billing_province: this.province,
        billing_postal_code: this.postalCode,
        billing_country_code: this.countryCode,
        billing_phone: this.phone
      }
    },
    ready () {
      return (
        this.street1 &&
        this.city &&
        this.postalCode &&
        this.countryCode
      )
    }
  },
  data () {
    return {
      street1: null,
      street2: null,
      street3: null,
      city: null,
      province: null,
      postalCode: null,
      countryCode: null,
      phone: null,
      isDirty: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    update () {
      this.saving = true
      this.$http.patch(this.endpoint, this.body).then(response => {
        this.$emit('partner:updated')
        this.$store.dispatch('setContext', response.data.name)
        this.$buefy.toast.open({ type: 'is-success', message: 'Partner updated!' })
        this.isDirty = false
        this.errors = {}
      }).catch(error => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There were problems with your input. Please review and try again.' })
        this.errors = error.response.data.errors
        this.isDirty = false
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.street1 = this.partner.billing_street1
    this.street2 = this.partner.billing_street2
    this.street3 = this.partner.billing_street3
    this.city = this.partner.billing_city
    this.province = this.partner.billing_province
    this.postalCode = this.partner.billing_postal_code
    this.countryCode = this.partner.billing_country_code
    this.phone = this.partner.billing_phone
  },
  props: ['partner']
}
</script>
