<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (billingAdjustment) ? 'Add' : 'Update' }} Billing Adjustment</p>
        </header>
        <section class="modal-card-body">
          <b-field
            label="Adjustment Date"
            :type="('date' in errors) ? 'is-danger' : ''"
            :message="('date' in errors) ? errors['date'][0] : ''">
            <b-input
              v-model="adjustmentDate"
              type="date"
              size="is-medium"
              expanded />
          </b-field>
          <!-- Amount -->
          <b-field
            label="Adjustment Amount"
            :type="('amount' in errors) ? 'is-danger' : ''"
            :message="('amount' in errors) ? errors['amount'][0] : ''">
            <b-input
              v-model="amount"
              type="number"
              size="is-medium"
              expanded />
          </b-field>
          <!-- Memo -->
          <b-field
            label="Memo"
            :type="('memo' in errors) ? 'is-danger' : ''"
            :message="('memo' in errors) ? errors['memo'][0] : ''">
            <b-input
              v-model="memo"
              type="textarea"
              expanded />
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <div>
            <b-button @click="$parent.close()">Close</b-button>
            <ConfirmButton
              v-if="billingAdjustment"
              type="is-danger"
              :outlined="true"
              @action:confirmed="archive"
              initialLabel="Delete?" />
          </div>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Save Billing Adjustment
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfirmButton from '@/components/Forms/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      if (!this.adjustmentDate) return false
      if (!this.amount) return false
      return true
    },
    method () {
      return (this.billingAdjustment) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.billingAdjustment)
        ? this.api.route + '/admin/billing-adjustments/' + this.billingAdjustment.id
        : this.api.route + '/admin/billing-adjustments/'
    },
    json () {
      const json = {
        project_id: this.project.id,
        date: this.adjustmentDate,
        amount: this.amount,
        memo: this.memo
      }
      return json
    }
  },
  data () {
    return {
      adjustmentDate: null,
      amount: null,
      memo: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Billing adjustment added!' })
        this.$parent.close()
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    archive () {
      this.saving = true
      this.$http.delete(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Adjustment deleted!' })
        this.$parent.close()
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.billingAdjustment) {
      this.adjustmentDate = this.billingAdjustment.date
      this.amount = Number(this.billingAdjustment.amount).toFixed(2)
      this.memo = this.billingAdjustment.memo
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    billingAdjustment: {
      type: Object
    }
  }
}
</script>
