<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Purchase Order Dates</p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field
              label="Start Date"
              :type="('start_date' in errors) ? 'is-danger' : ''"
              :message="('start_date' in errors) ? errors['start_date'][0] : ''"
              expanded>
                <b-input
                  size="is-medium"
                  type="date"
                  v-model="startDate"
                  :min="minStartDate"
                ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="End Date"
              :type="('end_date' in errors) ? 'is-danger' : ''"
              :message="('end_date' in errors) ? errors['end_date'][0] : ''"
              expanded>
                <b-input
                  size="is-medium"
                  type="date"
                  v-model="endDate"
                  :min="minEndDate"
                ></b-input>
            </b-field>
          </div>
        </div>
        <!-- Exisiting Dates -->
        <div v-if="order.projects.length > 0" class="mt-md is-size-7 has-background-light pa-md rounded-lg">
          <div class="mb-md">
            <p class="has-text-weight-bold">Existing Project Dates</p>
            <p>Please make sure you PO dates are in line with project dates</p>
          </div>
          <table class="table is-fullwidth is-narrow is-size-7 rounded-lg">
            <tbody>
              <tr>
                <th>Project</th>
                <th class="has-text-right" width="20%">Start Date</th>
                <th class="has-text-right" width="20%">End Date</th>
              </tr>
              <tr v-for="project in order.projects" :key="project.id">
                <td>{{ $utils.truncate(project.name, 10) }}</td>
                <td class="has-text-right">{{ $dates(project.start_date).format('MMM D, YYYY') }}</td>
                <td class="has-text-right">{{ $dates(project.end_date).format('MMM D, YYYY') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/purchase-orders/' + this.$route.params.id
    },
    ready () {
      return this.startDate && this.endDate
    },
    json () {
      return {
        start_date: this.startDate,
        end_date: this.endDate
      }
    },
    minStartDate () {
      return this.$dates().subtract(2, 'month').startOf('month').format('YYYY-MM-DD')
    },
    minEndDate () {
      return (this.startDate) ? this.startDate : this.$dates().format('YYYY-MM-DD')
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Purchase Order Updated!' })
        this.$emit('po:updated', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      startDate: this.order.start_date,
      endDate: this.order.end_date,
      saving: false,
      errors: []
    }
  },
  props: ['order']
}
</script>
