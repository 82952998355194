<template>
  <div class="section">
    <div class="container">
      <div v-if="user && !loading">
        <div class="mb-lg pb-lg has-border-b">
          <General :user="user" @user:updated="load"/>
        </div>
        <div class="mb-lg pb-lg has-border-b">
          <Photo :user="user" @user:updated="load" />
        </div>
        <div class="mb-lg pb-lg">
          <Security :user="user" @user:updated="load" />
        </div>
      </div>
      <div v-else>
        <Loading message="Loading profile..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import General from '../components/General'
import Photo from '../components/Photo'
import Security from '../components/Security'

export default {
  components: {
    General,
    Photo,
    Security
  },
  computed: {
    ...mapGetters(['auth'])
  },
  data: function () {
    return {
      loading: true,
      user: null
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get('v1/profile').then(response => {
        this.user = response.data
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
