<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.order.status === 'active') {
        presenter.class = 'has-text-success'
        presenter.label = 'Active'
      } else if (this.order.status === 'paused') {
        presenter.class = 'has-text-warning'
        presenter.label = 'Paused'
      } else if (this.order.status === 'completed') {
        presenter.class = 'has-text-info'
        presenter.label = 'Completed'
      } else {
        presenter.class = 'has-text-grey-light'
        presenter.label = 'Draft'
      }
      return presenter
    }
  },
  props: ['order']
}
</script>
