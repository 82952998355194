<template>
  <div>
    <form @submit.prevent="update">
      <!-- Validation Helper -->
      <div class="has-text-centered has-text-grey-light mb-xl">
        <i v-if="error !== null" v-text="error" class="has-text-danger"></i>
        <i v-else v-text="validationMessage.body" :class="validationMessage.class"></i>
      </div>
      <!-- Form Inputs -->
      <div class="mb-xxl">
        <b-field
          label="Current Password"
          :type="('old_password' in errors) ? 'is-danger' : ''"
          :message="('old_password' in errors) ? errors['old_password'][0] : ''">
          <b-input
            autocomplete="current-password"
            type="password"
            name="oldPassword"
            size="is-large"
            password-reveal
            v-model="oldPassword"
            @input="isDirty = 1"
          ></b-input>
        </b-field>
        <b-field
          label="New Password"
          :type="('new_password' in errors) ? 'is-danger' : ''"
          :message="('new_password' in errors) ? errors['new_password'][0] : ''">
          <password
            autocomplete="new-password"
            v-model="newPassword"
            defaultClass="input is-large"
            placeholder=""
            :toggle="false"
            :badge="false"
            @score="setScore"
          ></password>
        </b-field>
        <b-field label="Confirm Password">
          <b-input
            v-model="confirmPassword"
            type="password"
            name="confirmPassword"
            size="is-large"
            password-reveal
            @input="isDirty = 1"
          ></b-input>
        </b-field>
      </div>
      <!-- Submit -->
      <div>
        <input type="hidden" name="username" :value="auth.user.email" />
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-large"
          :disabled="saving || !ready"
          expanded>
          Update
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Password from 'vue-password-strength-meter'

export default {
  components: {
    Password
  },
  computed: {
    ...mapGetters(['auth', 'context']),
    endpoint () {
      return 'v1/profile/credentials'
    },
    json () {
      return {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.confirmPassword
      }
    },
    ready () {
      return (
        this.oldPassword &&
        this.newPassword &&
        this.newPassword !== this.oldPassword &&
        this.newPassword === this.confirmPassword &&
        this.passwordScore >= 2
      )
    },
    validationMessage () {
      const message = {
        class: 'has-text-warning',
        body: null
      }
      if (!this.oldPassword) {
        message.body = 'Please reset your password by completing the form below:'
      } else if (!this.newPassword) {
        message.body = 'Please enter a new password.'
      } else if (this.newPassword !== this.confirmPassword) {
        message.body = 'Please make sure to confirm your new password.'
      } else if (this.newPassword === this.oldPassword) {
        message.body = 'Please make sure your new password is different than your old password.'
      } else if (this.passwordScore < 2) {
        message.body = 'Please strengthen your password until you see a green score.'
      } else {
        message.class = 'has-text-success'
        message.body = 'You are ready to submit your new password!'
      }
      return message
    }
  },
  data () {
    return {
      saving: false,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      passwordScore: 0,
      error: null,
      errors: {}
    }
  },
  methods: {
    setScore (score) {
      this.isDirty = true
      this.passwordScore = score
    },
    update () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$store.dispatch('changedPassword').then(() => {
          this.$buefy.toast.open({ type: 'is-success', message: 'Password updated!' })
          if (this.context.resolved) {
            this.$router.push({ name: this.context.homeRoute })
          } else {
            this.$router.push({ name: 'context' })
          }
        })
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors
        } else {
          this.errors = { general: ['An unknown error occurred. Please contact support@gather.com'] }
        }
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    reset () {
      this.newPassword = null
      this.oldPassword = null
      this.confirmPassword = null
      this.passwordScore = 0
      this.error = null
      this.errors = {}
    }
  }
}
</script>
