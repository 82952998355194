<template>
  <form @submit.prevent="redirectToFreshbooks">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add {{ platform.name }} Integration</p>
      </header>
      <section class="modal-card-body">
        <div class="has-text-centered">
          <img :src="platform.logo_url" width="180" height="60" />
        </div>
        <div>
          <b-message type="is-info">
            Clicking "Add Integration" below will redirect you to FreshBooks to authorize
            the installation of GatherWorks to your FreshBooks account.
          </b-message>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$emit('canceled')">Back</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Add Integration</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context', 'services']),
    ready () {
      return true
    }
  },
  data () {
    return {
      installUrl: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    redirectToFreshbooks () {
      this.saving = true
      const endpoint = this.api.route + '/admin/freshbooks/install'
      this.$http.post(endpoint).then(response => {
        this.installUrl = response.data
        window.location.href = response.data.redirect_to
      })
    }
  },
  props: {
    platform: {
      type: Object,
      required: true
    }
  }
}
</script>
