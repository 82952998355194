<template>
  <b-tag :type="presenter.class" :size="this.size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'In Progress',
        class: 'is-primary is-light'
      }
      if (this.timesheet.approved_at) {
        presenter.label = 'Approved'
        presenter.class = 'is-success is-light'
      } else if (this.timesheet.submitted_at) {
        presenter.label = 'Submitted'
        presenter.class = 'is-info is-light'
      }
      return presenter
    }
  },
  props: {
    timesheet: {
      type: Object,
      required: true
    },
    size: {
      type: [String, null],
      default: 'is-small'
    }
  }
}
</script>
