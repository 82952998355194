<template>
  <span :class="textClass">{{ textValue }} </span>
</template>

<script>
export default {
  computed: {
    textValue () {
      if (this.hours === 0 || this.hours === '0' || this.hours === null || typeof this.hours === 'undefined') {
        if (this.zeroValue === 'dash') return '--'
        if (this.zeroValue === 'na') return 'N/A'
        if (this.zeroValue === 'number') return '0.00'
      }
      return this.$numbers(this.hours).format({ mantissa: this.mantissa, thousandSeparated: false })
    },
    textClass () {
      if (this.hours === 0 && (this.zeroValue === 'dash' || this.zeroValue === 'na')) {
        return 'has-text-grey-light'
      }
      if (this.temperatureReference && this.hours > this.temperatureReference) {
        return 'has-text-warning'
      }
      if (this.temperatureReference && this.hours < this.temperatureReference) {
        return 'has-text-info'
      }
      if (this.vsZero && Number(this.hours) > 0) return 'has-text-info'
      if (this.vsZero && Number(this.hours) < 0) return 'has-text-warning'
      if (this.vsZero && Number(this.hours) === 0) return 'has-text-success'
      return null
    }
  },
  props: {
    hours: {
      type: [String, Number]
    },
    temperatureReference: {
      type: [String, Number],
      default: null
    },
    mantissa: {
      type: Number,
      required: false,
      default: 2
    },
    zeroValue: {
      type: String,
      default: 'number' // 'dash' or 'na'
    },
    vsZero: {
      type: Boolean,
      default: false
    }
  }
}
</script>
