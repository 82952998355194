<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <!-- Header -->
          <div class="mb-lg">
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Approve Delivery?</p>
              <p class="is-size-7">
                When you approve this delivery, it will no longer be editable and will
                will be summarized using the billing period specified below.
              </p>
            </b-message>
          </div>
          <b-field label="Delivery Billing Period">
            <b-input
              :value="delivery.actual_billing_period.label"
              size="is-medium"
              expanded
              disabled>
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Approve Delivery
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/deliveries/' + this.delivery.id + '/actions/approve'
      this.$http.put(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Delivery updated!' })
        this.$parent.close()
        this.$emit('delivery:saved')
      })
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
