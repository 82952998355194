import Vue from 'vue'

// import key plugins/packages
import HttpPlugin from '@/plugins/http.js'
import DatesPlugin from '@/plugins/dates.js'
import NumbersPlugin from '@/plugins/numbers.js'
import UtilsPlugin from '@/plugins/utils.js'

import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

// import 3rd party components & directives
import Buefy from 'buefy'
import Cloudinary from 'cloudinary-vue'
import VueClipboard from 'vue-clipboard2'
import { Cropper } from 'vue-advanced-cropper'

// import 3p styles
import 'vue-advanced-cropper/dist/style.css'

// import global app components
import Breadcrumbs from './components/Global/Breadcrumbs'
import LoadingIndicator from './components/Global/LoadingIndicator'
import RequestErrors from './components/Global/RequestErrors'
import Hours from './components/Global/Hours'
import Currency from './components/Global/Currency'
import Percentage from './components/Global/Percentage'
import Margin from './components/Global/Margin'
import DateDisplay from './components/Global/DateDisplay'

// import app stylesheets
import './assets/css/vendors.scss'
import './assets/css/main.scss'

// install plugins
Vue.use(HttpPlugin)
Vue.use(DatesPlugin)
Vue.use(NumbersPlugin)
Vue.use(UtilsPlugin)

// install 3p libraries
Vue.use(VueClipboard)
Vue.use(Buefy, {
  defaultToastPosition: 'is-bottom'
})
Vue.use(Cloudinary, {
  configuration: { cloudName: 'gathertech', secure: true }
})

Vue.config.productionTip = false

// ACL Directive
Vue.directive('role', {
  bind (el, binding, vnode) {
    const permission = binding.expression.replace(/["']/g, '')
    if (
      store.getters.role &&
      store.getters.role.type &&
      store.getters.role.type.permissions.includes(permission)
    ) { return }
    vnode.elm.style.display = 'none'
  }
})

// install global components
Vue.component('RequestErrors', RequestErrors)
Vue.component('Cropper', Cropper)
Vue.component('Loading', LoadingIndicator)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Hours', Hours)
Vue.component('Currency', Currency)
Vue.component('Percentage', Percentage)
Vue.component('Date', DateDisplay)
Vue.component('Margin', Margin)

// sync router with the store
sync(store, router)

// let's get it going
store.dispatch('identify').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}).finally(() => {
  store.dispatch('initialize')
})
