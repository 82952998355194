<template>
  <b-autocomplete
    v-model="search"
    size="is-medium"
    field="name"
    max-height="100px"
    placeholder="Select Client"
    :data="filteredResults"
    :open-on-focus="false"
    :readonly="readonly"
    :disabled="readonly"
    @select="selectClient">
  </b-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    filteredResults () {
      if (this.loadingClients || this.clients.length === 0) {
        return []
      }
      if (this.search) {
        return this.clients.filter((option) => {
          return option.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        })
      } else {
        return this.clients
      }
    }
  },
  data () {
    return {
      search: null,
      loadingClients: false,
      client: null,
      clients: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.client)
    },
    selectClient (option) {
      this.client = option
      this.announce()
    },
    loadClients () {
      const endpoint = this.api.route + '/clients'
      this.loadingClients = true
      this.$http.get(endpoint).then(response => {
        this.clients = response.data.data
        if (this.value) {
          this.clients.forEach(client => {
            if (this.value.id === client.id) {
              this.client = client
              this.search = this.client.name
              this.announce()
            }
          })
        }
      }).finally(() => {
        this.loadingClients = false
      })
    }
  },
  mounted () {
    this.loadClients()
  },
  props: {
    value: {
      type: [Object, null]
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
