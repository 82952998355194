<template>
  <div>
    <form @submit.prevent="save">
      <div class="py-sm">
        <b-message v-if="'general' in errors" type="is-danger" class="is-size-7">
          {{ errors.general[0] }}
        </b-message>
        <b-message v-else-if="Object.keys(errors).length" type="is-danger" class="is-size-7">
          Please review your input and try again.
        </b-message>
        <b-message v-else-if="seat.status === 'active'" type="is-info" class="is-size-7">
          <b>Note:</b> This role has already started, so certain fields can no longer be edited.
        </b-message>
        <div class="columns">
          <div class="column">
            <!-- Contributor -->
            <div class="mb-lg">
              <b-field
                label="Contributor"
                :type="('partner_role_id' in errors) ? 'is-danger' : ''"
                :message="('partner_role_id' in errors) ? errors['partner_role_id'][0] : ''"
                expanded>
                <b-autocomplete
                  v-model="searchContributors"
                  max-height="100px"
                  field="label"
                  placeholder="Select Contributor"
                  size="is-medium"
                  :open-on-focus="false"
                  :data="filteredContributors"
                  :disabled="readonly"
                  @select="option => partnerRole = option">
                </b-autocomplete>
              </b-field>
            </div>
            <!-- Reporter -->
            <div class="mb-lg">
              <b-field
                label="Reporter"
                :type="('reporting_partner_role_id' in errors) ? 'is-danger' : ''"
                :message="('reporting_partner_role_id' in errors) ? errors['reporting_partner_role_id'][0] : ''"
                expanded>
                <b-autocomplete
                  v-model="searchReporters"
                  :data="filteredReporters"
                  max-height="100px"
                  field="label"
                  placeholder="Select Contributor"
                  size="is-medium"
                  :open-on-focus="false"
                  :disabled="readonly"
                  @select="option => reportingPartnerRole = option">
                </b-autocomplete>
              </b-field>
            </div>
            <!-- Rates -->
            <div class="mb-xl">
              <div class="columns is-vcentered">
                <div class="column">
                  <b-field
                    label="Bill Rate">
                    <p class="control"><span class="button is-medium border-none is-static">$</span></p>
                    <b-input
                      size="is-medium"
                      type="text"
                      :value="billRate"
                      :disabled="true"
                      :readonly="true"
                    />
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    label="Contributor Rate"
                    :type="('partner_rate' in errors) ? 'is-danger' : ''"
                    :message="('partner_rate' in errors) ? errors['partner_rate'][0] : ''">
                    <p class="control">
                      <span class="button is-medium is-static" :class="(readonly) ? 'border-none' : null">$</span>
                    </p>
                    <b-input
                      v-model="costRate"
                      size="is-medium"
                      type="number"
                      expanded
                      :min="0"
                      :max="billRate"
                      :disabled="readonly || saving"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    label="Margin"
                    :type="('partner_rate' in errors) ? 'is-danger' : ''"
                    :message="('partner_rate' in errors) ? errors['partner_rate'][0] : ''">
                    <b-input
                      size="is-medium"
                      type="text"
                      :value="margin"
                      disabled
                      readonly
                      expanded
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
            <!-- Confirmation -->
            <div v-if="!readonly" class="mb-lg">
              <b-field>
                <b-radio v-model="rateIsConfirmed" native-value="confirmed">This rate has been confirmed with the contributor.</b-radio>
              </b-field>
              <b-field>
                <b-radio v-model="rateIsConfirmed" native-value="unconfirmed">This rate is an estimate for planning purposes.</b-radio>
              </b-field>
            </div>
          </div>
          <div class="column is-one-third">
            <!-- Help Content -->
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="pt-lg">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-button
              native-type="submit"
              type="is-primary"
              size="is-medium"
              expanded
              :loading="saving"
              :disabled="saving || !ready"
            >Save</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return true
    },
    readonly () {
      return Boolean(this.seat.status !== 'pending')
    },
    loading () {
      return Boolean(this.loadingContributors)
    },
    filteredContributors () {
      if (this.loading || this.contributors.length === 0) return []
      if (this.searchContributors) {
        return this.contributors.filter((option) => {
          return option.label.toString().toLowerCase().indexOf(this.searchContributors.toLowerCase()) >= 0
        })
      } else {
        return this.contributors
      }
    },
    filteredReporters () {
      if (this.loading || this.contributors.length === 0) return []
      if (this.searchReporters) {
        return this.contributors.filter((option) => {
          return option.label.toString().toLowerCase().indexOf(this.searchReporters.toLowerCase()) >= 0
        })
      } else {
        return this.contributors
      }
    },
    margin () {
      if (!Number(this.billRate) || !Number(this.costRate)) return '--'
      let margin = 0
      if (Number(this.billRate) !== 0) {
        margin = (Number(this.billRate) - Number(this.costRate)) / Number(this.billRate)
      }
      return Number(margin * 100).toFixed(2) + '%'
    },
    json () {
      const json = {}
      if (this.seat.status === 'pending') {
        if (this.partnerRole) {
          json.partner_role_id = this.partnerRole.id
        }
        if (this.reportingPartnerRole) {
          json.reporting_partner_role_id = this.reportingPartnerRole.id
        }
        if (this.costRate) {
          json.plan_cost_rate = this.costRate
        }
        json.rate_is_confirmed = (this.rateIsConfirmed === 'confirmed') ? 1 : 0
      }
      return json
    }
  },
  data () {
    return {
      loadingContributors: true,
      contributors: [],
      searchContributors: null,
      searchReporters: null,
      partnerRole: null,
      reportingPartnerRole: null,
      saving: false,
      errors: {},
      costRate: null,
      billRate: null,
      rateIsConfirmed: null
    }
  },
  methods: {
    loadContributors () {
      const endpoint = this.api.route + '/contributors'
      this.loadingContributors = true
      this.$http.get(endpoint).then(response => {
        this.contributors = response.data
        this.loadingContributors = false
      })
    },
    save () {
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/actions/fill'
      this.$http.patch(endpoint, this.json).then(response => {
        this.$emit('seat:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.errors = {}
        this.saving = false
      }).catch(error => {
        this.errors = ('response' in error) ? error.response.data.errors : {}
      })
    }
  },
  mounted () {
    this.loadContributors()
    if (this.seat.partner_role_id) {
      this.searchContributors = this.seat.contributor.user.full_name
    }
    if (this.seat.reporting_partner_role_id) {
      this.searchReporters = this.seat.reporter.user.full_name
    }
    if (this.seat.plan_cost_rate) {
      this.costRate = Number(this.seat.plan_cost_rate).toFixed(2)
    }
    this.billRate = Number(this.seat.plan_bill_rate).toFixed(2)
    this.rateIsConfirmed = (this.seat.rate_is_confirmed) ? 'confirmed' : 'unconfirmed'
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
