<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <div v-if="panel === 'role'">
          <!-- Header -->
          <div class="mb-lg">
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Add Contributor Role</p>
              <p class="is-size-7">
                Adding a <span class="has-text-weight-bold">contributor role</span> allows you to assign a person to a <strong>time and materials</strong> role.
                Active contributors will report timesheets for each monthly billing period.
              </p>
            </b-message>
          </div>
          <!-- Role -->
          <div class="mb-lg">
            <p class="mb-sm">
              <span class="has-text-weight-bold">Role </span>
              <span class="is-size-7 has-text-grey-light">(e.g. Creative Director, Copywriter, etc)</span>
            </p>
            <b-field
              :type="('client_title_id' in errors) ? 'is-danger' : ''"
              :message="('client_title_id' in errors) ? errors['client_title_id'][0] : ''">
              <b-input
                size="is-medium"
                type="text"
                v-model="role"
                expanded
              ></b-input>
            </b-field>
          </div>
          <!-- Level -->
          <div class="mb-lg">
            <b-field
              label="Rate Card Level"
              :type="('client_title_id' in errors) ? 'is-danger' : ''"
              :message="('client_title_id' in errors) ? errors['client_title_id'][0] : ''">
              <ClientTitleSelect :client="project.client" v-model="title" />
            </b-field>
          </div>
          <!-- Bill Rate -->
          <div class="mb-lg">
            <p class="mb-sm"><label class="label">Billable Rate</label></p>
            <div class="columns is-vcentered">
              <div class="column">
                <b-field
                  :type="('billable_rate' in errors) ? 'is-danger' : ''"
                  :message="('billable_rate' in errors) ? errors['billable_rate'][0] : ''">
                  <p class="control"><span class="button is-medium is-static">$</span></p>
                  <b-input
                    size="is-medium"
                    type="number"
                    v-model="billableRate"
                    :min="0"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-one-third">
                <p><a href="#" @click.prevent="useRateCard">Use Rate Card</a></p>
              </div>
            </div>
          </div>
          <!-- Start Date -->
          <div class="mb-lg">
            <p class="mb-sm"><label class="label">Start Date</label></p>
            <div class="columns is-vcentered">
              <div class="column">
                <b-field
                  :type="('start_date' in errors) ? 'is-danger' : ''"
                  :message="('start_date' in errors) ? errors['start_date'][0] : ''">
                  <p class="control"><span class="button is-medium is-static"><b-icon icon="calendar" /></span></p>
                  <b-input
                    size="is-medium"
                    type="date"
                    v-model="startDate"
                    :min="project.start_date"
                    :max="project.end_date"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-one-third">
                <p><a href="#" @click.prevent="useProjectStartDate">Use Project Start Date</a></p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="panel === 'contributor'">
          <!-- Header -->
          <div class="mb-lg">
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Add Contributor Role</p>
              <p class="is-size-7">
                Adding a <span class="has-text-weight-bold">contributor role</span> allows you to assign a person to a <strong>time and materials</strong> role.
                Active contributors will report timesheets for each monthly billing period.
              </p>
            </b-message>
          </div>
          <!-- Contributor -->
          <div class="mb-lg">
            <b-field
              :type="('partner_role_id' in errors) ? 'is-danger' : ''"
              :message="('partner_role_id' in errors) ? errors['partner_role_id'][0] : ''"
              expanded>
              <b-autocomplete
                v-model="search"
                :data="filteredContributors"
                max-height="100px"
                field="label"
                placeholder="Select Contributor"
                size="is-medium"
                :open-on-focus="false"
                @select="option => partnerRole = option">
              </b-autocomplete>
            </b-field>
          </div>
          <!-- Rates -->
          <div class="mb-xl">
            <div class="columns is-vcentered">
              <div class="column is-half">
                <b-field
                  label="Contributor Rate"
                  :type="('partner_rate' in errors) ? 'is-danger' : ''"
                  :message="('partner_rate' in errors) ? errors['partner_rate'][0] : ''">
                  <p class="control"><span class="button is-medium is-static">$</span></p>
                  <b-input
                    size="is-medium"
                    type="number"
                    v-model="rate"
                    :min="0"
                    :max="billableRate"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field
                  label="Margin"
                  :type="('partner_rate' in errors) ? 'is-danger' : ''"
                  :message="('partner_rate' in errors) ? errors['partner_rate'][0] : ''">
                  <b-input
                    size="is-medium"
                    type="text"
                    :value="margin"
                    disabled
                    readonly
                    expanded
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <!-- Confirmation -->
          <div class="mb-lg">
            <b-field>
              <b-radio v-model="rateIsConfirmed" native-value="confirmed">This rate has been confirmed with the contributor.</b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="rateIsConfirmed" native-value="unconfirmed">This rate is an estimate for planning purposes.</b-radio>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >{{ (panel === 'role') ? 'Save & Select Contributor' : 'Save' }}</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientTitleSelect from '@/components/Models/ClientTitle/SelectMenu'

export default {
  components: {
    ClientTitleSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      if (this.panel === 'role') {
        return Boolean(this.role && this.title && this.billableRate && this.startDate)
      } else if (this.panel === 'contributor') {
        return Boolean(this.partnerRole && this.rate && this.rateIsConfirmed)
      }
      return false
    },
    method () {
      return (this.seat) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.seat)
        ? this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/actions/fill'
        : this.api.route + '/projects/' + this.project.id + '/seats'
    },
    json () {
      const payload = { position_id: this.position.id }
      if (this.panel === 'role') {
        payload.role = this.role
        payload.client_title_id = this.title.id
        payload.bill_rate = this.billableRate
        payload.start_date = this.startDate
      } else if (this.panel === 'contributor') {
        payload.partner_role_id = this.partnerRole.id
        payload.plan_cost_rate = this.rate
        payload.rate_is_confirmed = (this.rateIsConfirmed === 'confirmed') ? 1 : 0
      }
      return payload
    },
    filteredContributors () {
      if (this.loadingContributors || this.contributors.length === 0) {
        return []
      }
      if (this.search) {
        return this.contributors.filter((option) => {
          return option.label.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        })
      } else {
        return this.contributors
      }
    },
    margin () {
      let margin = '--'
      if (!this.billableRate || !this.rate) return '--'
      if (Number(this.billableRate) !== 0) {
        margin = (Number(this.billableRate) - Number(this.rate)) / Number(this.billableRate)
      }
      return Number(margin * 100).toFixed(2) + '%'
    }
  },
  data () {
    return {
      seat: null,
      loadingContributors: false,
      contributors: [],
      panel: 'role',
      saving: false,
      errors: {},
      search: null,
      partnerRole: null,
      title: null,
      role: null,
      startDate: null,
      billableRate: null,
      rate: null,
      knowsContributor: false,
      rateIsConfirmed: 'unconfirmed'
    }
  },
  methods: {
    targetMargin () {
      this.rate = this.$numbers(this.billableRate * (0.60)).format({ mantissa: 2 })
    },
    useLineRole () {
      this.role = this.position.role
    },
    useProjectStartDate () {
      this.startDate = this.project.start_date
    },
    useRateCard () {
      if (this.title) {
        this.billableRate = parseFloat(this.title.rate_card).toFixed(2)
      }
    },
    loadContributors () {
      const endpoint = this.api.route + '/contributors'
      this.loadingContributors = true
      this.$http.get(endpoint).then(response => {
        this.contributors = response.data
        this.loadingContributors = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$emit('seat:saved')
        if (this.panel === 'role') {
          this.seat = response.data
          this.panel = 'contributor'
        } else {
          this.$parent.close()
        }
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadContributors()
    this.startDate = this.project.start_date
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  }
}
</script>
