<template>
  <div>
    <!-- Content -->
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <TransferFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <router-link :to="{ name: 'network.finance.transfers.create' }" class="button is-primary is-outlined is-medium is-fullwidth">Create Transfer</router-link>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 transfer</p>
              <p v-else>{{ total }} transfers</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            field="id"
            label="ID"
            width="64"
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            field="id"
            label="Organization"
            width="160"
            v-slot="props">
            <span v-if="props.row.organization">{{ props.row.organization.name }}</span>
            <span v-else class="has-text-grey-light is-italic">Not Set</span>
          </b-table-column>
          <b-table-column
            field="name"
            label="Client Name"
            v-slot="props">
            <router-link
              class="has-text-weight-bold"
              :to="{ name: 'network.client', params: { code: context.code, id: props.row.id } }">
              {{ props.row.name }}
            </router-link>
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            centered
            width="128"
            v-slot="props">
            <StatusPresenter :status="props.row.status" />
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
    <!-- Modal: Create Transfer -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateTransferModal"
      :can-cancel="['x', 'esc']"
      ><CreateFundingTransferModal @transfer:saved="redirect" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Filterable from '@/mixins/Filterable'
import TransferFilters from '../components/Transfers/TransferFilters'
import StatusPresenter from '@/components/Models/FundingTransfer/Presenters/Status'
import CreateFundingTransferModal from '@/components/Models/FundingTransfer/Modals/Create'

export default {
  components: {
    TransferFilters,
    StatusPresenter,
    CreateFundingTransferModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Finance', name: 'network.finance', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/admin/transfers'
    }
  },
  data: function () {
    return {
      key: 'admin.transfers',
      showCreateTransferModal: false
    }
  },
  methods: {
    redirect (id) {
      this.$router.push({ name: 'network.finance.transfer', params: { code: this.context.code, transfer: id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
