<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Upload Attachment</p>
        </header>
        <section class="modal-card-body">
          <!-- File Tag -->
          <b-field
            label="Document"
            :type="('tag' in errors) ? 'is-danger' : ''"
            :message="('tag' in errors) ? errors['tag'][0] : ''">
            <b-select
              v-model="tag"
              placeholder="Select Document Type"
              expanded>
              <option value="sow">Statement of Work</option>
            </b-select>
          </b-field>
          <!-- File -->
          <div class="mb-lg">
            <FileUpload v-model="tempfile" />
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Upload Attachment
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FileUpload from '@/components/Forms/FileUpload'

export default {
  components: {
    FileUpload
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.tag && this.tempfile)
    },
    endpoint () {
      return this.api.route + '/purchase-orders/' + this.$route.params.id + '/attachments'
    },
    json () {
      if (this.tempfile === null) return null
      return {
        tempfile_id: this.tempfile.id,
        tag: this.tag
      }
    }
  },
  data () {
    return {
      saving: false,
      errors: [],
      tag: null,
      tempfile: null
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.attachment = response.data
        this.$buefy.toast.open({ type: 'is-success', message: 'File uploaded!' })
        this.$parent.close()
        this.$emit('attachment:uploaded')
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: ['order']
}
</script>
