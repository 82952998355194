<template>
  <Hours :hours="seat.units_fulfilled" :mantissa="2" :class="pacingClass" />
</template>

<script>
export default {
  computed: {
    pacingClass () {
      if (this.seat.status === 'pending') return null
      const pacing = {
        target: 0,
        actual: 0
      }
      const closed = []
      this.seat.periods.forEach(period => {
        if (period.billing_period.closed_at) {
          closed.push(period)
        }
      })
      if (closed.length) {
        closed.forEach(period => {
          pacing.target += Number(period.units_allocated)
          pacing.actual += Number(period.units_fulfilled)
        })
      }
      if (pacing.target < pacing.actual) return 'has-text-warning'
      if (pacing.target > pacing.actual) return 'has-text-info'
      // return 'has-text-success'
      return ''
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
