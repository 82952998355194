<template>
  <b-tag :type="presenter.class" size="is-small">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {
        label: 'Pending',
        class: 'has-text-grey'
      }
      if (this.expense.submitted_at) {
        presenter.label = 'In Review'
        presenter.class = 'is-info is-light'
      }
      if (this.expense.approved_at) {
        presenter.label = 'Approved'
        presenter.class = 'is-success is-light'
      }
      return presenter
    }
  },
  props: {
    expense: {
      type: Object,
      required: true
    }
  }
}
</script>
