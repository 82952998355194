<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <div>
          <div v-if="action === 'activate'">
            <b-message type="is-success">
              <p class="is-size-5 has-text-weight-bold mb-sm">Activate Budget?</p>
              <p>
                Are you sure you want to activate this budget? After a budget has been activated,
                it can no longer be changed without an approved revision.
              </p>
            </b-message>
          </div>
          <div v-if="action === 'pause'">
            <b-message type="is-warning">
              Are you sure you want to pause this project line?
            </b-message>
          </div>
          <div v-else-if="action === 'restart'">
            <b-message type="is-warning">
              Are you sure you want to restart this project line?
            </b-message>
          </div>
          <div v-else-if="action === 'archive'">
            <b-message type="is-danger">
              Are you sure you want to remove this project line?
            </b-message>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >{{ actionLabel }}</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    method () {
      switch (this.action) {
        case 'archive':
          return 'delete'
        default:
          return 'put'
      }
    },
    endpoint () {
      const base = this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id
      switch (this.action) {
        case 'activate':
          return base + '/actions/activate'
        case 'pause':
          return base + '/actions/pause'
        case 'restart':
          return base + '/actions/restart'
        default:
          return base
      }
    },
    actionLabel () {
      switch (this.action) {
        case 'activate':
          return 'Activate Budget?'
        case 'pause':
          return 'Pause Budget?'
        case 'restart':
          return 'Restart Budget'
        case 'archive':
          return 'Remove Budget?'
        default:
          return 'Edit Budget'
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project line updated!' })
        this.$emit('position:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  }
}
</script>
