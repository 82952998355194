<template>
  <div>
    <!-- Period Select -->
    <div>
      <div class="border-b pb-md">
        <div class="columns">
          <div class="column is-half">
            <b-select v-model="range" size="is-medium" expanded>
              <option value="1H">1H 2023 (Jan - Jun)</option>
              <option value="2H">2H 2023 (Jul - Dec)</option>
            </b-select>
          </div>
        </div>
      </div>
    </div>
    <!-- Period Select -->
    <div class="mb-xl">
      <div v-if="!loading">
        <table class="table is-fullwidth is-size-7">
          <thead>
            <tr>
              <th>Quarter</th>
              <template v-if="range === '1H'">
                <th colspan="3" class="has-text-centered has-background-light">Q1 2023</th>
                <th colspan="3" class="has-text-centered">Q2 2023</th>
              </template>
              <template v-if="range === '2H'">
                <th colspan="3" class="has-text-centered has-background-light">Q3 2023</th>
                <th colspan="3" class="has-text-centered">Q4 2023</th>
              </template>
            </tr>
            <tr>
              <th>Client</th>
              <th style="width:128px" class="has-text-centered has-background-light">Billable</th>
              <th style="width:128px" class="has-text-centered has-background-light">Non Billable</th>
              <th style="width:128px" class="has-text-centered has-background-light">Total</th>
              <th style="width:128px" class="has-text-centered">Billable</th>
              <th style="width:128px" class="has-text-centered">Non Billable</th>
              <th style="width:128px" class="has-text-centered">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in details" :key="client.id">
              <td>{{ client.name }}</td>
              <template v-if="range === '1H'">
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in client.quarters) ? client.quarters[1].billable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in client.quarters) ? client.quarters[1].nonbillable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in client.quarters) ? client.quarters[1].total : 0" /></td>
              </template>
              <template v-if="range === '1H'">
                <td class="has-text-centered"><Percentage :value="(2 in client.quarters) ? client.quarters[2].billable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(2 in client.quarters) ? client.quarters[2].nonbillable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(2 in client.quarters) ? client.quarters[2].total : 0" /></td>
              </template>
              <template v-if="range === '2H'">
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in client.quarters) ? client.quarters[3].billable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in client.quarters) ? client.quarters[3].nonbillable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in client.quarters) ? client.quarters[3].total : 0" /></td>
              </template>
              <template v-if="range === '2H'">
                <td class="has-text-centered"><Percentage :value="(4 in client.quarters) ? client.quarters[4].billable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(4 in client.quarters) ? client.quarters[4].nonbillable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(4 in client.quarters) ? client.quarters[4].total : 0" /></td>
              </template>
            </tr>
            <tr>
              <td>TOTAL</td>
              <template v-if="range === '1H'">
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in summary) ? summary[1].billable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in summary) ? summary[1].nonbillable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(1 in summary) ? summary[1].total : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(2 in summary) ? summary[2].billable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(2 in summary) ? summary[2].nonbillable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(2 in summary) ? summary[2].total : 0" /></td>
              </template>
              <template v-if="range === '2H'">
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in summary) ? summary[3].billable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in summary) ? summary[3].nonbillable : 0" /></td>
                <td class="has-text-centered has-background-light"><Percentage :value="(3 in summary) ? summary[3].total : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(4 in summary) ? summary[4].billable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(4 in summary) ? summary[4].nonbillable : 0" /></td>
                <td class="has-text-centered"><Percentage :value="(4 in summary) ? summary[4].total : 0" /></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    loading () {
      return Boolean(this.loadingSeats)
    },
    summary () {
      const summary = {
        1: { billable: 0, nonbillable: 0, total: 0 },
        2: { billable: 0, nonbillable: 0, total: 0 },
        3: { billable: 0, nonbillable: 0, total: 0 },
        4: { billable: 0, nonbillable: 0, total: 0 }
      }
      this.rows.forEach(row => {
        if (row.is_billable) {
          summary[row.quarter].billable += Number(row.hours_reported) / (this.yearlyHours / 4)
        } else {
          summary[row.quarter].nonbillable += Number(row.hours_reported) / (this.yearlyHours / 4)
        }
        summary[row.quarter].total += Number(row.hours_reported) / (this.yearlyHours / 4)
      })
      for (const q in summary) {
        if (summary[q].total === 0) delete summary[q]
      }
      return summary
    },
    details () {
      const details = {}
      this.rows.forEach(row => {
        const org = {
          id: row.organization_id,
          name: row.organization,
          quarters: {}
        }
        const quarter = { billable: 0, nonbillable: 0 }
        if (row.is_billable) {
          quarter.billable = Number(row.hours_reported) / (this.yearlyHours / 4)
        } else {
          quarter.nonbillable = Number(row.hours_reported) / (this.yearlyHours / 4)
        }
        quarter.total = quarter.billable + quarter.nonbillable

        if (row.organization_id in details) {
          details[row.organization_id].quarters[row.quarter] = quarter
        } else {
          details[row.organization_id] = org
          details[row.organization_id].quarters[row.quarter] = quarter
        }
      })
      return details
    }
  },
  data () {
    return {
      range: '1H',
      loadingSeats: true,
      yearlyHours: 1800,
      rows: {}
    }
  },
  methods: {
    loadSeats () {
      this.loadingSeats = true
      const endpoint = this.api.route + '/connections/' + this.connection.id + '/contributors/' + this.contributor.id + '/utilization'
      const query = {
        params: {
          start: this.$dates().startOf('year').format('YYYY-MM-DD'),
          end: this.$dates().endOf('year').format('YYYY-MM-DD')
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.rows = response.data
        this.loadingSeats = false
      })
    }
  },
  mounted () {
    this.loadSeats()
  },
  props: {
    connection: {
      type: Object,
      required: true
    },
    contributor: {
      type: Object,
      required: true
    }
  }
}
</script>
