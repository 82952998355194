<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Partner</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Business Name"
          :type="('company' in errors) ? 'is-danger' : ''"
          :message="('company' in errors) ? errors['company'][0] : ''">
          <b-input
            autocomplete="off"
            size="is-medium"
            type="text"
            v-model="partner.name"
          ></b-input>
        </b-field>
        <b-field
          label="Accounting ID"
          :type="('network_identifier' in errors) ? 'is-danger' : ''"
          :message="('network_identifier' in errors) ? errors['network_identifier'][0] : ''">
          <b-input
            size="is-medium"
            type="text"
            v-model="networkIdentifier"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()" >Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    ready () {
      return (this.partner.name)
    }
  },
  data () {
    return {
      partner: null,
      networkIdentifier: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/connections/' + this.connection.id
      const body = {
        partner_name: this.partner.name,
        network_identifier: this.networkIdentifier
      }
      this.$http.patch(endpoint, body).then(response => {
        this.$emit('connection:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Connection updated.' })
        this.$parent.close()
      }).catch(error => {
        if ('response' in error && error.response.status === 422) {
          this.errors = error.response.data.errors
        }
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.partner = this.connection.partner
    this.networkIdentifier = this.connection.network_identifier
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
