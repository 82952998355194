<template>
<div>
  <div class="section">
    <div class="container">
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <p class="is-size-5 has-text-weight-bold">{{ key }}</p>
          <!-- Column 1 -->
          <div v-for="(period, index) in periods" :key="period.id" >
            <div v-if="index < 4">
              <p>
                <router-link :to="{ name: 'network.finance.period.summary', params: { id: period.id } }" >{{ $dates(period.start_date).format('MMMM') }}</router-link>
                <span class="has-text-grey-light"> ({{ (period.closed_at) ? 'Closed' : 'Open' }})</span>
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/admin/billing-periods'
    },
    query () {
      return {
        params: {
          group_by: 'year'
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      periods: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint, this.query).then(response => {
        this.periods = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  }
}
</script>
