<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  computed: {
    chartData () {
      const periods = ['Start']
      this.project.periods.forEach(period => {
        periods.push(period.billing_period.label + ' (Q' + period.fiscal_quarter.split('.')[1] + ')')
      })
      const start = (Number(this.project.amount_allocated > 0))
        ? Number(this.project.amount_allocated)
        : 0
      const allocated = [start]
      const actual = [start]
      // burn down or up depending on estimate
      const direction = (start > 0) ? 'down' : 'up'
      let allocatedBalance = start
      let actualBalance = start
      this.project.periods.forEach(period => {
        allocatedBalance = (direction === 'down')
          ? Number(allocatedBalance) - Number(period.amount_allocated)
          : Number(allocatedBalance) + Number(period.amount_allocated)
        actualBalance = (direction === 'down')
          ? Number(actualBalance) - Number(period.amount_fulfilled)
          : Number(actualBalance) + Number(period.amount_fulfilled)
        allocated.push(allocatedBalance)
        if (period.billing_period.closed_at) {
          actual.push(actualBalance)
        }
      })
      return {
        labels: periods,
        datasets: [{
          label: 'Actual',
          data: actual,
          fill: false,
          borderColor: '#fa4516',
          backgroundColor: '#fa4516',
          borderWidth: 4
        }, {
          label: 'Allocated',
          data: allocated,
          fill: false,
          borderColor: '#D0D0D0',
          backgroundColor: '#D0D0D0',
          borderWidth: 4
        }]
      }
    }
  },
  data () {
    return {
      options: {
        elements: { point: { radius: 4 } },
        tooltips: {
          enabled: true,
          titleFontSize: 16,
          bodyFontSize: 16,
          callbacks: {
            label: function (tooltipItem, data) {
              // set the basics
              const label = data.datasets[tooltipItem.datasetIndex].label
              // get the maximum index
              const max = data.datasets[tooltipItem.datasetIndex].data.length - 1
              // get the current value in the series (i.e. the remaining value)
              const remaining = Number(tooltipItem.value)
              // get the previous value in the series
              const prev = (tooltipItem.index > 0)
                ? Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index - 1])
                : 0
              // first tooltip
              if (tooltipItem.index === 0) {
                return label + ': $' + remaining.toLocaleString() + ' (Start)'
              // last tooltip
              } else if (tooltipItem.index === max) {
                const start = prev
                const allocated = prev
                const end = remaining
                return label + ': $' + start.toLocaleString() + ' (Start), $' + allocated.toLocaleString() + ' (Period), $' + end.toLocaleString() + ' (End)'
              // middle tooltips
              } else {
                const start = prev
                const allocated = (prev - remaining)
                const end = remaining
                return label + ': $' + start.toLocaleString() + ' (Start), $' + allocated.toLocaleString() + ' (Period), $' + end.toLocaleString() + ' (End)'
              }
            }
          }
        },
        scales: {
          yAxes: [{
            offset: false,
            ticks: { display: false },
            gridLines: { display: true }
          }],
          xAxes: [{
            offset: false,
            ticks: { display: true },
            gridLines: { display: false }
          }]
        },
        layout: { padding: { left: 20, right: 20, top: 20, bottom: 20 } },
        legend: { display: false },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
