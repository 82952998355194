<template>
  <div>
    <div class="columns">
      <div class="column">
        <!-- Filters Toolbar -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <PartnerFilters :applied-filters="filters" @filter="filter" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="id_desc">Most Recent</option>
                <option value="id_asc">Oldest</option>
              </b-select>
            </div>
            <div v-role="'manage-partners'" class="column is-narrow">
              <b-button
                type="is-primary"
                outlined
                size="is-medium"
                @click="showCreatePartnerModal = true"
              >Add Partner</b-button>
            </div>
          </div>
        </div>
        <!-- Actions Toolbar -->
        <div class="has-background-white has-sa px-md py-sm mb-xxs">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <div class="is-size-7">
                <p v-if="loading">Loading...</p>
                <p v-else-if="total === 1">1 partner found</p>
                <p v-else>{{ total }} partners found</p>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="">
                <b-button size="is-small" @click="toggleView('list')" :disabled="view === 'list'" icon-left="format-list-bulleted"></b-button>
                <b-button size="is-small" @click="toggleView('grid')" :disabled="view === 'grid'" icon-left="grid"></b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- List View -->
        <div v-if="view === 'list'" class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <b-table-column
              field="id"
              label="ID"
              width="96"
              v-slot="props">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column
              field="name"
              label="Partner Name"
              v-slot="props">
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.partner', params: { code: context.code, connection: props.row.id } }">
                {{ props.row.partner.name }}
              </router-link>
              <p class="is-size-8 has-text-grey">{{ props.row.partner.billing_contact }}
                <span v-if="props.row.partner.billing_email">&bull; {{ props.row.partner.billing_email }}</span>
              </p>
            </b-table-column>
            <b-table-column
              field="status"
              label="Status"
              v-slot="props"
              centered
              width="128">
              <StatusPresenter :connection="props.row" />
            </b-table-column>
            <template slot="empty">
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <!-- Grid View -->
        <div v-else class="my-lg">
          <div class="columns is-multiline">
            <div v-for="connection in results" :key="connection.id" class="column is-one-third">
              <div class="has-background-white rounded-lg pa-md">
                <div class="has-text-centered">
                  <div class="has-text-centered">
                    <cld-image v-if="connection.partner.logo_path" :publicId="connection.partner.logo_path" width="96" dpr="2.0" crop="scale"  class="border pa-xs rounded-full"/>
                    <img v-else src="https://res.cloudinary.com/gathertech/image/upload/v1640737422/platform/placeholder.png" width="96" height="96" class="border pa-xs rounded-full" />
                  </div>
                  <p class="has-text-centered">
                    <router-link
                      class="has-text-weight-bold"
                      :to="{ name: 'network.partner', params: { code: context.code, connection: connection.id } }">
                      {{ connection.partner.billing_contact }}
                    </router-link>
                  </p>
                  <div class="has-text-grey is-size-8">
                    <p class="has-text-grey mb-xs">
                      <span v-if="connection.partner.billing_contact">{{ connection.partner.name }} </span>
                      <span v-if="connection.partner.billing_email">&bull; {{ connection.partner.billing_email }}</span>
                    </p>
                  </div>
                  <p><StatusPresenter :connection="connection" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate" />
      </div>
    </div>
    <!-- Create Modal -->
    <b-modal
      :active.sync="showCreatePartnerModal"
      :can-cancel="['x', 'esc']"
      has-modal-card>
      <CreatePartnerModal @partner:saved="load" />
    </b-modal>
  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import PartnerFilters from '../components/List/PartnerFilters'
import StatusPresenter from '@/components/Models/Connection/Presenters/StatusTag'
import CreatePartnerModal from '@/components/Models/Partner/Modals/CreatePartnerModal'

import { mapGetters } from 'vuex'

export default {
  components: {
    CreatePartnerModal,
    PartnerFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/connections'
    }
  },
  data: function () {
    return {
      key: 'partners',
      view: 'grid',
      showCreatePartnerModal: false,
      alwaysAttach: {}
    }
  },
  methods: {

  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
