<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Project Loaded -->
        <div v-if="!loading && project !== null">
          <!-- Layout -->
          <div class="columns space-between">
            <div class="column is-narrow">
              <b-menu>
                <b-menu-list label="Menu">
                  <b-menu-item
                    icon="finance"
                    label="Dashboard"
                    tag="router-link"
                    :to="{ name: 'network.project.lines', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="clipboard-account-outline"
                    label="Allocate"
                    tag="router-link"
                    :to="{ name: 'network.project.allocate', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="invoice-outline"
                    label="Expenses"
                    tag="router-link"
                    :to="{ name: 'network.project.expenses', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="file-sign"
                    label="Funding"
                    tag="router-link"
                    :to="{ name: 'network.project.funding', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="cash-multiple"
                    label="Billing"
                    tag="router-link"
                    :to="{ name: 'network.project.billing', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="check-all"
                    label="Approve"
                    tag="router-link"
                    :to="{ name: 'network.project.approve', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="rss"
                    label="Activity"
                    tag="router-link"
                    :to="{ name: 'network.project.activities', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                  <b-menu-item
                    icon="cog-outline"
                    label="Settings"
                    tag="router-link"
                    :to="{ name: 'network.project.marginseats', params: { code: context.code, id: project.id } }"
                    exact
                  ></b-menu-item>
                </b-menu-list>
              </b-menu>
            </div>
            <div class="column is-10">
              <div class="mb-md">
                <ProjectHeader :project="project" @project:saved="loadProject(true)" @preview="previewBetaFeatures = !previewBetaFeatures" />
              </div>
              <div v-if="project !== null">
                <router-view
                  :project="project"
                  :preview="previewBetaFeatures"
                  @project:saved="loadProject(true)"
                  @refresh="loadProject(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectHeader from '../components/Lines/ProjectHeader'

export default {
  components: {
    ProjectHeader
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [{ label: 'Projects', name: 'network.projects', params: { code: this.context.code } }]
    },
    loading () {
      return Boolean(this.loadingProject)
    },
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      loadingProject: true,
      project: null,
      previewBetaFeatures: true
    }
  },
  methods: {
    loadProject (refreshOnly) {
      if (!refreshOnly) this.loadingProject = true
      const endpoint = this.api.route + '/projects/' + this.$route.params.id
      this.$http.get(endpoint).then(response => {
        this.project = response.data
        this.loadingProject = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.loadProject()
  }
}
</script>
