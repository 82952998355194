<template>
  <b-dropdown position="is-bottom-left" class="has-text-left" >
    <template #trigger>
      <b-button
        type="is-ghost"
        :append-to-body="true"
        outlined
      ><b-icon icon="dots-vertical" /></b-button>
    </template>
    <template v-if="delivery.submitted_at">
      <b-dropdown-item @click="workflowAction('delivery:withdraw')">Withdraw Delivery</b-dropdown-item>
    </template>
    <template v-else>
      <b-dropdown-item disabled>No Actions</b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context'])
  },
  methods: {
    workflowAction (action) {
      this.$emit(action, this.delivery)
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
