<template>
  <div>
    <div class="section">
      <div class="container">
        <!-- Header -->
        <div>
          <div v-if="!loading">
            <Breadcrumbs :history="breadcrumbs" current="Transaction Detail" />
            <div class="has-background-white has-shadow pa-md mb-xxs mb-lg">
              <div class="columns is-vcentered">
                <div class="column">
                  <p class="has-text-weight-bold is-size-5">
                    {{ invoice.billing_period.label }}:
                    {{ invoice.project.name }}
                  </p>
                  <p class="has-text-grey-light is-size-7">ID: {{ invoice.id }}</p>
                </div>
                <div class="column">
                  <p class="has-text-right is-size-5">
                    <StatusPresenter :invoice="invoice"  size="is-medium" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="has-text-weight-bold is-size-5">Loading...</p>
            <p>&nbsp;</p>
          </div>
        </div>
        <!-- Content -->
        <div>
          <div v-if="!loading && invoice" class="columns">
            <div class="column is-narrow">
              <div class="">
                <b-menu>
                  <b-menu-list label="Transaction Menu">
                    <b-menu-item
                      label="Summary"
                      tag="router-link"
                      :to="{ name: 'network.client.invoice', params: { code: context.code, invoice: invoice.id } }"
                      exact
                    ></b-menu-item>
                  </b-menu-list>
                </b-menu>
              </div>
            </div>
            <!-- Child Router View -->
            <div class="column">
              <router-view :invoice="invoice" @invoice:saved="loadInvoice(true)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/ClientInvoice/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Finance', name: 'network.finance', params: { code: this.context.code } },
        { label: 'Transactions', name: 'network.finance.client.invoices', params: { code: this.context.code } }
      ]
    },
    loading () {
      return this.loadingInvoice
    }
  },
  data () {
    return {
      loadingInvoice: true,
      invoice: null
    }
  },
  methods: {
    loadInvoice (quiet = false) {
      if (!quiet) this.loadingInvoice = true
      const endpoint = this.api.route + '/admin/client-invoices/' + this.$route.params.invoice
      this.$http.get(endpoint).then(response => {
        this.invoice = response.data
        this.loadingInvoice = false
      })
    }
  },
  mounted () {
    this.loadInvoice()
  }
}
</script>
