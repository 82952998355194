<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <b-message type="is-info">
          <p class="is-size-5 has-text-weight-bold mb-lg">Link Project to External Platforms</p>
          <div class="is-size-7">
            <p>
              You can link this project to external platforms that you have connected to GatherWorks in order
              to sync data easily.
            </p>
          </div>
        </b-message>
        <!-- Form -->
        <div class="mb-lg">
          <div class="columns is-align-items-flex-end is-mobile">
            <div class="column">
              <b-field
                label="Platform"
                :type="('platform_account_id' in errors) ? 'is-danger' : ''"
                :message="('platform_account_id' in errors) ? errors['platform_account_id'][0] : ''">
                <PlatformAccountSelect v-model="platformAccount" :size="null" />
              </b-field>
            </div>
            <div class="column">
              <b-field
                label="Identifier"
                :type="('platform_identifier' in errors) ? 'is-danger' : ''"
                :message="('platform_identifier' in errors) ? errors['platform_identifier'][0] : ''">
                <b-input type="text" v-model="platformIdentifier" />
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-button
                type="is-primary"
                native-type="submit"
                :loading="saving"
                :disabled="!ready || saving"
              >Save</b-button>
            </div>
          </div>
        </div>
        <!-- Existing -->
        <div v-if="identifiers.length">
          <p class="has-text-weight-bold">Existing Links</p>
          <table class="table is-fullwidth is-narrow is-paddingless is-size-7">
            <thead>
              <tr>
                <th>Platform</th>
                <th>Identifier</th>
                <th style="width:64px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="identifier in identifiers" :key="identifier.id">
                <td>{{ identifier.platform_account.platform.name }}</td>
                <td>
                  {{ identifier.platform_resource }}:
                  {{ identifier.platform_identifier }}
                </td>
                <td class="has-text-right">
                  <a href="#" @click.prevent="focus(identifier)">Edit</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/Models/PlatformAccount/SelectMenu'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.platformAccount && this.platformIdentifier)
    },
    method () {
      return (this.id) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.id)
        ? this.api.route + '/identifiers/' + this.id
        : this.api.route + '/identifiers/'
    },
    json () {
      return {
        resource_type: 'project',
        resource_id: this.project.id,
        platform_resource: 'Customer',
        platform_account_id: this.platformAccount.id,
        platform_identifier: this.platformIdentifier
      }
    }
  },
  data () {
    return {
      loadingIdentifiers: true,
      identifiers: [],
      id: null,
      platformAccount: null,
      platformIdentifier: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadIdentifiers () {
      this.loadingIdentifiers = true
      const endpoint = this.api.route + '/identifiers/'
      const query = {
        params: {
          project_id: this.project.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.identifiers = response.data.data
        this.loadingIdentifiers = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.loadIdentifiers()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    focus (identifier) {
      this.id = identifier.id
      this.platformAccount = identifier.platform_account
      this.platformIdentifier = identifier.platform_identifier
    }
  },
  mounted () {
    this.loadIdentifiers()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
