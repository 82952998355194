<template>
  <div>
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <ProjectFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
            </b-select>
          </div>
          <div v-role="'manage-projects'" class="column is-narrow">
            <b-button type="is-primary" outlined size="is-medium" @click="showCreateProjectModal = true">New Project</b-button>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 project found</p>
              <p v-else>{{ total }} projects found</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            header-class="is-size-8"
            width="48"
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            label="Project"
            header-class="is-size-8"
            v-slot="props">
            <p>
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.project.lines', params: { code: context.code, id: props.row.id } }">
                {{ props.row.name }}
              </router-link>
            </p>
            <div class="is-size-8">
              <p>{{ props.row.client.organization.name }}</p>
            </div>
          </b-table-column>
          <b-table-column
            label="Dates"
            header-class="is-size-8"
            width="164"
            centered
            v-slot="props">
            <p>{{ $dates(props.row.start_date).format('MMM D, YY') }} - {{ $dates(props.row.end_date).format('MMM D, YY') }}</p>
          </b-table-column>
          <b-table-column
            label="Funded"
            header-class="is-size-8"
            width="64"
            cell-class="nowrap"
            centered
            v-slot="props">
            <p v-if="props.row.funding.length">
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.project.detail', params: { code: context.code, id: props.row.id } }">
                Yes
              </router-link>
            </p>
            <p v-else class="has-text-centered has-text-grey-light">No</p>
          </b-table-column>
          <b-table-column
            label="Status"
            header-class="is-size-8"
            v-slot="props"
            centered
            width="128">
            <StatusPresenter :project="props.row" />
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate" />
    </div>
    <!-- Modal: Create Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateProjectModal"
      :can-cancel="['x', 'esc']"
      ><CreateProjectModal @project:created="load(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import presets from '../presets/projects'
import Filterable from '@/mixins/Filterable'
import ProjectFilters from '../components/Active/ProjectFilters'
import StatusPresenter from '@/components/Models/Project/Presenters/Status'
import CreateProjectModal from '@/components/Models/Project/Modals/CreateProject'

export default {
  components: {
    CreateProjectModal,
    ProjectFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/projects'
    }
  },
  data: function () {
    return {
      key: 'projects',
      showCreateProjectModal: false,
      alwaysAttach: {
        approved: 1
      },
      // presets
      presets: presets
    }
  },
  methods: {

  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
