<template>
  <div>
    <div class="columns">
      <div v-if="canEdit" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('change:role')">Change Role</b-button>
      </div>
      <div v-if="canActivate" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('activate:role')">Activate User</b-button>
      </div>
      <div v-if="canDeactivate" class="column is-one-quarter">
        <b-button type="is-light" size="is-medium" expanded @click="$emit('suspend:role')">Deactivate User</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    canEdit () {
      return true
    },
    canActivate () {
      return (this.role.suspended_at !== null)
    },
    canDeactivate () {
      return (this.role.approved_at !== null && this.role.suspended_at === null)
    }
  },
  props: ['role']
}
</script>
