<template lang="html">
  <div>
    <div class="pa-sm">
      <!-- Filter Header -->
      <div class="columns is-mobile is-marginless">
        <div class="column is-narrow">
          <b-icon :icon="filter.icon"></b-icon>
        </div>
        <div class="column">
          <p class="has-text-weight-bold mb-sm">{{ filter.label }}</p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="expanded = !expanded">{{ (expanded) ? 'Collapse' : 'Expand' }}</b-button>
        </div>
      </div>
      <!-- Selected Options (Tags) -->
      <div v-if="!expanded" class="pb-md px-md">
        <b-field grouped group-multiline>
          <!-- No filters enabled -->
          <div v-if="selected.length === 0">
            <p class="has-text-grey-light mb-sm is-size-7">No options applied for this filter (click 'Expand' to select).</p>
          </div>
          <div v-else v-for="option in selected" class="control" :key="option.value">
            <b-tag type="is-primary" attached closable @close="remove(option)">{{ option.label }}</b-tag>
          </div>
        </b-field>
      </div>
      <!-- Available Options (Checkboxes/Radios) -->
      <div v-else class="pb-md px-md">
        <div v-for="option in filter.options" :key="option.value" class="field">
          <b-checkbox v-model="selected" :native-value="option" @input="announce">{{ option.label }}</b-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      expanded: false,
      selected: []
    }
  },
  methods: {
    remove (option) {
      const index = this.$utils.findIndex(this.selected, (n) => {
        return n.value === option.value
      })
      this.selected.splice(index, 1)
      this.announce()
    },
    announce () {
      this.$emit('input', this.selected)
    }
  },
  created () {
    if (typeof this.value !== 'undefined' && Array.isArray(this.value) && this.value.length > 0) {
      this.selected = this.value
    }
  },
  props: ['filter', 'value']
}
</script>
