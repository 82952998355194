<template>
  <div>
    <div class="has-background-white rounded-lg px-lg py-md mb-lg">
      <div class="columns is-multiline">
        <!-- General -->
        <div class="column is-half">
          <h1 class="has-text-weight-bold has-border-b pb-sm">Details</h1>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>ID</td>
                <td class="has-text-right">{{ period.id }}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td class="has-text-right">{{ $dates(period.start_date).format("MMM D, YYYY") }}</td>
              </tr>
              <tr>
                <td>End Date</td>
                <td class="has-text-right">{{ $dates(period.end_date).format('MMM D, YYYY') }}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td class="has-text-right">{{ period.duration }} Days</td>
              </tr>
              <tr>
                <td>Business Days</td>
                <td class="has-text-right">{{ period.business_days }} Days</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Contact & Billing -->
        <div class="column is-half">
          <h1 class="has-text-weight-bold has-border-b pb-sm">Key Dates</h1>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Hours Due</td>
                <td class="has-text-right">{{ $dates(period.hours_due_on).format("MMM D, YYYY") }}</td>
              </tr>
              <tr>
                <td>Estimated Close Date</td>
                <td class="has-text-right">{{ $dates(period.estimated_close_on).format('MMM D, YYYY') }}</td>
              </tr>
              <tr>
                <td>Actual Close Date</td>
                <td class="has-text-right">
                  <span v-if="period.closed_at">{{ $dates(period.closed_at).format('MMM D, YYYY') }}</span>
                  <span class="has-text-grey-light">--</span>
                </td>
              </tr>
              <tr>
                <td>Invoices Due</td>
                <td class="has-text-right">{{ $dates(period.invoices_due_on).format('MMM D, YYYY') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['period']
}
</script>
