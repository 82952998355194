<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Manage Project Roles</p>
      </header>
      <section class="modal-card-body">
        <!-- Add -->
        <div class="mb-lg">
          <label class="label">Add People</label>
          <div class="columns">
            <div class="column">
              <b-field>
                <b-taginput
                  v-model="users"
                  :data="filteredAvailableRoles"
                  :allow-new="false"
                  :open-on-focus="false"
                  field="label"
                  size="is-medium"
                  icon="account"
                  placeholder="Add a user"
                  autocomplete
                  @typing="getFilteredRoles">
                </b-taginput>
              </b-field>
              <b-field>
                <b-checkbox v-model="notify" true-value="1" false-value="0">Notify people?</b-checkbox>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-select v-model="roleType" size="is-medium" placeholder="Role" expanded>
                <option v-for="type in roleTypes" :key="type.id" :value="type">{{ type.name }}</option>
              </b-select>
            </div>
          </div>
        </div>
        <!-- Existing -->
        <div v-if="!loadingExistingRoles" class="mb-lg">
          <div v-if="existingRoles.length > 0">
            <p class="has-text-weight-bold mb-sm">People with access</p>
            <div v-for="role in existingRoles" :key="role.id" class="has-background-light rounded-lg pa-md mb-xxs">
              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <div class="has-background-primary has-text-white rounded-full has-text-centered is-size-8" style="width:32px; height:32px; padding-top:6px">
                    {{ role.user.initials }}
                  </div>
                </div>
                <div class="column">
                  <div class="is-size-7">
                    <p class="has-text-weight-bold">{{ role.user.full_name }}</p>
                    <p class="has-text-grey">{{ role.type.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="has-text-grey-light has-text-centered py-xl">
            <p>Only administrators may access this project.</p>
            <p>Add a person above to grant access to other users.</p>
          </div>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save Roles</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.roleType && this.users.length > 0)
    },
    json () {
      const json = {
        role_type_id: this.roleType.id,
        notify: this.notify,
        users: []
      }
      this.users.forEach(user => {
        json.users.push({
          id: user.user_id
        })
      })
      return json
    }
  },
  data () {
    return {
      loadingRoleTypes: true,
      loadingAvailableRoles: true,
      loadingExistingRoles: true,
      roleTypes: [],
      availableRoles: [],
      filteredAvailableRoles: [],
      existingRoles: [],
      roleType: null,
      notify: '1',
      users: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadRoleTypes () {
      this.loadingRoleTypes = true
      const endpoint = this.api.route + '/role-types'
      const query = {
        params: {
          resource: 'project',
          is_assignable: 1
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.roleTypes = response.data
        if (response.data.length === 1) {
          this.roleType = response.data[0]
        }
        this.loadingRoleTypes = false
      })
    },
    loadAvailableRoles () {
      this.loadingAvailableRoles = true
      const endpoint = this.api.route + '/roles/leadership'
      this.$http.get(endpoint).then(response => {
        this.availableRoles = response.data
        this.loadingAvailableRoles = false
      })
    },
    loadExistingRoles () {
      this.loadingExistingRoles = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/roles'
      this.$http.get(endpoint).then(response => {
        this.existingRoles = response.data
        this.loadingExistingRoles = false
      })
    },
    getFilteredRoles (text) {
      this.filteredAvailableRoles = this.availableRoles.filter((option) => {
        return option.label
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/roles'
      this.$http.post(endpoint, this.json).then(response => {
        this.users = []
        this.$buefy.toast.open({ type: 'is-success', message: 'Roles updated!' })
        this.loadExistingRoles()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadRoleTypes()
    this.loadAvailableRoles()
    this.loadExistingRoles()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
