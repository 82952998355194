<template>
  <div>
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <FundingFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
            </b-select>
          </div>
          <div v-role="'create-funding'" class="column is-narrow">
            <router-link
              :to="{ name: 'network.funding.create', params: { code: context.code }}"
              class="button is-primary is-outlined is-medium"
            >New Funding</router-link>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 record found</p>
              <p v-else>{{ total }} records found</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="64"
            centered
            cell-class="nowrap"
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            label="Type"
            width="96"
            centered
            v-slot="props">
            <p>{{ props.row.funding_type.abbr }}</p>
          </b-table-column>
          <b-table-column
            label="Identifier"
            width="128"
            cell-class="nowrap"
            v-slot="props">
            <span v-if="props.row.client_identifier">{{ props.row.client_identifier }}</span>
            <span v-else class="has-text-grey-light">--</span>
          </b-table-column>
          <b-table-column
            label="Name"
            cell-class="nowrap"
            v-slot="props">
            <p class="">
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.funding', params: { code: context.code, id: props.row.id } }">
                {{ props.row.name }}
              </router-link>
            </p>
            <p class="has-text-grey">{{ props.row.client.name }}</p>
          </b-table-column>
          <b-table-column
            label="Total Budget"
            cell-class="nowrap"
            numeric
            v-slot="props">
            <Currency :value="props.row.total_funding" />
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            v-slot="props"
            centered
            width="128">
            <StatusPresenter :funding="props.row" />
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No funding found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate" />
    </div>
    <!-- Modal: Create Funding -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateFundingModal"
      :can-cancel="['x', 'esc']"
      ><CreateFundingModal @funding:created="redirect" />
    </b-modal>
  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import FundingFilters from '../components/List/FundingFilters'
import StatusPresenter from '@/components/Models/Funding/Presenters/Status'
import CreateFundingModal from '@/components/Models/Funding/Modals/CreateFundingModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateFundingModal,
    FundingFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/funding'
    }
  },
  data: function () {
    return {
      key: 'funding',
      showCreateFundingModal: false,
      alwaysAttach: {
        approved: 1
      }
    }
  },
  methods: {
    redirect (funding) {
      this.$router.push({
        name: 'network.funding',
        params: { code: this.context.code, id: funding.id }
      })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
