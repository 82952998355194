<template>
  <b-dropdown
    ref="dropdown"
    class="has-text-left"
    position="is-bottom-left"
    :close-on-click="false"
    @active-change="reset">
    <template #trigger="{ active }">
      <b-button
        label="Options"
        type="is-primary"
        size="is-small"
        outlined
        :icon-right="active ? 'menu-up' : 'menu-down'" />
    </template>
    <b-dropdown-item @click="download">Download</b-dropdown-item>
    <b-dropdown-item v-if="!confirmRemoval" @click="confirm" :disabled="readonly">Remove</b-dropdown-item>
    <b-dropdown-item v-else @click="remove" class="has-text-danger" :disabled="readonly">Confirm Delete?</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/' + this.attachment.route
    }
  },
  data () {
    return {
      loading: true,
      confirmRemoval: false
    }
  },
  methods: {
    download () {
      this.loading = true
      const endpoint = this.api.route + '/' + this.route + '/attachments/' + this.attachment.id + '/download'
      this.$http.get(endpoint, { responseType: 'blob' }).then(response => {
        const blob = new Blob([response.data], { type: this.attachment.mime })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.attachment.filename
        link.click()
      }).finally(() => {
        this.$refs.dropdown.toggle()
        this.loading = false
      })
    },
    remove () {
      this.loading = true
      const endpoint = this.api.route + '/' + this.route + '/attachments/' + this.attachment.id
      this.$http.delete(endpoint).then(() => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Attachment Removed!' })
        this.$emit('attachment:deleted')
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'Attachment could not be removed!' })
      }).finally(() => {
        this.$refs.dropdown.toggle()
        this.loading = false
      })
    },
    confirm () {
      this.confirmRemoval = true
      setTimeout(() => {
        this.confirmRemoval = false
      }, 5000)
    },
    reset (status) {
      if (status) this.confirmRemoval = false
    }
  },
  props: {
    attachment: {
      type: Object,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
