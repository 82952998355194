<template>
  <div>
    <div v-if="typeof this.errors === 'object'" class="mb-lg">
      <b-message type="is-danger is-size-7">
        <p>Please review the following errors...</p>
        <div class="content">
          <ul>
            <li v-for="(property, index) in errors.errors" :key="index">{{ property[0] }}</li>
          </ul>
        </div>
      </b-message>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: [Object, Array],
      required: true
    }
  }
}
</script>
