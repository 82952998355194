<template>
  <b-dropdown position="is-bottom-left" class="has-text-left">
    <template #trigger="{ active }">
      <b-button
        label="Add"
        :type="type"
        :append-to-body="true"
        :icon-right="active ? 'menu-up' : 'menu-down'"
        outlined
      ></b-button>
    </template>
    <b-dropdown-item @click="workflowAction('position:seat')">Add Time &amp; Materials Role</b-dropdown-item>
    <b-dropdown-item @click="workflowAction('position:delivery')">Add Fixed Fee</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['context']),
    canCopy () {
      return (navigator.clipboard && window.isSecureContext)
    }
  },
  data () {
    return {}
  },
  methods: {
    workflowAction (action) {
      this.$emit(action, this.position)
    },
    copy (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Copied!' })
      })
    }
  },
  mounted () {

  },
  props: {
    projectId: {
      type: Number,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    type: {
      type: [String, null],
      default: 'is-ghost'
    }
  }
}
</script>
