<template>
  <div class="section">
    <div class="container">
      <div class="py-xxl">
        <div class="has-text-centered mb-lg">
          <p class="is-size-4 mb-sm">Select Account</p>
          <p class="has-text-grey">
            Please select the account you wish to view.
          </p>
        </div>
        <div class="py-lg">
          <ContextInternal v-if="auth.isStaff"></ContextInternal>
          <ContextExternal v-else></ContextExternal>
        </div>
        <p class="has-text-centered mt-lg"><a href="" @click.prevent="logout">Logout</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContextInternal from '../components/ContextInternal'
import ContextExternal from '../components/ContextExternal'
export default {
  components: {
    ContextInternal,
    ContextExternal
  },
  computed: {
    ...mapGetters(['auth'])
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>
