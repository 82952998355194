<template>
  <div>
    <!-- Has Attachments -->
    <b-table
      :data="funding.projects"
      :mobile-cards="false"
      hoverable
      narrowed
      class="is-size-7">
      <b-table-column
        label="ID"
        header-class="is-size-8"
        width="64"
        v-slot="props">
        <span class="has-text-grey-light">{{ props.row.id }}</span>
      </b-table-column>
      <b-table-column
        label="Name"
        header-class="is-size-8"
        v-slot="props">
        <router-link
          class="has-text-weight-bold"
          :to="{ name: 'network.project.lines', params: { code: context.code, id: props.row.id } }">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        label="Dates"
        width="128"
        header-class="is-size-8"
        v-slot="props">
        {{ $dates(props.row.start_date).format('MMM D') }} - {{ $dates(props.row.end_date).format('MMM D') }}
      </b-table-column>
      <b-table-column
        label="Project Budget"
        header-class="is-size-8"
        width="128"
        centered
        v-slot="props">
        <Currency :value="props.row.amount_budgeted" />
      </b-table-column>
      <b-table-column
        label="Status"
        header-class="is-size-8"
        v-slot="props"
        centered
        width="128">
        <StatusPresenter :project="props.row" />
      </b-table-column>
      <template #empty>
        <div class="has-text-centered has-text-grey-light pa-lg">No projects have been linked yet.</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Project/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['context'])
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
