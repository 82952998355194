<template>
  <span :class="output.textClass">{{ output.value }} </span>
</template>

<script>
export default {
  computed: {
    output () {
      if (this.spend > 0) {
        return {
          value: this.$numbers(this.spend).formatCurrency({ thousandSeparated: true, mantissa: 2 }),
          textClass: 'has-text-weight-bold has-text-info'
        }
      } else if (this.spend < 0) {
        const spend = Math.abs(parseFloat(this.spend))
        return {
          value: this.$numbers(spend).formatCurrency({ thousandSeparated: true, mantissa: 2 }),
          textClass: 'has-text-weight-bold has-text-warning'
        }
      }
      return {
        value: '--',
        textClass: 'has-text-grey-light'
      }
    }
  },
  props: ['spend']
}
</script>
