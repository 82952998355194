<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <div>
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Mark as Incomplete?</p>
              <p v-if="context.type === 'partner'" class="is-size-7">
                If you need to make changes to this delivery, you can withdraw it from review.
                Are you sure you want to proceed?
              </p>
              <p v-else class="is-size-7">
                If you need to make changes to this delivery, you can withdraw it from review.
                Are you sure you want to proceed?
              </p>
            </b-message>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving">
            Mark as Incomplete
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context', 'role']),
    endpoint () {
      return (this.context.type === 'network')
        ? this.api.route + '/projects/' + this.delivery.position.project_id + '/deliveries/' + this.delivery.id + '/actions/withdraw'
        : this.api.route + '/deliveries/' + this.delivery.id + '/actions/withdraw'
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.put(this.endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Deliverable updated!' })
        this.$parent.close()
        this.$emit('delivery:saved')
      })
    }
  },
  props: {
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
