<template>
  <div>
    <p class="has-text-weight-bold is-uppercase is-size-7 mb-md">Budget</p>
    <div class="columns">
      <div class="column">
        <!-- Budget -->
        <div class="">
          <p class="is-size-4"><Currency :value="budget" /></p>
          <p class="is-size-8 has-text-grey-light is-uppercase">Plan</p>
        </div>
        <!-- Spend -->
        <div class="  ">
          <p class="is-size-4"><Currency :value="spend" /></p>
          <p class="is-size-8 has-text-grey-light is-uppercase">Spend</p>
        </div>
        <!-- Remaining -->
        <div class="">
          <p class="is-size-4"><Currency :value="budget - spend" /></p>
          <p class="is-size-8 has-text-grey-light is-uppercase">Remaining</p>
        </div>
      </div>
      <div>
        <BurnSummaryChart :project="project" :stage="'billable'" :height="200" />
      </div>
    </div>

  </div>
</template>

<script>
import BurnSummaryChart from './BurnSummaryChart'

export default {
  components: {
    BurnSummaryChart
  },
  computed: {
    budget () {
      if ('planned' in this.project.aggregates) {
        return this.project.aggregates.planned.billable_total
      }
      return 0
    },
    spend () {
      if ('billable' in this.project.aggregates) {
        return this.project.aggregates.billable.billable_total
      }
      return 0
    },
    remaining () {
      if ('billable' in this.project.aggregates) {
        return this.project.aggregates.billable.billable_total
      }
      return 0
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
