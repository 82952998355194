<template>
  <div >
    <div class="has-background-white has-shadow pa-md mb-xxs">
      <div class="columns space-between">
        <div class="column is-half">
          <b-select v-model="quarter" expanded>
            <option :value="null">- All Fiscal Quarters -</option>
            <option v-for="quarter in quarters" :key="quarter" :value="quarter">Q{{ quarter.split('.')[1] }} {{ quarter.split('.')[0] }}</option>
          </b-select>
        </div>
        <div class="column is-narrow">
          <b-button
            type="is-primary"
            @click.prevent="save"
            :disabled="saving"
            :loading="saving"
          >Save Allocations</b-button>
        </div>
      </div>
    </div>
    <div class="has-background-white has-shadow pa-md">
      <b-table
        :data="seats"
        :mobile-cards="false"
        class="is-size-7">
        <b-table-column
          v-slot="props"
          label="Contributor"
          header-class="nowrap is-size-8"
          cell-class="nowrap"
          sticky>
          <p class="has-text-weight-bold">{{ props.row.contributor }}</p>
          <p class="has-text-grey-light is-italic is-size-8">{{ props.row.role }}</p>
        </b-table-column>
        <b-table-column>&nbsp;</b-table-column>
        <template>
          <b-table-column
            v-for="(period, index) in periods"
            :key="index"
            :label="period.billing_period.label"
            header-class="nowrap is-size-8"
            cell-class="nowrap"
            v-slot="props"
            centered>
            <b-input
              v-model="props.row.periods[period.billing_period_id].units"
              size="is-small"
              type="number"
              :min="0"
              :disabled="props.row.periods[period.billing_period_id].is_closed"
            />
          </b-table-column>
        </template>
        <b-table-column
          label="Total (Hr)"
          v-slot="props"
          width="96"
          header-class="nowrap is-size-8"
          cell-class="nowrap"
          centered>
          <b-input
            :value="totals[props.row.id].units"
            size="is-small"
            readonly
            disabled
          />
        </b-table-column>
        <b-table-column
          label="Total ($)"
          v-slot="props"
          width="96"
          header-class="nowrap is-size-8"
          cell-class="nowrap"
          centered>
          <b-input
            :value="$numbers(totals[props.row.id].billable).formatCurrency({ mantissa: 0, thousandSeparated: true })"
            size="is-small"
            readonly
            disabled
          />
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api']),
    periods () {
      if (!this.quarter) return this.project.periods
      const periods = []
      this.project.periods.forEach(period => {
        if (period.fiscal_quarter === this.quarter) {
          periods.push(period)
        }
      })
      return periods
    },
    totals () {
      const totals = {
        units: 0,
        billable: 0
      }
      this.seats.forEach(seat => {
        const seatTotal = {
          units: 0,
          billable: 0
        }
        for (const billingPeriodId in seat.periods) {
          seatTotal.units += Number(seat.periods[billingPeriodId].units)
          seatTotal.billable += Number(seat.periods[billingPeriodId].units) * Number(seat.billRate)
        }
        totals[seat.id] = seatTotal
      })
      return totals
    },
    json () {
      const seats = []
      this.seats.forEach(seat => {
        const allocations = []
        for (const billingPeriodId in seat.periods) {
          if (!seat.periods[billingPeriodId].is_closed) {
            if (seat.periods[billingPeriodId].units) {
              allocations.push({
                billing_period_id: billingPeriodId,
                units: seat.periods[billingPeriodId].units
              })
            } else {
              allocations.push({
                billing_period_id: billingPeriodId,
                units: 0
              })
            }
          }
        }
        seats.push({
          seat_id: seat.id,
          allocations: allocations
        })
      })
      return { seats: seats }
    }
  },
  data () {
    return {
      seats: [],
      quarters: [],
      quarter: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/allocate'
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Allocations updated!' })
        this.$emit('project:saved')
      }).finally(() => {
        this.saving = false
      })
    },
    parseAllocations () {
      this.seats = []
      this.project.positions.forEach(position => {
        position.seats.forEach(seat => {
          const row = {
            id: seat.id,
            contributor: seat.contributor.user.full_name,
            role: seat.role,
            billRate: seat.plan_bill_rate,
            costRate: seat.plan_cost_rate,
            periods: {}
          }
          this.project.periods.forEach(period => {
            if (!this.quarter || (this.quarter && period.fiscal_quarter === this.quarter)) {
              const allocation = this.$utils.find(seat.allocations, { billing_period_id: period.billing_period_id })
              if (allocation) {
                row.periods[period.billing_period_id] = {
                  quarter: period.fiscal_quarter,
                  billing_period_id: period.billing_period_id,
                  is_closed: Boolean(period.billing_period.closed_at),
                  label: period.billing_period.label,
                  units: (Number(allocation.units)) ? Number(allocation.units) : 0,
                  billable: Number(allocation.billable)
                }
              } else {
                row.periods[period.billing_period_id] = {
                  quarter: period.fiscal_quarter,
                  billing_period_id: period.billing_period_id,
                  is_closed: Boolean(period.billing_period.closed_at),
                  label: period.billing_period.label,
                  units: null,
                  billable: 0
                }
              }
            }
          })
          this.seats.push(row)
        })
      })
    },
    parseQuarters () {
      this.project.periods.forEach(period => {
        if (!this.quarters.includes(period.fiscal_quarter)) {
          this.quarters.push(period.fiscal_quarter)
        }
      })
    }
  },
  mounted () {
    this.parseQuarters()
    this.parseAllocations()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    quarter (value) {
      this.parseAllocations()
    }
  }
}
</script>
