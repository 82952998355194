<template>
<div>
  <div v-if="!loading && projects.length > 0">
    <div class="has-background-white rounded-lg pa-md mb-xs">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-grey-light">
            {{ projects.length }} {{ projects.length === 1 ? 'Project' : 'Projects' }}
          </p>
        </div>
        <div class="column">
          <b-select
            v-model="view"
            expanded>
            <option value="period">Billing Period</option>
            <option value="totals">Totals</option>
          </b-select>
        </div>
      </div>
    </div>
    <div v-for="project in projects" :key="project.id">
      <div class="has-background-white rounded-lg pa-md mb-md">
        <ProjectSummary :project="project" :view="view" />
      </div>
    </div>
  </div>
  <div v-else>
    <Loading message="Loading projects..." />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectSummary from '../components/Projects/Project'

export default {
  components: {
    ProjectSummary
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/admin/billing-periods/' + this.$route.params.id + '/projects'
    }
  },
  data () {
    return {
      loading: true,
      projects: [],
      view: 'period'
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.projects = response.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.load()
  }
}
</script>
