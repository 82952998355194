<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Client Rate History</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loadingRateHistory">
          <table class="table is-fullwidth is-size-7 is-narrow">
            <thead>
              <tr>
                <th>Effective Date</th>
                <th class="has-text-centered">Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rate, index) in positionWithRates.rates" :key="index">
                <td>{{ $dates(rate.start_date).format('MMM D, YYYY') }}</td>
                <td class="has-text-centered">
                  <Currency :value="rate.rate" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api'])
  },
  methods: {
    loadRateHistory () {
      this.loadingRateHistory = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id + '/rates'
      this.$http.get(endpoint).then(response => {
        this.positionWithRates = response.data
        this.loadingRateHistory = false
      })
    }
  },
  data () {
    return {
      loadingRateHistory: true,
      positionWithRates: null
    }
  },
  mounted () {
    this.loadRateHistory()
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    }
  }
}
</script>
