<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <section class="modal-card-body">
          <!-- Header -->
          <div class="mb-lg">
            <b-message type="is-info">
              <p class="is-size-5 has-text-weight-bold mb-sm">Mark as Delivered?</p>
              <p v-if="context.type === 'partner'" class="is-size-7">
                When you mark this deliverable as done, your project lead will be
                notified and asked to approve your work. Are you sure you want to
                mark this deliverable as done?
              </p>
              <p v-else class="is-size-7">
                When you mark this fee as delivered, it will no longer be editable and will
                will be summarized into the billing period based on the actual delivery date.
              </p>
            </b-message>
          </div>
          <b-field label="Planned Delivery Date">
            <b-input
              type="date"
              size="is-medium"
              expanded
              :value="delivery.planned_delivery_date"
              readonly
              disabled>
            </b-input>
          </b-field>
          <b-field
            label="Actual Delivery Date"
            :type="('actual_delivery_date' in errors) ? 'is-danger' : ''"
            :message="('actual_delivery_date' in errors) ? errors['actual_delivery_date'][0] : ''">
            <b-input
              v-model="actualDeliveryDate"
              type="date"
              size="is-medium"
              expanded>
            </b-input>
          </b-field>
          <div class="mt-xl is-flex space-between">
            <b-button @click="$parent.close()" type="is-light">Cancel</b-button>
            <b-button
              type="is-primary"
              native-type="submit"
              :loading="saving"
              :disabled="saving">
              Mark as Delivered
            </b-button>
          </div>
        </section>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context', 'role']),
    endpoint () {
      return (this.context.type === 'network')
        ? this.api.route + '/projects/' + this.delivery.position.project_id + '/deliveries/' + this.delivery.id + '/actions/submit'
        : this.api.route + '/deliveries/' + this.delivery.id + '/actions/submit'
    }
  },
  data () {
    return {
      actualDeliveryDate: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const json = {
        actual_delivery_date: this.actualDeliveryDate
      }
      this.$http.put(this.endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Deliverable updated!' })
        this.$parent.close()
        this.$emit('delivery:saved')
      })
    }
  },
  mounted () {
    this.actualDeliveryDate = this.delivery.planned_delivery_date
  },
  props: {
    delivery: {
      type: Object,
      required: true
    }
  }
}
</script>
