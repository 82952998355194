<template>
  <div>
    <!-- Content -->
    <div>
      <General />
    </div>
  </div>
</template>

<script>
import General from '../components/Client/General'
export default {
  components: {
    General
  }
}
</script>
