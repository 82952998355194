<template>
  <div class="section py-xxl">
    <div class="container">
      <div class="">
        <p class="has-text-centered mb-xl">
          <router-link :to="{ name: 'auth.login' }"><Logo :height="67"  /></router-link>
        </p>
      </div>
      <div class="has-background-white rounded-lg pa-xl">
        <p class="has-text-weight-bold has-text-centered mb-lg">Privacy Policy</p>
        <!-- Content -->
        <div class="mb-md">
          {{ app.companyName }}, dba GatherWorks.com ({{ app.name }}), considers your privacy a high priority.
          This privacy statement explains what information {{ app.name }} collects from you through our web site
          and how we may use it. If you have any questions, please contact us at {{ app.supportEmail }}.
          The only individualized information that {{ app.name }} obtains about you through our web site is
          information you voluntarily supply. We will only use that information to fulfill
          the purpose for which it was supplied to us. {{ app.name }} obtains non-individualized, generic
          information about your visit to our web site through the use of cookies.
          Cookies are small pieces of data which are sent by a web site to your web browser and
          are stored on your computer. Data derived from the cookies is aggregated and used by {{ app.name }}
          for statistical tracking purposes. We use this information to understand general traffic
          patterns on our web site and to gauge and improve the web site's effectiveness. The data
          is not used for individual identification and {{ app.name }} in no way relates your personal
          information to these traffic pattern statistics. If you are concerned about the use of cookies,
          you may configure your web browser to either request your specific acceptance of a cookie or
          disable cookies entirely. You will still be able to fully navigate our web site if you choose
          to refuse or disable cookies. Legal Notice Unless otherwise indicated, this Web Site and
          its contents, including but not limited to all text, HTML code, CGI scripts, graphics,
          button images, and the site design and “look and feel,” are the property of {{ app.name }} and are
          protected pursuant to U.S. and foreign copyright and trademark laws. By accessing this Web Site,
          you agree to the following terms and conditions, which govern this Web Site: This Web Site and
          the material on this Web Site may not be modified, copied, distributed, republished,
          downloaded, uploaded or commercially exploited in any manner without the prior written consent of
          {{ app.name }}, except that you are permitted to download a copy of any of the materials on this
          Web Site on a single computer for personal noncommercial use, provided no copyright, trademark
          or other proprietary notices are removed. No intellectual property or other rights in or to
          this Web Site or its contents are transferred to you. The information on this Web Site is
          provided solely for illustrative purposes and does not create any business, contractual or
          employment relationship.
        </div>
        <div class="mb-md">
          THE MATERIAL ON THIS WEB SITE IS PROVIDED 'AS IS' AND IS WITHOUT
          WARRANTY OF ANY KIND. {{ $utils.upperCase(app.companyName) }}. DBA {{ app.name }} ({{ app.name }}) DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
          MERCHANTIBILITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS WEB SITE AND THE
          MATERIAL ON THIS WEB SITE. {{ app.name }} ALSO MAKES NO REPRESENTATIONS OR WARRANTIES AS TO WHETHER
          THE INFORMATION ACCESSIBLE ON THIS WEB SITE IS ACCURATE, COMPLETE OR CURRENT. TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAWS, IN NO EVENT SHALL {{ app.name }} BE LIABLE FOR ANY DAMAGES OF
          ANY KIND OR CHARACTER, INCLUDING WITHOUT LIMITATION ANY COMPENSATORY, INCIDENTAL, DIRECT,
          INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSS OF USE, LOSS OF DATA, LOSS OF
          INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES
          OF ANY KIND OR CHARACTER, EVEN IF {{ app.name }} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
          OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THIS WEB SITE OR THE CONTENTS OF THIS WEB
          SITE. The posting of any content or material to this Web Site and the creation of a link
          from this Web Site to any other Web Site are expressly prohibited without the prior written
          consent of {{ app.companyName}}, ({{ app.name }}) {{ app.name }} does not regularly
          review materials on Web Sites linked to this Web Site and does not necessarily endorse
          the materials appearing on any linked Web Sites. {{ app.name }} reserves the right to change
          these terms and conditions at any time by updating this posting. Use of this Web Site
          following any such changes shall constitute acceptance of such changes.
        </div>
        <p class="has-text-centered mb-xl">
          <router-link :to="{ name: 'login' }">Sign In</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logos/GatherWorks'
import { mapGetters } from 'vuex'

export default {
  components: {
    Logo
  },
  computed: {
    ...mapGetters(['app'])
  }
}
</script>
