<template>
  <form @submit.prevent="approve">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Activate Connection</p>
      </header>
      <section class="modal-card-body">
        <div>
          <p class="py-xl has-text-centered">Are you sure you want to activate this connection?</p>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Activate Connection</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return this.api.route + '/connections/' + this.connection.id + '/activate'
    },
    json () {
      return {}
    }
  },
  methods: {
    approve () {
      this.saving = true
      this.$http.put(this.endpoint, this.json).then(response => {
        this.saving = false
        this.$buefy.toast.open({ type: 'is-success', message: 'Partner activated!' })
        this.$parent.close()
        this.$emit('connection:activated', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: []
    }
  },
  props: {
    connection: {
      type: Object,
      default: null
    }
  }
}
</script>
