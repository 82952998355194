<template>
  <div>
    <!-- Message -->
    <div class="has-text-weight-bold has-text-primary is-size-5 mb-lg">
      <!-- Not Found -->
      <div v-if="!loading && invite === null">
        <p class="has-text-centered">
          We're sorry, but your invitation code is invalid or has expired. Please reach out
          to your partner to have your invitation resent
        </p>
      </div>
      <!-- Loading -->
      <div v-else-if="loading" class="mb-lg">
        <p class="has-text-centered">Checking invitation code...</p>
      </div>
      <div v-else class="mb-lg">
        <p class="has-text-centered">Welcome to GatherWorks!</p>
      </div>
    </div>
    <!-- Invite Action Selection -->
    <div v-if="!registering" class="columns is-mobile is-centered">
      <div class="column">
        <!-- Invite Loaded -->
        <div class="has-text-centered">
          <div class="columns">
            <div class="column">
              <div class="has-background-white pa-xl rounded-lg">
                <div v-if="!loading">
                  <p class="has-text-weight-bold mb-sm">Need an account?<p>
                  <p class="has-text-grey is-size-7 mb-lg">If you're new to Gather, start by registering for a new partner account</p>
                  <p v-if="invite !== null">
                    <b-button type="is-primary" @click="registering = true">Register with Gather</b-button>
                  </p>
                  <p v-else><b-button type="is-primary" :disabled="true">Register with Gather</b-button></p>
                </div>
                <div v-else><Loading size="is-medium" /></div>
              </div>
            </div>
            <div class="column">
              <div class="has-background-white pa-xl rounded-lg">
                <div v-if="!loading">
                  <p class="has-text-weight-bold mb-sm">Already a Partner?<p>
                  <p class="has-text-grey is-size-7 mb-lg">If you've already registred with Gather, sign in to accept this invitation.</p>
                  <div v-if="invite !== null">
                    <!-- User Not Authenticated (show Link to sign in)-->
                    <p v-if="!auth.authenticated">
                      <router-link :to="{ name: 'auth.login' }" class="button is-primary">Sign In</router-link>
                    </p>
                    <!-- User Is Authenticated (Show link to dashboard) -->
                    <p v-else-if="auth.authenticated">
                      <router-link :to="{ name: 'context' }" class="button is-primary">Go To Dashboard</router-link>
                    </p>
                    <!-- Invite Not Found -->
                    <p v-else><b-button type="is-primary" :disabled="true">Sign In</b-button></p>
                  </div>
                </div>
                <div v-else><Loading size="is-medium" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Registration Form -->
    <form v-else @submit.prevent="register">
      <div class="has-background-white pa-lg">
        <div class="mb-md">
          <b-field
            label="Business Name"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''"
            expanded>
              <b-input
                autocomplete="off"
                size="is-medium"
                type="text"
                name="name"
                v-model="businessName"
                @input="isDirty = 1"
              ></b-input>
          </b-field>
          <div class="columns">
            <div class="column is-half">
              <b-field
                label="First Name"
                :type="('first_name' in errors) ? 'is-danger' : ''"
                :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="firstName"
                    v-model="firstName"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field
                label="Last Name"
                :type="('last_name' in errors) ? 'is-danger' : ''"
                :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                expanded>
                  <b-input
                    autocomplete="off"
                    size="is-medium"
                    type="text"
                    name="lastName"
                    v-model="lastName"
                    @input="isDirty = 1"
                  ></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="mb-xl">
          <b-field
            label="Email"
            :type="('email' in errors) ? 'is-danger' : ''"
            :message="('email' in errors) ? errors['email'][0] : ''"
            expanded>
            <b-input
              placeholder="Email Address"
              size="is-medium"
              type="email"
              v-model="email"
              readonly
              :disabled="true"
            ></b-input>
          </b-field>
        </div>
        <div class="mb-md">
          <b-field label="Password">
            <b-input
              class="is-borderless is-shadowless"
              password-reveal
              placeholder="Password"
              size="is-medium"
              type="password"
              v-model="password"
            ></b-input>
          </b-field>
        </div>
        <div class="mb-xxl">
          <b-field label="Confirm Password">
            <b-input
              class="is-borderless is-shadowless"
              password-reveal
              placeholder="Password"
              size="is-medium"
              type="password"
              v-model="password"
            ></b-input>
          </b-field>
        </div>
        <!-- Buttons -->
        <div>
          <b-button
            class="mb-lg"
            native-type="submit"
            type="is-primary"
            size="is-medium"
            :disabled="saving"
            expanded
          >Register</b-button>
          <div class="has-text-centered">
            <p class="mb-sm"><a href="#" @click.prevent="registering=false">Go Back</a></p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'auth']),
    endpoint () {
      return this.api.host + '/v1/connect/' + this.code
    },
    json () {
      return {
        name: this.businessName,
        first_name: this.firstName,
        last_name: this.lastName,
        password: this.password,
        password_confirmation: this.passwordConfirmation
      }
    }
  },
  data () {
    return {
      loading: true,
      invite: null,
      code: null,
      registering: false,
      saving: false,
      businessName: null,
      firstName: null,
      lastName: null,
      email: null,
      password: 'password',
      passwordConfirmation: 'password',
      message: 'Please sign in.',
      errors: []
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.invite = response.data
        this.businessName = response.data.name
        this.firstName = response.data.first_name
        this.lastName = response.data.last_name
        this.email = response.data.email
      }).finally(() => {
        this.loading = false
      })
    },
    register () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$router.push({ name: 'auth.login' })
        this.$buefy.toast.open({ type: 'is-success', message: 'Registration complete. Try it out!' })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mixins: [],
  mounted () {
    this.code = this.$route.params.code
    setTimeout(() => {
      this.load()
    }, 500)
  }
}
</script>
