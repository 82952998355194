<template>
  <div>
    <form @submit.prevent="save">
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <!-- Back Link -->
        <div class="border-b pb-md mb-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-weight-bold">Add Fixed Fee</p>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'network.project.detail', params: { code: context.code }}">&lt; Back to Project Detail</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Fee Details -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <div v-if="!loading">
                <div class="mb-lg">
                  <b-field
                    label="Title"
                    :type="('title' in errors) ? 'is-danger' : ''"
                    :message="('title' in errors) ? errors['title'][0] : ''"
                    expanded>
                    <b-input
                      size="is-medium"
                      v-model="title"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Description"
                    :type="('description' in errors) ? 'is-danger' : ''"
                    :message="('description' in errors) ? errors['description'][0] : ''"
                    expanded>
                    <b-input
                      v-model="description"
                      type="textarea"
                    ></b-input>
                  </b-field>
                </div>
                <!-- Targets -->
                <div class="mb-lg">
                  <div class="columns">
                    <div class="column is-half">
                      <b-field
                        v-if="fee === null || !fee.started_at"
                        label="Client Budget"
                        :type="('client_budget' in errors) ? 'is-danger' : ''"
                        :message="('client_budget' in errors) ? errors['client_budget'][0] : ''"
                        expanded>
                        <p class="control"><span class="button is-medium is-static">$</span></p>
                        <b-input
                          size="is-medium"
                          type="number"
                          v-model="targetBudget"
                          expanded
                        ></b-input>
                      </b-field>
                      <b-field v-else label="Client Budget">
                        <b-input
                          size="is-medium"
                          type="text"
                          :value="$numbers(fee.target_budget).formatCurrency({ mantissa: 2, thousandSeparated: true })"
                          :disabled="true"
                          :readonly="true"
                          expanded
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <Loading message="Loading..." />
              </div>
            </div>
            <div class="column is-one-third">
              <div class="has-background-light rounded-lg pa-md">
                <p class="has-text-weight-bold is-size-6 mb-sm">Fixed Fee Details</p>
                <p class="has-text-grey is-size-7 mb-md">
                  Complete the form to add a new <b>fixed fee</b> to your project.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <div class="columns">
          <div class="column is-one-quarter">
            <b-button
              native-type="submit"
              type="is-primary"
              size="is-medium"
              :loading="saving"
              :disabled="saving || !ready"
              expanded
            >Save &amp; Return</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingFee)
    },
    method () {
      return (this.fee) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.fee)
        ? this.api.route + '/projects/' + this.project.id + '/fees/' + this.fee.id
        : this.api.route + '/projects/' + this.project.id + '/fees'
    },
    ready () {
      return Boolean(
        this.title && this.targetBudget
      )
    },
    json () {
      const json = {
        title: this.title,
        description: this.description
      }
      json.target_budget = this.targetBudget
      json.target_spend = this.targetSpend
      return json
    }
  },
  data () {
    return {
      loadingFee: false,
      fee: null,
      title: null,
      description: null,
      targetBudget: null,
      targetSpend: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadFee () {
      this.loadingFee = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/fees/' + this.$route.params.fee
      this.$http.get(endpoint).then(response => {
        this.fee = response.data
        this.title = this.fee.title
        this.description = this.fee.description
        this.targetBudget = this.fee.target_budget
        this.loadingFee = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Fee saved!' })
        this.$emit('refresh')
        this.$router.push({ name: 'network.project.detail', params: { code: this.context.code, id: this.project.id } })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (typeof this.$route.params.fee !== 'undefined') {
      this.loadFee()
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
