<template>
  <div>
    <!-- Filters Toolbar -->
    <form @submit.prevent="loadReport">
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <b-input
              type="text"
              v-model="search"
              size="is-medium"
              placeholder="Search" />
          </div>
          <div class="column is-narrow">
            <b-datepicker
              placeholder="All Time"
              v-model="dates"
              size="is-medium"
              range>
          </b-datepicker>
          </div>
          <div class="column is-narrow">
            <b-select v-model="sort" expanded @input="loadReport" size="is-medium">
              <option value="billable_total::desc">Highest Billable Amount</option>
              <option value="hours_billable::desc">Most Billable Hours</option>
              <option value="margin::desc">Highest Margin</option>
              <option value="margin::asc">Lowest Margin</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <b-button native-type="submit" size="is-medium" type="is-primary" @click="loadReport" outlined>Run</b-button>
          </div>
        </div>
      </div>
    </form>
    <b-table
      class="is-size-7"
      :data="results">
      <b-table-column
        label="Contributor"
        v-slot="props">
        <p>{{ props.row.contributor }}</p>
      </b-table-column>
      <b-table-column
        label="Hours"
        v-slot="props"
        width="128"
        numeric>
        <p><Hours :hours="props.row.hours_billable" /></p>
      </b-table-column>
      <b-table-column
        label="Approved"
        v-slot="props"
        width="128"
        numeric>
        <p><Currency :value="props.row.billable_total" /></p>
      </b-table-column>
      <b-table-column
        label="Cost"
        v-slot="props"
        width="128"
        numeric>
        <p><Currency :value="props.row.cost" /></p>
      </b-table-column>
      <b-table-column
        label="Bill Rate"
        v-slot="props"
        width="128"
        numeric>
        <p><Currency :value="props.row.bill_rate" /></p>
      </b-table-column>
      <b-table-column
        label="Cost Rate"
        v-slot="props"
        width="128"
        numeric>
        <p><Currency :value="props.row.cost_rate" /></p>
      </b-table-column>
      <b-table-column
        label="Margin"
        v-slot="props"
        width="128"
        numeric>
        <p><Percentage :value="props.row.margin" /></p>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered is-size-7 pa-md">
          <p v-if="!loadingReport">No results matching query</p>
          <p v-else>Loading...</p>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api']),
    query () {
      const sort = this.sort.split('::')
      const query = {
        params: {
          search: this.search,
          order: sort[0],
          direction: sort[1]
        }
      }
      if (this.dates !== null && this.dates.length === 2) {
        query.params.start = this.$dates(this.dates[0]).format('YYYY-MM-DD')
        query.params.end = this.$dates(this.dates[1]).format('YYYY-MM-DD')
      }
      return query
    }
  },
  data () {
    return {
      loadingReport: false,
      results: [],
      search: null,
      dates: null,
      sort: 'billable_total::desc'
    }
  },
  methods: {
    loadReport () {
      this.loadingReport = true
      const endpoint = this.api.route + '/admin/profit/contributor'
      this.$http.get(endpoint, this.query).then(response => {
        this.results = response.data
        this.loadingReport = false
      })
    }
  },
  mounted () {
    this.loadReport()
  }
}
</script>
