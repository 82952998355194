<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Add A Title</p>
        </header>
        <section class="modal-card-body">
          <!-- <b-field
            label="Category"
            :type="('category' in errors) ? 'is-danger' : ''"
            :message="('category' in errors) ? errors['category'][0] : ''">
            <b-select
              type="text"
              size="is-medium"
              v-model="category"
              expanded>
              <option value="Content">Content</option>
              <option value="Design">Design</option>
              <option value="IT">IT</option>
              <option value="Management">Management</option>
              <option value="Production">Production</option>
              <option value="Technical Writing">Technical Writing</option>
            </b-select>
          </b-field> -->
          <div class="columns">
            <div class="column">
              <b-field
                label="Title"
                :type="('title' in errors) ? 'is-danger' : ''"
                :message="('title' in errors) ? errors['title'][0] : ''"
                expanded>
                <b-input
                  type="text"
                  size="is-medium"
                  v-model="title"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field
                label="Rate Card ($ per hr)"
                :type="('rate_card' in errors) ? 'is-danger' : ''"
                :message="('rate_card' in errors) ? errors['rate_card'][0] : ''"
                expanded>
                <b-input
                  type="number"
                  size="is-medium"
                  v-model="rateCard"
                ></b-input>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="saving || !ready">
            Save Title
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      const base = this.api.route + '/clients/' + this.client.id + '/titles'
      return (this.seed)
        ? base + '/' + this.seed.id + '/'
        : base
    },
    method () {
      return (this.seed === null) ? 'post' : 'patch'
    },
    json () {
      return {
        // category: this.category,
        title: this.title,
        rate_card: this.rateCard
      }
    },
    ready () {
      return (this.title && this.rateCard)
    }
  },
  data () {
    return {
      category: null,
      title: null,
      rateCard: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(repsonse => {
        this.$emit('title:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Title saved!' })
        this.$parent.close()
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.seed) {
      this.category = this.seed.category
      this.title = this.seed.title
      this.rateCard = parseFloat(this.seed.rate_card).toFixed(2)
    }
  },
  props: {
    client: {
      type: Object
    },
    seed: {
      type: Object,
      default: null
    }
  }
}
</script>
