<template>
  <div>
    <div>
      <!-- Header -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div v-if="!loading">
          <div class="columns is-vcentered">
            <div class="column">
              <p class="has-text-weight-bold is-size-5">{{ revision.name }}</p>
              <p class="has-text-grey-light">ID: {{ revision.id }}</p>
            </div>
            <div class="column">
              <p class="has-text-right is-size-5">
                <StatusPresenter :revision="revision" />
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="has-text-weight-bold is-size-5">Loading...</p>
          <p>&nbsp;</p>
        </div>
      </div>
      <!-- Actions -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div v-if="!loading && revision">
          <Workflow :revision="revision"
            @action:approve="showApprovalModal = true"
            @archive:revision="showArchiveModal = true"
          />
        </div>
      </div>
      <!-- Profile -->
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <div v-if="!loading && revision">
          <div class="columns is-multiline">
            <!-- General -->
            <div class="column is-half">
              <h1 class="has-text-weight-bold has-border-b pb-sm">General Information</h1>
              <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>Organization</td>
                    <td class="has-text-right">{{ revision.funding.client.organization.name }}</td>
                  </tr>
                  <tr>
                    <td>Client</td>
                    <td class="has-text-right">{{ revision.funding.client.name }}</td>
                  </tr>
                  <tr>
                    <td>Funding</td>
                    <td class="has-text-right">
                      <router-link
                        class="has-text-weight-bold"
                        :to="{ name: 'network.funding', params: { code: context.code, id: revision.funding.id } }">
                        {{ revision.funding.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td class="has-text-right">{{ revision.description }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Contact & Billing -->
            <div class="column is-half">
              <h1 class="has-text-weight-bold has-border-b pb-sm">Approval</h1>
              <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>Approved</td>
                    <td class="has-text-right">
                      <span v-if="revision.approved_at !== null">{{ $dates.utc(revision.approved_at).format('MMM D, YYYY') }}</span>
                      <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Approved By</td>
                    <td class="has-text-right">
                      <span v-if="revision.approved_at !== null">
                        {{ revision.approver.full_name }}
                      </span>
                      <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--Loading -->
        <div v-else class="py-xl">
          <Loading />
        </div>
      </div>
      <!-- Lines -->
      <div v-if="!loading" class="has-background-white rounded-lg px-lg py-md mb-lg">
        <p class="has-text-weight-bold">Revision Lines</p>
        <b-table
          :data="revision.lines"
          :mobile-cards="false"
          class="is-size-7">
          <b-table-column
            label="ID"
            v-slot="props"
            cell-class="has-text-grey-light"
            width="96">
            <p>{{ props.row.id }}</p>
          </b-table-column>
          <b-table-column
            label="Line"
            v-slot="props">
            <p>{{ props.row.resource.description }}</p>
          </b-table-column>
          <b-table-column
            label="Original Amount"
            v-slot="props"
            centered
            width="200">
            <p><Currency :value="props.row.original_amount" /></p>
          </b-table-column>
          <b-table-column
            label="Revised Amount"
            v-slot="props"
            centered
            width="200">
            <p v-if="props.row.amount > 0" class=""><Currency :value="props.row.amount" /></p>
            <p v-else><Currency :value="props.row.revised_amount" /></p>
          </b-table-column>
        </b-table>
      </div>
    </div>
    <!-- Modal: Approve Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showApprovalModal"
      :can-cancel="['x', 'esc']"
      ><ApproveRevisionModal :revision="revision" @revision:saved="loadRevision(true)" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Workflow from '../components/Revision/Workflow'
import StatusPresenter from '@/components/Models/FundingRevision/Presenters/Status'
import ApproveRevisionModal from '@/components/Models/FundingRevision/Modals/Approve'

export default {
  components: {
    StatusPresenter,
    Workflow,
    ApproveRevisionModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return this.loadingRevision
    }
  },
  data () {
    return {
      loadingRevision: true,
      revision: null,
      showApprovalModal: false,
      showArchiveModal: false
    }
  },
  methods: {
    loadRevision (silent = false) {
      if (!silent) this.loadingRevision = true
      const endpoint = this.api.route + '/admin/revisions/' + this.$route.params.revision
      this.$http.get(endpoint).then(response => {
        this.revision = response.data
        this.loadingRevision = false
      })
    }
  },
  mounted () {
    this.loadRevision()
  }
}
</script>
