<template>
  <div>
    <!-- Content -->
    <div>
      <!-- Filters Toolbar -->
      <div class="has-background-white rounded-lg pa-md mb-xxs">
        <div class="columns">
          <div class="column">
            <AnnouncementFilters :applied-filters="filters" @filter="filter" />
          </div>
          <div class="column is-narrow">
            <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
              <option value="id_desc">Most Recent</option>
              <option value="id_asc">Oldest</option>
            </b-select>
          </div>
          <div class="column is-narrow">
            <router-link :to="{ name: 'network.settings.announcement' }" class="button is-primary is-outlined is-medium is-fullwidth">Create Announcement</router-link>
          </div>
        </div>
      </div>
      <!-- Actions Toolbar -->
      <div class="has-background-white rounded-lg px-md py-md mb-xxs">
        <div class="columns is-vcentered is-mobile">
          <div class="column">
            <div class="has-text-right is-size-7">
              <p v-if="loading">Loading...</p>
              <p v-else-if="total === 1">1 announcement</p>
              <p v-else>{{ total }} announcements</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Results -->
      <div class="mb-md">
        <b-table
          :data="results"
          :checked-rows.sync="selected"
          :mobile-cards="false"
          :loading="loading"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="64"
            v-slot="props">
            <span class="has-text-grey-light">{{ props.row.id }}</span>
          </b-table-column>
          <b-table-column
            label="Visibility"
            width="128"
            v-slot="props">
            <span>{{ $utils.upperFirst(props.row.visibility) }}</span>
          </b-table-column>
          <b-table-column
            label="Title"
            v-slot="props">
            <router-link
              :to="{ name: 'network.settings.announcement', params: { network: context.code, announcement: props.row.id }}"
              class="has-text-weight-bold"
            >{{ props.row.title }}</router-link>
          </b-table-column>
          <b-table-column
            label="Status"
            centered
            width="128"
            v-slot="props">
            <StatusPresenter :announcement="props.row" />
          </b-table-column>
          <template slot="empty">
            <div class="py-xxl has-text-grey has-text-centered">
              {{ (loading) ? "Loading data..." : 'No records found!' }}
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        :total="total"
        :current.sync="currentPage"
        :rounded="true"
        :per-page="perPage"
        @change="paginate">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Filterable from '@/mixins/Filterable'
import AnnouncementFilters from '../components/Announcements/AnnouncementFilters'
import StatusPresenter from '@/components/Models/Announcement/Presenters/Status'

export default {
  components: {
    AnnouncementFilters,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Finance', name: 'network.finance', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/admin/announcements'
    }
  },
  data: function () {
    return {
      key: 'admin.announcements'
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
