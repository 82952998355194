<template>
  <div>
    <div class="has-background-white rounded-lg pa-md">
      <!-- Results -->
      <div class="mb-lg">
        <b-table
          :data="order.activities"
          :mobile-cards="false"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            width="64"
            centered
            cell-class="has-text-grey-light"
            v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column
            label="User"
            width="200"
            v-slot="props">
            {{ props.row.actor.full_name }}
          </b-table-column>
          <b-table-column
            label="Action"
            v-slot="props">
            {{ $utils.truncate(props.row.action, {length: 128}) }}
          </b-table-column>
            <b-table-column
            label="Date"
            v-slot="props">
            {{ $dates.utc(props.row.created_at).local().format('MMM DD, YYYY @ h:mm A')}}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
