<template>
  <div>
    <!-- Content -->
    <div>
      <div class="columns is-multiline">
        <!-- Profile -->
        <div class="column is-half">
          <ProfileHeader>Funding Profile</ProfileHeader>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>GatherWorks ID</td>
                <td class="has-text-right">{{ funding.id }}</td>
              </tr>
              <tr>
                <td>Funding Type</td>
                <td class="has-text-right">
                  {{ funding.funding_type.name }}
                </td>
              </tr>
              <tr>
                <td>Client</td>
                <td class="has-text-right">
                  {{ funding.client.name }}
                </td>
              </tr>
              <tr>
                <td>Client {{ funding.funding_type.abbr }} #</td>
                <td class="has-text-right has-text-weight-bold">
                  <a href="#" @click.prevent="$emit('edit:meta')">
                    <span v-if="funding.client_identifier">{{ funding.client_identifier }}</span>
                    <span v-else>Edit</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Key Dates -->
        <div class="column is-half">
          <ProfileHeader>Key Dates</ProfileHeader>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Started On</td>
                <td class="has-text-right">
                  <span v-if="funding.activated_at !== null">{{ $dates.utc(funding.activated_at).format('MMM D, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Started Yet</span>
                </td>
              </tr>
              <tr v-if="funding.paused_at">
                <td>Paused On</td>
                <td class="has-text-right">
                  <span>{{ $dates.utc(funding.paused_at).format('MMM D, YYYY') }}</span>
                </td>
              </tr>
              <tr>
                <td>Ended On</td>
                <td class="has-text-right">
                  <span v-if="funding.ended_at !== null">{{ $dates.utc(funding.ended_at).format('MMM D, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Ended Yet</span>
                </td>
              </tr>
              <tr>
                <td>Approved On</td>
                <td class="has-text-right">
                  <span v-if="funding.approved_at !== null">{{ $dates.utc(funding.approved_at).format('MMM D, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Approved Yet</span>
                </td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td class="has-text-right">{{ $dates.utc(funding.updated_at).local().format('MMM D, YYYY') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Procument -->
        <div class="column is-half">
          <div class="has-border-b pb-sm">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-weight-bold is-size-8 is-uppercase">Procurement</p>
              </div>
              <div class="column is-narrow">
                <a href="#"
                  @click.prevent="showLinkProcurementModal = true"
                  class="is-size-8 is-uppercase has-text-weight-bold"
                >{{ (procurementPlatform) ? 'Edit' : 'Link' }}</a>
              </div>
            </div>
          </div>
          <div v-if="procurementPlatform">
            <table class="table is-narrow is-size-7 is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>Platform</td>
                  <td class="has-text-right">{{ procurementPlatform.platform_account.platform.name }}</td>
                </tr>
                <tr v-for="attribute in procurementPlatform.meta" :key="attribute.code">
                  <td>{{ attribute.label }}</td>
                  <td class="has-text-right">{{ attribute.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <div class="has-text-centered pa-md is-size-7">
              <p class="has-text-grey-light mb-sm">This {{ funding.funding_type.abbr }} is not linked to a procurement platform.</p>
              <p><b-button type="is-primary" size="is-small" outlined @click="showLinkProcurementModal = true">Link</b-button></p>
            </div>
          </div>
        </div>
        <!-- Accounting -->
        <div class="column is-half">
          <div class="has-border-b pb-sm">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-weight-bold is-size-8 is-uppercase">Accounting</p>
              </div>
              <div class="column is-narrow">
                <a href="#"
                  @click.prevent="showLinkAccountingModal = true"
                  class="is-size-8 is-uppercase has-text-weight-bold"
                >{{ (accoutingPlatform) ? 'Edit' : 'Link' }}</a>
              </div>
            </div>
          </div>
          <div v-if="accoutingPlatform">
            <table class="table is-narrow is-size-7 is-fullwidth is-striped">
              <tbody>
                <tr>
                  <td>Platform</td>
                  <td class="has-text-right">{{ accoutingPlatform.platform_account.platform.name }}</td>
                </tr>
                <tr v-for="attribute in accoutingPlatform.meta" :key="attribute.code">
                  <td>{{ attribute.label }}</td>
                  <td class="has-text-right">{{ attribute.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <div class="has-text-centered pa-md is-size-7">
              <p class="has-text-grey-light mb-sm">This {{ funding.funding_type.abbr }} is not linked to an accounting platform.</p>
              <p><b-button type="is-primary" size="is-small" outlined @click="showLinkAccountingModal = true">Link</b-button></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Link Procurement Platform -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showLinkProcurementModal"
      :can-cancel="['x', 'esc']"
      ><LinkProcurementModal :funding="funding" @funding:saved="$emit('funding:saved')"  />
    </b-modal>
    <!-- Modal: Link Accounting Platform -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showLinkAccountingModal"
      :can-cancel="['x', 'esc']"
      ><LinkAccountingModal :funding="funding" @funding:saved="$emit('funding:saved')"  />
    </b-modal>
  </div>
</template>

<script>
import ProfileHeader from '@/components/General/ProfileHeader'
import LinkProcurementModal from '@/components/Models/Funding/Modals/LinkProcurement'
import LinkAccountingModal from '@/components/Models/Funding/Modals/LinkAccounting'

export default {
  components: {
    ProfileHeader,
    LinkAccountingModal,
    LinkProcurementModal
  },
  computed: {
    procurementPlatform () {
      const platform = this.funding.platforms.find(platform => {
        return (platform.purpose === 'procurement')
      })
      return (platform) || null
    },
    accoutingPlatform () {
      const platform = this.funding.platforms.find(platform => {
        return (platform.purpose === 'accounting')
      })
      return (platform) || null
    }
  },
  data () {
    return {
      showLinkAccountingModal: false,
      showLinkProcurementModal: false
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
