<template>
  <nav class="breadcrumb is-size-7" aria-label="breadcrumbs">
    <ul>
      <li v-for="(route, index) in history" :key="index">
        <router-link :to="{ name: route.name, params: route.params }" exact>{{ route.label }}</router-link>
      </li>
      <li class="is-active"><a href="#" aria-current="page">{{ $utils.truncate(current, {length: 24}) }}</a></li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['context'])
  },
  props: {
    history: {
      type: Array
    },
    current: {
      type: String
    },
    showHome: {
      type: Boolean,
      default: true
    }
  }
}
</script>
