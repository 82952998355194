<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white has-shadow pa-md mb-md">
      <div class="columns is-vcentered">
        <div class="column is-offset-half is-half">
          <BillingPeriodSelect
            v-model="period"
            start="2023-01-01"
            :end="$dates().add(12, 'month').format('YYYY-MM-DD')" />
        </div>
      </div>
    </div>
    <!-- Forecast -->
    <div class="mb-md">
      <div class="columns">
        <div class="column">
          <div class="has-background-white has-shadow px-lg py-md has-text-centered has-text-weight-bold">
            <p class="is-size-8 is-uppercase has-text-grey-light">Forecast (Fixed)</p>
            <p class="is-size-5 has-text-weight-bold">
              <Currency :value="forecasts.fixed" />
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white has-shadow px-lg py-md has-text-centered has-text-weight-bold">
            <p class="is-size-8 is-uppercase has-text-grey-light">Forecast (Estimates)</p>
            <p class="is-size-5 has-text-weight-bold">
              <Currency :value="forecasts.estimates" />
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white has-shadow px-lg py-md has-text-centered has-text-weight-bold">
            <p class="is-size-8 is-uppercase has-text-grey-light">Forecast (Total)</p>
            <p class="is-size-5 has-text-weight-bold">
              <Currency :value="forecasts.total" />
            </p>
          </div>
        </div>
        <div class="column">
          <div class="has-background-white has-shadow px-lg py-md has-text-centered has-text-weight-bold">
            <p class="is-size-8 is-uppercase has-text-grey-light">Actual Billed</p>
            <p class="is-size-5 has-text-weight-bold">
              <Currency :value="0" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Bulk Actions -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <b-dropdown>
        <template #trigger="{ active }">
          <b-button
            label="Actions"
            type="is-primary"
            size="is-small"
            outlined
            :icon-right="active ? 'menu-up' : 'menu-down'" />
        </template>
        <b-dropdown-item @click.prevent>Mark as Billed</b-dropdown-item>
        <b-dropdown-item @click.prevent>Mark as Not Billed</b-dropdown-item>
        <b-dropdown-item @click.prevent>Mark as Skipped</b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- Table -->
    <div class="mb-md">
      <b-table
        :data="billings"
        :mobile-cards="false"
        :checked-rows.sync="selected"
        checkable
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          v-slot="props"
          cell-class="has-text-grey-light"
          width="48">
          <p>{{ props.row.id }}</p>
        </b-table-column>
        <b-table-column
          label="Billing Date"
          v-slot="props"
          width="164">
          <p>{{ $dates(props.row.billing_date).format('ddd,  MMM D, YYYY')}}</p>
        </b-table-column>
        <b-table-column
          label="Organization"
          v-slot="props"
          width="164">
          <p>{{ props.row.project.client.organization.name }}</p>
        </b-table-column>
        <b-table-column
          label="Project"
          v-slot="props">
          <router-link :to="{ name: 'network.project.lines', params: { code: context.code, id: props.row.project.id }}" class="has-text-weight-bold">
            {{ props.row.project.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          label="Amount"
          v-slot="props"
          centered
          width="128">
          <p><Currency :value="props.row.amount" /></p>
        </b-table-column>
        <b-table-column
          label="Project Status"
          v-slot="props"
          centered
          width="128">
          <p><StatusPresenter :project="props.row" /></p>
        </b-table-column>
        <b-table-column
          label="Billing Status"
          cell-class="has-text-weight-bold"
          v-slot="props"
          centered
          width="128">
          <p v-if="props.row.billed_at" class="has-text-success">Billed</p>
          <p v-else-if="props.row.approved_at" class="has-text-info">Approved</p>
          <p v-else class="has-text-grey">
            <router-link :to="{ name: 'network.finance.client.invoices.create', params: { code: context.code }, query: { event: props.row.id } }">Create Invoice</router-link>
          </p>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">No projects found that require billing.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'
import StatusPresenter from '@/components/Models/Project/Presenters/Status'

export default {
  components: {
    BillingPeriodSelect,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context']),
    forecasts () {
      const forecasts = {
        fixed: 0,
        estimates: 0,
        total: 0
      }
      this.billings.forEach(billingEvent => {
        if (billingEvent.model === 'fixed') {
          forecasts.fixed += Number(billingEvent.amount)
        }
        if (billingEvent.model === 'actuals') {
          forecasts.estimates += Number(billingEvent.amount)
        }
        forecasts.total += Number(billingEvent.amount)
      })
      return forecasts
    }
  },
  data () {
    return {
      selected: [],
      period: null,
      loadingBillings: false,
      billings: []
    }
  },
  methods: {
    loadBilling () {
      if (!this.period) return
      this.loadingBillings = true
      const endpoint = this.api.route + '/admin/billing-events'
      const query = {
        params: {
          billing_period_id: this.period.id
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.billings = response.data
        this.loadingBillings = false
      })
    }
  },
  mounted () {
    this.loadBilling()
  },
  watch: {
    period (period) {
      if (period) this.loadBilling()
    }
  }
}
</script>
