<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="has-text-weight-bold">
          {{ project.name }}
          <span class="has-text-grey-light">{{ project.client.name }}</span>
        </p>
      </div>
    </div>
    <div>
      <b-table
        :data="project.seats"
        :mobile-cards="false"
        hoverable
        narrowed
        class="is-size-7">
        <b-table-column
          label="Contributor"
          v-slot="props">
          <p>{{ props.row.contributor.user.full_name }}</p>
          <p class="has-text-grey-light">{{ props.row.role }}</p>
        </b-table-column>
        <b-table-column
          label="Forecast (Hrs)"
          centered
          :visible="view === 'period'"
          width="128"
          v-slot="props">
          <p><Hours :hours="props.row.statements[0].forecast_hours" /></p>
        </b-table-column>
        <b-table-column
          label="Actual (Hrs)"
          centered
          :visible="view === 'period'"
          width="128"
          v-slot="props">
          <p><Hours :hours="props.row.statements[0].actual_hours" /></p>
        </b-table-column>
        <b-table-column
          label="Difference (Hrs)"
          v-slot="props"
          centered
          :visible="view === 'period'"
          width="128">
          <p><DiffHours :hours="props.row.statements[0].diff_period_hours" /></p>
        </b-table-column>
        <!-- Spend -->
        <b-table-column
          label="Forecast ($)"
          centered
          :visible="view === 'period'"
          width="128"
          v-slot="props">
          <p><Spend :spend="props.row.statements[0].forecast_spend" /></p>
        </b-table-column>
        <b-table-column
          label="Actual ($)"
          centered
          :visible="view === 'period'"
          width="128"
          v-slot="props">
          <p><Spend :spend="props.row.statements[0].actual_spend" /></p>
        </b-table-column>
        <b-table-column
          label="Difference ($)"
          v-slot="props"
          centered
          :visible="view === 'period'"
          width="128">
          <p><DiffSpend :spend="props.row.statements[0].diff_period_spend" /></p>
        </b-table-column>
        <!-- Variance -->
        <b-table-column
          label="Variance (Hrs)"
          v-slot="props"
          centered
          :visible="view === 'period'"
          width="128">
          <p><Variance :variance="props.row.statements[0].diff_period_hours_variance" /></p>
        </b-table-column>
        <!-- Totals -->
        <!-- Totals -->
        <b-table-column
          label="Forecast (Hrs)"
          centered
          :visible="view === 'totals'"
          width="128"
          v-slot="props">
          <p><Hours :hours="props.row.statements[0].forecast_closing_hours" /></p>
        </b-table-column>
        <b-table-column
          label="Actual (Hrs)"
          centered
          :visible="view === 'totals'"
          width="128"
          v-slot="props">
          <p><Hours :hours="props.row.statements[0].actual_closing_hours" /></p>
        </b-table-column>
        <b-table-column
          label="Difference (Hrs)"
          v-slot="props"
          centered
          :visible="view === 'totals'"
          width="128">
          <p><DiffHours :hours="props.row.statements[0].diff_total_hours" /></p>
        </b-table-column>
        <!-- Spend -->
        <b-table-column
          label="Forecast ($)"
          centered
          :visible="view === 'totals'"
          width="128"
          v-slot="props">
          <p><Spend :spend="props.row.statements[0].forecast_closing_balance" /></p>
        </b-table-column>
        <b-table-column
          label="Actual ($)"
          centered
          :visible="view === 'totals'"
          width="128"
          v-slot="props">
          <p><Spend :spend="props.row.statements[0].actual_closing_balance" /></p>
        </b-table-column>
        <b-table-column
          label="Difference ($)"
          v-slot="props"
          centered
          :visible="view === 'totals'"
          width="128">
          <p><DiffSpend :spend="props.row.statements[0].diff_total_spend" /></p>
        </b-table-column>
        <!-- Variance -->
        <b-table-column
          label="Variance (Hrs)"
          v-slot="props"
          centered
          :visible="view === 'totals'"
          width="128">
          <p><Variance :variance="props.row.statements[0].diff_total_hours_variance" /></p>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>
import Hours from '@/components/Presenters/SeatStatement/Hours'
import DiffHours from '@/components/Presenters/SeatStatement/DiffHours'
import DiffSpend from '@/components/Presenters/SeatStatement/DiffSpend'
import Spend from '@/components/Presenters/SeatStatement/Spend'
import Variance from '@/components/Presenters/SeatStatement/Variance'

export default {
  components: {
    DiffHours,
    DiffSpend,
    Hours,
    Spend,
    Variance
  },
  computed: {
    periodTotals () {
      const totals = {
        forecastHours: 0,
        actualHours: 0,
        diffHours: 0,
        diffHoursVariance: 0,
        forecastSpend: 0,
        actualSpend: 0,
        diffSpend: 0,
        diffSpendVariance: 0
      }
      this.project.seats.forEach(seat => {
        if (seat.statements[0].forecast_hours) {
          totals.forecastHours += parseFloat(seat.statements[0].forecast_hours)
        }
        if (seat.statements[0].actual_hours) {
          totals.actualHours += parseFloat(seat.statements[0].actual_hours)
        }
        if (seat.statements[0].forecast_spend) {
          totals.forecastSpend += parseFloat(seat.statements[0].forecast_spend)
        }
        if (seat.statements[0].actual_spend) {
          totals.actualSpend += parseFloat(seat.statements[0].actual_spend)
        }
      })

      // calcualte differences
      totals.diffHours = totals.forecastHours - totals.actualHours
      totals.diffSpend = totals.forecastSpend - totals.actualSpend

      return totals
    }
  },
  props: ['project', 'view']
}
</script>
