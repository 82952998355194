import Index from './views/Index'
import FundingList from '../Funding/views/List'
import ProjectList from '../Projects/views/List'
import OrderList from '../PurchaseOrders/views/List'

export default [{
  path: '/network/:code/work',
  component: Index,
  children: [{
    path: 'funding',
    name: 'network.fundings',
    component: FundingList
  }, {
    path: 'orders',
    name: 'network.orders',
    component: OrderList
  }, {
    path: 'projects',
    name: 'network.projects',
    component: ProjectList
  }]
}]
