<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (billingEvent) ? 'Add' : 'Update' }} Billing Event</p>
        </header>
        <section class="modal-card-body">
          <b-field
            label="Expected Billing Date"
            :type="('billing_date' in errors) ? 'is-danger' : ''"
            :message="('billing_date' in errors) ? errors['billing_date'][0] : ''">
            <b-input
              v-model="billingDate"
              placeholder="Billing Date"
              type="date"
              size="is-medium"
              expanded />
          </b-field>
          <b-field
            label="Model"
            :type="('model' in errors) ? 'is-danger' : ''"
            :message="('model' in errors) ? errors['model'][0] : ''">
            <b-select
              v-model="model"
              placeholder="Billing Model"
              size="is-medium"
              expanded>
              <option value="actuals">Actuals</option>
              <option value="fixed">Fixed</option>
            </b-select>
          </b-field>
          <!-- Actuals + Fixed -->
          <template v-if="model === 'fixed' || model === 'actuals'">
            <b-field
              :label="(model === 'fixed') ? 'Fixed Amount' : 'Estimated Amount'"
              :type="('amount' in errors) ? 'is-danger' : ''"
              :message="('amount' in errors) ? errors['amount'][0] : ''">
              <b-input
                v-model="amount"
                type="number"
                step="0.01"
                size="is-medium"
                expanded />
            </b-field>
          </template>
          <b-field
            label="Memo"
            :type="('memo' in errors) ? 'is-danger' : ''"
            :message="('memo' in errors) ? errors['memo'][0] : ''">
            <b-input
              v-model="memo"
              type="textarea"
              expanded />
          </b-field>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Save Billing Event
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      if (!this.billingDate) return false
      if (this.model === 'percentage' && !this.percentage) return false
      if (this.model === 'fixed' && !this.amount) return false
      if (this.model === 'actuals' && !this.amount) return false
      return true
    },
    method () {
      return (this.billingEvent) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.billingEvent)
        ? this.api.route + '/projects/' + this.$route.params.id + '/billing-events/' + this.billingEvent.id
        : this.api.route + '/projects/' + this.$route.params.id + '/billing-events/'
    },
    json () {
      const json = {
        project_id: this.project.id,
        billing_date: this.billingDate,
        model: this.model,
        percentage: null,
        amount: null,
        memo: this.memo
      }
      if (this.model === 'percentage') {
        json.model = 'fixed'
        json.amount = this.computedAmount
      }
      if (this.model === 'fixed' || this.model === 'actuals') {
        json.amount = this.amount
      }
      return json
    },
    computedAmount () {
      return Number((this.percentage / 100) * this.project.amount_budgeted).toFixed(2)
    }
  },
  data () {
    return {
      billingDate: null,
      model: 'actuals',
      percentage: null,
      amount: null,
      memo: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$emit('schedule:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Billing event added!' })
        this.$parent.close()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    if (this.billingEvent) {
      this.billingDate = this.billingEvent.billing_date
      this.model = this.billingEvent.model
      this.percentage = Number(this.billingEvent.percentage * 100).toFixed(2)
      this.amount = Number(this.billingEvent.amount).toFixed(2)
      this.memo = this.billingEvent.memo
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    billingEvent: {
      type: Object
    }
  }
}
</script>
