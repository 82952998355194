<template>
  <div>
    <!-- Header -->
    <div class="has-border-b pb-md">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="has-text-weight-bold is-size-5 mb-sm">Billing Schedule</p>
        </div>
        <div class="column is-narrow">
          <b-button type="is-primary" size="is-small" outlined @click="createBillingEvent">Add Billing Event</b-button>
        </div>
      </div>
    </div>
    <!-- Datatable -->
    <div>
      <b-table
        :data="project.billingSchedule"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          header-class="is-size-8"
          cell-class="has-text-grey-light"
          width="64"
          v-slot="props">
          <span class="has-text-grey-light">{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          label="Date"
          header-class="is-size-8"
          width="128"
          v-slot="props">
            <Date :value="props.row.billing_date" />
        </b-table-column>
        <b-table-column
          label="Model"
          header-class="is-size-8"
          v-slot="props">
          <span v-if="props.row.model === 'percentage'">
            {{ $utils.startCase(props.row.model) }}
            ({{ $numbers(props.row.percentage).format({ output: 'percent', mantissa: 2 }) }})
          </span>
          <span v-else>{{ $utils.startCase(props.row.model) }}</span>
        </b-table-column>
        <b-table-column
          label="Scheduled Amount"
          header-class="is-size-8"
          width="164"
          centered
          v-slot="props">
          <Currency :value="props.row.amount" />
        </b-table-column>
        <b-table-column
          header-class="is-size-8"
          width="48"
          centered
          v-slot="props">
          <a href="#" class="is-size-8" @click.prevent="editBillingEvent(props.row)">Edit</a>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey-light pa-lg">
            <p>No billing events scheduled yet.</p>
          </div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Update Schedule -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUpdateScheduleModal"
      :can-cancel="['x', 'esc']"
      @close="resetModal"
      ><EditBillingEventModal :project="project" :billingEvent="focusEvent" @schedule:saved="$emit('schedule:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditBillingEventModal from '@/components/Models/BillingEvent/Modals/EditBillingEvent'

export default {
  components: {
    EditBillingEventModal
  },
  computed: {
    ...mapGetters(['context']),
    scheduledBillings () {
      const billings = {
        events: 0,
        amount: 0
      }
      this.project.billingSchedule.forEach(event => {
        billings.events += 1
        billings.amount += Number(event.amount)
      })
      return billings
    }
  },
  data () {
    return {
      focusEvent: null,
      showUpdateScheduleModal: false
    }
  },
  methods: {
    createBillingEvent () {
      this.showUpdateScheduleModal = true
    },
    editBillingEvent (billingEvent) {
      this.focusEvent = billingEvent
      this.showUpdateScheduleModal = true
    },
    resetModal () {
      this.focusEvent = null
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
