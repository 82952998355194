<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Funding</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Funding Type"
          :type="('funding_type' in errors) ? 'is-danger' : ''"
          :message="('funding_type' in errors) ? errors['name'][0] : ''" >
          <b-select
            size="is-medium"
            type="text"
            v-model="fundingType"
            expanded>
            <option value="sow">Fully Executed Statement of Work (SoW)</option>
            <option value="po">Client Purchase Order (PO)</option>
          </b-select>
        </b-field>
        <b-field
          :label="fundingTypeLabel + ' Name'"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''"
          expanded>
          <b-input
            size="is-medium"
            type="text"
            v-model="name"
          ></b-input>
        </b-field>
        <b-field
          :label="fundingTypeLabel + ' #'"
          :type="('client_po_number' in errors) ? 'is-danger' : ''"
          :message="('client_po_number' in errors) ? errors['client_po_number'][0] : ''"
          expanded>
          <b-input
            size="is-medium"
            type="text"
            v-model="clientPoNumber"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/purchase-orders/' + this.$route.params.id
    },
    ready () {
      return (this.name && this.fundingType)
    },
    json () {
      return {
        funding_type: this.fundingType,
        name: this.name,
        client_po_number: (this.clientPoNumber) ? this.clientPoNumber : null,
        ariba_identifier: (this.aribaIdentifier) ? this.aribaIdentifier : null
      }
    },
    fundingTypeLabel () {
      if (this.fundingType === 'sow') return 'SoW'
      if (this.fundingType === 'po') return 'PO'
      return 'PO or SoW'
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Purchase Order Updated!' })
        this.$emit('po:updated', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      fundingType: this.order.funding_type,
      name: this.order.name,
      clientPoNumber: this.order.client_po_number,
      aribaIdentifier: this.order.ariba_identifier,
      saving: false,
      errors: []
    }
  },
  props: ['order']
}
</script>
