<template>
  <span :class="textClass">{{ textValue }}</span>
</template>

<script>
export default {
  computed: {
    textValue () {
      if (this.value === 0 || this.value === '0' || this.value === null || typeof this.value === 'undefined' || isNaN(this.value)) {
        if (this.zeroValue === 'dash') return '--'
        if (this.zeroValue === 'na') return 'N/A'
        if (this.zeroValue === 'number') return this.$numbers(0).formatCurrency({ mantissa: this.mantissa })
      }
      return this.$numbers(this.value).formatCurrency({ mantissa: this.mantissa, thousandSeparated: true })
    },
    textClass () {
      if (this.value === 0 && (this.zeroValue === 'dash' || this.zeroValue === 'na')) {
        return 'has-text-grey-light'
      }
      if (this.compare && this.isHot) return 'has-text-warning'
      if (this.compare && this.isCold) return 'has-text-info'
      if (this.compare && !this.isHot && !this.isCold) return 'has-text-success'
      if (this.vsZero && Number(this.value) > 0) return 'has-text-info'
      if (this.vsZero && Number(this.value) < 0) return 'has-text-warning'
      if (this.vsZero && Number(this.value) === 0) return 'has-text-success'
      return null
    },
    difference () {
      // formula = abs(v1 - v2) / [ (v1 + v2) / 2]
      if (!this.compare) return null
      if (!this.value && !this.compareTo) return null
      if (this.value + this.compareTo === 0) return null
      const numerator = Math.abs(Number(this.value) - Number(this.compareTo))
      const denominator = (Number(this.value) + Number(this.compareTo)) / 2
      return (numerator / denominator) * 100
    },
    isHot () {
      if (!this.compare) return false
      if (Number(this.compareTo) < Number(this.value)) return false
      if (this.difference > this.tolerance) return true
      return false
    },
    isCold () {
      if (!this.compare) return false
      if (Number(this.compareTo) > Number(this.value)) return false
      if (this.difference > this.tolerance) return true
      return false
    }
  },
  props: {
    mantissa: {
      type: Number,
      default: 2
    },
    compare: {
      type: Boolean,
      default: false
    },
    compareTo: {
      type: [String, Number],
      default: null
    },
    tolerance: {
      type: [String, Number],
      default: 5
    },
    value: {
      type: [String, Number]
    },
    zeroValue: {
      type: String,
      default: 'number' // 'dash' or 'na'
    },
    vsZero: {
      type: Boolean,
      default: false
    }
  }
}
</script>
