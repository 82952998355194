<template>
  <div>
    <!-- Toolbar -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-size-7 has-text-grey-light">{{ revisions.length }} {{ (revisions.length === 1) ? 'Revision' : 'Revisions' }}</p>
        </div>
        <div class="column is-narrow">
          <div v-role="'manage-projects'">
            <b-button type="is-primary" outlined size="is-medium" @click="showCreateRevisionModal = true">Add Revision</b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div class="has-background-white rounded-lg pa-md">
      <b-table
        :data="revisions"
        :mobile-cards="false"
        hoverable
        class="is-size-7">
        <b-table-column
          label="ID"
          width="64"
          centered
          cell-class="has-text-grey-light"
          v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          label="Name"
          v-slot="props">
          <router-link :to="{ name: 'network.finance.revision', params: { code: context.code, id: funding.id, revision: props.row.id } }" class="has-text-weight-bold">{{ props.row.name }}</router-link>
        </b-table-column>
        <!-- <b-table-column
          label="Original Amount"
          centered
          width="164"
          v-slot="props">
          <Currency :value="props.row.funding.total_funding" />
        </b-table-column>
        <b-table-column
          label="Revised Amount"
          centered
          width="164"
          v-slot="props">
          <Currency :value="props.row.total_amount" />
        </b-table-column> -->
        <b-table-column
          label="Created"
          centered
          width="128"
          v-slot="props">
          {{ $dates.utc(props.row.created_at).local().format('MMM D, YYYY')}}
        </b-table-column>
        <b-table-column
          label="Approved"
          centered
          width="128"
          v-slot="props">
          <span v-if="props.row.approved_at">
            {{ $dates.utc(props.row.approved_at).local().format('MMM D, YYYY')}}
          </span>
          <span v-else>--</span>
        </b-table-column>
        <b-table-column
          label="Status"
          centered
          width="96"
          v-slot="props">
          <StatusPresenter :revision="props.row" />
        </b-table-column>
        <template #empty>
          <div class="has-text-centered pt-md">No revisions have been created yet.</div>
        </template>
      </b-table>
    </div>
    <!-- Modal: Create Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateRevisionModal"
      :can-cancel="['x', 'esc']"
      ><CreateRevisionModal :funding="funding" @revision:saved="loadRevisions(true)"  />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateRevisionModal from '@/components/Models/FundingRevision/Modals/Create'
import StatusPresenter from '@/components/Models/FundingRevision/Presenters/Status'

export default {
  components: {
    CreateRevisionModal,
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      loadingRevisions: true,
      revisions: [],
      showCreateRevisionModal: false
    }
  },
  methods: {
    loadRevisions (refresh) {
      if (!refresh) this.loadingRevisions = true
      const endpoint = this.api.route + '/funding/' + this.funding.id + '/revisions'
      const query = {
        params: { sortBy: 'id' }
      }
      this.$http.get(endpoint, query).then(response => {
        this.revisions = response.data.data
        this.loadingRevisions = false
      })
    }
  },
  mounted () {
    this.loadRevisions()
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
