<template>
  <div class="has-text-centered">
    <p class="is-size-5">
      <Currency v-if="type === 'currency'" :value="value" :mantissa="mantissa" />
      <Hours v-else-if="type === 'hours'" :hours="value" :mantissa="mantissa" />
      <Margin v-else-if="type === 'margin'" :revenue="revenue" :cogs="cogs" :mantissa="mantissa" />
      <Percentage v-else-if="type === 'percent'" :value="value" :mantissa="mantissa" />
    </p>
    <p class="is-size-8 has-text-grey">{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number]
    },
    revenue: {
      type: [String, Number]
    },
    cogs: {
      type: [String, Number]
    },
    mantissa: {
      type: Number,
      default () {
        return 2
      }
    }
  }
}
</script>
