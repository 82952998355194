<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Pending Invitations</p>
      </header>
      <section class="modal-card-body">
        <!-- Only 1 Invite -->
        <div v-if="!loading && invites.length === 1">
          Single Invite
        </div>
        <!-- Multiple Invites -->
        <div v-else-if="!loading && invites.length > 1">
          <b-message type="is-info is-light mb-0">
            Accept invitations to connect with new Network partners!
          </b-message>
          <table class="table is-fullwidth is-vcentered">
            <tbody>
              <tr>
                <th>From</th>
                <th></th>
              </tr>
              <tr v-for="invite in invites" :key="invite.code">
                <td>
                  <p>{{ invite.network.name }}</p>
                  <p class="is-size-7 has-text-grey">Received on...</p>
                </td>
                <td class="has-text-right">
                  <div class="buttons">
                    <b-button type="is-primary"
                      outlined
                      @click="accept(invite)"
                    >Accept</b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- No Invites -->
        <div v-else-if="!loading && invites.length === 0">
          No inviets
        </div>
        <!-- Loading -->
        <div v-else>
          <Loading message="Loading..." />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/invitations'
    },
    json () {
      return {
        name: this.name
      }
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.invites = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    accept (invite) {
      this.saving = true
      const endpoint = this.endpoint + '/' + invite.id + '/accept'
      this.$http.post(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Invitation accepted!' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem accepting this connection.' })
      }).finally(() => {
        this.saving = false
      })
    },
    ignore (invite) {
      this.saving = true
      const endpoint = this.endpoint + '/' + invite.id + '/ignore'
      this.$http.post(endpoint).then(response => {
        this.$buefy.toast.open({ type: 'is-info', message: 'Invitation ignored' })
      }).catch(() => {
        this.$buefy.toast.open({ type: 'is-danger', message: 'There was a problem ignoring this connection.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      loading: false,
      invites: []
    }
  },
  mounted () {
    this.load()
  },
  props: {

  }
}
</script>
