<template>
  <div>
    <!-- Billing Summary -->
    <div class="has-background-white has-shadow pa-md py-lg mb-lg">
      <div class="columns is-mobile">
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="project.amount_funded" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Funding</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="project.amount_scheduled" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Scheduled</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="project.amount_billed" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Billed</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="Number(project.amount_funded) - Number(project.amount_billed)" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Remaining</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="project.amount_fulfilled" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Delivered</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="project.amount_adjusted" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Adjustments</p>
          </div>
        </div>
        <div class="column">
          <div class="has-text-centered">
            <p class="is-size-4"><Currency :value="Number(project.amount_prebill)" :mantissa="0" /></p>
            <p class="is-size-7 has-text-grey-light">Prebill Balance</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Billing Schedule -->
    <div class="has-background-white has-shadow pa-md mb-lg">
      <div v-if="projectBilling">
        <BillingSchedule :project="projectBilling" @schedule:saved="loadBilling(true)" />
      </div>
    </div>
    <!-- Billing Transactions -->
    <div class="has-background-white has-shadow pa-md mb-lg">
      <p class="has-text-weight-bold is-size-5">Billing Transactions</p>
      <div v-if="projectBilling">
        <ClientInvoices :project="projectBilling" />
      </div>
    </div>
    <!-- Billing Adjustments -->
    <div class="has-background-white has-shadow pa-md mb-lg">
      <div v-if="projectBilling">
        <BillingAdjustments :project="projectBilling" @project:saved="$emit('project:saved')" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import BillingAdjustments from '../components/Billing/BillingAdjustments'
import BillingSchedule from '../components/Billing/BillingSchedule'
import ClientInvoices from '../components/Billing/ClientInvoices'

export default {
  components: {
    BillingAdjustments,
    BillingSchedule,
    ClientInvoices
  },
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingBilling: false,
      projectBilling: null
    }
  },
  methods: {
    loadBilling (quiet = false) {
      if (!quiet) this.loadingBilling = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/billing'
      this.$http.get(endpoint).then(response => {
        this.projectBilling = response.data
        this.loadingBilling = false
      })
    }
  },
  mounted () {
    this.loadBilling()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
