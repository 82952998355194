<template>
  <div>
    <div class="has-background-white rounded-lg px-lg py-md">
      <div class="columns is-multiline">
        <!-- General -->
        <div class="column is-half">
          <ProfileHeader>Project Profile</ProfileHeader>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Project ID</td>
                <td class="has-text-right">{{ project.id }}</td>
              </tr>
              <tr>
                <td>Client</td>
                <td class="has-text-right">
                  {{ project.client.name }}
                </td>
              </tr>
              <tr>
                <td>Project Lead</td>
                <td class="has-text-right">
                  {{ project.lead.full_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Contact & Billing -->
        <div class="column is-half">
          <ProfileHeader>Dates</ProfileHeader>
          <table class="table is-narrow is-size-7 is-fullwidth is-striped">
            <tbody>
              <tr>
                <td>Run Dates</td>
                <td class="has-text-right">
                  <a href="#" @click.prevent="$emit('edit:dates')" class="has-text-weight-bold">
                    {{ $dates(project.start_date).format("MMM D, YYYY") }} -
                    {{ $dates(project.end_date).format('MMM D, YYYY') }}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Started On</td>
                <td class="has-text-right">
                  <span v-if="project.started_at !== null">{{ $dates.utc(project.started_at).format('MMM D, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Started Yet</span>
                </td>
              </tr>
              <tr v-if="project.paused_at !== null">
                <td>Paused On</td>
                <td class="has-text-right">
                  <span>{{ $dates.utc(project.paused_at).format('MMM D, YYYY') }}</span>
                </td>
              </tr>
              <tr>
                <td>Ended On</td>
                <td class="has-text-right">
                  <span v-if="project.ended_at !== null">{{ $dates.utc(project.ended_at).format('MMM D, YYYY') }}</span>
                  <span v-else class="has-text-grey-light is-italic">Not Ended Yet</span>
                </td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td class="has-text-right">{{ $dates.utc(project.updated_at).local().format('MMM D, YYYY') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Attachments -->
        <!-- <div class="column">
          <h1 class="has-text-weight-bold has-border-b pb-sm">Attachments</h1>
          <Attachments :connection="connection" @attachment:removed="load(true)"/>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileHeader from '@/components/General/ProfileHeader'

export default {
  components: {
    ProfileHeader
  },
  computed: {
    ...mapGetters(['context'])
  },
  props: ['project']
}
</script>
