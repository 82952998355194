<template>
  <b-tag :type="presenter.class" :size="this.size">{{ presenter.label }}</b-tag>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.revision.status === 'approved') {
        presenter.class = 'is-success is-light'
        presenter.label = 'Approved'
      } else if (this.revision.status === 'archived') {
        presenter.class = 'is-grey is-light'
        presenter.label = 'Archived'
      } else {
        presenter.class = 'is-warning is-light'
        presenter.label = 'Pending'
      }
      return presenter
    }
  },
  props: {
    revision: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default () {
        return null
      }
    }
  }
}
</script>
