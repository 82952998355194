import Auth from './views/Auth'
import Change from './views/Change'
import Login from './views/Login'
import Forgot from './views/Forgot'
import Reset from './views/Reset'
import Connect from './views/Connect'
import OauthError from './views/OauthError'

// import Success from '../views/Success'

export default [
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    children: [{
      path: 'login',
      name: 'auth.login',
      component: Login,
      meta: {
        skipContext: true,
        skipAuth: true,
        hideNavigation: true
      }
    }, {
      path: 'forgot',
      name: 'auth.forgot',
      component: Forgot,
      meta: {
        skipContext: true,
        skipAuth: true,
        hideNavigation: true
      }
    }, {
      path: 'error',
      name: 'auth.error',
      component: OauthError,
      meta: {
        skipContext: true,
        skipAuth: true,
        hideNavigation: true
      }
    }, {
      path: 'reset-password',
      name: 'auth.reset',
      component: Reset,
      meta: {
        skipContext: true,
        skipAuth: true,
        hideNavigation: true
      }
    }, {
      path: 'change-password',
      name: 'auth.change',
      component: Change,
      meta: {
        skipContext: true,
        skipAuth: false,
        hideNavigation: true
      }
    }, {
      path: 'connect/:code',
      name: 'auth.connect',
      component: Connect,
      meta: {
        skipContext: true,
        skipAuth: true,
        hideNavigation: true
      }
    }]
  }
]
