<template>
  <div>
    <form @submit.prevent="save">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ (term) ? 'Update' : 'Add' }} Terms</p>
        </header>
        <section class="modal-card-body">
          <b-field
            label="Term Name"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''">
            <b-input
              v-model="name"
              type="text"
              size="is-medium"
              expanded />
          </b-field>
          <b-field
            label="Days"
            :type="('days' in errors) ? 'is-danger' : ''"
            :message="('days' in errors) ? errors['days'][0] : ''">
            <b-input
              v-model="days"
              type="text"
              size="is-medium"
              expanded />
          </b-field>
          <div>
            <div v-if="identifiers.length">
              <table class="table is-fullwidth is-vcentered is-narrow">
                <tr>
                  <th style="width:45%">Platform</th>
                  <th>Identifier</th>
                  <th></th>
                </tr>
                <tr v-for="(identifier, index) in identifiers" :key="index">
                  <td>
                    <b-field
                      :type="('platform_account_id' in errors) ? 'is-danger' : ''"
                      :message="('platform_account_id' in errors) ? errors['platform_account_id'][0] : ''">
                      <PlatformAccountSelect v-model="identifier.platformAccount" size="null" :disabled="identifier.delete" />
                    </b-field>
                  </td>
                  <td>
                    <b-field
                      :type="('platform_identifier' in errors) ? 'is-danger' : ''"
                      :message="('platform_identifier' in errors) ? errors['platform_identifier'][0] : ''">
                      <b-input type="text" v-model="identifier.platformIdentifier" :disabled="identifier.delete"/>
                    </b-field>
                  </td>
                  <td>
                    <a href="#" @click.prevent="removeLine(index)">
                      <b-icon icon="trash-can-outline" type="is-primary" size="is-small" />
                    </a>
                  </td>
                </tr>
              </table>
              <p class="is-size-7 py-md">
                <a href="#" class="has-text-weight-bold" @click.prevent="addLine">Add Additional Platform</a>
              </p>
            </div>
            <div v-else>
              <p><a href="#" class="has-text-weight-bold is-size-7" @click.prevent="addLine">Link to external platform?</a></p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot space-between">
          <b-button @click="$parent.close()">Close</b-button>
          <b-button
            type="is-primary"
            native-type="submit"
            :loading="saving"
            :disabled="!ready || saving">
            Save Terms
          </b-button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/Models/PlatformAccount/SelectMenu'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return Boolean(this.name && this.days)
    },
    method () {
      return (this.term) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.term)
        ? this.api.route + '/admin/sales-terms/' + this.term.id
        : this.api.route + '/admin/sales-terms/'
    },
    json () {
      const json = {
        name: this.name,
        days: this.days,
        identifiers: []
      }
      this.identifiers.forEach(identifier => {
        json.identifiers.push({
          platform_account_id: identifier.platformAccount.id,
          platform_identifier: identifier.platformIdentifier,
          delete: (identifier.delete) ? 1 : 0
        })
      })
      return json
    }
  },
  data () {
    return {
      name: null,
      days: null,
      identifiers: [],
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Terms updated!' })
        this.$parent.close()
        this.$emit('term:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    addLine () {
      this.identifiers.push({
        id: null,
        platformAccount: null,
        platformIdentifier: null,
        delete: false
      })
    },
    removeLine (index) {
      if (this.identifiers[index].id) {
        this.identifiers[index].delete = !this.identifiers[index].delete
        return
      }
      this.identifiers = this.identifiers.toSpliced(index, 1)
    }
  },
  mounted () {
    if (this.term) {
      this.name = this.term.name
      this.days = this.term.days
    }
    if (this.term && this.term.identifiers.length) {
      this.identifiers = []
      this.term.identifiers.forEach(identifier => {
        this.identifiers.push({
          id: identifier.id,
          platformAccount: identifier.platform_account,
          platformIdentifier: identifier.platform_identifier,
          delete: false
        })
      })
    }
  },
  props: {
    term: {
      type: Object
    }
  }
}
</script>
