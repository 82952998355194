<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Funding</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading">
          <b-field
            label="Funding Type"
            :type="('funding_type_id' in errors) ? 'is-danger' : ''"
            :message="('funding_type_id' in errors) ? errors['funding_type_id'][0] : ''" >
            <b-select
              size="is-medium"
              type="text"
              v-model="fundingType"
              expanded>
              <option v-for="type in fundingTypes" :key="type.id" :value="type">{{ type.name }} ({{ type.abbr}})</option>
            </b-select>
          </b-field>
          <b-field
            :label="fundingType.abbr + ' Name'"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''">
            <b-input
              size="is-medium"
              type="text"
              v-model="name"
            ></b-input>
          </b-field>
          <b-field
            :label="fundingType.abbr + ' #'"
            :type="('client_identifier' in errors) ? 'is-danger' : ''"
            :message="('client_identifier' in errors) ? errors['client_identifier'][0] : ''">
            <b-input
              size="is-medium"
              type="text"
              v-model="clientIdentifier"
              placeholder="Optional"
            ></b-input>
          </b-field>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return this.loadingFundingTypes
    },
    endpoint () {
      return this.api.route + '/funding/' + this.$route.params.id
    },
    ready () {
      return (this.name && this.fundingType)
    },
    json () {
      return {
        funding_type_id: this.fundingType.id,
        name: this.name,
        client_identifier: (this.clientIdentifier) ? this.clientIdentifier : null,
        ariba_identifier: (this.aribaIdentifier) ? this.aribaIdentifier : null
      }
    },
    fundingTypeLabel () {
      if (this.fundingType === 'sow') return 'SoW'
      if (this.fundingType === 'po') return 'PO'
      return 'PO or SoW'
    }
  },
  methods: {
    loadFundingTypes () {
      this.loadingFundingTypes = true
      const endpoint = this.api.route + '/funding-types'
      this.$http.get(endpoint).then(response => {
        this.fundingTypes = response.data.data
        this.fundingType = response.data.data.find(type => {
          return (type.code === this.funding.funding_type.code)
        })
        this.loadingFundingTypes = false
      })
    },
    save () {
      this.saving = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Purchase Order Updated!' })
        this.$emit('funding:updated', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      loadingFundingTypes: true,
      fundingTypes: [],
      fundingType: null,
      name: null,
      clientIdentifier: null,
      aribaIdentifier: null,
      saving: false,
      errors: {}
    }
  },
  mounted () {
    this.loadFundingTypes()
    this.name = this.funding.name
    this.clientIdentifier = this.funding.client_identifier
    this.aribaIdentifier = this.funding.ariba_identifier
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
