<template>
  <div>
    <!-- Breadcrumbs -->
    <div class="mb-lg">
      <Breadcrumbs :history="history" current="Team Member" />
    </div>
    <!-- Actions -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <div class="columns">
        <div class="column is-one-quarter">
          <b-button
            type="is-light"
            size="is-medium"
            expanded
            :disabled="true"
            @click="showUpdateRoleModal = true">Update Role</b-button>
        </div>
      </div>
    </div>
    <!-- Info -->
    <div class="has-background-white py-md px-lg rounded-lg mb-xxs">
      <div class="mb-lg">
        <p class="is-size-8 has-text-grey">Name</p>
        <p class="has-text-weight-bold">{{ role.user.full_name }}</p>
      </div>
      <div class="mb-lg">
        <p class="is-size-8 has-text-grey">Email</p>
        <p class="has-text-weight-bold">{{ (role.user.id) ? role.user.email : '--' }}</p>
      </div>
      <div class="mb-lg">
        <p class="is-size-8 has-text-grey">Role</p>
        <p class="has-text-weight-bold">{{ role.type.name  }}</p>
      </div>
      <div class="mb-lg">
        <p class="is-size-8 has-text-grey">Status</p>
        <p class="has-text-weight-bold">Active</p>
      </div>
    </div>
    <!-- Invitations Modal -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUpdateRoleModal"
      :can-cancel="['x', 'esc']"
      ><PartnerUpdateRoleModal :role="role" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PartnerUpdateRoleModal from '@/components/Models/Role/Modals/PartnerUpdate'

export default {
  components: {
    PartnerUpdateRoleModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Settings', name: 'partner.settings', params: { code: this.context.code } },
        { label: 'Team', name: 'partner.settings.people', params: { code: this.context.code } }
      ]
    }
  },
  data () {
    return {
      showUpdateRoleModal: false
    }
  },
  props: {
    role: {
      type: Object,
      required: true
    }
  }
}
</script>
