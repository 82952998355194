<template>
  <b-autocomplete
    v-model="search"
    :data="filteredMembers"
    :clearable="!readonly"
    max-height="100px"
    field="label"
    placeholder="Select Member"
    size="is-medium"
    :open-on-focus="false"
    @select="selectMember"
    :readonly="readonly"
    :disabled="readonly">
  </b-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    filteredMembers () {
      if (this.loadingMembers || this.members.length === 0) {
        return []
      }
      if (this.search) {
        return this.members.filter((option) => {
          return option.label.toString().toLowerCase().indexOf(this.search.toLowerCase()) >= 0
        })
      } else {
        return this.members
      }
    }
  },
  data () {
    return {
      search: null,
      loadingMembers: false,
      member: null,
      members: []
    }
  },
  methods: {
    announce () {
      this.$emit('input', this.member)
    },
    selectMember (option) {
      this.member = option
      this.announce()
    },
    loadMembers () {
      this.loadingMembers = true
      const endpoint = this.api.route + '/connections'
      const query = {
        params: {
          paginate: 0
        }
      }
      this.members = []
      this.$http.get(endpoint, query).then(response => {
        response.data.forEach(member => {
          this.members.push({
            id: member.id,
            label: member.partner.name + ' (' + member.partner.billing_contact + ')'
          })
        })
        if (this.value) {
          this.member = this.members.find((member) => { return member.id === this.value.id })
          this.search = this.member.label
        } else if (this.members.length === 1) {
          this.member = response.data[0]
          this.search = this.member.label
        }
        this.announce()
        this.loadingMembers = false
      })
    }
  },
  mounted () {
    this.loadMembers()
  },
  props: {
    value: {
      type: [Object, null]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>
