<template>
  <div>
    <div class="has-background-white has-shadow pa-lg mb-lg">
      <div class="columns is-vcentered">
        <div class="column is-narrow is-hidden-mobile">
          <CldImage :publicId="project.client.organization.logo_path" dpr="2.0" crop="scale" style="height:32px" />
        </div>
        <div class="column">
          <div class="mb-xs">
            <span class="has-text-grey-light has-text-weight-bold">{{ project.client.name }}</span>
            <StatusPresenter :project="project" class="ml-md" />
          </div>
          <p class="is-size-4 condensed has-text-primary has-text-weight-bold mb-md">{{ project.name }}</p>
          <div class="columns is-size-7">
            <div class="column">
              <p class="mb-xs">
                <span class="has-text-grey-light">Project Lead </span>
                <strong>{{ project.lead.full_name }}</strong>
              </p>
              <p class="mb-xs">
                <span class="has-text-grey-light">Run Dates </span>
                <strong>
                  {{ $dates(project.start_date).format('MMM D, YYYY') }}
                  -
                  {{ $dates(project.end_date).format('MMM D, YYYY') }}
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <ProjectOptions
            :project="project"
            type="is-primary rounded-lg"
            label="Manage Project"
            size="is-medium"
            @project:settings="showSettingsModal = true"
            @project:identifiers="showIdentifiersModal = true"
            @project:dates="showDatesModal = true"
            @project:lead="showProjectLeadModal = true"
            @project:funding="showLinkOrderModal = true"
            @project:roles="showProjectRolesModal = true"
            @project:start="showStartProjectModal = true"
            @project:pause="showPauseProjectModal = true"
            @project:end="showEndProjectModal = true"
            @project:restart="showRestartProjectModal = true"
            @project:archive="showArchiveProjectModal = true"
            @project:saved="$emit('project:saved')"
            @preview="$emit('preview')"
          />
        </div>
      </div>
    </div>
    <!-- Modal: Edit Basic Project Settings -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showSettingsModal"
      :can-cancel="['x', 'esc']"
      ><SettingsModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Edit External Identifiers -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showIdentifiersModal"
      :can-cancel="['x', 'esc']"
      ><UpdateIdentifiersModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Edit Project Lead -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showProjectLeadModal"
      :can-cancel="['x', 'esc']"
      ><UpdateProjectLeadModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Project Roles -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showProjectRolesModal"
      :can-cancel="['x', 'esc']"
      ><ManageProjectRolesModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Edit Project Dates -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showDatesModal"
      :can-cancel="['x', 'esc']"
      ><EditDatesModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Link Funding -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showLinkOrderModal"
      :can-cancel="['x', 'esc']"
      ><LinkFundingModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Start Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showStartProjectModal"
      :can-cancel="['x', 'esc']"
      ><StartProjectModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: End Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEndProjectModal"
      :can-cancel="['x', 'esc']"
      ><EndProjectModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Pause Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showPauseProjectModal"
      :can-cancel="['x', 'esc']"
      ><PauseProjectModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Restart Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showRestartProjectModal"
      :can-cancel="['x', 'esc']"
      ><RestartProjectModal :project="project" @project:saved="$emit('project:saved')" />
    </b-modal>
    <!-- Modal: Archive Project -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveProjectModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveProjectModal :project="project" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Project/Presenters/Status'
import ProjectOptions from '@/components/Models/Project/Options'
import SettingsModal from '@/components/Models/Project/Modals/Settings'
import EditDatesModal from '@/components/Models/Project/Modals/EditDates'
import UpdateProjectLeadModal from '@/components/Models/Project/Modals/UpdateNetworkLead'
import LinkFundingModal from '@/components/Models/Project/Modals/LinkOrder'
import StartProjectModal from '@/components/Models/Project/Modals/StartProject'
import ManageProjectRolesModal from '@/components/Models/Project/Modals/ManageProjectRoles'
import EndProjectModal from '@/components/Models/Project/Modals/EndProject'
import PauseProjectModal from '@/components/Models/Project/Modals/PauseProject'
import RestartProjectModal from '@/components/Models/Project/Modals/RestartProject'
import ArchiveProjectModal from '@/components/Models/Project/Modals/Archive'
import UpdateIdentifiersModal from '@/components/Models/Project/Modals/UpdateIdentifiers'

export default {
  components: {
    ProjectOptions,
    StatusPresenter,
    SettingsModal,
    EditDatesModal,
    UpdateProjectLeadModal,
    LinkFundingModal,
    StartProjectModal,
    ManageProjectRolesModal,
    EndProjectModal,
    PauseProjectModal,
    RestartProjectModal,
    ArchiveProjectModal,
    UpdateIdentifiersModal
  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      showSettingsModal: false,
      showIdentifiersModal: false,
      showDatesModal: false,
      showProjectLeadModal: false,
      showLinkOrderModal: false,
      showProjectRolesModal: false,
      showStartProjectModal: false,
      showPauseProjectModal: false,
      showEndProjectModal: false,
      showRestartProjectModal: false,
      showArchiveProjectModal: false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
