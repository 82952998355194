<template>
  <div>
    <div class="has-background-white has-shadow pa-md mb-lg">
      <div class="columns is-vcentered">
        <div class="column">
          <BillingPeriodSelect v-model="period" :size="null" />
        </div>
        <div class="column">
          <b-select
            v-model="status"
            placeholder="Select a Status"
            expanded>
            <option value="all">All</option>
            <option value="draft">Drafts</option>
            <option value="active">Active</option>
            <option value="paused">Paused</option>
            <option value="completed">Completed</option>
          </b-select>
        </div>
        <div class="column">
          <OrganizationSelect v-model="organization" />
        </div>
        <div class="column is-narrow">
          <b-button
            type="is-primary"
            outlined
            expanded
            :loading="loading"
            :disabled="(!period || !organization) || loading"
            @click="loadProjectTimesheets"
          >Show Activity</b-button>
        </div>
        <!-- Options -->
        <div class="column is-narrow">
          <b-dropdown position="is-bottom-left" >
            <template #trigger="{ active }">
              <b-button
                label="Export"
                type="is-ghost is-size-7"
                :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>
            <b-dropdown-item has-link>
              <a href="#" @click.prevent="exportAll" :disabled="(!period) || loading">Export All Hours</a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div v-if="!loading && period && organization && projects.length">
      <div v-for="project in projects" :key="project.id" class="has-background-white has-shadow pa-md mb-xs">
        <Project :period="period" :project="project"  @project:saved="loadProjectTimesheets(true)" @timesheet:saved="loadProjectTimesheets(true)" :expanded="false" />
      </div>
    </div>
    <!-- Loading -->
    <div v-else-if="loading">
      <div class="has-background-white rounded-lg px-md py-xxl mb-xxs">
        <div class="has-text-centered has-text-grey is-size-7">
          Loading timesheets...
        </div>
      </div>
    </div>
    <!-- No Results -->
    <div v-else>
      <div class="has-background-white has-shadow px-md py-xxl mb-xxs">
        <div class="has-text-centered has-text-grey is-size-7">
          Please select a billing period and organization.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'
import OrganizationSelect from '@/components/Models/Organization/SelectMenu'
import Project from '../components/ProjectTimesheets/Project'

export default {
  components: {
    BillingPeriodSelect,
    OrganizationSelect,
    Project
  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return (this.loadingProjects)
    }
  },
  data () {
    return {
      loadingProjects: false,
      projects: [],
      focusProject: null,
      period: null,
      organization: null,
      status: 'active',
      showBillTimesheetsModal: false
    }
  },
  methods: {
    loadProjectTimesheets (quiet = false) {
      if (!quiet) this.loadingProjects = true
      const endpoint = this.api.route + '/admin/projects'
      const query = {
        params: {
          short: 1,
          period: this.period.id,
          organization: this.organization.id,
          status: this.status
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.projects = response.data.data
        this.loadingProjects = false
      })
    },
    approveBilling (project) {
      this.focusProject = project
      this.showBillTimesheetsModal = true
    },
    exportAll () {
      const endpoint = this.api.route + '/admin/projects/export'
      const query = {
        period: this.period.id,
        organization: (this.organization) ? this.organization.id : null
      }
      axios({
        url: endpoint,
        params: query,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hours.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  }
}
</script>
