<template>
  <div>
    <b-select
      v-model="selectedPeriod"
      :size="size"
      placeholder="Select Period"
      :loading="loadingPeriods"
      :disabled="loadingPeriods || disabled"
      :readonly="readonly"
      @input="announce"
      expanded>
      <option v-for="period in periods" :key="period.id" :value="period">
        {{ $dates(period.start_date).format('MMM YYYY') }}
        {{ (period.closed_at) ? '(Closed)' : '(Open)' }}
      </option>
    </b-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      loadingPeriods: false,
      periods: [],
      selectedPeriod: null
    }
  },
  methods: {
    loadBillingPeriods () {
      this.loadingPeriods = true
      const endpoint = this.api.base + 'billing-periods'
      const query = { params: {} }
      // set a start date
      if (this.start) {
        query.params.start = this.$dates(this.start).format('YYYY-MM-DD')
      } else {
        query.params.start = this.$dates().subtract(12, 'month').format('YYYY-MM-DD')
      }
      // set an end date
      if (this.end) {
        query.params.end = this.$dates(this.end).format('YYYY-MM-DD')
      } else {
        query.params.end = this.$dates().add(1, 'month').format('YYYY-MM-DD')
      }
      // set a status filter
      if (this.status === 'open') {
        query.params.is_open = 1
      }
      this.$http.get(endpoint, query).then(response => {
        this.periods = response.data
        if (this.value) {
          const period = this.periods.find(period => {
            if ('id' in this.value) {
              return period.id === this.value.id
            }
            return null
          })
          if (period) {
            this.selectedPeriod = period
            this.announce()
          }
        } else {
          this.periods.forEach(period => {
            if (this.$dates(period.start_date).startOf('month').format('YYYY-MM-DD') === this.currentPeriod) {
              this.selectedPeriod = period
              this.announce()
            }
          })
        }
        this.loadingPeriods = false
      })
    },
    announce () {
      if (this.selectedPeriod) {
        this.$emit('input', this.selectedPeriod)
      }
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String
    },
    status: {
      type: String
    },
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>
