<template>
  <div>
    <form @submit.prevent="save">
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <!-- Back Link -->
        <div class="border-b pb-md mb-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-weight-bold">Add Role</p>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'network.project.detail', params: { code: context.code }}">&lt; Back to Project Detail</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Position Details -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <div v-if="!loadingTitles">
                <div class="mb-lg">
                  <div class="columns">
                    <div class="column">
                      <b-field
                        label="Role Description"
                        :type="('role' in errors) ? 'is-danger' : ''"
                        :message="('role' in errors) ? errors['role'][0] : ''">
                        <b-input
                          size="is-medium"
                          v-model="role"
                        ></b-input>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <Loading message="Loading..." />
              </div>
            </div>
            <div class="column is-one-third">
              <div class="has-background-light rounded-lg pa-md">
                <p class="has-text-weight-bold is-size-6 mb-sm">Role Details</p>
                <p class="has-text-grey is-size-7 mb-md">
                  Complete the form to add a new <b>role</b> to your project. After you add a role,
                  you will be able to choose one or more partners to fill the role.
                </p>
                <p class="has-text-grey is-size-7">
                  If you need to manage the role <b>levels</b> for {{ project.client.name }}, click
                  <router-link :to="{ name: 'network.client.rates', params: { code: context.code, id: project.client.id } }">here</router-link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
          :loading="saving"
          :disabled="saving || !ready"
        >Save &amp; Continue to Plan</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingTitles || this.loadingPosition)
    },
    method () {
      return (this.position) ? 'patch' : 'post'
    },
    endpoint () {
      return (this.position)
        ? this.api.route + '/projects/' + this.project.id + '/positions/' + this.position.id
        : this.api.route + '/projects/' + this.project.id + '/positions'
    },
    ready () {
      return Boolean(this.role)
    },
    json () {
      const json = {
        role: this.role,
        billing_model: 'time'
      }
      return json
    }
  },
  data () {
    return {
      loadingTitles: true,
      loadingPosition: false,
      titles: [],
      position: null,
      role: null,
      title: null,
      billingModel: 'hourly',
      rate: null,
      hasTargets: null,
      targetHours: null,
      targetBudget: null,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadPosition () {
      this.loadingPosition = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/positions/' + this.$route.params.position
      this.$http.get(endpoint).then(response => {
        this.position = response.data
        this.role = this.position.role
        this.title = this.position.title
        this.rate = parseFloat(this.position.client_rate.rate).toFixed(2)
        this.billingModel = this.position.client_rate.model
        if (this.position.client_target_budget || this.position.client_target_hours) {
          this.hasTargets = 'hours'
          this.targetBudget = this.position.client_target_budget
          this.targetHours = this.position.client_target_hours
        } else {
          this.hasTargets = 'skip'
        }
        this.loadingPosition = false
      })
    },
    loadTitles () {
      this.loadingTitles = true
      const endpoint = this.api.route + '/clients/' + this.project.client.id + '/titles'
      this.$http.get(endpoint).then(response => {
        this.titles = response.data
        this.loadingTitles = false
      })
    },
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$emit('refresh')
        // redirect to plan page if not editing and they have targets
        if (this.position === null && (this.hasTargets === 'budget' || this.hasTargets === 'hours')) {
          this.$router.push({ name: 'network.project.position.plans', params: { code: this.context.code, id: this.project.id, position: response.data.id } })
          return
        }
        // otherwise, return back to dashboard
        this.$router.push({ name: 'network.project.detail', params: { code: this.context.code, id: this.project.id } })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.loadTitles()
    if (typeof this.$route.params.position !== 'undefined') {
      this.loadPosition()
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    title (value) {
      if (this.position === null) {
        this.rate = parseFloat(value.rate_card).toFixed(2)
      }
    },
    targetBudget (value) {
      if (this.hasTargets === 'budget') {
        this.targetHours = parseFloat(value / this.rate).toFixed(2)
      }
    },
    targetHours (value) {
      if (this.hasTargets === 'hours') {
        this.targetBudget = parseFloat(value * this.rate).toFixed(2)
      }
    }
  }
}
</script>
