<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      // active
      if (this.connection.status === 'active') {
        presenter.class = 'has-text-success'
        presenter.label = 'Active'
      // onboarding
      } else if (this.connection.status === 'onboarding') {
        presenter.class = 'has-text-info'
        presenter.label = 'Onboarding'
      // suspended
      } else if (this.connection.status === 'inactive') {
        presenter.class = 'has-text-danger'
        presenter.label = 'Suspended'
      } else {
        presenter.class = 'has-text-grey-light'
        presenter.label = 'Pending'
      }
      return presenter
    }
  },
  props: ['connection']
}
</script>
