<template>
  <div>
    <div class="mb-lg">
      <div class="columns">
        <div class="column">
          <div class="has-background-white pa-md has-shadow">
            <ProjectBurnSummary :project="project" />
          </div>
        </div>
        <div class="column">
          <div class="has-background-white pa-md has-shadow">
            <ProjectBurn :project="project" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectBurnSummary from '../components/Summary/ProjectBurnSummary'
import ProjectBurn from '../components/Summary/ProjectBurn'

export default {
  components: {
    ProjectBurnSummary,
    ProjectBurn
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
