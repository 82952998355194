<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Funding</p>
      </header>
      <section class="modal-card-body">
        <!-- Identifiers -->
        <div class="mb-md">
          <b-field
            label="Client Name"
            :type="('client' in errors) ? 'is-danger' : ''"
            :message="('client' in errors) ? errors['client'][0] : ''"
            expanded>
              <Autocomplete
              v-model="client"
              resource="clients"
              labelField="name"
              valueField="id"
              size="is-medium" />
          </b-field>
          <b-field
            label="Funding Type"
            :type="('funding_type' in errors) ? 'is-danger' : ''"
            :message="('funding_type' in errors) ? errors['name'][0] : ''" >
            <b-select
              size="is-medium"
              type="text"
              v-model="fundingType"
              expanded>
              <option value="sow">Fully Executed Statement of Work (SoW)</option>
              <option value="po">Client Purchase Order (PO)</option>
            </b-select>
          </b-field>
          <b-field
            :label="fundingTypeLabel + ' Name'"
            :type="('name' in errors) ? 'is-danger' : ''"
            :message="('name' in errors) ? errors['name'][0] : ''">
            <b-input
              size="is-medium"
              type="text"
              v-model="name"
            ></b-input>
          </b-field>
          <b-field
            :label="fundingTypeLabel + ' #'"
            :type="('client_po_number' in errors) ? 'is-danger' : ''"
            :message="('client_po_number' in errors) ? errors['client_po_number'][0] : ''">
            <b-input
              size="is-medium"
              type="text"
              v-model="clientPoNumber"
              placeholder="Optional"
            ></b-input>
          </b-field>
        </div>
        <!-- Staff Budget -->
        <div class="mb-md">
          <div class="mb-sm">
            <label class="label mb-0">Staff Budget</label>
            <p class="is-size-7 has-text-grey">Enter the budget that can be used on partners for hourly contributions.</p>
          </div>
          <b-field
            :type="('staff_budget' in errors) ? 'is-danger' : ''"
            :message="('staff_budget' in errors) ? errors['staff_budget'][0] : ''">
            <p class="control"><span class="button is-static is-medium">$</span></p>
            <b-input
              size="is-medium"
              type="number"
              v-model="staffBudget"
              :min="0"
              expanded
            ></b-input>
          </b-field>
        </div>
        <!-- Project Expenses -->
        <div class="mb-md">
          <div class="mb-sm">
            <label class="label mb-0">Project Expense Allowance</label>
            <p class="is-size-7 has-text-grey">Enter the allowance for non-reimbursable project expenses.</p>
          </div>
          <b-field
            :type="('project_expenses' in errors) ? 'is-danger' : ''"
            :message="('project_expenses' in errors) ? errors['project_budget'][0] : ''">
            <p class="control"><span class="button is-static is-medium">$</span></p>
            <b-input
              size="is-medium"
              type="number"
              v-model="projectExpenses"
              :min="0"
              expanded
            ></b-input>
          </b-field>
        </div>
        <!-- Reimbursable Expenses -->
        <div class="mb-md">
          <div class="mb-sm">
            <label class="label mb-0">Reimbursable Expense Allowance</label>
            <p class="is-size-7 has-text-grey">Enter the allowance for reimbursable project expenses.</p>
          </div>
          <b-field
            :type="('project_expenses' in errors) ? 'is-danger' : ''"
            :message="('project_expenses' in errors) ? errors['project_budget'][0] : ''">
            <p class="control"><span class="button is-static is-medium">$</span></p>
            <b-input
              size="is-medium"
              type="number"
              v-model="reimbursableExpenses"
              :min="0"
              expanded
            ></b-input>
          </b-field>
        </div>
        <!-- Total PO Value -->
        <div class="mb-md">
          <div class="mb-sm">
            <label class="label mb-0">Total Order Value</label>
          </div>
          <div class="has-background-light pa-md">
            <Currency :value="totalOrderValue" zeroValue="dash" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/Forms/Autocomplete'

export default {
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (
        this.fundingType &&
        this.name &&
        this.client &&
        this.staffBudget &&
        this.projectExpenses &&
        this.reimbursableExpenses
      )
    },
    endpoint () {
      return this.api.route + '/purchase-orders'
    },
    json () {
      return {
        name: this.name,
        funding_type: this.fundingType,
        client_id: this.client.value,
        client_po_number: this.clientPoNumber,
        staff_budget: this.staffBudget,
        project_expenses: this.projectExpenses,
        reimbursable_expenses: this.reimbursableExpenses,
        start_date: this.startDate,
        end_date: this.endDate
      }
    },
    minStartDate () {
      return this.$dates().subtract(2, 'month').startOf('month').format('YYYY-MM-DD')
    },
    minEndDate () {
      return (this.startDate) ? this.startDate : this.$dates().format('YYYY-MM-DD')
    },
    totalOrderValue () {
      let value = 0
      if (!isNaN(parseFloat(this.staffBudget))) {
        value += parseFloat(this.staffBudget)
      }
      if (!isNaN(parseFloat(this.projectExpenses))) {
        value += parseFloat(this.projectExpenses)
      }
      if (!isNaN(parseFloat(this.reimbursableExpenses))) {
        value += parseFloat(this.reimbursableExpenses)
      }
      return value
    },
    fundingTypeLabel () {
      if (this.fundingType === 'sow') return 'SoW'
      if (this.fundingType === 'po') return 'PO'
      return 'PO or SoW'
    }
  },
  data () {
    return {
      fundingType: null,
      name: null,
      client: null,
      clientPoNumber: null,
      staffBudget: null,
      projectExpenses: null,
      reimbursableExpenses: null,
      startDate: null,
      endDate: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project added!' })
        this.$emit('po:created', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
