<template>
  <div>
    <div>
      <!-- Actions -->
      <div class="has-background-white has-shadow pa-md mb-xxs">
        <div>
          <Workflow :invoice="invoice"
            @action:approve="showApproveInvoiceModal = true"
            @action:unapprove="showUnapproveInvoiceModal = true"
            @action:publish="showPublishInvoiceModal = true"
            @action:archive="showArchiveInvoiceModal = true"
          />
        </div>
      </div>
      <!-- Profile -->
      <div class="has-background-white has-shadow px-lg py-md mb-md">
        <div >
          <div class="columns is-multiline">
            <!-- General -->
            <div class="column is-half">
              <p class="has-text-weight-bold has-border-b pb-sm is-size-7">
                <span v-if="invoice.type === 'invoice'">Invoice Profile</span>
                <span v-else>Credit Profile</span>
              </p>
              <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>Organization</td>
                    <td class="has-text-right">{{ invoice.organization.name }}</td>
                  </tr>
                  <tr>
                    <td>Client</td>
                    <td class="has-text-right">{{ invoice.client.name }}</td>
                  </tr>
                  <tr>
                    <td>Funding</td>
                    <td class="has-text-right">
                      <router-link
                        class="has-text-weight-bold"
                        :to="{ name: 'network.project', params: { code: context.code, id: invoice.project.id } }">
                        {{ invoice.project.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td>Approved At</td>
                    <td class="has-text-right">
                      <p v-if="invoice.approved_at">{{ $dates.utc(invoice.approved_at).local().format('MMM D, YYYY @ h:MM A') }}</p>
                      <p v-else class="has-text-grey-light">- Not Set -</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Identifiers -->
            <div class="column is-half">
              <div class="has-border-b pb-sm is-size-7">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p class="has-text-weight-bold">Finance Platform</p>
                  </div>
                  <div class="column">
                    <p class="has-text-right"><a href="#" class="has-text-weight-bold" @click.prevent="editTransactionDetails = true">Edit</a></p>
                  </div>
                </div>
              </div>
              <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>Platform</td>
                    <td class="has-text-right">
                      <p v-if="invoice.platform_account">{{ invoice.platform_account.platform.name }}</p>
                      <p v-else class="has-text-grey-light">- Not Set -</p>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ (invoice.type === 'credit') ? 'Credit' : 'Invoice' }} Number</td>
                    <td class="has-text-right">
                      <p v-if="invoice.network_identifier">{{ invoice.network_identifier }}</p>
                      <p v-else class="has-text-grey-light">- Not Set -</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p v-if="invoice.platform_account">{{ invoice.platform_account.platform.name }} ID</p>
                      <p v-else>Platform ID</p>
                    </td>
                    <td class="has-text-right">
                      <p v-if="invoice.platform_account_identifier">{{ invoice.platform_account_identifier }}</p>
                      <p v-else class="has-text-grey-light">- Not Set -</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Published</td>
                    <td class="has-text-right">
                      <p v-if="invoice.published_at">{{ $dates.utc(invoice.published_at).local().format('MMM D, YYYY @ h:MM A') }}</p>
                      <p v-else class="has-text-grey-light">- Not Set -</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Billing Event -->
            <!-- <div class="column is-half">
              <p class="has-text-weight-bold has-border-b pb-sm is-size-7 is-uppercase">Billing Event</p>
              <table class="table is-narrow is-size-7 is-fullwidth is-striped">
                <tbody>
                  <tr>
                    <td>Expected Billing Date</td>
                    <td class="has-text-right">
                      <p>{{ $dates(invoice.billing_event.billing_date).format('MMM D, YYYY') }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Expected Amount</td>
                    <td class="has-text-right">
                      <p><Currency :value="invoice.billing_event.amount" /></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </div>
        </div>
      </div>
      <!-- Summary Statistics -->
      <div class="mb-md">
        <div class="columns">
          <div class="column">
            <div class="has-background-white has-shadow px-lg py-md has-text-centered">
              <p class="is-size-3">
                <Currency :value="invoice.subtotal_amount" />
              </p>
              <p class="is-size-7 has-text-grey-light">
                <span v-if="invoice.type === 'credit'">Credit Total</span>
                <span v-else>Invoice Total</span>
              </p>
            </div>
          </div>
          <div class="column">
            <div class="has-background-white has-shadow px-lg py-md has-text-centered">
              <p class="is-size-3">
                <Currency :value="invoice.adjustments_amount" />
              </p>
              <p class="is-size-7 has-text-grey-light">Adjustments</p>
            </div>
          </div>
          <div class="column">
            <div class="has-background-white has-shadow px-lg py-md has-text-centered">
              <p class="is-size-3">
                <span v-if="Number(invoice.total_amount) === 0"><Currency :value="0" /></span>
                <span v-else-if="invoice.type === 'invoice'"><Currency :value="invoice.total_amount" /></span>
                <span v-else-if="invoice.type === 'credit'">(<Currency :value="invoice.total_amount" />)</span>
              </p>
              <p class="is-size-7 has-text-grey-light">Project Balance Impact</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Lines -->
      <div class="mb-lg">
        <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="is-size-7">
                <p class="has-text-weight-bold is-uppercase">
                  Line Items
                  <span class="has-text-grey-light">[{{ invoice.lines.length }}]</span></p>
              </div>
            </div>
            <div v-if="!invoice.approved_at" class="column is-narrow">
              <b-dropdown position="is-bottom-left">
                <template #trigger="{ active }">
                  <b-button label="Add Line Items" type="is-primary" outlined :icon-right="active ? 'menu-up' : 'menu-down'" />
                </template>
                <!-- <b-dropdown-item @click="showConvertBillablesModal = true">Add Billable Items</b-dropdown-item> -->
                <b-dropdown-item @click="createLine">Add Manual Line</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="has-background-white rounded-lg px-lg py-md mb-lg">
          <b-table
            class="is-size-7"
            :data="invoice.lines"
            :mobile-cards="false">
            <b-table-column
              label="ID"
              v-slot="props"
              cell-class="has-text-grey-light"
              width="64">
              <p>{{ props.row.id }}</p>
            </b-table-column>
            <b-table-column
              label="Line"
              v-slot="props"
              cell-class="has-text-grey-light"
              centered
              width="64">
              <p>{{ props.index + 1 }}</p>
            </b-table-column>
            <b-table-column
              label="Type"
              v-slot="props"
              cell-class="has-text-grey-light"
              centered
              width="96">
              <p>
                <span v-if="props.row.source === 'billables'">Auto</span>
                <span v-else>Manual</span>
              </p>
            </b-table-column>
            <b-table-column
              label="Service"
              cell-class="nowrap"
              v-slot="props">
              <p class="has-text-weight-bold">{{ props.row.service }}</p>
              <p>{{ props.row.description }}</p>
            </b-table-column>
            <b-table-column
              label="Quantity"
              centered
              width="96"
              v-slot="props">
              <p>{{ $numbers(props.row.quantity).format({ mantissa: 2 }) }}</p>
            </b-table-column>
            <b-table-column
              label="Rate"
              width="96"
              centered
              v-slot="props">
              <p><Currency :value="props.row.rate" /></p>
            </b-table-column>
            <b-table-column
              label="Total"
              width="96"
              centered
              v-slot="props">
              <p><Currency :value="props.row.total" /></p>
            </b-table-column>
            <b-table-column
              v-if="invoice.approved_at === null"
              width="128"
              cell-class="has-text-right"
              v-slot="props">
              <b-dropdown
                class="has-text-left"
                position="is-bottom-left"
                :close-on-click="false">
                <template #trigger="{ active }">
                  <b-button
                    label="Options"
                    type="is-primary"
                    size="is-small"
                    outlined
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
                </template>
                <b-dropdown-item @click=editLine(props.row) :disabled="Boolean(invoice.approved_at || props.row.source === 'billables')">Edit Line</b-dropdown-item>
                <b-dropdown-item @click=removeLine(props.row) :disabled="Boolean(invoice.approved_at)">Remove Line</b-dropdown-item>
              </b-dropdown>
            </b-table-column>
            <template slot="empty">
              <div class="py-xxl has-text-grey has-text-centered">
                No records found
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- Modal: Edit Transaction -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="editTransactionDetails"
      :can-cancel="['x', 'esc']"
      ><EditTransactionModal :transaction="invoice" @invoice:approved="$emit('invoice:saved')" @transaction:saved="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Approve Invoice -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showApproveInvoiceModal"
      :can-cancel="['x', 'esc']"
      ><ApproveInvoiceModal :invoice="invoice" @invoice:approved="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Unapprove Invoice -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUnapproveInvoiceModal"
      :can-cancel="['x', 'esc']"
      ><UnapproveInvoiceModal :invoice="invoice" @invoice:unapproved="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Export Invoice -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showPublishInvoiceModal"
      :can-cancel="['x', 'esc']"
      ><PublishInvoiceModal :invoice="invoice" @invoice:published="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Archive Invoice -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showArchiveInvoiceModal"
      :can-cancel="['x', 'esc']"
      ><ArchiveInvoiceModal :invoice="invoice" />
    </b-modal>
    <!-- Modal: Add/Update Invoice Line -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showEditLineModal"
      :can-cancel="['x', 'esc']"
      @close="resetLine"
      ><EditLineModal :invoice="invoice" :line="focusLine" @invoice:saved="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Convert Billables -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showConvertBillablesModal"
      :can-cancel="['x', 'esc']"
      ><ConvertBillablesModal :invoice="invoice" @invoice:saved="$emit('invoice:saved')" />
    </b-modal>
    <!-- Modal: Remove Line -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showRemoveLineModal"
      :can-cancel="['x', 'esc']"
      @close="resetLine"
      ><RemoveLineModal :invoice="invoice" :line="focusLine" @invoice:saved="$emit('invoice:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTransactionModal from '@/components/Models/ClientInvoice/Modals/Edit'
import ApproveInvoiceModal from '@/components/Models/ClientInvoice/Modals/Approve'
import UnapproveInvoiceModal from '@/components/Models/ClientInvoice/Modals/Unapprove'
import ArchiveInvoiceModal from '@/components/Models/ClientInvoice/Modals/Archive'
import PublishInvoiceModal from '@/components/Models/ClientInvoice/Modals/Publish'
import EditLineModal from '@/components/Models/ClientInvoice/Modals/EditLine'
import RemoveLineModal from '@/components/Models/ClientInvoice/Modals/RemoveLine'
import ConvertBillablesModal from '@/components/Models/ClientInvoice/Modals/ConvertBillables'
import Workflow from '../components/Summary/Workflow'

export default {
  components: {
    EditTransactionModal,
    ApproveInvoiceModal,
    UnapproveInvoiceModal,
    ArchiveInvoiceModal,
    PublishInvoiceModal,
    RemoveLineModal,
    EditLineModal,
    ConvertBillablesModal,
    Workflow
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      focusLine: null,
      editTransactionDetails: false,
      showApproveInvoiceModal: false,
      showUnapproveInvoiceModal: false,
      showPublishInvoiceModal: false,
      showArchiveInvoiceModal: false,
      showEditLineModal: false,
      showRemoveLineModal: false,
      showConvertBillablesModal: false
    }
  },
  methods: {
    createLine () {
      this.focusLine = null
      this.showEditLineModal = true
    },
    editLine (line) {
      this.focusLine = line
      this.showEditLineModal = true
    },
    removeLine (line) {
      this.focusLine = line
      this.showRemoveLineModal = true
    },
    resetLine () {
      this.focusLine = null
      this.showEditLineModal = false
      this.showRemoveLineModal = false
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  }
}
</script>
