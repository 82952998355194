<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- Intro -->
        <b-message type="is-info">
          <p class="is-size-5 has-text-weight-bold mb-sm">Create A New Project</p>
          <p class="is-size-7">
            Projects help you track budgets and manage project
            resources such as contributors, fixed-fee deliverables,
            and expenses.
          </p>
        </b-message>
        <!-- Content -->
        <div>
          <div>
            <b-field
              label="Client"
              :type="('client' in errors) ? 'is-danger' : ''"
              :message="('client' in errors) ? errors['client'][0] : ''"
              expanded>
              <Autocomplete
                v-model="client"
                resource="clients"
                labelField="name"
                valueField="id"
                size="is-medium" />
            </b-field>
            <!-- Project Name -->
            <b-field
              label="Project Name"
              :type="('name' in errors) ? 'is-danger' : ''"
              :message="('name' in errors) ? errors['name'][0] : ''"
              expanded>
              <b-input
                size="is-medium"
                type="text"
                v-model="name"
              ></b-input>
            </b-field>
            <b-field
              label="Project Leader"
              :type="('network_lead_user_id' in errors) ? 'is-danger' : ''"
              :message="('network_lead_user_id' in errors) ? errors['network_lead_user_id'][0] : ''"
              expanded>
              <!-- Owner -->
              <div class="mb-lg">
                <NetworkLeadSelect v-model="networkLead" />
              </div>
            </b-field>
            <div class="columns">
              <div class="column">
                <b-field
                  label="Start Date"
                  :type="('start_date' in errors) ? 'is-danger' : ''"
                  :message="('start_date' in errors) ? errors['start_date'][0] : ''">
                  <b-input
                    size="is-medium"
                    type="date"
                    v-model="startDate"
                    :min="minStartDate"
                  ></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field
                  label="End Date"
                  :type="('end_date' in errors) ? 'is-danger' : ''"
                  :message="('end_date' in errors) ? errors['end_date'][0] : ''">
                  <b-input
                    size="is-medium"
                    type="date"
                    v-model="endDate"
                    :min="minEndDate"
                    :max="maxEndDate"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/Forms/Autocomplete'
import NetworkLeadSelect from '@/components/Models/Role/NetworkLeaders'

export default {
  components: {
    Autocomplete,
    NetworkLeadSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (
        this.client &&
        this.name &&
        this.networkLead &&
        this.startDate &&
        this.endDate
      )
    },
    endpoint () {
      return this.api.route + '/projects'
    },
    json () {
      return {
        name: this.name,
        client_id: this.client.value,
        network_lead_user_id: this.networkLead.user_id,
        // staff_budget: this.budget,
        // expense_allowance: this.expenses,
        start_date: this.startDate,
        end_date: this.endDate
      }
    },
    minStartDate () {
      return this.$dates().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
    },
    minEndDate () {
      return this.$dates(this.startDate).format('YYYY-MM-DD')
    },
    maxEndDate () {
      return this.$dates().add(18, 'month').endOf('month').format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      loading: false,
      client: null,
      name: null,
      networkLead: null,
      startDate: null,
      endDate: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project added!' })
        this.$router.push({ name: 'network.project.lines', params: { code: this.context.code, id: response.data.id } })
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  }
}
</script>
