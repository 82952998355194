<template>
  <div>
    <div class="has-background-white has-shadow px-lg py-md">
      <div v-if="!loadingContributor">
        <!-- Header -->
        <div class="mb-md">
          <div class="columns is-vcentered">
            <div class="column">
              <div class="is-size-5">
                <p class="has-text-weight-bold">{{ contributor.role.user.full_name }}</p>
                <p class="has-text-grey-light is-size-7">
                  Contributor ID: {{ contributor.id }}
                </p>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'network.partner.contributors', params: { code: context.code, connection: connection.id }}">&lt; Back to Contributors</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Content -->
        <div>
          <b-tabs v-model="activeTab" :animated="false" :destroy-on-hide="true" >
            <b-tab-item
              value="notifications"
              headerClass="has-text-left has-text-weight-bold is-size-7 is-uppercase"
              label="Notifications">
              <Notifications :connection="connection" :contributor="contributor" @save:contributor="loadContributor" />
            </b-tab-item>
            <b-tab-item
              value="utilization"
              headerClass="has-text-left has-text-weight-bold is-size-7 is-uppercase"
              label="Utilization">
              <Utilization :connection="connection" :contributor="contributor" @save:contributor="loadContributor" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <div v-else>
        <Loading message="Loading contributor details..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Notifications from '../components/Contributor/Notifications'
import Utilization from '../components/Contributor/Utilization'

export default {
  components: {
    Notifications,
    Utilization
  },
  computed: {
    ...mapGetters(['api', 'context'])
  },
  data () {
    return {
      loadingContributor: true,
      contributor: null,
      activeTab: 'notifications'
    }
  },
  methods: {
    loadContributor () {
      this.loadingContributor = true
      const endpoint = this.api.route + '/connections/' + this.connection.id + '/contributors/' + this.$route.params.contributor
      this.$http.get(endpoint).then(response => {
        this.contributor = response.data
        this.loadingContributor = false
      })
    }
  },
  mounted () {
    this.loadContributor()
  },
  props: {
    connection: {
      type: Object,
      required: true
    }
  }
}
</script>
