<template>
  <div>
    <!-- Billing Period -->
    <div class="has-background-white pa-md rounded-lg mb-xxs">
      <div class="columns space-between is-vcentered">
        <div class="column">
          <div >
            <b-select
              v-model="period"
              placeholder="Select a Billing Period"
              size="is-medium"
              expanded>
              <option v-for="period in periods" :key="period.id" :value="period">
                {{ $dates(period.start_date).format('MMMM YYYY') }}
                {{ (period.closed_at) ? '(Closed)' : '(Open)' }}
              </option>
            </b-select>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <!-- Actions -->
    <div class="has-background-white rounded-lg pa-md mb-xxs">
      <b-dropdown aria-role="list">
        <template #trigger="{ active }">
          <b-button label="Actions" size="is-small" :icon-right="active ? 'menu-up' : 'menu-down'" />
        </template>
        <b-dropdown-item @click="showBillTimesheetsModal = true">Approve for Billing...</b-dropdown-item>
      </b-dropdown>
    </div>
    <!-- Timesheets Table -->
    <div class="mb-md">
      <b-table
        :data="seats"
        :mobile-cards="false"
        :checked-rows.sync="selected"
        checkable
        hoverable
        class="is-size-7">
        <b-table-column
          label="Contributor"
          v-slot="props"
          width="164">
          <p class="has-text-weight-bold">{{ props.row.seat.contributor.user.full_name }}</p>
        </b-table-column>
        <b-table-column
          label="Role"
          v-slot="props">
          <p>{{ props.row.role }}</p>
        </b-table-column>
        <b-table-column
          label="Planned Hrs"
          header-class="nowrap"
          centered
          width="128"
          v-slot="props">
          <p v-if="props.row.seat.timesheets.length === 1">
            <Hours :hours="props.row.seat.timesheets[0].total_hours" />
          </p>
          <p v-else class="has-text-weight-grey">0</p>
        </b-table-column>
        <b-table-column
          label="Reported Hrs"
          header-class="nowrap"
          centered
          width="128"
          v-slot="props">
          <p v-if="props.row.seat.timesheets.length === 1">
            <Hours :hours="props.row.seat.timesheets[0].total_hours" />
          </p>
          <p v-else class="has-text-weight-grey">0</p>
        </b-table-column>
        <b-table-column
          label="Billable"
          centered
          width="96"
          v-slot="props">
          <p v-if="props.row.seat.timesheets.length === 1">
            <Currency :value="props.row.seat.timesheets[0].total_billable" />
          </p>
          <p v-else class="has-text-weight-grey">$0.00</p>
        </b-table-column>
        <b-table-column
          label="Spend"
          centered
          width="96"
          v-slot="props">
          <p v-if="props.row.seat.timesheets.length === 1">
            <Currency :value="props.row.seat.timesheets[0].total_spend" />
          </p>
          <p v-else class="has-text-weight-grey">$0.00</p>
        </b-table-column>
        <b-table-column
          label="Margin"
          centered
          width="96"
          v-slot="props">
          <p v-if="props.row.seat.timesheets.length === 1">
            <Margin :revenue="props.row.seat.timesheets[0].total_billable"   :cogs="props.row.seat.timesheets[0].total_spend" />
          </p>
          <p v-else class="has-text-weight-grey">$0.00</p>
        </b-table-column>
        <b-table-column
          label="Status"
          v-slot="props"
          centered
          width="128">
          <p v-if="props.row.seat.timesheets.length === 1">
            <StatusPresenter :timesheet="props.row.seat.timesheets[0]" />
          </p>
          <p v-else>
            <span class="has-text-weight-bold has-text-danger">Not Started</span>
          </p>
        </b-table-column>
        <!-- <b-table-column
          label=""
          centered
          width="128"
          v-slot="props">
          <div v-if="props.row.seat.timesheets.length === 1">
            <ApproveButton
              :project="project"
              :timesheet="props.row.seat.timesheets[0]"
              size="is-small"
              @timesheet:saved="loadTimesheets(true)"
            />
          </div>
          <div v-else>
            <b-button size="is-small" type="is-primary" outlined disabled>Approve</b-button>
          </div>
        </b-table-column> -->
        <template #empty>
          <div class="has-text-centered pa-lg">No contributors have been added to this project yet.</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusPresenter from '@/components/Models/Timesheet/Presenters/Status'

export default {
  components: {
    StatusPresenter
  },
  computed: {
    ...mapGetters(['api']),
    currentPeriod () {
      return this.$dates().startOf('month').format('YYYY-MM-DD')
    },
    loading () {
      return (this.loadingTimesheets || this.loadingBillingPeriods)
    },
    seats () {
      const seats = []
      if (this.loading || this.projectTimesheets == null) return []
      this.projectTimesheets.positions.forEach(position => {
        position.seats.forEach(seat => {
          const flatten = {
            role: position.role,
            seat: seat
          }
          seats.push(flatten)
        })
      })
      return seats
    }
  },
  data () {
    return {
      loadingTimesheets: true,
      loadingBillingPeriods: true,
      periods: [],
      period: null,
      projectTimesheets: null,
      selected: [],
      seat: null,
      timesheet: null,
      showHourDetailModal: false,
      showPlanDetailModal: false
    }
  },
  methods: {
    loadBillingPeriods () {
      this.loadingBillingPeriods = true
      const endpoint = this.api.base + 'billing-periods'
      const query = {
        params: {
          start: this.project.start_date,
          end: this.project.end_date
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.periods = response.data
        response.data.forEach(period => {
          if (this.currentPeriod === period.start_date) {
            this.period = period
          }
        })
        this.loadingBillingPeriods = false
      })
    },
    loadTimesheets (refresh = false) {
      if (!refresh) this.loadingTimesheets = true
      const endpoint = this.api.route + '/projects/' + this.$route.params.id
      const params = {
        params: {
          billing_period: this.period.id
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.projectTimesheets = response.data
        this.loadingTimesheets = false
      })
    },
    showPlanDetail (seat) {
      this.seat = seat
      this.showPlanDetailModal = true
    },
    showHourDetail (timesheet) {
      this.timesheet = timesheet
      this.showHourDetailModal = true
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  watch: {
    period (value) {
      if (value) {
        this.loadTimesheets()
      }
    }
  }
}
</script>
