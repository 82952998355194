<template>
  <div class="section">
    <div class="container">
      <!-- Period Selector -->
      <div class="has-background-white pa-md shadow mb-lg">
        <div class="columns is-vcentered">
          <div class="column is-one-third">
            <div>
              <b-select
                v-model="period"
                size="is-medium"
                placeholder="Select a Billing Period"
                :loading="loadingBillingPeriods"
                expanded>
                <option v-for="period in periods" :key="period.id" :value="period">
                  {{ $dates(period.start_date).format('MMMM YYYY') }}
                  {{ (period.closed_at) ? '(Closed)' : '(Open)' }}
                </option>
              </b-select>
            </div>
          </div>
          <div v-if="period" class="column is-narrow">
            <a href="#"
              @click.prevent="exportHours"
            >Export Report</a>
          </div>
        </div>
      </div>
      <!-- Summary -->
      <div>
        <div class="columns">
          <div class="column">
            <div class="has-background-white pa-md shadow mb-lg has-text-centered">
              <p class="is-size-3">
                <span v-if="!loading">{{ summary.timesheets }}</span>
                <span v-else class="has-text-grey">Loading...</span>
              </p>
              <p class="has-text-grey-light is-size-7 has-text-centered">Roles Reporting</p>
            </div>
          </div>
          <div class="column">
            <div class="has-background-white pa-md shadow mb-lg has-text-centered">
              <p class="is-size-3">
                <span v-if="!loading"><Hours :hours="summary.hours" /></span>
                <span v-else class="has-text-grey">Loading...</span>
              </p>
              <p class="has-text-grey-light is-size-7">Hours Reported</p>
            </div>
          </div>
          <div class="column">
            <div class="has-background-white pa-md shadow mb-lg has-text-centered">
              <p class="is-size-3">
                <span v-if="!loading"><Currency :value="summary.earnings" /></span>
                <span v-else class="has-text-grey">Loading...</span>
              </p>
              <p class="has-text-grey-light is-size-7 has-text-centered">Expected Earnings</p>
            </div>
          </div>
          <div class="column">
            <div class="has-background-white pa-md shadow mb-lg has-text-centered">
              <p class="is-size-3">
                <span v-if="!loading"><Currency :value="(summary.hours > 0) ? summary.earnings / summary.hours : 0" /></span>
                <span v-else class="has-text-grey">Loading...</span>
              </p>
              <p class="has-text-grey-light is-size-7 has-text-centered">Overall Rate</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Timesheets -->
      <div class="pa-md has-background-white shadow mb-lg">
        <b-table
          :data="timesheets"
          :mobile-cards="false"
          hoverable
          class="is-size-7">
          <b-table-column
            label="ID"
            cell-class="has-text-grey-light"
            centered
            width="64"
            v-slot="props">
            <p>{{ props.row.id }}</p>
          </b-table-column>
          <b-table-column
            label="Month"
            centered
            width="96"
            v-slot="props">
            <p>{{ props.row.billing_period.label }}</p>
          </b-table-column>
          <b-table-column
            label="Project"
            v-slot="props">
            <p>
              {{ props.row.seat.position.project.name }}
              #{{props.row.seat.position.project_id }}
            </p>
          </b-table-column>
          <b-table-column
            label="Role"
            v-slot="props">
            <p>{{ props.row.seat.position.role }}</p>
          </b-table-column>
          <b-table-column
            label="Hours"
            header-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <Hours :hours="props.row.hours_reported" />
          </b-table-column>
          <b-table-column
            label="Earnings"
            header-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <Currency :value="props.row.cost" />
          </b-table-column>
          <b-table-column
            label="Rate"
            header-class="nowrap"
            centered
            width="128"
            v-slot="props">
            <Currency :value="Number(props.row.cost) / Number(props.row.hours_reported)" />
          </b-table-column>
          <b-table-column
            label="Status"
            centered
            width="128"
            v-slot="props">
            <p><Status :timesheet="props.row" /></p>
          </b-table-column>
          <template #empty>
            <div class="has-text-centered pa-lg py-3xl">
              You haven't added any timesheets for this billing period.
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Status from '@/components/Models/Timesheet/Presenters/Status'

export default {
  components: {
    Status
  },
  computed: {
    ...mapGetters(['api', 'auth', 'context']),
    loading () {
      return (this.loadingBillingPeriods)
    },
    summary () {
      const summary = {
        timesheets: 0,
        hours: 0,
        earnings: 0
      }
      if (this.timesheets.length) {
        this.timesheets.forEach(timesheet => {
          summary.timesheets += 1
          summary.hours += Number(timesheet.hours_reported)
          summary.earnings += Number(timesheet.cost)
        })
      }
      return summary
    }
  },
  data () {
    return {
      loadingBillingPeriods: true,
      periods: [],
      period: null,
      timesheets: []
    }
  },
  methods: {
    loadBillingPeriods () {
      this.loadingBillingPeriods = true
      const endpoint = this.api.base + 'billing-periods'
      const params = {
        params: {
          start: this.auth.user.joined,
          end: this.$dates().add(1, 'month').format('YYYY-MM-DD')
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.periods = response.data
        this.periods.forEach(period => {
          if (this.$dates(period.start_date).startOf('month').format('YYYY-MM-DD') === this.$dates().startOf('month').format('YYYY-MM-DD')) {
            this.period = period
          }
        })
        this.loadingBillingPeriods = false
      })
    },
    loadTimesheets () {
      this.loadingTimesheets = true
      const endpoint = this.api.route + '/timesheets'
      const params = {
        params: {
          short: 1,
          billing_period: this.period.id
        }
      }
      this.$http.get(endpoint, params).then(response => {
        this.timesheets = response.data.data
        this.loadingTimesheets = false
      })
    },
    exportHours () {
      const endpoint = this.api.route + '/timesheets/export'
      axios({
        url: endpoint,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hours.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  watch: {
    period (value) {
      if (value !== null) this.loadTimesheets()
    }
  }
}
</script>
