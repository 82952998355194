<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.billing', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Billing</p>
              <p class="has-text-grey-light is-size-7">Review project billing status.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.activity', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Approve Time &amp; Expenses</p>
              <p class="has-text-grey-light is-size-7">Review timesheets across all projects.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.timesheets', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Timesheet History</p>
              <p class="has-text-grey-light is-size-7">Review all historical timesheets.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.client.invoices', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Client Transactions</p>
              <p class="has-text-grey-light is-size-7">Generate and manage client invoices &amp; credits.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.reports', params: { code: context.code } }">
            <div class="has-background-white rounded-lg px-xl py-md">
              <p class="has-text-weight-bold">Reports</p>
              <p class="has-text-grey-light is-size-7">Generate reports.</p>
            </div>
          </router-link>
        </div>
        <div class="column is-half">
          <div class="has-background-white rounded-lg px-xl py-md">
            <div class="columns is-vcentered">
              <div class="column">
                <p class="has-text-weight-bold has-text-primary">Profitabilty</p>
                <p class="has-text-grey-light is-size-7">Review profitablity reports.</p>
              </div>
              <div class="column is-narrow">
                <b-select v-model="profitReport" expanded placeholder="Select View..." @input="viewProfitReport">
                  <option value="organization">By Organization</option>
                  <option value="project">By Project</option>
                  <option value="contributor">By Contributor</option>
                </b-select>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="column is-half">
          <router-link :to="{ name: 'network.finance.revisions', params: { code: context.code } }">
            <RevisionsLink />
          </router-link>
        </div>
        <div class="column is-half">
          <router-link :to="{ name: 'network.finance.transfers', params: { code: context.code } }">
            <TransfersLink />
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['context'])
  },
  data () {
    return {
      profitReport: null
    }
  },
  methods: {
    viewProfitReport () {
      this.$router.push({ name: 'network.finance.profit.' + this.profitReport })
    }
  }
}
</script>
