<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Link Accounting Platform</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Platform">
          <PlatformAccountSelect v-model="account" />
        </b-field>
        <b-field label="Customer ID">
          <b-input v-model="customerId" type="text" size="is-medium" />
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <b-button @click="$parent.close()">Close</b-button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformAccountSelect from '@/components/Models/PlatformAccount/SelectMenu'

export default {
  components: {
    PlatformAccountSelect
  },
  computed: {
    ...mapGetters(['api', 'context']),
    json () {
      return {
        platform_account_id: this.account.id,
        purpose: 'accounting',
        meta: [{
          code: 'customer_id',
          label: 'Customer ID',
          value: this.customerId
        }]
      }
    }
  },
  data () {
    return {
      account: null,
      customerId: null,
      saving: false,
      errors: []
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/funding/' + this.$route.params.id + '/linkPlatform'
      this.$http.put(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Funding linked!' })
        this.$emit('funding:saved', response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    const platform = this.funding.platforms.find(platform => {
      return (platform.purpose === 'accounting')
    })
    if (platform) {
      this.account = platform.platform_account
      this.customerId = platform.meta.find(row => {
        return (row.code === 'customer_id')
      }).value
    }
  },
  props: {
    funding: {
      type: Object,
      required: true
    }
  }
}
</script>
