<template>
  <div>
    <b-table
      :data="seats"
      :mobile-cards="false"
      hoverable
      narrowed
      class="is-size-7">
      <b-table-column
        v-slot="props"
        label="Time &amp; Materials"
        class="is-size-7"
        header-class="has-text-primary"
        cell-class="nowrap">
        <!-- Has Contributor -->
        <div>
          <p v-if="props.row.contributor">
            <span class="has-text-weight-bold">{{ props.row.contributor.user.full_name }}</span>
            <StatusPresenter :seat="props.row" class="ml-sm" />
          </p>
          <p v-else>
            <span class="has-text-weight-bold">- Unassigned -</span>
          </p>
          <div class="has-text-grey is-size-8">
            <p v-if="props.row.role">{{ props.row.role }}</p>
            <p>
              Bill Rate <Currency :value="props.row.plan_bill_rate" :mantissa="0"/> / Hr
              &bull;
              Cost Rate: <Currency :value="props.row.plan_cost_rate" :mantissa="0" /> / Hr <span v-if="!props.row.rate_is_confirmed" class="has-text-danger" :mantissa="0">**</span>
            </p>
          </div>
        </div>
      </b-table-column>
      <!-- Allocated Hours -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Allocated</p>
          <p class="has-text-grey">(hrs)</p>
        </template>
        <template v-slot="props">
          <p><Hours :hours="props.row.units_allocated" :mantissa="2" /></p>
        </template>
      </b-table-column>
      <!-- Pending Hours -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Pending</p>
          <p class="has-text-grey">(hrs)</p>
        </template>
        <template v-slot="props">
          <p><Hours :hours="props.row.units_pending" :mantissa="2" /></p>
        </template>
      </b-table-column>
      <!-- Delivered Hours -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Delivered</p>
          <p class="has-text-grey">(hrs)</p>
        </template>
        <template v-slot="props">
          <PacedUnits :seat="props.row" />
        </template>
      </b-table-column>
      <!-- Remaining Hours -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Remaining</p>
          <p class="has-text-grey">(hrs)</p>
        </template>
        <template v-slot="props">
          <p v-if="Number(props.row.units_allocated)"><Hours :hours="Number(props.row.units_allocated) - Number(props.row.units_fulfilled)" :mantissa="2" /></p>
          <p v-else>--</p>
        </template>
      </b-table-column>
      <!-- Allocated ($) -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Allocated</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p><Currency :value="props.row.amount_allocated" :mantissa="2" /></p>
        </template>
      </b-table-column>
      <!-- Pending ($) -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Pending</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p><Currency :value="props.row.amount_pending" :mantissa="2" /></p>
        </template>
      </b-table-column>
      <!-- Delivered ($) -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Delivered</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <PacedDelivery :seat="props.row" />
        </template>
      </b-table-column>
      <!-- Remaining ($) -->
      <b-table-column
        header-class="is-size-8"
        cell-class="nowrap"
        centered
        width="84">
        <template v-slot:header>
          <p>Remaining</p>
          <p class="has-text-grey">($)</p>
        </template>
        <template v-slot="props">
          <p v-if="Number(props.row.amount_allocated)"><Currency :value="Number(props.row.amount_allocated) - Number(props.row.amount_fulfilled)" :mantissa="2" /></p>
          <p v-else>--</p>
        </template>
      </b-table-column>
      <b-table-column
        v-slot="props"
        cell-class="nowrap"
        width="32"
        numeric>
        <OptionsMenu
          :projectId="project.id"
          :seat="props.row"
          @seat:manage="manageSeat"
          @seat:view-rates="showSeatRates"
          @seat:change-rate="changeSeatRate"
          @seat:activate="activateSeat"
          @seat:pause="pauseSeat"
          @seat:restart="restartSeat"
          @seat:archive="archiveSeat"
          @seat:review="reviewSeat"
        />
      </b-table-column>
      <template #empty>
        <div class="has-text-centered is-size -7 pa-md">
          <b-button type="is-primary rounded-lg" size="is-small" outlined @click="$emit('seat:add')">Add Partner</b-button>
        </div>
      </template>
    </b-table>
    <!-- Modal: Seat Workflow -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatWorkflowModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><UpdateSeatStatusModal :project="project" :seat="focusSeat" :action="focusAction" @seat:saved="$emit('seat:saved')" />
    </b-modal>
    <!-- Modal: Change Seat Rate -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatRateChangeModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><SeatRateChangeModal :project="project" :seat="focusSeat" @seat:saved="$emit('seat:saved')" />
    </b-modal>
    <!-- Modal: View Seat Rate History -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatRateHistoryModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><SeatRateHistory :project="project" :seat="focusSeat" />
    </b-modal>
    <!-- Modal: View Seat Rate History -->
    <b-modal
      v-if="focusSeat"
      has-modal-card
      scroll="keep"
      :active.sync="showSeatPerformanceModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><SeatPerformanceModal :project="project" :seat="focusSeat" />
    </b-modal>
  </div>
</template>

<script>
import PacedUnits from '@/components/Models/Seat/Presenters/PacedFulfillmentUnits'
import PacedDelivery from '@/components/Models/Seat/Presenters/PacedFulfillment'
import StatusPresenter from '@/components/Models/Seat/Presenters/Status'
import OptionsMenu from '@/components/Models/Seat/Options'
import SeatRateHistory from '@/components/Models/Seat/Modals/RateHistory'
import SeatRateChangeModal from '@/components/Models/Seat/Modals/RateChange'
import UpdateSeatStatusModal from '@/components/Models/Seat/Modals/UpdateStatus'
import SeatPerformanceModal from '@/components/Models/Seat/Modals/Performance'

export default {
  components: {
    PacedUnits,
    PacedDelivery,
    StatusPresenter,
    OptionsMenu,
    // modals
    SeatRateHistory,
    SeatRateChangeModal,
    UpdateSeatStatusModal,
    SeatPerformanceModal
  },
  data () {
    return {
      focusSeat: null,
      focusAction: null,
      showSeatRateHistoryModal: false,
      showSeatWorkflowModal: false,
      showSeatRateChangeModal: false,
      showSeatPerformanceModal: false
    }
  },
  methods: {
    manageSeat (seat) {
      this.$emit('seat:manage', seat)
    },
    activateSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'activate'
      this.showSeatWorkflowModal = true
    },
    pauseSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'pause'
      this.showSeatWorkflowModal = true
    },
    restartSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'restart'
      this.showSeatWorkflowModal = true
    },
    archiveSeat (seat) {
      this.focusSeat = seat
      this.focusAction = 'archive'
      this.showSeatWorkflowModal = true
    },
    showSeatRates (seat) {
      this.focusSeat = seat
      this.showSeatRateHistoryModal = true
    },
    changeSeatRate (seat) {
      this.focusSeat = seat
      this.showSeatRateChangeModal = true
    },
    reviewSeat (seat) {
      this.focusSeat = seat
      this.showSeatPerformanceModal = true
    },
    reset () {
      this.focusSeat = null
      this.focusAction = null
      this.showSeatRateHistoryModal = false
      this.showSeatRateChangeModal = false
      this.showSeatWorkflowModal = false
      this.showSeatPerformanceModal = false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    seats: {
      type: Array,
      required: true
    }
  }
}
</script>
