<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Client</p>
      </header>
      <section class="modal-card-body">
        <b-field
          label="Parent Organization"
          :type="('organization' in errors) ? 'is-danger' : ''"
          :message="('organization' in errors) ? errors['organization'][0] : ''">
          <Autocomplete
            v-model="organization"
            resource="organizations"
            labelField="name"
            valueField="id"
            size="is-medium"
          />
        </b-field>
        <b-field
          label="Client Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''">
            <b-input
              autocomplete="off"
              size="is-medium"
              type="text"
              v-model="name"
            ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from '@/components/Forms/Autocomplete'
export default {
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters(['api']),
    ready () {
      return true
    },
    endpoint () {
      return this.api.route + '/clients'
    },
    json () {
      const json = { name: this.name }
      if (this.organization !== null) {
        json.organization = this.organization.value
      }
      return json
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http.post(this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Client added!' })
        this.$parent.close()
        this.$emit('client:updated', response.data.id)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      name: null,
      organization: null,
      saving: false,
      errors: []
    }
  }
}
</script>
