<template>
  <div>
    <div>
      <div>
        <!-- Filters Toolbar -->
        <div class="has-background-white rounded-lg pa-md mb-xxs">
          <div class="columns">
            <div class="column">
              <OrganizationFilters :applied-filters="filters" @filter="filter" />
            </div>
            <div class="column is-narrow">
              <b-select v-model="orderBy" expanded @input="sort" size="is-medium">
                <option value="id_desc">Most Recent</option>
                <option value="id_asc">Oldest</option>
              </b-select>
            </div>
            <div class="column is-narrow">
              <b-button type="is-primary" size="is-medium" class="is-fullwidth" outlined @click.prevent="showCreateOrganizationModal = true">New Organization</b-button>
            </div>
          </div>
        </div>
        <!-- Actions Toolbar -->
        <div class="has-background-white rounded-lg px-md py-md mb-xxs">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              <div class="has-text-right is-size-7">
                <p v-if="loading">Loading...</p>
                <p v-else-if="total === 1">1 organization found</p>
                <p v-else>{{ total }} organizations found</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Results -->
        <div style="overflow-x:auto" class="mb-md">
          <b-table
            :data="results"
            :checked-rows.sync="selected"
            :mobile-cards="false"
            :loading="loading"
            hoverable
            class="is-size-7">
            <b-table-column
              label="ID"
              width="64"
              v-slot="props">
              <span class="has-text-grey-light">{{ props.row.id }}</span>
            </b-table-column>
            <b-table-column
              label="Logo"
              width="164"
              v-slot="props">
              <div v-if="props.row.logo_path">
                <cld-image :publicId="props.row.logo_path" width="120" dpr="2.0" crop="scale" style="display:block;" />
              </div>
            </b-table-column>
            <b-table-column
              label="Organization Name"
              v-slot="props">
              <router-link
                class="has-text-weight-bold"
                :to="{ name: 'network.organization', params: { code: context.code, id: props.row.id } }">
                {{ props.row.name }}
              </router-link>
            </b-table-column>
            <b-table-column
              label="Fiscal Start"
              width="128"
              v-slot="props">
              <span v-if="props.row.fiscal_start_month">{{ $dates().month(props.row.fiscal_start_month - 1).format('MMMM') }}</span>
              <span v-else>January</span>
            </b-table-column>
            <template slot="empty">
              <div class="py-xxl has-text-grey has-text-centered">
                {{ (loading) ? "Loading data..." : 'No records found!' }}
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          :total="total"
          :current.sync="currentPage"
          :rounded="true"
          :per-page="perPage"
          @change="paginate">
        </b-pagination>
      </div>
    </div>
    <!-- Modal: Create -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateOrganizationModal"
      :can-cancel="['x', 'esc']"
      ><EditOrganizationModal @organization:saved="redirect" />
    </b-modal>
  </div>
</template>

<script>
import Filterable from '@/mixins/Filterable'
import OrganizationFilters from '../components/Organizations/OrganizationFilters'
import EditOrganizationModal from '@/components/Models/Organization/CreateOrganizationModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    EditOrganizationModal,
    OrganizationFilters
  },
  computed: {
    ...mapGetters(['api', 'context']),
    history () {
      return [
        { label: 'Organizations', name: 'network.organizations', params: { code: this.context.code } }
      ]
    },
    endpoint () {
      return this.api.route + '/organizations'
    }
  },
  data: function () {
    return {
      key: 'organizations',
      showCreateOrganizationModal: false
    }
  },
  methods: {
    redirect (id) {
      this.$router.push({ name: 'network.organization', params: { code: this.context.code, id: id } })
    }
  },
  mounted () {
    this.load()
  },
  mixins: [Filterable]
}
</script>
