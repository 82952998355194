import Index from './views/Index'

export default [{
  path: '/context',
  name: 'context',
  component: Index,
  meta: {
    skipContext: true,
    hideNavigation: true
  }
}]
