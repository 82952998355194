<template>
  <b-select
    v-model="title"
    size="is-medium"
    placeholder="Select Title"
    :loading="loadingClientTitles"
    @input="announce"
    expanded>
    <option v-for="title in titles" :key="title.id" :value="title">
      {{ title.title }} (Rate Card: <Currency :value="title.rate_card" /> / hr)
    </option>
  </b-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api'])
  },
  data () {
    return {
      loadingClientTitles: false,
      titles: [],
      title: null
    }
  },
  methods: {
    loadClientTitles () {
      this.loadingClientTitles = true
      const endpoint = this.api.route + '/clients/' + this.client.id + '/titles'
      const query = {
        params: {
          paginate: 0
        }
      }
      this.$http.get(endpoint, query).then(response => {
        this.titles = response.data
        if (this.value) {
          response.data.forEach(title => {
            if (title.id === this.value.id) {
              this.title = this.value
            }
          })
        }
        this.loadingClientTitles = false
      })
    },
    announce () {
      this.$emit('input', this.title)
    }
  },
  mounted () {
    if (this.autoload) {
      this.loadClientTitles()
    }
  },
  props: {
    autoload: {
      type: Boolean,
      default: true
    },
    client: {
      type: Object,
      required: true
    },
    value: {
      type: Object
    }
  }
}
</script>
