<template>
  <div id="app">
    <div v-if="ready">
      <!-- Navigation -->
      <div class="border-t has-border-primary has-border-xl mb-sm">
        <Navigation v-if="showNavigation" @invitations:show="showInvitationsModal = true" @announcements:show="showAnnouncementsModal = true"/>
      </div>
      <!-- Router View -->
      <router-view v-if="showRouterView" />
      <!-- Footer -->
      <Footer />
    </div>
    <div v-else class="py-xxl">
      <Loading message="Welcome to GatherWorks.com!" />
    </div>
    <!-- Announcements  -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showAnnouncementsModal"
      :can-cancel="['x', 'esc']"
      ><AnnouncementsModal />
    </b-modal>
    <!-- Invitations Modal -->
    <b-modal
      v-if="context.type === 'partner'"
      has-modal-card
      scroll="keep"
      :active.sync="showInvitationsModal"
      :can-cancel="['x', 'esc']"
      ><PartnerInviteModal />
    </b-modal>
    <!-- Error Modal -->
    <b-modal
      :active="app.showErrorModal"
      @close="$store.dispatch('clearServerError')"
      has-modal-card :can-cancel="['escape', 'x']">
      <ServerErrorModal />
    </b-modal>
  </div>
</template>

<script>
import Navigation from './modules/App/Navigation'
import AnnouncementsModal from '@/components/Models/Announcement/Modals/List'
import PartnerInviteModal from '@/components/Models/PartnerInvite/PartnerInviteModal'
import ServerErrorModal from './modules/App/ServerErrorModal'
import { mapGetters } from 'vuex'

export default {
  components: {
    Navigation,
    AnnouncementsModal,
    PartnerInviteModal,
    ServerErrorModal
  },
  computed: {
    ...mapGetters(['app', 'context']),
    showNavigation () {
      return !this.$route.meta.hideNavigation && this.context.resolved
    },
    showRouterView () {
      return this.ready
    }
  },
  data () {
    return {
      ready: false,
      showInvitationsModal: false,
      showAnnouncementsModal: false
    }
  },
  mounted () {
    this.$store.dispatch('resolveRole').then(() => {

    }).catch(() => {
      this.$router.push({ name: 'context' })
    }).finally(() => {
      this.ready = true
    })
  }
}
</script>
