<template>
  <div>
    <form @submit.prevent="save">
      <div class="has-background-white rounded-lg pa-lg mb-xxs">
        <!-- Back Link -->
        <div class="border-b pb-md mb-lg">
          <div class="columns">
            <div class="column">
              <p class="has-text-primary has-text-weight-bold is-size-5">
                <span v-if="!loading">{{ seat.contributor.user.full_name }}</span>
                <span v-else class="has-text-grey-light">Loading...</span>
              </p>
              <p class="has-text-weight-bold">Contributor Planner</p>
            </div>
            <div class="column is-narrow">
              <div class="has-text-right">
                <router-link :to="{ name: 'network.project.detail', params: { code: context.code }}">&lt; Back to Project Detail</router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- Seat Plan Details -->
        <div v-if="!loading">
          <div class="columns">
            <div class="column">
              <!-- Toolbar -->
              <div class="border-b pb-md">
                <div class="columns">
                  <!-- <div class="column">
                    <b-radio v-model="planBy" native-value="hours">Plan by hours</b-radio>
                  </div> -->
                  <div class="column">
                    <b-checkbox v-model="showClosedPlans">Show Closed Periods</b-checkbox>
                  </div>
                </div>
              </div>

              <!-- Closed Plans Table -->
              <div v-if="closedPlans.length && showClosedPlans">
                <b-table
                  :data="closedPlans"
                  :mobile-cards="false"
                  hoverable
                  class="is-size-7">
                  <b-table-column
                    label="Period"
                    header-class="nowrap is-size-8"
                    cell-class="nowrap"
                    v-slot="props">
                    <p class="has-text-weight-bold">
                      {{ $dates(props.row.period.start_date).format('MMM YYYY') }}
                      <span class="is-size-8 is-uppercase"><PeriodStatus :period="props.row.period" /></span>
                    </p>
                    <p class="is-size-8 has-text-grey-light">
                      {{ $dates(props.row.period.start_date).format('MMM D') }}
                      -
                      {{ $dates(props.row.period.end_date).format('MMM D') }}
                    </p>
                    <div class="is-size-8 has-text-grey-light">
                      <p>Total Days: {{ props.row.period.days_total }}, Business Days: {{ props.row.period.days_business }}, Holidays: {{ props.row.period.days_holidays }}</p>
                    </div>
                  </b-table-column>
                  <b-table-column
                    label="Hours Assigned"
                    header-class="is-size-8"
                    centered
                    width="128"
                    v-slot="props">
                    <Currency :value="openPlans[props.index].seatPlan.hours_billed" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Hours Billed"
                    header-class="is-size-8"
                    centered
                    width="128"
                    v-slot="props">
                    <Currency :value="openPlans[props.index].seatPlan.hours_billed" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Billed ($)"
                    header-class="is-size-8"
                    centered
                    width="128"
                    v-slot="props">
                    <Currency :value="openPlans[props.index].seatPlan.hours_billed" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Spend ($)"
                    header-class="is-size-8"
                    centered
                    width="128"
                    v-slot="props">
                    <Currency :value="openPlans[props.index].seatPlan.budget_billed" zeroValue="dash" />
                  </b-table-column>
                  <b-table-column
                    label="Margin"
                    header-class="is-size-8"
                    width="64"
                    centered
                    v-slot="props">
                    <Margin
                      :revenue="closedPlans[props.index].seatPlan.hours_assigned * seat.position.client_rate.rate"
                      :cogs="closedPlans[props.index].seatPlan.hours_assigned * seat.rate.rate"
                    />
                  </b-table-column>
                </b-table>
              </div>

              <!-- Table -->
              <b-table
                :data="openPlans"
                :mobile-cards="false"
                hoverable
                class="is-size-7">
                <b-table-column
                  label="Period"
                  header-class="nowrap is-size-8"
                  cell-class="nowrap"
                  v-slot="props">
                  <p class="has-text-weight-bold">
                    {{ $dates(props.row.period.start_date).format('MMM YYYY') }}
                    <span class="is-size-8 is-uppercase"><PeriodStatus :period="props.row.period" /></span>
                  </p>
                  <p class="is-size-8 has-text-grey-light">
                    {{ $dates(props.row.period.start_date).format('MMM D') }}
                    -
                    {{ $dates(props.row.period.end_date).format('MMM D') }}
                  </p>
                  <div class="is-size-8 has-text-grey-light">
                    <p>Total Days: {{ props.row.period.days_total }}, Business Days: {{ props.row.period.days_business }}, Holidays: {{ props.row.period.days_holidays }}</p>
                  </div>
                </b-table-column>
                <b-table-column
                  label="Planned for Role (Hrs)"
                  header-class="is-size-8"
                  cell-class="nowrap"
                  width="128"
                  centered
                  v-slot="props">
                  <Hours :hours="('positionPlan' in props.row) ? props.row.positionPlan.hours_planned : 0" zeroValue="na" />
                </b-table-column>
                <b-table-column
                  label="Assigned to Partner (Hrs)"
                  header-class="is-size-8"
                  centered
                  width="148"
                  v-slot="props">
                  <b-input
                    type="number"
                    v-model="openPlans[props.index].seatPlan.hours_assigned"
                    expanded
                    :disabled="props.row.seatPlan !== null && props.row.seatPlan.closed_at"
                  ></b-input>
                </b-table-column>
                <!-- <b-table-column
                  label="Available to Assign (Hrs)"
                  header-class="is-size-8"
                  width="132"
                  centered
                  v-slot="props">
                  <a href="#"
                    class="has-text-weight-bold"
                    @click.prevent="assignHours(props.index, props.row)"
                  ><Hours :hours="('positionPlan' in props.row && 'seatPlan' in props.row) ? props.row.positionPlan.hours_planned - openPlans[props.index].seatPlan.hours_assigned : 0" zeroValue="zero" /></a>
                </b-table-column> -->
                <!-- <b-table-column
                  label="Billable ($)"
                  header-class="is-size-8"
                  centered
                  width="128"
                  v-slot="props">
                  <Currency :value="openPlans[props.index].seatPlan.hours_assigned * seat.position.client_rate.rate" zeroValue="dash" />
                </b-table-column> -->
                <!-- <b-table-column
                  label="Spend ($)"
                  header-class="is-size-8"
                  centered
                  width="128"
                  v-slot="props">
                  <Currency :value="openPlans[props.index].seatPlan.hours_assigned * seat.rate.rate" zeroValue="dash" />
                </b-table-column> -->
                <!-- <b-table-column
                  label="Margin"
                  header-class="is-size-8"
                  width="64"
                  centered
                  v-slot="props">
                  <Margin
                    :revenue="openPlans[props.index].seatPlan.hours_assigned * seat.position.client_rate.rate"
                    :cogs="openPlans[props.index].seatPlan.hours_assigned * seat.rate.rate"
                  />
                </b-table-column>-->
                <template #empty>
                  <div class="has-text-centered pa-lg">No plans found.</div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="has-background-white rounded-lg px-lg py-md mb-xxs">
        <b-button
          native-type="submit"
          type="is-primary"
          size="is-medium"
          :disabled="saving"
          :loading="saving"
        >Save</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PeriodStatus from '@/components/Models/BillingPeriod/Presenters/Status'

export default {
  components: {
    PeriodStatus
  },
  computed: {
    ...mapGetters(['api', 'context']),
    breadcrumbs () {
      return [
        { label: 'Projects', name: 'network.projects', params: { code: this.context.code } },
        { label: 'Project Detail', name: 'network.project.detail', params: { code: this.context.code, id: this.$route.params.project } }
      ]
    },
    loading () {
      return (this.loadingSeat || this.loadingBillingPeriods)
    },
    summary () {
      const summary = {
        hours: 0,
        budget: 0,
        spend: 0
      }
      this.plans.forEach(plan => {
        if (plan.plan.hours_assigned) {
          summary.hours += parseFloat(plan.plan.hours_assigned)
          summary.budget += parseFloat(plan.plan.hours_assigned) * parseFloat(this.seat.position.client_rate.rate)
          summary.spend += parseFloat(plan.plan.hours_assigned) * parseFloat(this.seat.rate.rate)
        }
      })
      return summary
    },
    json () {
      const plans = []
      this.openPlans.forEach(plan => {
        if (plan.period.closed_at === null) {
          plans.push({
            billing_period_id: plan.period.id,
            hours_assigned: (plan.seatPlan.hours_assigned) ? plan.seatPlan.hours_assigned : 0
          })
        }
      })
      const json = {
        plans: plans
      }
      return json
    }
  },
  data () {
    return {
      loadingSeat: true,
      planBy: 'hours',
      seat: null,
      periods: [],
      openPlans: [],
      closedPlans: [],
      showClosedPlans: false,
      saving: false,
      errors: {}
    }
  },
  methods: {
    loadSeat () {
      this.loadingSeat = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.$route.params.seat
      this.$http.get(endpoint).then(response => {
        this.seat = response.data
        this.seat.position.plans.forEach(plan => {
          if (plan.closed_at === null) {
            const index = this.openPlans.findIndex(element => element.period.id === plan.billing_period_id)
            if (index !== -1) {
              this.$set(this.openPlans[index], 'positionPlan', plan)
            }
          } else {
            const index = this.closedPlans.findIndex(element => element.period.id === plan.billing_period_id)
            if (index !== -1) {
              this.$set(this.closedPlans[index], 'positionPlan', plan)
            }
          }
        })
        this.seat.plans.forEach(plan => {
          if (plan.closed_at === null) {
            const index = this.openPlans.findIndex(element => element.period.id === plan.billing_period_id)
            if (index !== -1) {
              this.$set(this.openPlans[index], 'seatPlan', plan)
              if ('positionPlan' in this.openPlans[index]) {
                this.$set(this.openPlans[index], 'available', this.openPlans[index].positionPlan.hours_planned - this.openPlans[index].seatPlan.hours_assigned)
              } else {
                this.$set(this.openPlans[index], 'available', 0)
              }
            }
          }
        })
        this.loadingSeat = false
      })
    },
    loadBillingPeriods (refresh) {
      if (!refresh) this.loadingBillingPeriods = true
      const endpoint = this.api.base + 'billing-periods'
      const params = {
        params: {
          start: this.project.start_date,
          end: this.project.end_date
        }
      }
      this.$http.get(endpoint, params).then(response => {
        response.data.forEach(period => {
          const base = {
            period: period,
            seatPlan: {},
            positionPlan: {},
            availableHours: 0
          }
          if (period.closed_at === null) {
            this.openPlans.push(base)
          } else {
            this.closedPlans.push(base)
          }
        })
        this.loadingBillingPeriods = false
        this.loadSeat()
      })
    },
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id + '/plans'
      this.$http.put(endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Seat plans updated!' })
        this.$router.push({ name: 'network.project.detail', params: { code: this.context.code, id: this.project.id } })
      })
    },
    assignHours (index, row) {
      let value = 0
      if (row.positionPlan && this.openPlans[index]) {
        value = row.positionPlan.hours_planned - this.openPlans[index].seatPlan.hours_assigned
      }
      this.openPlans[index].seatPlan.hours_assigned = parseFloat(this.openPlans[index].seatPlan.hours_assigned) + parseFloat(value)
    }
  },
  mounted () {
    this.loadBillingPeriods()
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
