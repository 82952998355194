<template>
  <form @submit.prevent="update" novalidate autocomplete="off">
    <div class="columns">
      <div class="column is-one-fifth">
        <p class="has-text-weight-bold is-size-6 mb-sm">Profile</p>
        <p class="has-text-grey is-size-7">
          Customize your profile. Your profile will be visible to project
          leaders looking to fill opportunities.
        </p>
      </div>
      <div class="column">
        <div class="has-background-white rounded-xl">
          <div class="pa-lg pb-xl">
            <div>
              <div class="columns">
                <div class="column is-half">
                  <b-field
                    label="First Name"
                    :type="('first_name' in errors) ? 'is-danger' : ''"
                    :message="('first_name' in errors) ? errors['first_name'][0] : ''"
                    expanded>
                    <b-input
                      autocomplete="off"
                      type="text"
                      name="firstName"
                      v-model="firstName"
                      @input="isDirty = 1"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field
                    label="Last Name"
                    :type="('last_name' in errors) ? 'is-danger' : ''"
                    :message="('last_name' in errors) ? errors['last_name'][0] : ''"
                    expanded>
                    <b-input
                      autocomplete="off"
                      type="text"
                      name="lastName"
                      v-model="lastName"
                      @input="isDirty = 1"
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t">
            <div class="columns is-vcentered is-marginless">
              <div class="column">
                <div class="px-sm has-text-grey-light">
                  <i v-if="isDirty">Click 'Update' to save your changes</i>
                </div>
              </div>
              <div class="column is-narrow">
                <div class="px-sm">
                  <b-button native-type="submit" type="is-primary rounded-lg" :disabled="updating || !isDirty">Update</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['api']),
    endpoint () {
      return 'v1/profile'
    },
    json () {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        headline: this.headline
      }
    }
  },
  data () {
    return {
      loading: true,
      isDirty: false,
      firstName: null,
      lastName: null,
      headline: null,
      bio: null,
      skills: null,
      errors: [],
      updating: false
    }
  },
  methods: {
    update () {
      this.updating = true
      this.$http.patch(this.endpoint, this.json).then(response => {
        this.updating = false
        this.isDirty = false
        this.$emit('user:updated')
        this.$store.dispatch('setUser', response.data)
        this.$buefy.toast.open({ type: 'is-success', message: 'Profile updated!' })
      })
    }
  },
  mounted () {
    this.firstName = this.user.first_name
    this.lastName = this.user.last_name
    this.headline = this.user.headline
  },
  props: ['user']
}
</script>

<style>
.talltext { min-height: 200px !important; max-height: 500px !important}
</style>
