<template>
  <div>
    <h1 class="has-text-weight-bold has-border-b pb-sm">Onboarding Tasks</h1>
    <div>
      <!-- Tasks Loaded -->
      <div v-if="!loading && tasks.length > 0">
        <table class="table is-striped is-fullwidth is-size-7 is-narrow is-vcentered">
          <tbody>
            <tr v-for="task in tasks" :key="task.id">
              <td>{{ task.task.label }}</td>
              <td class="has-text-right">
                <!-- Incomplete Tasks -->
                <b-button
                  v-if="task.owner === 'network' && task.completed_at === null && task.skipped_at === null"
                  type="is-primary"
                  size="is-small"
                  outlined
                  @click="markComplete(task)"
                >Mark as Complete</b-button>
                <!-- Complete Tasks -->
                <p v-else-if="task.owner === 'network' && task.completed_at" class="has-text-weight-bold has-text-grey-success">Completed</p>
                <!-- Partner Tasks -->
                <p v-else class="has-text-weight-bold has-text-grey-light">Waiting on Partner</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Tasks Loaded (None) -->
      <div v-else-if="!loading && tasks.length === 0">
        <p class="has-text-centered">No tasks found!</p>
      </div>
      <!-- Loading -->
      <div v-else>
        <Loading message="Loading onboarding tasks..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/connections/' + this.$route.params.id + '/tasks'
    }
  },
  data () {
    return {
      loading: true,
      tasks: []
    }
  },
  methods: {
    load (refresh) {
      if (!refresh) {
        this.loading = true
      }
      this.$http.get(this.endpoint).then(response => {
        this.tasks = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    markComplete (task) {
      const endpoint = this.endpoint + '/' + task.id
      const json = {
        status: 'completed'
      }
      this.$http.patch(endpoint, json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Client added!' })
        this.load(true)
      })
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: {
    connection: {
      type: Object,
      default: null
    }
  }
}
</script>
