<template>
  <div>
    <div class="columns is-vcentered is-gapless week-wrapper">
      <div v-for="(day, index) in days" :key="index" class="column">
        <div v-if="day.date !== null" class="is-flex p-2 day-wrapper">
          <div class="mr-2 is-flex is-flex-direction-column is-align-items-center icons-container">
            <p class="has-text-weight-bold is-size-7" :class="day.disabled ? 'has-text-grey-light' : 'has-text-grey'">{{ day.day }}</p>
            <div v-if="!day.disabled">
              <b-tooltip label="Add a daily note" :delay="1000">
                <b-icon
                  @click.native="openNotesModal(day)"
                  icon="note-outline"
                  size="is-small"
                  :type="day.notes ? 'is-success' : ''" key=""/>
              </b-tooltip>
              <b-tooltip label="Mark day as 'Did Not Work'" :delay="1000" >
                <b-icon
                  @click.native="toggleIsUnavailable(day)"
                  icon="close-circle-outline"
                  size="is-small"
                  :type="day.is_unavailable ? 'is-primary' : ''"
                />
              </b-tooltip>
            </div>
          </div>
          <div class="p-2 is-flex is-align-items-center" :class="(day.is_weekend) ? 'weekend-container' : 'weekday-container'">
            <b-numberinput
              v-model="day.hours"
              min-step="0.5"
              :controls="false"
              :disabled="day.disabled || day.is_unavailable"
              @input="editActivities"
            />
          </div>
        </div>
        <div v-else></div>
      </div>
      <!-- Summary Row -->
      <div class="column">
        <div class="has-text-centered has-text-weight-bold">
          <p class="is-size-5" :class="temperature">{{ totalHours }}</p>
          <div v-if="forecast" class="is-size-8">
            <p class="is-size-8 has-text-grey-light">vs {{ forecast.hours }} allocated</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Notes -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showNotesModal"
      :can-cancel="['x', 'esc']">
      <NotesModal @notes:updated="updateNotes" :selected-day="selectedDay"/>
    </b-modal>
  </div>
</template>

<script>
import NotesModal from './NotesModal'

export default {
  components: {
    NotesModal
  },
  computed: {
    totalHours () {
      let total = 0
      this.days.forEach(day => {
        if ('hours' in day) {
          total += Number(day.hours)
        }
      })
      return total
    },
    forecast () {
      const forecast = this.$utils.find(this.forecasts, { week_id: this.week.id })
      if (forecast) {
        return forecast
      }
      return null
    },
    temperature () {
      if (this.forecast && this.totalHours) {
        if (this.forecast.hours > this.totalHours) return 'has-text-info'
        if (this.forecast.hours < this.totalHours) return 'has-text-warning'
        if (this.forecast.hours === this.totalHours) return 'has-text-success'
      }
      return null
    }
  },
  data () {
    return {
      showNotesModal: false,
      selectedDay: null,
      days: []
    }
  },
  methods: {
    buildDays () {
      this.days = []
      // start the week on monday
      const start = this.$dates(this.week.start_date).day()
      // pad the early days that might come before the month starts
      for (let i = 0; i < start; i++) {
        this.days.push({ date: null })
      }
      let current = this.$dates(this.week.start_date)
      const stop = this.$dates(this.week.end_date)
      while (current.isBefore(stop) || current.isSame(stop)) {
        // initialize day object
        const day = {
          date: current.format('YYYY-MM-DD'),
          month: current.format('MMM'),
          day: current.format('D'),
          notes: null,
          disabled: false,
          is_unavailable: false,
          is_weekend: Boolean(current.day() === 0 || current.day() === 6)
        }
        // check that the timesheet and period are not closed
        if (this.period.closed_at || this.timesheet.closed_at || this.timesheet.submitted_at || this.timesheet.approved_at) {
          day.disabled = true
        }
        // validate that the billing period is not in the future
        const now = this.$dates()
        if (now.isBefore(this.period.start_date, 'day')) {
          day.disabled = true
        }
        // check that the date is within the bounds of the seat dates
        const seatStart = this.$dates(this.timesheet.seat.position.project.start_date)
        const seatEnd = this.$dates(this.timesheet.seat.position.project.end_date)
        if (current.isBefore(seatStart) || current.isAfter(seatEnd)) {
          day.disabled = true
        }
        // populate an existing value
        const existing = this.$utils.find(this.timesheet.activities, { date: current.format('YYYY-MM-DD') })
        if (existing) {
          day.hours = (existing.hours_reported !== null) ? (Number(existing.hours_reported)) : null
          day.is_unavailable = existing.is_unavailable
          day.notes = existing.notes
        }
        // push the date
        this.days.push(day)
        current = current.add(1, 'day')
      }
      // pad the later days that might come after the month ends
      const empty = 7 - this.days.length
      for (let i = 0; i < empty; i++) {
        this.days.push({ date: null })
      }
      this.setActivities()
    },
    setActivities () {
      this.$emit('set:activity', this.days)
    },
    editActivities () {
      this.$emit('edit:activity', this.days)
    },
    toggleIsUnavailable (day) {
      day.is_unavailable = !day.is_unavailable
      day.hours = day.is_unavailable ? 0 : null
      this.editActivities()
    },
    openNotesModal (day) {
      this.selectedDay = day
      this.showNotesModal = true
    },
    updateNotes (notes) {
      this.selectedDay.notes = notes
      this.editActivities()
    }
  },
  mounted () {
    this.buildDays()
  },
  props: {
    timesheet: {
      type: Object,
      required: true
    },
    period: {
      type: Object,
      required: true
    },
    week: {
      type: Object,
      required: true
    },
    forecasts: {
      type: Array
    }
  },
  watch: {
    timesheet (value) {
      this.buildDays()
    },
    period (value) {
      this.buildDays()
    }
  }
}
</script>
<style lang="scss">
.week-wrapper {
  margin-top: -1px;
  margin-left: -1px;
}

.day-wrapper {
  outline: 1px solid lightgrey;
  margin-top: 1px;
  margin-left: 1px;

  .icons-container {
    width: 40px;

    .icon {
      color: #A3A4A5;
      cursor: pointer;
    }

    p {
      flex-grow: 1;
    }
  }

  .weekday-container {
    input {
      background-color: #F6F6F6 !important;
      border: none;
      font-size: 1.25rem;
      font-weight: bold;
    }

    height: 88px;
    background-color: #F6F6F6;
    border-radius: 0.5rem;
  }

  .weekend-container {
    input {
      background-color: #DADADA !important;
      border: none;
      font-size: 1.25rem;
      font-weight: bold;
    }

    height: 88px;
    background-color: #DADADA;
    border-radius: 0.5rem;
  }
}
</style>
