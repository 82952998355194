<template>
  <img src="https://res.cloudinary.com/gathertech/image/upload/v1658903825/platform/logos/gatherworks-logo-750px_xlth4n.png" :style="style" :height="height" alt="Logo" />
</template>

<script>
export default {
  computed: {
    color () {
      return (this.context === 'partner') ? '#fa4516' : '#fa4516'
    },
    style () {
      if (this.height) {
        return 'height: ' + this.height + 'px'
      }
      return null
    }
  },
  props: {
    width: {
      default: 375
    },
    height: {
      default: 91
    },
    context: {
      default: 'network'
    }
  }
}
</script>
