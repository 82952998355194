<template>
<div>
  <div>
    <!-- Allocation Warning (if not 100%) -->
    <div class="mb-md" v-if="seats.length > 0 && totalAllocated !== 50">
      <b-message type="is-warning">Please ensure you review your margin seat allocations!
      </b-message>
    </div>
    <!-- Toolbar -->
    <div class="has-background-white rounded-lg mb-xxs pa-md">
      <b-button type="is-primary mr-md" outlined @click="showMarginSeatModal = true">Add Margin Seat</b-button>
      <b-button
        outlined
        :disabled="loading || seats.length === 0"
        @click="showMarginAllocationModal = true"
      >Allocate Margin</b-button>
    </div>
    <!-- Has Margin Seats -->
    <div class="mb-3xl">
      <b-table
        :data="seats"
        :mobile-cards="false"
        :loading="loading"
        hoverable
        class="is-size-7">
        <b-table-column
          cell-class="nowrap"
          label="Margin Seat"
          v-slot="props">
          <a class="has-text-weight-bold" href="#" @click.prevent="editMarginSeat(props.row)">
            <p>{{ props.row.role.user.full_name }}</p>
          </a>
        </b-table-column>
        <b-table-column
          cell-class="nowrap"
          label="Rate"
          v-slot="props"
          width="96">
          <p>{{ $numbers(props.row.rate).format({ mantissa: 1 }) }}%</p>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered has-text-grey py-xxl">
            <p>No margin seats have been added yet</p>
            <p>Click or tap 'Add Margin Seat' to add the first margin seat.</p>
          </div>
        </template>
        <!-- <b-table-column
          cell-class="nowrap"
          label=""
          centered
          width="140"
          v-slot="props">
            <b-dropdown position="is-bottom-left" class="has-text-left" :append-to-body="true" >
              <template #trigger="{ active }">
                <b-button
                  label="Options"
                  type="is-primary"
                  size="is-small"
                  outlined
                  :icon-right="active ? 'menu-up' : 'menu-down'" />
              </template>
            <b-dropdown-item v-if="props.row.proposed_at === null" @click.prevent></b-dropdown-item>
          </b-dropdown>
        </b-table-column> -->
      </b-table>
    </div>
  </div>
  <!-- Modal: Edit Margin Seat -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showMarginSeatModal"
    :can-cancel="['x', 'esc']"
    @close="resetMarginSeat"
    ><MarginSeatModal :project="project" :seat="marginSeat" @seat:saved="refresh" />
  </b-modal>
  <!-- Modal: Edit Allocations -->
  <b-modal
    has-modal-card
    scroll="keep"
    :active.sync="showMarginAllocationModal"
    :can-cancel="['x', 'esc']"
    ><MarginAllocationModal :project="project" :marginSeats="seats" @seat:saved="refresh" />
  </b-modal>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MarginSeatModal from '@/components/Models/MarginSeat/MarginSeatModal'
import MarginAllocationModal from '@/components/Models/MarginSeat/MarginAllocationModal'

export default {
  components: {
    MarginSeatModal,
    MarginAllocationModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    endpoint () {
      return this.api.route + '/projects/' + this.project.id + '/margin-seats'
    },
    totalAllocated () {
      let allocation = 0
      this.seats.forEach(seat => {
        allocation += parseFloat(seat.rate)
      })
      return allocation
    }
  },
  data () {
    return {
      loading: true,
      seats: [],
      selected: [],
      marginSeat: null,
      showMarginSeatModal: false,
      showMarginAllocationModal: false
    }
  },
  methods: {
    load () {
      this.loading = true
      this.$http.get(this.endpoint).then(response => {
        this.seats = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    refresh () {
      this.load()
    },
    editMarginSeat (seat) {
      this.marginSeat = seat
      this.showMarginSeatModal = true
    },
    resetMarginSeat (seat) {
      this.marginSeat = null
      this.showMarginSeatModal = false
    }
  },
  mixins: [],
  mounted () {
    this.load()
  },
  props: ['project']
}
</script>
