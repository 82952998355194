<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- Client -->
        <b-field
          label="Client"
          :type="('client' in errors) ? 'is-danger' : ''"
          :message="('client' in errors) ? errors['client'][0] : ''"
          expanded>
          <ClientSelectMenu v-model="client"/>
        </b-field>
        <!-- Name -->
        <b-field
          label="Project Name"
          :type="('name' in errors) ? 'is-danger' : ''"
          :message="('name' in errors) ? errors['name'][0] : ''">
          <b-input
            size="is-medium"
            type="text"
            v-model="name"
            expanded
          ></b-input>
        </b-field>
        <!-- Budget -->
        <!-- <b-field
          label="Budget"
          :type="('estimate' in errors) ? 'is-danger' : ''"
          :message="('estimate' in errors) ? errors['estimate'][0] : ''">
          <b-input
            size="is-medium"
            type="number"
            v-model="estimate"
            :min="0"
            :disabled="project.started_at && Number(this.project.target_budget) > 0"
            :readonly="project.started_at && Number(this.project.target_budget) > 0"
            expanded
          ></b-input>
        </b-field> -->
      </section>
      <footer class="modal-card-foot space-between has-background-white">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientSelectMenu from '@/components/Models/Client/SelectMenu'

export default {
  components: {
    ClientSelectMenu
  },
  computed: {
    ...mapGetters(['api', 'context']),
    ready () {
      return (this.name && this.client)
    },
    json () {
      return {
        client_id: this.client.id,
        name: this.name,
        estimate: this.estimate
      }
    }
  },
  data () {
    return {
      client: null,
      name: null,
      estimate: null,
      planProjectBy: 'month',
      saving: false,
      errors: {}
    }
  },
  methods: {
    save () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id
      this.$http.patch(endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Project updated!' })
        this.$emit('project:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  mounted () {
    this.name = this.project.name
    this.client = this.project.client
    this.estimate = Number(this.project.target_budget) ? Number(this.project.target_budget).toFixed(2) : null
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>
