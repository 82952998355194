<template>
  <span class="has-text-weight-bold" :class="presenter.class">{{ presenter.label }}</span>
</template>

<script>
export default {
  computed: {
    presenter () {
      const presenter = {}
      if (this.announcement.status === 'published') {
        presenter.class = 'has-text-success'
        presenter.label = 'Published'
      } else {
        presenter.class = 'has-text-grey-light'
        presenter.label = 'Draft'
      }
      return presenter
    }
  },
  props: {
    announcement: {
      type: Object,
      required: true
    }
  }
}
</script>
