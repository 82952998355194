<template>
  <tr>
    <td>{{ seat.contributor.user.short_name }}</td>
    <td class="has-text-centered" style="width:25%">
      <span v-if="seat.allocations.length"><Hours :hours="seat.allocations[0].units" /></span>
      <span v-else class="has-text-grey-light">0.00</span>
    </td>
    <td class="has-text-centered" style="width:25%">
      <span v-if="seat.timesheets.length" :class="warningClass">
        <Hours :hours="seat.timesheets[0].hours_billable" />
      </span>
      <span v-else :class="warningClass">0.00</span>
    </td>
  </tr>
</template>

<script>
export default {
  computed: {
    hasAllocation () {
      return this.seat.allocations.length
    },
    hasTimesheet () {
      return this.seat.timesheets.length
    },
    warningClass () {
      if (this.hasAllocation && this.hasTimesheet) {
        if (Number(this.seat.allocations[0].units) < Number(this.seat.timesheets[0].hours_billable)) return 'has-text-warning'
        if (Number(this.seat.allocations[0].units) > Number(this.seat.timesheets[0].hours_billable)) return 'has-text-info'
      }
      if (this.hasAllocation && !this.hasTimesheet) {
        return 'has-text-danger'
      }
      if (!this.hasAllocation && !this.hasTimesheet) {
        return 'has-text-grey-light'
      }
      return null
    }
  },
  props: {
    seat: {
      type: Object,
      required: true
    }
  }
}
</script>
