<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ actionLabel }}</p>
      </header>
      <section class="modal-card-body">
        <div>
          <div v-if="action === 'activate'">
            <b-message type="is-success">
              If you activate this role, {{ seat.contributor.user.full_name }} will be able to
              see and contribute hours to this role. Are you sure you are ready to activate this role?
            </b-message>
          </div>
          <div v-if="action === 'pause'">
            <b-message type="is-warning">
              If you pause this role, {{ seat.contributor.user.full_name }} will no longer be able to
              see or contribute hours to this role. Are you sure your want to pause this role?
            </b-message>
          </div>
          <div v-else-if="action === 'restart'">
            <b-message type="is-warning">
              If you reactivate this role, {{ seat.contributor.user.full_name }} will be able to
              see and contribute hours to this role. Are you sure your want to restart this role?
            </b-message>
          </div>
          <div v-else-if="action === 'archive'">
            <b-message type="is-danger">
              Are you sure your want to remove this role?
            </b-message>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="saving"
        >{{ actionLabel }}</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    method () {
      switch (this.action) {
        case 'archive':
          return 'delete'
        default:
          return 'put'
      }
    },
    endpoint () {
      const base = this.api.route + '/projects/' + this.project.id + '/seats/' + this.seat.id
      switch (this.action) {
        case 'propose':
          return base + '/actions/propose'
        case 'activate':
          return base + '/actions/activate'
        case 'pause':
          return base + '/actions/pause'
        case 'restart':
          return base + '/actions/restart'
        default:
          return base
      }
    },
    actionLabel () {
      switch (this.action) {
        case 'propose':
          return 'Propose Role to Contributor?'
        case 'activate':
          return 'Activate Contributor Role?'
        case 'pause':
          return 'Pause Contributor Role?'
        case 'restart':
          return 'Restart Contributor Role?'
        case 'archive':
          return 'Remove Contributor Role?'
        default:
          return 'Edit Contributor'
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$parent.close()
        this.$buefy.toast.open({ type: 'is-success', message: 'Role saved!' })
        this.$emit('seat:saved')
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  data () {
    return {
      saving: false,
      errors: {}
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  }
}
</script>
