<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Integration</p>
      </header>
      <section class="modal-card-body">
        <div v-if="!loading">
          <!-- Category Selector -->
          <div class="mb-lg">
            <b-field label="Category">
              <b-select v-model="category" expanded>
                <option value="All">All</option>
                <option value="Accounting">Accounting</option>
                <option value="Procurement">Procurement</option>
              </b-select>
            </b-field>
          </div>
          <div class="columns is-vcentered is-multiline">
            <div v-for="platform in filteredPlatforms" :key="platform.code" class="column is-half">
              <div class="has-text-centered pa-md border">
                <p><img :src="platform.logo_url" width="180" height="60" style="width:180px; height:60px" /></p>
                <p>
                  <b-button
                    type="is-light"
                    expanded
                    @click="selectPlatform(platform)"
                  >Add</b-button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Loading />
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return Boolean(this.loadingPlatforms)
    },
    filteredPlatforms () {
      const filtered = []
      this.platforms.forEach(platform => {
        if (platform.context === 'any' || platform.context === this.context.type) {
          if (this.category === 'All') {
            filtered.push(platform)
          } else {
            if (platform.categories.search(this.category) !== -1) {
              filtered.push(platform)
            }
          }
        }
      })
      return filtered
    }
  },
  data () {
    return {
      loadingPlatforms: true,
      category: 'All',
      platforms: []
    }
  },
  methods: {
    loadPlatforms () {
      this.loadingPlatforms = true
      const endpoint = this.api.host + '/v1/platforms'
      this.$http.get(endpoint).then(response => {
        this.platforms = response.data
        this.loadingPlatforms = false
      })
    },
    selectPlatform (platform) {
      this.$emit('platform:selected', platform)
    }
  },
  mounted () {
    this.loadPlatforms()
  }
}
</script>
