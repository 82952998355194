<template>
  <div>
    <div>
      <div class="columns">
        <div class="column is-one-fifth">
          <p class="has-text-weight-bold is-size-6 mb-sm">Photo</p>
          <p class="has-text-grey is-size-7">
            Add a photo or image to your profile. For best results, use a square photo.
          </p>
        </div>
        <div class="column">
          <div class="has-background-white rounded-xl">
            <div class="pa-lg">
              <div class="columns is-vcentered">
                <div class="column is-narrow">
                  <div class="border pa-sm">
                    <cld-image v-if="user.photo_path" :publicId="user.photo_path" width="128" dpr="2.0" gravity="face" crop="fill" style="display:block" />
                    <b-skeleton v-else width="128" height="128" :animated="false" />
                  </div>
                </div>
                <div class="column is-narrow">
                  <a href="#" @click.prevent="showUploadPhotoModal = true">Upload Photo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Change Address -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showUploadPhotoModal"
      :can-cancel="['x', 'esc']"
      ><UploadUserPhotoModal :user="user" @user:updated="$emit('user:updated')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadUserPhotoModal from '@/components/Models/User/UploadPhoto'

export default {
  components: {
    UploadUserPhotoModal
  },
  computed: {
    ...mapGetters(['api', 'auth'])
  },
  data () {
    return {
      showUploadPhotoModal: false,
      saving: false,
      errors: []
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
