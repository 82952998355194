<template>
  <div>
    <div>
      <!-- Summary Row -->
      <div class="mb-md">
        <div class="columns is-vcentered is-mobile">
          <!-- Header -->
          <div class="column">
            <div class="columns is-vcentered is-mobile">
              <div class="column">
                <p class="has-text-weight-bold px-xs is-size-5">
                  <a href="#" @click.prevent="expanded = !expanded">{{ line.role }}</a>
                </p>
              </div>
            </div>
          </div>
          <!-- Manage -->
          <div class="column is-narrow">
            <div class="buttons">
              <AddOptionsMenu
                :projectId="project.id"
                :position="line"
                @position:seat="addSeat"
                @position:delivery="addDelivery"
                @position:expense="addExpense"
              />
              <OptionsMenu
                :projectId="project.id"
                :position="line"
                @position:edit="editLine"
                @position:activate="activatePosition"
                @position:pause="pausePosition"
                @position:restart="restartPosition"
                @position:archive="archivePosition"
                @position:allocate="allocatePosition"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Summary -->
      <div class="">
        <LineSummary :line="line" />
      </div>
      <!-- Detail Row -->
      <div v-if="expanded" class="columns is-vcentered">
        <div class="column">
          <!-- Seats Table -->
          <div >
            <div>
              <Seats v-if="line.seats.length && !allocateMode"
                :project="project"
                :line="line"
                :seats="line.seats"
                @seat:add="addSeat"
                @seat:saved="$emit('line:saved')"
              />
            </div>
            <div>
              <Deliveries v-if="line.deliveries.length && !allocateMode"
                :project="project"
                :line="line"
                :deliveries="line.deliveries"
                @delivery:add="addDelivery"
                @delivery:saved="$emit('line:saved')"
              />
            </div>
            <!-- <div v-if="line.expenses.length">
              <Expenses
                :project="project"
                :line="line"
                :expenses="line.expenses"
                @expense:add="addExpense"
                @expense:saved="$emit('line:saved')"
              />
            </div> -->
            <div v-if="!line.seats.length && !line.deliveries.length" class="py-lg mb-lg">
              <div class="is-size-5 has-text-grey-light has-text-centered pa-md mb-sm">
                <p>You're almost done setting up this budget.</p>
                <p>Next, add any number of contributor roles or deliverables!</p>
              </div>
              <div class="buttons is-flex is-justify-content-center">
                <b-button type="is-primary is-outlined" @click="showCreateSeatModal = true">Add Time &amp; Materials Role</b-button>
                <b-button type="is-primary is-outlined" @click="showCreateDeliveryModal = true">Add Fixed-Fee Deliverable</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal: Line Workflow -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showPositionWorkflowModal"
      :can-cancel="['x', 'esc']"
      ><UpdateLineStatusModal :project="project" :position="line" :action="focusAction" @position:saved="$emit('line:saved')" />
    </b-modal>
    <!-- Modal: Create Seat -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateSeatModal"
      :can-cancel="['x', 'esc']"
      @close="reset"
      ><CreateSeatModal :project="project" :position="line" @seat:saved="$emit('line:saved')" />
    </b-modal>
    <!-- Modal: Create Delivery -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateDeliveryModal"
      :can-cancel="['x', 'esc']"
      ><CreateDeliveryModal :project="project" :position="line" @delivery:saved="$emit('line:saved')" />
    </b-modal>
    <!-- Modal: Create Expense -->
    <b-modal
      has-modal-card
      scroll="keep"
      :active.sync="showCreateExpenseModal"
      :can-cancel="['x', 'esc']"
      ><CreateExpenseModal :project="project" :line="line" @expense:saved="$emit('line:saved')" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddOptionsMenu from '@/components/Models/Position/AddOptions'
import OptionsMenu from '@/components/Models/Position/Options'
import LineSummary from './LineSummary'
import Seats from './Seats'
import Deliveries from './Deliveries'
import UpdateLineStatusModal from '@/components/Models/Position/Modals/UpdateStatus'
import CreateSeatModal from '@/components/Models/Seat/Modals/Create'
import CreateDeliveryModal from '@/components/Models/Delivery/Modals/Create'
import CreateExpenseModal from '@/components/Models/Expense/Modals/Create'

export default {
  components: {
    LineSummary,
    AddOptionsMenu,
    OptionsMenu,
    Seats,
    Deliveries,
    UpdateLineStatusModal,
    CreateSeatModal,
    CreateDeliveryModal,
    CreateExpenseModal
  },
  computed: {
    ...mapGetters(['api', 'context']),
    rotateClass () {
      return (this.expanded) ? 'mdi-rotate-90' : 'null'
    },
    showChart () {
      return false
    }
  },
  data () {
    return {
      expanded: true,
      focusAction: null,
      allocateMode: false,
      showCreateSeatModal: false,
      showCreateDeliveryModal: false,
      showCreateExpenseModal: false,
      showPositionWorkflowModal: false
    }
  },
  methods: {
    addSeat () {
      this.showCreateSeatModal = true
    },
    addDelivery (line) {
      this.showCreateDeliveryModal = true
    },
    addExpense (line) {
      this.showCreateExpenseModal = true
    },
    editLine (line) {
      this.$emit('line:edit', line)
    },
    activatePosition () {
      this.focusAction = 'activate'
      this.showPositionWorkflowModal = true
    },
    allocatePosition () {
      this.$router.push({ name: 'network.project.allocate', params: { id: this.project.id } })
    },
    pausePosition (line) {
      this.focusAction = 'pause'
      this.showPositionWorkflowModal = true
    },
    restartPosition (position) {
      this.focusAction = 'restart'
      this.showPositionWorkflowModal = true
    },
    archivePosition (position) {
      this.focusAction = 'archive'
      this.showPositionWorkflowModal = true
    },
    reset () {
      this.showCreateSeatModal = false
      this.showCreateDeliveryModal = false
      this.showCreateExpenseModal = false
    }
  },
  mounted () {

  },
  props: {
    project: {
      type: Object,
      required: true
    },
    line: {
      type: Object,
      required: true
    }
  }
}
</script>
