<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <div>
          <!-- Reported Hours -->
          <div class="mb-lg">
            <div class="pb-sm border-b">
              <div class="columns is-mobile">
                <div class="column">
                  <p class="has-text-primary has-text-weight-bold">Time Entry Status</p>
                </div>
                <div class="column is-narrow">
                  <b-tag>Not Started</b-tag>
                </div>
              </div>
            </div>
            <!-- Summary -->
            <div class="mb-xl">
              <table class="table is-fullwidth is-size-7 is-narrow mb-sm">
                <tr>
                  <th>Client</th>
                  <td class="has-text-right">
                    {{ project.client.organization.name }}
                  </td>
                </tr>
                <tr>
                  <th>Project</th>
                  <td class="has-text-right">
                    {{ project.name }}
                  </td>
                </tr>
                <tr>
                  <th>Contributor</th>
                  <td class="has-text-right">
                    {{ seat.contributor.user.full_name }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <b-message type="is-info">
          <div class="has-text-centered py-md">
            <p class="mb-lg">
              {{ seat.contributor.user.full_name }} has not created a timesheet yet for this billing period. If this
              intentional, you may mark this role approved anyways.
            </p>
            <div class=" has-text-centered">
              <b-button type="is-primary" @click="createTimesheet">Approve Zero Time</b-button>
            </div>
          </div>
        </b-message>
      </section>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {

  },
  computed: {
    ...mapGetters(['api', 'context']),
    loading () {
      return this.loadingTimesheet
    }
  },
  data () {
    return {

    }
  },
  methods: {
    createTimesheet () {
      this.saving = true
      const endpoint = this.api.route + '/projects/' + this.project.id + '/timesheets'
      const json = {
        seat_id: this.seat.id,
        billing_period_id: this.period.id
      }
      this.$http.post(endpoint, json).then(response => {
        this.$emit('timesheet:saved')
        this.$buefy.toast.open({ type: 'is-success', message: 'Project saved!' })
        this.$parent.close()
      }).catch(error => {
        this.errors = (error.response.status === 422) ? error.response.data.errors : {}
        this.$buefy.toast.open({ type: 'is-danger', message: 'Timesheet could not be saved!' })
      }).finally(() => {
        this.saving = false
      })
    }
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    seat: {
      type: Object,
      required: true
    }
  }
}

</script>
