<template>
  <form @submit.prevent="save">
    <div class="modal-card">
      <section class="modal-card-body">
        <!-- Header -->
        <div class="mb-lg">
          <b-message type="is-info">
            <p class="is-size-5 has-text-weight-bold mb-sm">Add Fixed-Fee Delivery</p>
            <p class="is-size-7">
              Adding a <span class="has-text-weight-bold">fixed-fee delivery</span>
              allows you to add a one-time fixed fee that can be applied to a
              billing period once the work has been delivered.
            </p>
          </b-message>
        </div>
        <!-- Title -->
        <div class="mb-lg">
          <b-field
            label="Title"
            :type="('title' in errors) ? 'is-danger' : ''"
            :message="('title' in errors) ? errors['title'][0] : ''"
            expanded>
            <b-input
              size="is-medium"
              v-model="title"
            ></b-input>
          </b-field>
        </div>
        <!-- Cost -->
        <div class="mb-lg">
          <div class="columns">
            <div class="column">
              <b-field
                label="Partner Cost"
                :type="('cost' in errors) ? 'is-danger' : ''"
                :message="('cost' in errors) ? errors['cost'][0] : ''">
                <p class="control"><span class="button is-medium is-static">$</span></p>
                <b-input
                  type="number"
                  v-model="cost"
                  size="is-medium"
                  expanded
                ></b-input>
              </b-field>
            </div>
            <!-- Margin -->
            <div class="column is-one-quarter">
              <b-field
                label="Target Margin"
                :type="('margin' in errors) ? 'is-danger' : ''"
                :message="('margin' in errors) ? errors['margin'][0] : ''">
                <b-input
                  type="text"
                  v-model="margin"
                  size="is-medium"
                  expanded
                ></b-input>
                <p class="control"><span class="button is-medium is-static">%</span></p>
              </b-field>
            </div>
            <!-- Budget -->
            <div class="column">
              <b-field label="Billable">
                <b-input
                  type="text"
                  size="is-medium"
                  :value="$numbers(billableTotal).formatCurrency({ mantissa: 2, thousandSeparated: true })"
                  :disabled="true"
                  :readonly="true"
                  expanded
                ></b-input>
              </b-field>
            </div>
          </div>
        </div>
        <!-- Planned Delivery Date -->
        <div class="mb-lg">
          <b-field
            label="Planned Billing Period"
            :type="('billing_period_id' in errors) ? 'is-danger' : ''"
            :message="('billing_period_id' in errors) ? errors['billing_period_id'][0] : ''">
            <BillingPeriodSelect status="open" :end="project.end_date" v-model="billingPeriod" />
          </b-field>
        </div>
        <!-- Scheduled Date -->
        <div class="mb-lg">
          <b-field label="Planned Delivery Date (optional)">
            <b-input
              type="date"
              v-model="plannedDeliveryDate"
              size="is-medium"
              :value="$numbers(billableTotal).formatCurrency({ mantissa: 2, thousandSeparated: true })"
              expanded
            ></b-input>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot space-between">
        <button class="button" type="button" @click="$parent.close()">Close</button>
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="saving"
          :disabled="!ready || saving"
        >Save</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import BillingPeriodSelect from '@/components/Models/BillingPeriod/SelectMenu'

export default {
  components: {
    BillingPeriodSelect
  },
  data () {
    return {
      title: null,
      margin: null,
      cost: null,
      billingPeriod: null,
      plannedDeliveryDate: null,
      saving: false,
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['api']),
    method () {
      return 'post'
    },
    endpoint () {
      return this.api.route + '/projects/' + this.project.id + '/deliveries'
    },
    json () {
      return {
        position_id: this.position.id,
        title: this.title,
        description: this.description,
        billing_period_id: this.billingPeriod.id,
        planned_delivery_date: this.plannedDeliveryDate,
        cost: this.cost,
        billable_total: this.billableTotal
      }
    },
    ready () {
      return Boolean(
        this.title && this.cost
      )
    },
    billableTotal () {
      return this.cost / (1 - (this.margin / 100))
    }
  },
  methods: {
    save () {
      this.saving = true
      this.$http[this.method](this.endpoint, this.json).then(response => {
        this.$buefy.toast.open({ type: 'is-success', message: 'Delivery saved!' })
        this.$emit('delivery:saved')
        this.$parent.close()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$buefy.toast.open({ type: 'is-danger', message: 'Please review your input and try again.' })
      }).finally(() => {
        this.saving = false
      })
    },
    resetForm () {
      this.title = null
      this.margin = null
      this.cost = null
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    edit: {
      type: [Object, null]
    },
    source: {
      type: [Object, null]
    }
  },
  mounted () {
    if (this.edit) {
      this.title = this.edit.title
    } else if (this.source) {
      this.title = this.source.title
    }
  },
  watch: {

  }
}
</script>
